var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"formuser",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('CFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Nombre Completo"}},[_c('MTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Nombre Completo' : '',"dark":_vm.isMobile,"background":_vm.isMobile ? '' : 'white',"styleTextField":_vm.isMobile
                ? 'font-weight:300 !important'
                : '',"counter":50,"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => (`${value}`).length <= 50 || _vm.smsExecedCounter(50)
            ]},model:{value:(_vm.dataClient.full_name),callback:function ($$v) {_vm.$set(_vm.dataClient, "full_name", $$v)},expression:"dataClient.full_name"}})],1),_c('CFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Celular"}},[_c('MTextFieldComp',{attrs:{"dark":_vm.isMobile,"background":_vm.isMobile ? '' : 'white',"styleTextField":_vm.isMobile
                ? 'font-weight:300 !important'
                : '',"label":_vm.isMobile ? 'Celular' : '',"counter":15,"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => !_vm.mRHasSpaces(value) || _vm.smsWithSpaces,
                value => (`${value}`).length <= 15 || _vm.smsExecedCounter(15)
            ]},model:{value:(_vm.dataClient.phone),callback:function ($$v) {_vm.$set(_vm.dataClient, "phone", $$v)},expression:"dataClient.phone"}})],1),_c('CFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Correo GMAIL"}},[_c('MTextFieldComp',{attrs:{"dark":_vm.isMobile,"background":_vm.isMobile ? '' : 'white',"styleTextField":_vm.isMobile
                ? 'font-weight:300 !important'
                : '',"label":_vm.isMobile ? 'Correo GMAIL' : '',"counter":50,"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => !_vm.mRHasSpaces(value) || _vm.smsWithSpaces,
                value => _vm.mIsGmail(value) || 'Debe enviar un correo gmail',
                value => (`${value}`).length <= 50 || _vm.smsExecedCounter(50)
            ]},model:{value:(_vm.dataClient.email),callback:function ($$v) {_vm.$set(_vm.dataClient, "email", $$v)},expression:"dataClient.email"}})],1),_c('CFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Contraseña"}},[_c('MTextFieldComp',{attrs:{"dark":_vm.isMobile,"background":_vm.isMobile ? '' : 'white',"styleTextField":_vm.isMobile
                ? 'font-weight:300 !important'
                : '',"label":_vm.isMobile ? 'Contraseña' : '',"type":_vm.showPassword ? 'text' : 'password',"icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye',"counter":20,"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => !_vm.mRHasSpaces(value) || _vm.smsWithSpaces,
                value => (`${value}`).length >= 5 || _vm.smsMinCounter(5),
                value => (`${value}`).length <= 20 || _vm.smsExecedCounter(20)
            ]},on:{"clicksee":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.dataUser.password),callback:function ($$v) {_vm.$set(_vm.dataUser, "password", $$v)},expression:"dataUser.password"}})],1),_c('CFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Repita Contraseña"}},[_c('MTextFieldComp',{attrs:{"dark":_vm.isMobile,"background":_vm.isMobile ? '' : 'white',"styleTextField":_vm.isMobile
                ? 'font-weight:300 !important'
                : '',"label":_vm.isMobile ? 'Repita Contraseña' : '',"type":_vm.showPassword ? 'text' : 'password',"icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye',"counter":20,"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => !_vm.mRHasSpaces(value) || _vm.smsWithSpaces,
                value => (`${value}`).length >= 5 || _vm.smsMinCounter(5),
                value => !_vm.mRDifferentData(value, _vm.dataUser.password) || 'La contraseña no coincide',
                value => (`${value}`).length <= 20 || _vm.smsExecedCounter(20)
            ]},on:{"clicksee":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.dataUser.repit_password),callback:function ($$v) {_vm.$set(_vm.dataUser, "repit_password", $$v)},expression:"dataUser.repit_password"}})],1),_c('div',{staticClass:"d-flex justify-center mt-1"},[_c('PrMBtnNormalComp',{attrs:{"className":"cp-pu-color"},on:{"click":function($event){return _vm.eValidateForm()}}},[_c('v-icon',[_vm._v("mdi-bookmark-check")]),_vm._v(" REGISTRARME ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }