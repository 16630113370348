<template>
    <v-dialog
        persistent
        :value="value"
        width="600"
    >
        <v-card class="pa-0 ma-0 cs-pu-background">
            <div
                v-if="product.id != undefined"
                class="d-flex align-center pa-2 cf-pu-background"
            >
                <h1 class="pr-app-title-dark">
                    {{product.product.product}}
                </h1>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    x-small
                    depressed
                    color="red"
                    @click="$emit('clickclose')"
                >
                    <v-icon
                        class="pr-cd-color"
                    >mdi-close</v-icon>
                </v-btn>
            </div>
            <div
                v-if="product.id != undefined"
                class="pa-2 o-dialog-detail-product__body"
            >
                <div class="d-flex justify-center">
                    <CImageProductComp
                        :path="product.product.route_file"
                    />
                </div>
                <div class="o-dialog-detail-product__info" style="width:100%">
                    <v-card
                        outlined
                        dark
                        class="pa-2 mb-1"
                        width="100%"
                        v-for="(digital, index) in product.digitals"
                        :key="index"
                    >
                        <div class="d-flex align-center">
                            <div
                                :class="digital.this_slope === 0
                                    ? 'categories__enabled mr-1'
                                    : 'categories__disabled mr-1'"
                            ></div>
                            <h1 class="pu-app-title-dark">{{`Código N° ${(index+1)}`}}</h1>
                        </div>
                        <CFillInformationComp
                            title="Envío"
                            :subtitle="digital.this_slope === 1
                                ? 'Pendiente' : 'Entregado'"
                        />
                        <v-divider dark></v-divider>
                        <h1 class="pu-app-subtitle-dark">
                            {{digital.description != null
                                ? digital.description : "Sin instrucciones"}}
                        </h1>
                        <v-divider dark></v-divider>
                        <CFillInformationComp
                            v-if="digital.this_slope === 0"
                            title="Fecha expiración"
                            :subtitle="mDateFormat(digital.expiration)"
                        />
                        <CFillInformationComp
                            v-if="digital.this_slope === 0"
                            title="Código"
                            :subtitle="mCryDecryptText(digital.code)"
                        />
                    </v-card>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    CFillInformationComp,
    CImageProductComp
} from "../../cells";
import {
    datesMixin,
    cryptographyMixin
} from "../../../../mixins";
export default {
    mixins: [
        datesMixin,
        cryptographyMixin
    ],
    props: {
        product: Object,
        value: Boolean
    },
    components: {
        CFillInformationComp,
        CImageProductComp
    }
}
</script>