import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from "../store/modules";
class DigitalSale {
    static async getCodePendingSale(id)
    {
        const response = await axios.get(
            `${apiUrls.DIGITAL_SALE}codes-pending?id=${id}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async updateCodesPending(parameters)
    {
        const response = await axios.put(
            `${apiUrls.DIGITAL_SALE}update-codes-pending`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async keysProductSale(id, idProductSale)
    {
        const response = await axios.get(
            `${apiUrls.DIGITAL_SALE}keys-product-sale?id=${id}&id_product_sale=${idProductSale}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async getExpiredCodes(page)
    {
        const response = await axios.get(
            `${apiUrls.DIGITAL_SALE}expired-codes?page=${page}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async quantityExpiredCodes(header)
    {
        const response = await axios.get(
            `${apiUrls.DIGITAL_SALE}quantity-expired-codes`,
            { headers: header }
        );
        return response.data;
    }
}
export default DigitalSale;