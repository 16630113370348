<template>
    <div>
        <div class="d-flex align-center pa-2">
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    v-if="!isMobile"
                    :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                    :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                    class="mr-2"
                    @click="$emit('clickmini')"
                />
                <h1 class="pr-app-title">
                    CIERRE DE CAJA
                </h1>
            </div>
            <v-spacer></v-spacer>
            <PrCBtnToolTipHeaderComp
                icon="mdi-calculator"
                :smsToolTip="calculatorIsOpen ? 'Cerrar Calculadora' : 'Abrir Calculadora'"
                @click="calculatorIsOpen = !calculatorIsOpen"
            />
        </div>
        <PrMLineHeaderComp />
        <div class="d-flex justify-center flex-wrap pa-2">
            <div class="pr-products__search">
                <div class="pr-products__search-block">
                    <PrMSelectComp
                        label="Usuario"
                        v-model="idUser"
                        :items="users"
                        itemValue="id"
                        background=""
                        itemText="full_name"
                        :hideDetails="true"
                        @change="eChangeUser"
                    />
                </div>
                <div class="pr-products__search-block">
                    <PrMSelectComp
                        label="Lugar Venta"
                        :items="optionsTypeSale"
                        itemValue="value"
                        itemText="text"
                        background=""
                        v-model="valueTypeSale"
                        :hideDetails="true"
                        @change="eChangeTypeSale"
                    />
                </div>
            </div>
        </div>
        <v-divider></v-divider>
        <PrCLoaderDataComp
            v-if="dialogLoaderDataIsOpen"
            class="mt-2"
        />
        <div
            v-if="infoCloseBox.total_sale_enabled != undefined && !dialogLoaderDataIsOpen"
            class="pa-2 pt-1 pb-0"
        >
            <PrCFillInformationComp
                title="Total Ventas"
                :subtitle="infoCloseBox.total_sale_enabled.toFixed(2)"
            />
            <PrCFillInformationComp
                title="Total Ventas Anuladas"
                :subtitle="infoCloseBox.total_sale_disabled.toFixed(2)"
            />
        </div>
        <PrOCalculatorComp
            :calculatorIsOpen="calculatorIsOpen"
        />
        <div v-if="!isCloseBox">
            <v-form
                v-model="valid"
                lazy-validation
                ref="formopenbox"
            >
                <PrOTableFormCloseBoxComp
                    v-if="!dialogLoaderDataIsOpen && !isMobile"
                    :listCloseBox="listCloseBox"
                />
                <PrMWithoutInfoCardsComp
                    v-if="!dialogLoaderDataIsOpen && listCloseBox.length===0 && isMobile"
                    class="ma-2"
                />
                <div
                    v-if="!dialogLoaderDataIsOpen && isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardFormCloseBoxComp
                        class="ma-2"
                        v-for="data in listCloseBox"
                        :key="data.id"
                        :data="data"
                    />
                </div>
                <div
                    v-if="listCloseBox.length > 0"
                    class="d-flex justify-center mt-1"
                >
                    <PrMBtnNormalComp
                        @click="eValidateForm()"
                    >
                        <v-icon>mdi-bookmark-check</v-icon>
                        GUARDAR
                    </PrMBtnNormalComp>
                </div>
            </v-form>
        </div>
        <div v-else>
            <h1 class="text-center pr-app-subtitle pa-2">
                !!! SE REGISTRÓ EL CIERRE DE CAJA
            </h1>
        </div>
        <PrOGeneralDialogsComp
            :dialogErrorIsOpen="dialogErrorIsOpen"
            :smsErrors="smsErrors"
            :actionErrorIsActivated="actionErrorIsActivated"
            @clickerror="eCloseError()"
            :dialogConfirmationIsOpen="dialogConfirmationIsOpen"
            :smsConfirmations="smsConfirmations"
            @clickyes="eSaveCloseBox()"
            @clickno="dialogConfirmationIsOpen = false"
            :smsActions="smsActions"
            :frameAction="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
            :dialogOperationIsOpen="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrCBtnToolTipHeaderComp,
    PrCLoaderDataComp,
    PrCFillInformationComp
} from "../../components/components_private/cells";
import {
    PrMLineHeaderComp,
    PrMSelectComp,
    PrMBtnNormalComp
} from "../../components/components_private/molecules";
import {
    PrOGeneralDialogsComp,
    PrOCalculatorComp,
    PrOTableFormCloseBoxComp,
    PrOCardFormCloseBoxComp
} from "../../components/components_private/organisms";
import {
    responseServerMixin,
    datesMixin,
    userMixin,
    dataGeneralCompsMixin,
    animationScrollMixin
} from "../../mixins";
import {
    Sale,
    User,
    CloseBox
} from "../../models";
export default {
    mixins: [
        responseServerMixin,
        datesMixin,
        userMixin,
        dataGeneralCompsMixin,
        animationScrollMixin
    ],
    props: {
        mini: Boolean
    },
    components: {
        PrMBtnNormalComp,
        PrCBtnToolTipHeaderComp,
        PrMLineHeaderComp,
        PrCLoaderDataComp,
        PrMSelectComp,
        PrCFillInformationComp,
        PrOGeneralDialogsComp,
        PrOCalculatorComp,
        PrOTableFormCloseBoxComp,
        PrOCardFormCloseBoxComp
    },
    data: () => ({
        infoCloseBox: {},
        idUser: 0,
        users: [
            { id: 0, full_name: "Todos"}
        ],
        valueTypeSale: "t",
        optionsTypeSale: [
            { value: "t", text: "Todos" },
            { value: "s", text: "Tienda" },
            { value: "e", text: "Página Web" },
            { value: "p", text: "Personal" }
        ],
        calculatorIsOpen: false,
        listCloseBox: [],
        isCloseBox: false,
        valid: true
    }),
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        async getRequest() {
            this.dialogLoaderDataIsOpen = true;
            try {
                const users = User.allEmployees();
                const infoCloseBox = Sale.closeBox(0, "t");
                const rankings = CloseBox.listOpenClox();
                const responses = await Promise.all([users, infoCloseBox, rankings]);
                responses[0].forEach(element => {
                    element.full_name = element.employee.full_name;
                    this.users.push(element);
                });
                this.infoCloseBox = responses[1];
                responses[2].forEach(element => {
                    this.listCloseBox.push({
                        id: element.id,
                        finished: null,
                        name: element.ranking.name
                    });
                });
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async getCloseBox(idUser, typeSale) {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Sale.closeBox(idUser, typeSale);
                this.infoCloseBox = response;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eConfirmForm(isValidForm) {
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                if (isValidForm) {
                    this.smsConfirmations = [`¿Está seguro(a) de registrar el cierre de caja?`];
                    this.dialogConfirmationIsOpen = true;
                } else this.mGDShowError();
            }, time);
        },
        eChangeUser(idUser) {
            this.getCloseBox(idUser, this.valueTypeSale);
        },
        eChangeTypeSale(typeSale) {
            this.getCloseBox(this.idUser, typeSale);
        },
        eValidateForm() {
            if (!this.$refs.formopenbox.validate()) this.mGDShowError();
            else  {
                this.smsConfirmations = ["¿Está seguro de cerrar caja el día de hoy?"];
                this.dialogConfirmationIsOpen = true;
            }
        },
        async eSaveCloseBox() {
            this.dialogConfirmationIsOpen = false;
            this.dialogOperationIsOpen = true;
            try {
                await CloseBox.closeBox({detail: this.listCloseBox});
                this.isCloseBox = true;
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se cerró caja"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            this.dateOne = this.dateTwo = this.mDateSystem(false);
            if (this.mUserCategoryEmployee() === 'a')
                this.getRequest();
            else this.$router.replace('/admin/home');
        } else this.$router.replace('/login-admin');
    }
}
</script>