<template>
    <div class="pr-o-table-categories animation-opacity">
        <table class="pr-o-table-categories__table">
            <thead>
                <tr>
                    <th>
                        <h1 class="pr-app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Cliente</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Celular</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Producto</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Imágen</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Fecha Compra</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Fecha Expiración</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(code, index) in codes"
                    :key="code.id"
                >
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{(index + 1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{code.product_sale.sale.client.full_name}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{code.product_sale.sale.client.phone != null
                                ? code.product_sale.sale.client.phone
                                : "S/N"}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{code.product_sale.product.product}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex justify-center">
                            <PrCImageTableComp
                                :path="code.product_sale.product.route_file"
                            />
                        </div>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{code.product_sale.sale.date}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex align-center">
                            <h1 class="pr-app-subtitle">
                                {{mDateFormat(code.expiration)}}
                            </h1>
                            <div
                                :class="code.expired
                                    ? 'categories__disabled ml-1'
                                    : 'categories__enabled ml-1'"
                                style="margin-top:-2px"    
                            ></div>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex justify-center align-center flex-wrap">
                            <PrCBtnToolTipTableComp
                                icon="mdi-format-list-bulleted-square"
                                :smsToolTip="`Ver detalle: ${code.product_sale.product.product}`"
                                @click="$emit('clickdetail', code)"
                            />
                            <PrCBtnToolTipTableComp
                                icon="mdi-whatsapp"
                                :smsToolTip="`Enviar whatsapp a: ${code.product_sale.sale.client.full_name}`"
                                @click="$emit('clickwhatsapp', code)"
                            />
                            <PrCBtnToolTipTableComp
                                icon="mdi-email"
                                :smsToolTip="`Enviar mensaje a: ${code.product_sale.sale.client.full_name}`"
                                @click="$emit('clickmail', code)"
                            />
                        </div>
                    </td>
                </tr>
                <tr v-if="codes.length === 0">
                    <td colspan="8">
                        <h1 class="pr-app-subtitle text-center">Sin Información</h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    PrCBtnToolTipTableComp,
    PrCImageTableComp
} from "../../cells";
import {
    datesMixin
} from "../../../../mixins";
export default {
    mixins: [
        datesMixin
    ],
    props: {
        codes: Array
    },
    components: {
        PrCBtnToolTipTableComp,
        PrCImageTableComp
    }
}
</script>