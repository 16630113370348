import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from "../store/modules";
import { userClientStore } from "../store/modules";
class User {
    constructor(
        type_user = 'a',
        username = null,
        password = null,
        repit_password = null
    ) {
        this.type_user = type_user;
        this.username = username;
        this.password = password;
        this.repit_password = repit_password;
    }
    static async getUsersEmployees() {
        const response = await axios.get(
            `${apiUrls.USER}list-employees`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async getUsersClients(page, search) {
        const response = await axios.get(
            `${apiUrls.USER}list-clients?page=${page}&search=${search}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addUserEmployee(parameters) {
        const response = await axios.post(
            `${apiUrls.USER}add-user-employee`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async updateUser(parameters) {
        const response = await axios.put(
            `${apiUrls.USER}change-credentials`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async changeState(parameters) {
        const response = await axios.patch(
            `${apiUrls.USER}change-state`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async logoutEmployee() {
        const response = await axios.post(
            `${apiUrls.USER}logout-employee`,
            {},
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async allEmployees() {
        const response = await axios.get(
            `${apiUrls.USER}all-employees`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    //peticiones cliente
    static async changePassword(parameters) {
        const response = await axios.put(
            `${apiUrls.USER}change-password`,
            parameters,
            { headers: userClientStore.state.header }
        );
        return response.data;
    }
    static async loginClient(parameters) {
        const response = await axios.post(
            `${apiUrls.USER}login-client`,
            parameters
        );
        return response.data;
    }
    static async logoutClient() {
        const response = await axios.post(
            `${apiUrls.USER}logout-client`,
            {},
            { headers: userClientStore.state.header }
        );
        return response.data;
    }
    static async checkScreenEmail() {
        const response = await axios.get(
            `${apiUrls.USER}check-screem-email`,
            { headers: userClientStore.state.header }
        );
        return response.data;
    }
    static async sendEmail() {
        const response = await axios.post(
            `${apiUrls.USER}send-email`,
            {},
            { headers: userClientStore.state.header }
        );
        return response.data;
    }
    //no requieren token
    static async loginEmployee(parameters) {
        const response = await axios.post(
            `${apiUrls.USER}login-employee`,
            parameters
        );
        return response.data;
    }
    static async addUserClient(parameters) {
        const response = await axios.post(
            `${apiUrls.USER}register-client`,
            parameters
        );
        return response.data;
    }
}
export default User;