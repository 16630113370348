<template>
    <div>
        <div class="o-footer">
            <div class="o-footer__body">
                <div class="o-footer__body-group">
                    <div>
                        <div class="d-flex align-center">
                            <v-img
                                src="/imgs_public/logos/logo_o.svg"
                                width="30px"
                                max-width="30px"
                            ></v-img>
                            <h1 class="pu-app-title-dark ml-2">GATONET</h1>
                        </div>
                        <div class="o-footer__body-google-map">
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3771.4599646938336!2d-65.25813769999999!3d-19.043504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x93fbcf42d4564575%3A0x28954abe6370881c!2sGATONET%20recargas%20de%20videojuegos%20y%20streaming!5e0!3m2!1ses!2sbo!4v1675092854469!5m2!1ses!2sbo"
                                width="250"
                                height="250"
                                style="border:0;"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>
                    </div>
                    <div class="o-footer__body-info">
                        <CFillInformationComp
                            class="mb-2"
                            v-for="(info, index) in listInfo"
                            :key="index"
                            :title="info.title"
                            :subtitle="info.subtitle"
                        />
                    </div>
                </div>
                <div class="o-footer__body-group">
                    <div class="o-footer__body-links">
                        <router-link
                            class="o-menu-public-pc__items mb-2"
                            to="/"
                        >
                            <v-icon class="cp-pu-color">mdi-home</v-icon>
                            <h1 class="pu-app-subtitle-dark ml-1">Inicio</h1>
                            <div class="o-menu-public-pc__items-line"></div>
                        </router-link>
                        <router-link
                            class="o-menu-public-pc__items mb-2"
                            v-for="(option, index) in menu"
                            :key="index"
                            :to="option.route"
                        >
                            <v-icon class="cp-pu-color">{{option.icon}}</v-icon>
                            <h1 class="pu-app-subtitle-dark ml-1">{{option.title}}</h1>
                            <div class="o-menu-public-pc__items-line"></div>
                        </router-link>
                    </div>
                    <div class="o-footer__body-social-media">
                        <div
                            v-for="(option, index) in listSocialMedia"
                            :key="index"
                            class="d-flex align-center mb-3"
                            @click="$emit('clicksocialmedia', option)"
                            style="cursor:pointer"
                        >
                            <v-card
                                elevation="0"
                                :width="isMobile ? 20 : 30"
                                :height="isMobile ? 20 : 30"
                                class="rounded-0"
                                :style="isMobile ? 'opacity:0.85' : ''"
                                color="transparent"
                            >
                                <v-img
                                    :src="`/imgs_public/mobile/${option.icon}`"
                                    width="100%"
                                    height="100%"
                                    contain
                                ></v-img>
                            </v-card>
                            <h1 class="pu-app-subtitle-dark ml-2">{{option.title}}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="o-footer__important">
            <router-link to="/terms-use">
                <h1 class="pu-app-title-dark">Politicas de Uso</h1>
            </router-link>
            <router-link to="/terms-use" class="ml-2">
                <h1 class="pu-app-title-dark">Condiciones de Uso</h1>
            </router-link>
        </div>-->
    </div>
</template>
<script>
import {
    CFillInformationComp
} from "../../cells";
export default {
    components: {
        CFillInformationComp
    },
    props: {
        menu: Array,
        setting: Object,
        listSocialMedia: Array,
        listInfo: Array
    },
    data: () => ({
        isMobile: false
    }),
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
    }
}
</script>