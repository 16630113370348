<template>
    <div>
        <div class="categories__header pa-2">
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    v-if="!isMobile"
                    :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                    :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                    class="mr-2"
                    @click="$emit('clickmini')"
                />
                <h1 class="pr-app-title">
                    {{`RECOMENDACIONES (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="categories__header-search">
                <v-card
                    width="100%"
                    height="32"
                    outlined
                    :ripple="false"
                    elevation="0"
                    class="mr-1"
                    @click="dialogProductSearch = true"
                >
                    <h1 class="pr-app-subtitle text-center mt-1">
                        Elegir Producto
                    </h1>
                </v-card>
            </div>
        </div>
        <PrMLineHeaderComp />
        <PrMWithoutInfoCardsComp
            v-if="!dialogLoaderDataIsOpen && recommendations.length === 0 && isMobile"
            class="ma-2"
        />
        <PrCLoaderDataComp
            v-if="dialogLoaderDataIsOpen"
            class="mt-2"
        />
        <PrOTRecommendationsComp
            v-if="!dialogLoaderDataIsOpen && !isMobile"
            :recommendations="recommendations"
            @clickdetail="eShowDetailProduct"
            @clickdelete="eConfirmDelete"
        />
        <div
            v-if="!dialogLoaderDataIsOpen && isMobile"
            class="d-flex justify-center flex-wrap"
        >
            <PrOCardRecommendationComp
                class="ma-2"
                v-for="recommendation in recommendations"
                :key="recommendation.id"
                :recommendation="recommendation"
                @clickdetail="eShowDetailProduct(recommendation)"
                @clickdelete="eConfirmDelete(recommendation)"
            />
        </div>
        <PrSSearchProductComp
            ref="searchproductcomp"
            :value="dialogProductSearch"
            @clickproduct="eConfirmAddProduct"
            :isSelect="true"
            @clickclose="eCloseDialogProducts"
        />
        <PrSDialogDetailProductComp
            :value="dialogDetailIsOpen"
            :product="product"
            :categorieUser="categorieUser"
            @clickclose="dialogDetailIsOpen = false"
        />
        <PrOGeneralDialogsComp
            :dialogErrorIsOpen="dialogErrorIsOpen"
            :smsErrors="smsErrors"
            :actionErrorIsActivated="actionErrorIsActivated"
            @clickerror="eCloseError()"
            :dialogConfirmationIsOpen="dialogConfirmationIsOpen"
            :smsConfirmations="smsConfirmations"
            @clickyes="eProcessOperation()"
            @clickno="dialogConfirmationIsOpen = false"
            :smsActions="smsActions"
            :frameAction="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
            :dialogOperationIsOpen="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMWithoutInfoCardsComp,
    PrMBtnNormalComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCFillInformationComp,
    PrCLoaderDataComp
} from "../../components/components_private/cells";
import {
    PrOGeneralDialogsComp,
    PrOTRecommendationsComp,
    PrOCardRecommendationComp
} from "../../components/components_private/organisms";
import {
    PrESkeletonFormComp,
    PrSSearchProductComp,
    PrSDialogDetailProductComp
} from "../../components/components_private/species";
import {
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin
} from "../../mixins";
import {
    Recommendation
} from "../../models";
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin
    ],
    components: {
        PrCBtnToolTipHeaderComp,
        PrMLineHeaderComp,
        PrCFillInformationComp,
        PrMWithoutInfoCardsComp,
        PrCLoaderDataComp,
        PrMBtnNormalComp,
        PrESkeletonFormComp,
        PrOGeneralDialogsComp,
        PrOTRecommendationsComp,
        PrSSearchProductComp,
        PrSDialogDetailProductComp,
        PrOCardRecommendationComp
    },
    data: () => ({
        dialogProductSearch: false,
        recommendations: [],
        product: {},
        dataRecommendation: new Recommendation,
        dialogDetailIsOpen: false,
        indexRecommendation: -1,
        idRecommendation: -1,
        numberOperation: 0,
        categorieUser: ""
    }),
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        async getRecommendations() {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Recommendation.getRecommendations();
                this.recommendations = response;
                this.total = response.length;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eCloseDialogProducts() {
            this.$refs.searchproductcomp.resetData();
            this.dialogProductSearch = false;
        },
        eConfirmAddProduct(product) {
            this.numberOperation = 0;
            this.dataRecommendation.fk_product = product.id;
            this.dialogProductSearch = false;
            this.$refs.searchproductcomp.resetData();
            this.product = product;
            this.smsConfirmations = [`¿Está seguro de registrar como recomendación el producto: ${product.product}?`]
            this.dialogConfirmationIsOpen = true;
        },
        async addRecommendation() {
            try {
                const response = await Recommendation.addRecommendation(this.dataRecommendation)
                this.recommendations.unshift(response);
                this.total += 1;
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se registró la recomendación"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async deleteRecommendation() {
            try {
                await Recommendation.deleteRecommendation({id: this.idRecommendation});
                this.recommendations.splice(this.indexRecommendation, 1);
                this.total -= 1;
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se eliminó la recomendación"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eProcessOperation() {
            this.dialogConfirmationIsOpen = false;
            this.dialogOperationIsOpen = true;
            switch (this.numberOperation) {
                case 0:
                    this.addRecommendation();
                    break;
                case 1:
                    this.deleteRecommendation();
                    break;
            }
        },
        eShowDetailProduct(recommendation) {
            this.product = recommendation.product;
            this.dialogDetailIsOpen = true;
        },
        eConfirmDelete(recommendation) {
            this.numberOperation = 1;
            this.indexRecommendation = this.recommendations.indexOf(recommendation);
            this.idRecommendation = recommendation.id;
            this.smsConfirmations = [`¿Está seguro de eliminar la recomendación del producto ${recommendation.product.product}?`]
            this.dialogConfirmationIsOpen = true;
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            this.categorieUser = this.mUserCategoryEmployee();
            if (this.mUserCategoryEmployee() === 'a') this.getRecommendations();
            else this.$router.replace('/admin/home');
        } else this.$router.replace('/login-admin');
    }
}
</script>