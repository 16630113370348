import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from "../store/modules";
class Reserve {
    constructor(
        name = null,
        sald = null
    ) {
        this.name = name;
        this.sald = sald;
    }
    static async getBalances() {
        const response = await axios.get(
            `${apiUrls.RESERVE}list`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addBalance(parameters) {
        const response = await axios.post(
            `${apiUrls.RESERVE}add`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async editBalance(parameters) {
        const response = await axios.put(
            `${apiUrls.RESERVE}edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addSaldBalance(parameters) {
        const response = await axios.put(
            `${apiUrls.RESERVE}add-sald`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default Reserve;