<template>
    <v-form
        v-model="valid"
        lazy-validation
        ref="formsetting"
    >
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Dirección"
        >
            <PrMTextFieldComp
                v-model="dataSetting.address"
                :label="isMobile ? 'Dirección' : ''"
                :counter="50"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => (`${value}`).length <= 50 || smsExecedCounter(50)
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Correo Gmail"
        >
            <PrMTextFieldComp
                v-model="dataSetting.email"
                :label="isMobile ? 'Correo Gmail' : ''"
                :counter="70"
                :rules="[
                    value => !this.mRItIsEmpty(value) || smsEmpty,
                    value => !this.mRHasSpaces(value) || smsWithSpaces,
                    value => this.mIsGmail(value) || 'Debe enviar un correo gmail',
                    value => (`${value}`).length <= 70 || smsExecedCounter(70)
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Celular"
        >
            <PrMTextFieldComp
                v-model="dataSetting.phone"
                :label="isMobile ? 'Celular' : ''"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => mRIsInteger(value) || smsNotInteger
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Facebook"
        >
            <PrMTextFieldComp
                v-model="dataSetting.facebook"
                :label="isMobile ? 'Facebook' : ''"
                :counter="100"
                :rules="[
                    value => (`${value}`).length <= 100 || smsExecedCounter(100)
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Instagram"
        >
            <PrMTextFieldComp
                v-model="dataSetting.instagram"
                :label="isMobile ? 'Instagram' : ''"
                :counter="100"
                :rules="[
                    value => (`${value}`).length <= 100 || smsExecedCounter(100)
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Tiktok"
        >
            <PrMTextFieldComp
                v-model="dataSetting.tik_tock"
                :label="isMobile ? 'Tiktok' : ''"
                :counter="100"
                :rules="[
                    value => (`${value}`).length <= 100 || smsExecedCounter(100)
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Cambio Dolar"
        >
            <PrMTextFieldComp
                v-model="dataSetting.change_dollar"
                :label="isMobile ? 'Cambio Dolar' : ''"
                :rules="[
                    value => !this.mRItIsEmpty(value) || smsEmpty,
                    value => this.mRIsNumeric(value) || smsNotNumeric
                ]"
            />
        </PrCFillFormComp>
        <div class="d-flex justify-center mt-1">
            <PrMBtnNormalComp
                @click="eValidateForm()"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                GUARDAR
            </PrMBtnNormalComp>
        </div>
    </v-form>
</template>
<script>
import {
    PrMTextFieldComp,
    PrMBtnNormalComp,
    PrMSelectComp
} from "../../molecules";
import {
    PrCFillFormComp
} from "../../cells";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        PrMTextFieldComp,
        PrCFillFormComp,
        PrMBtnNormalComp,
        PrMSelectComp
    },
    props: {
        dataSetting: Object,
        isMobile: Boolean
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidateForm() {
            if (this.$refs.formsetting.validate())
                this.$emit('clickform', true);
            else this.$emit('clickform', false);
        },
        resetForm() {
            this.$refs.formsetting.resetValidation();
        }
    }
}
</script>