import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from "../store/modules";
class Ranking {
    constructor(
        name = null
    ) {
        this.name = name;
    }
    static async getRankings() {
        const response = await axios.get(
            `${apiUrls.RANKING}list`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addRankings(parameters) {
        const response = await axios.post(
            `${apiUrls.RANKING}add`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async editRankings(parameters) {
        const response = await axios.put(
            `${apiUrls.RANKING}edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async deleteRanking(parameters) {
        const response = await axios.delete(
            `${apiUrls.RANKING}delete`,
            { data: parameters, headers: userStore.state.header }
        );
        return response.data;
    }
}
export default Ranking;