<template>
    <v-dialog
        persistent
        :value="value"
        width="450"
    >
        <v-card class="pa-0 ma-0">
            <div class="d-flex align-center pa-2 pr-cp-background">
                <h1 class="pr-app-title-dark">Buscar Producto</h1>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    x-small
                    depressed
                    color="red"
                    @click="$emit('clickclose')"
                >
                    <v-icon
                        class="pr-cd-color"
                    >mdi-close</v-icon>
                </v-btn>
            </div>
            <div class="pa-2">
                <PrOSearchHeaderComp
                    label="Buscar Producto"
                    v-model="search"
                    @entersearch="eValidSearch()"
                />
                <v-card
                    width="100%"
                    class="rounded-0 pr-cp-background mt-2"
                    elevation="0"
                    height="10"
                ></v-card>
                <v-card
                    elevation="0"
                    width="100%"
                    min-height="200"
                >
                    <PrCLoaderDataComp
                        v-if="dialogLoaderDataIsOpen"
                        class="mt-2"
                    />
                    <PrMWithoutInfoCardsComp
                        v-if="!dialogLoaderDataIsOpen && products.length===0"
                        :title="title"
                        class="mt-2"
                    />
                    <div v-if="!dialogLoaderDataIsOpen">
                        <div
                            v-for="product in products"
                            :key="product.id"
                            class="mt-2 animation-opacity"
                        >
                            <v-hover
                                v-slot="{ hover }"
                            >
                                <v-card
                                    :color="!hover ? 'transparent' : 'rgba(0,0,0,0.05)'"
                                    class="pa-1"
                                    :ripple="false"
                                    width="100%"
                                    @click="$emit('clickproduct', product)"
                                >
                                    <text-highlight
                                        class="pr-app-title"
                                        :queries="searchFilter"
                                    >
                                        {{product.product}}
                                    </text-highlight>
                                    <h1 class="pr-app-subtitle">{{product.description}}</h1>
                                </v-card>
                            </v-hover>
                        </div>
                    </div>
                </v-card>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    PrMWithoutInfoCardsComp
} from "../molecules";
import {
    PrCLoaderDataComp
} from "../cells";
import {
    PrOSearchHeaderComp
} from "../organisms";
import {
    Product
} from "../../../models";
import TextHighlight from 'vue-text-highlight';
import {
    rulesMixin
} from "../../../mixins";
export default {
    props: {
        value: Boolean,
        isSelect: Boolean
    },
    mixins: [
        rulesMixin
    ],
    components: {
        PrOSearchHeaderComp,
        PrMWithoutInfoCardsComp,
        PrCLoaderDataComp,
        TextHighlight
    },
    data: () => ({
        search: "",
        products: [],
        dialogLoaderDataIsOpen: false,
        title: "Sin Datos"
    }),
    computed: {
        searchFilter: function() {
            if (this.mRItIsEmpty(this.search)) return "";
            return this.search;
        }
    },
    methods: {
        async eValidSearch() {
            this.dialogLoaderDataIsOpen = true;
            this.products = [{ id: 0, product: "Todos" }]
            try {
                const response = await Product.getProductsSale(this.search, 0, "t");
                if (this.isSelect)
                    this.products = response;
                else response.forEach(element => {
                    this.products.push(element);
                });
                this.title = "Sin Datos";
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.title = "Error de Internet, revise su internet y vuelva a intentarlo";
                this.dialogLoaderDataIsOpen = false;
            }
        },
        resetData() {
            this.search = "";
            if (this.isSelect) this.products = [];
            else this.products = [{ id: 0, product: "Todos" }];
        }
    }
}
</script>