<template>
    <v-form
        v-model="valid"
        lazy-validation
        ref="formuser"
    >
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Usuario"
        >
            <PrMTextFieldComp
                v-model="dataUser.username"
                :label="isMobile ? 'Usuario' : ''"
                @click="eValidateForm()"
                :counter="30"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => !mRHasSpaces(value) || smsWithSpaces,
                    value => (`${value}`).length <= 30 || smsExecedCounter(50)
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Contraseña"
        >
            <PrMTextFieldComp
                v-model="dataUser.password"
                :label="isMobile ? 'Contraseña' : ''"
                @click="eValidateForm()"
                :type="showPassword ? 'text' : 'password'"
                :icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @clicksee="showPassword = !showPassword"
                :counter="30"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => (`${value}`).length <= 30 || smsExecedCounter(50)
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Repita Contraseña"
        >
            <PrMTextFieldComp
                v-model="dataUser.repit_password"
                :label="isMobile ? 'Repita Contraseña' : ''"
                @click="eValidateForm()"
                :type="showPassword ? 'text' : 'password'"
                :icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @clicksee="showPassword = !showPassword"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty
                ]"
            />
        </PrCFillFormComp>
        <div class="d-flex justify-center mt-1">
            <PrMBtnNormalComp
                @click="eValidateForm()"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                GUARDAR
            </PrMBtnNormalComp>
        </div>
    </v-form>
</template>
<script>
import {
    PrMTextFieldComp,
    PrMBtnNormalComp
} from "../../molecules";
import {
    PrCFillFormComp
} from "../../cells";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        PrMTextFieldComp,
        PrCFillFormComp,
        PrMBtnNormalComp
    },
    props: {
        dataUser: Object,
        isMobile: Boolean
    },
    data: () => ({
        valid: true,
        showPassword: false
    }),
    methods: {
        eValidateForm() {
            if (this.$refs.formuser.validate())
                this.$emit('clickform', true);
            else this.$emit('clickform', false);
        },
        resetForm() {
            this.$refs.formuser.resetValidation();
            this.showPassword = false;
        }
    }
}
</script>