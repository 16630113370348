<template>
    <v-dialog
        persistent
        :value="value"
        width="600"
    >
        <v-card class="pa-0 ma-0 cs-pu-background">
            <div class="d-flex align-center pa-2 cf-pu-background">
                <h1 class="pr-app-title-dark">
                    {{product.product}}
                </h1>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    x-small
                    depressed
                    color="red"
                    @click="$emit('clickclose')"
                >
                    <v-icon
                        class="pr-cd-color"
                    >mdi-close</v-icon>
                </v-btn>
            </div>
            <div
                v-if="product.id != undefined"
                class="pa-2 o-dialog-detail-product__body"
            >
                <div class="d-flex justify-center">
                    <CImageProductComp
                        :path="product.route_file"
                    />
                </div>
                <div class="o-dialog-detail-product__info">
                    <h1 class="pu-app-subtitle-dark">
                        {{product.description}}
                    </h1>
                    <v-divider dark class="mt-1 mb-1"></v-divider>
                    <CFillInformationComp
                        title="Categoría"
                        :subtitle="product.categorie"
                    />
                    <CFillInformationComp
                        title="Tipo"
                        :subtitle="product.type === 'n' ? 'Físico' : 'Digital'"
                    />
                    <CFillInformationComp
                        title="Precio"
                        :subtitle="`${product.price.toFixed(2)} Bs.`"
                    />
                    <CFillInformationComp
                        title="Tiempo entrega"
                        :subtitle="product.consider_time === 1
                            ? product.time : 'Inmediata'"
                    />
                    <!--<CFillInformationComp
                        title="Stock"
                        :subtitle="`${product.quantity}`"
                    />-->
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    CFillInformationComp,
    CImageProductComp
} from "../../cells";
export default {
    props: {
        product: Object,
        value: Boolean
    },
    components: {
        CFillInformationComp,
        CImageProductComp
    }
}
</script>