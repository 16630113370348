import { render, staticRenderFns } from "./PrOTCategoriesComp.vue?vue&type=template&id=9747a8f2&"
import script from "./PrOTCategoriesComp.vue?vue&type=script&lang=js&"
export * from "./PrOTCategoriesComp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports