<template>
    <div>
        <v-tooltip
            bottom
            content-class="pr-c-tooltip"
            v-if="!mobile"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    fab
                    v-bind="attrs"
                    v-on="on"
                    :ripple="false"
                    x-small
                    dark
                    class="pr-cs-background"
                    :disabled="disabled"
                    depressed
                    @click="$emit('click')"
                >
                    <slot></slot>
                </v-btn>
            </template>
            <div class="d-flex align-center">
                <v-icon
                    classIcon="pr-cd-color"
                >mdi-hand-pointing-right</v-icon>
                <h1 class="pr-app-subtitle-dark ml-1">{{smsToolTip}}</h1>
            </div>
        </v-tooltip>
        <v-btn
            v-else
            fab
            :ripple="false"
            x-small
            class="fondo-btn-cabecera"
            :disabled="disabled"
            depressed
            @click="$emit('click')"
        >
            <slot></slot>
        </v-btn>
    </div>
</template>
<script>
export default {
    props: {
        smsToolTip: String,
        disabled: Boolean,
        mobile: Boolean
    }
}
</script>