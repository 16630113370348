<template>
    <div class="pr-o-card-categorie animation-opacity">
        <div class="pr-o-card-categorie__header d-flex justify-center">
            <h1 class="pr-app-title-dark">
                {{`N° ${(index + 1)}`}}
            </h1>
        </div>
        <div class="pa-2 pb-1">
            <PrCFillInformationComp
                title="Nombre"
                :subtitle="ranking.name"
            />
        </div>
        <v-divider class="mb-1"></v-divider>
        <div class="pa-2 pt-1 d-flex justify-center flex-wrap">
            <PrCBtnCardComp
                icon="mdi-pencil"
                @click="$emit('clickedit')"
            />
            <PrCBtnCardComp
                icon="mdi-delete"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import {
    PrCFillInformationComp,
    PrCBtnCardComp
} from "../../cells";
export default {
    props: {
        ranking: Object,
        index: Number
    },
    components: {
        PrCFillInformationComp,
        PrCBtnCardComp
    }
}
</script>