export default {
    data: () => ({
        smsEmpty: "Es requerido",
        smsWithSpaces: "No debe tener espacios",
        smsNotInteger: "Debe ser un número entero",
        smsNotNumeric: "Debe ser un número",
        smsIsZero: "No debe ser cero"
    }),
    methods: {
        mRItIsEmpty(input) {
            if (input === null || input === '') return true;
            const filterSpaces = /\s/g;
            const myArraySpaces = (input+'').match(filterSpaces);
            if (myArraySpaces === null) return false;
            return input.length === myArraySpaces.length;
        },
        mRHasSpaces(input) {
            const spaces = (`${input}`).match(/\s+/g);
            return !(spaces === null);
        },
        mRIsInteger(input) {
            if (isNaN(input)) return false;
            const number = parseFloat(input);
            return number%1 === 0;
        },
        mRIsNumeric(input) {
            if (isNaN(input)) return false;
            return !(input < 0);
        },
        mRExcedeLimite(input, limit) {
            return parseFloat(input) > parseFloat(limit);
        },
        mRIsNotZero(input) {
            return parseInt(input) !== 0;
        },
        smsExecedCounter(digitos) {
            return `No puede exceder los ${digitos} dígitos`;
        },
        smsMinCounter(digitos) {
            return `Debe ser mayor o igual a ${digitos} dígitos`;
        },
        mRDifferentData(input, inputTwo) {
            return input !== inputTwo;
        },
        mIsGmail(input) {
            const filterGmail = /(\w+)@gmail.com\b/;
            const myArrayGmail = (`${input}`).match(filterGmail);
            return myArrayGmail === null ? false : true;
        }
    }
}