<template>
    <div class="pr-s-menu-pc">
        <v-navigation-drawer
            permanent
            :mini-variant="mini"
            class="pr-s-menu-pc__navegation"
            width="300"
            fixed
        >
            <v-card
                class="cf-pu-background pa-1 ma-0 rounded-0 d-flex justify-center align-center"
                elevation="0"
                min-height="48"
                height="48"
                max-height="48"
            >
                <v-img
                    src="/imgs_public/logos/logo_green.svg"
                    :height="mini ? sizeLogoMini : sizeLogo"
                    :max-height="mini ? sizeLogoMini : sizeLogo"
                    :width="mini ? sizeLogoMini : sizeLogo"
                    :max-width="mini ? sizeLogoMini : sizeLogo"
                ></v-img>
                <h1 v-if="!mini" class="ml-3 pr-app-title-dark">Gatonet</h1>
                <div v-if="!mini && codes.length > 0" class="ml-2">
                    <v-menu
                        transition="slide-x-transition"
                        bottom
                        right
                        offset-y
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                depressed
                                v-bind="attrs"
                                v-on="on"
                                icon
                                small
                                :ripple="false"
                            >
                                <PrMIconComp
                                    icon="mdi-bell-plus"
                                    color="red"
                                    :small="false"
                                />
                            </v-btn>
                        </template>
                        <v-card width="400">
                            <v-card
                                elevation="0"
                                class="rounded-0 pa-1 pr-cs-background d-flex align-center"
                            >
                                <h1 class="pr-app-title-dark">
                                    {{codes.length == 1
                                        ? `Tiene 1 código a vencer`
                                        : `Tiene ${codes.length} códigos a vencer`
                                    }}
                                </h1>
                            </v-card>
                            <v-card
                                height="180px"
                                class="pa-1"
                                style="overflow: auto"
                            >
                                <v-card
                                    v-for="code in codes"
                                    :key="code.id"
                                    class="pa-1 pt-0 mt-1"
                                >
                                    <h1 class="pr-app-title">{{code.product_sale.product.product}}</h1>
                                    <h1 class="pr-app-subtitle">
                                        {{mDateFormat(code.expiration)}}
                                    </h1>
                                    <h1 class="pr-app-subtitle">
                                        {{code.product_sale.sale.client.full_name}}
                                    </h1>
                                </v-card>
                            </v-card>
                        </v-card>
                    </v-menu>
                </div>
            </v-card>
            <v-list nav>
                <div
                    v-for="(option, index) in menu"
                    :key="index"
                >
                    <div v-if="option.submenu.length>0">
                        <v-list-group
                            no-action
                            :ripple="false"
                            color="white"
                            v-if="!mini"
                            :class="(index+1)==menu.length ? '' : 'mb-2'"
                        >
                            <template v-slot:activator>
                                <v-list-item
                                    :ripple="false"
                                    class="ma-0 pl-0"
                                >
                                    <v-icon class="pr-s-menu-pc__list-icon mr-2">{{option.icon}}</v-icon>
                                    <h1 class="pr-app-subtitle-dark">{{option.title}}</h1>
                                </v-list-item>
                            </template>
                            <div
                                v-for="(child, subindex) in option.submenu"
                                :key="subindex"
                                class="pl-7"
                            >
                                <v-list-item
                                    :to="child.route"
                                    class="pr-s-menu-pc__list-option"
                                    :ripple="false"
                                    active-class="pr-s-menu-pc__list-option-activate"
                                >
                                    <v-icon class="pr-s-menu-pc__list-icon mr-2">{{child.icon}}</v-icon>
                                    <h1 class="pr-app-subtitle-dark">{{child.title}}</h1>
                                </v-list-item>
                            </div>
                        </v-list-group>
                        <v-menu
                            v-else
                            transition="slide-x-transition"
                            bottom
                            right
                            offset-x
                            z-index="60"
                            open-on-hover
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-list-item
                                    :ripple="false"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="pr-s-menu-pc__list-option"
                                >
                                    <v-icon class="pr-s-menu-pc__list-icon">
                                        {{option.icon}}</v-icon>
                                </v-list-item>
                            </template>
                            <v-card class="pa-1">
                                <v-list-item
                                    v-for="(child, index) in option.submenu"
                                    :key="index"
                                    :to="child.route"
                                    class="pr-s-menu-pc__menu-option"
                                    :ripple="false"
                                    active-class="pr-s-menu-pc__menu-option-activate"
                                >
                                    <v-icon class="pr-cp-color mr-2">{{child.icon}}</v-icon>
                                    <h1 class="pr-app-subtitle">{{child.title}}</h1>
                                </v-list-item>
                            </v-card>
                        </v-menu>
                    </div>
                    <div v-else>
                        <div v-if="mini">
                            <v-tooltip bottom color="#010d14">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-list-item
                                        v-bind="attrs"
                                        v-on="on"
                                        :to="option.route"
                                        :ripple="false"
                                        :class="(index+1)==menu.length
                                            ? 'pr-s-menu-pc__list-option'
                                            : 'pr-s-menu-pc__list-option mb-2'"
                                    >
                                        <v-icon class="pr-s-menu-pc__list-icon">{{option.icon}}</v-icon>
                                    </v-list-item>
                                </template>
                                <div class="d-flex">
                                    <v-icon class="pr-s-menu-pc__list-icon mr-2">mdi-hand-pointing-right</v-icon>
                                    <h1 class="pr-app-title-dark">{{option.title}}</h1>
                                </div>
                            </v-tooltip>
                        </div>
                        <v-list-item
                            v-else
                            :to="option.route"
                            :ripple="false"
                            :class="(index+1)==menu.length
                                ? 'pr-s-menu-pc__list-option'
                                : 'pr-s-menu-pc__list-option mb-2'"
                            active-class="pr-s-menu-pc__list-option-activate"
                        >
                            <v-icon class="pr-s-menu-pc__list-icon mr-2">{{option.icon}}</v-icon>
                            <h1 class="pr-app-subtitle-dark">{{option.title}}</h1>
                        </v-list-item>
                    </div>
                </div>
                <div v-if="mini">
                    <v-tooltip bottom color="#010d14">
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item
                                v-bind="attrs"
                                v-on="on"
                                @click="eCloseSesion()"
                                :ripple="false"
                                class="pr-s-menu-pc__list-option mt-2"
                            >
                                <v-icon class="pr-s-menu-pc__list-icon">
                                    mdi-account-key
                                </v-icon>
                            </v-list-item>
                        </template>
                        <div class="d-flex">
                            <v-icon class="pr-s-menu-pc__list-icon mr-2">mdi-hand-pointing-right</v-icon>
                            <h1 class="pr-app-title-dark">
                                Cerrar Sesión
                            </h1>
                        </div>
                    </v-tooltip>
                </div>
                <v-list-item
                    v-else
                    :ripple="false"
                    @click="eCloseSesion()"
                    class="pr-s-menu-pc__list-option d-flex mt-2"
                    active-class="pr-s-menu-pc__list-option-activate"
                >
                    <v-icon class="pr-s-menu-pc__list-icon mr-2">
                        mdi-account-key
                    </v-icon>
                    <h1 class="pr-app-subtitle-dark">
                        Cerrar Sesión
                    </h1>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <PrCLoaderOperationComp
            message="Cerrando Sesión"
            :value="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrMIconComp
} from "../molecules";
import {
    PrCLoaderOperationComp
} from "../cells";
import {
    User
} from "../../../models";
import {
    datesMixin
} from "../../../mixins";
export default {
    mixins: [
        datesMixin
    ],
    props: {
        menu: Array,
        mini: Boolean,
        codes: Array
    },
    components: {
        PrCLoaderOperationComp,
        PrMIconComp
    },
    data: () => ({
        sizeLogo: '35',
        sizeLogoMini: '30',
        dialogOperationIsOpen: false
    }),
    methods: {
        async eCloseSesion() {
            this.dialogOperationIsOpen = true;
            try {
                await User.logoutEmployee();
                this.dialogOperationIsOpen = false;
                localStorage.removeItem("ecomerce_gatonet");
                setTimeout(() => {
                    this.$router.replace('/login-admin');
                }, 150);
            } catch (error) {
                localStorage.removeItem("ecomerce_gatonet");
                this.$router.replace('/login-admin');
            }
        }
    }
}
</script>