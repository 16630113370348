<template>
    <div class="pr-o-card-categorie animation-opacity">
        <div class="pr-o-card-categorie__header d-flex justify-center">
            <text-highlight
                class="pr-app-title-dark"
                :queries="search"
            >
                {{categorie.categorie}}
            </text-highlight>
        </div>
        <div class="pa-2 pb-1">
            <PrCFillInformationComp
                title="Para Productos"
                :subtitle="getCategorieType()"
            />
            <PrCFillInformationComp
                title="Activo Web"
                :subtitle="categorie.enabled === 1 ? 'Habilitado' : 'Inhabilitado'"
            >
                <div
                    :class="categorie.enabled === 1
                        ? 'categories__enabled ml-1'
                        : 'categories__disabled ml-1'"
                ></div>
            </PrCFillInformationComp>
            <PrCFillInformationComp
                title="Vista"
                :subtitle="getViews()"
            />
            <PrCFillInformationComp
                title="Para Vendedor"
                :subtitle="categorie.is_seller === 1 ? 'SI' : 'NO'"
            >
                <div
                    :class="categorie.is_seller === 1
                        ? 'categories__enabled ml-1'
                        : 'categories__disabled ml-1'"
                ></div>
            </PrCFillInformationComp>
        </div>
        <v-divider class="mb-1"></v-divider>
        <div class="pa-2 pt-1 d-flex justify-center flex-wrap">
            <PrCBtnCardComp
                icon="mdi-pencil"
                @click="$emit('clickedit')"
            />
            <PrCBtnCardComp
                :icon="categorie.enabled === 1
                    ? 'mdi-eye-off'
                    : 'mdi-eye'"
                @click="$emit('clickstate')"
            />
            <PrCBtnCardComp
                icon="mdi-delete"
                @click="$emit('clickdelete')"
            />
            <PrCBtnCardComp
                :icon="categorie.is_seller === 1
                    ? 'mdi-account-off'
                    : 'mdi-account'"
                @click="$emit('clickseller')"
            />
        </div>
    </div>
</template>
<script>
import TextHighlight from 'vue-text-highlight';
import {
    PrCFillInformationComp,
    PrCBtnCardComp
} from "../../cells";
export default {
    props: {
        categorie: Object,
        search: String
    },
    components: {
        TextHighlight,
        PrCFillInformationComp,
        PrCBtnCardComp
    },
    methods: {
        getCategorieType() {
            if (this.categorie.choice === "t") return "Todos";
            if (this.categorie.choice === "d") return "Digitales";
            return "Físicos";
        },
        getViews() {
            if (this.categorie.view === "c") return "Gift Card";
            if (this.categorie.view === "g") return "Digitales";
            if (this.categorie.view === "s") return "Streaming";
            if (this.categorie.view === "p") return "Pin Digital";
            return "Ninguno";
        }
    }
}
</script>