var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"formlogin",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('CFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Correo GMAIL"}},[_c('MTextFieldComp',{class:_vm.isMobile ? 'mt-5' : '',attrs:{"dark":_vm.isMobile,"background":_vm.isMobile ? '' : 'white',"styleTextField":_vm.isMobile
                ? 'font-weight:300 !important'
                : '',"label":_vm.isMobile ? 'Correo GMAIL' : '',"counter":50,"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => !_vm.mRHasSpaces(value) || _vm.smsWithSpaces,
                value => _vm.mIsGmail(value) || 'Debe enviar un correo gmail',
                value => (`${value}`).length <= 50 || _vm.smsExecedCounter(50)
            ]},model:{value:(_vm.dataLogin.username),callback:function ($$v) {_vm.$set(_vm.dataLogin, "username", $$v)},expression:"dataLogin.username"}})],1),_c('CFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Contraseña"}},[_c('MTextFieldComp',{attrs:{"dark":_vm.isMobile,"background":_vm.isMobile ? '' : 'white',"styleTextField":_vm.isMobile
                ? 'font-weight:300 !important'
                : '',"label":_vm.isMobile ? 'Contraseña' : '',"type":_vm.showPassword ? 'text' : 'password',"icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye',"counter":20,"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => !_vm.mRHasSpaces(value) || _vm.smsWithSpaces,
                value => (`${value}`).length >= 5 || _vm.smsMinCounter(5),
                value => (`${value}`).length <= 20 || _vm.smsExecedCounter(20)
            ]},on:{"clicksee":function($event){_vm.showPassword = !_vm.showPassword},"click":function($event){return _vm.eValidateForm()}},model:{value:(_vm.dataLogin.password),callback:function ($$v) {_vm.$set(_vm.dataLogin, "password", $$v)},expression:"dataLogin.password"}})],1),_c('div',{staticClass:"d-flex justify-center mt-1"},[_c('PrMBtnNormalComp',{attrs:{"className":"cp-pu-color","small":!_vm.isMobile,"xSmall":_vm.isMobile,"loading":_vm.loadingBtn},on:{"click":function($event){return _vm.eValidateForm()}}},[_vm._v(" ingresar ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }