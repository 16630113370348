export default {
    methods: {
        mSaleTotalLocalStore(productSale) {
            let total = 0;
            for (let i = 0; i < productSale.length; i++) {
                const totalProduct = productSale[i].price * productSale[i].quantity;
                const valueTotal = parseFloat(totalProduct.toFixed(2));
                total += valueTotal;
            }
            return total;
        },
        mSaleTotalDB(detail) {
            let total = 0;
            for (let i = 0; i < detail.length; i++) {
                const totalProduct = detail[i].price_unity * detail[i].quantity;
                const valueTotal = parseFloat(totalProduct.toFixed(2));
                total += valueTotal;
            }
            return total;
        },
        mSaleTotalCostDB(detail) {
            let total = 0;
            for (let i = 0; i < detail.length; i++) {
                const totalProduct = detail[i].price_cost * detail[i].quantity;
                const valueTotal = parseFloat(totalProduct.toFixed(2));
                total += valueTotal;
            }
            return total;
        }
    }
}