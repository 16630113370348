<template>
    <div>
        <v-tooltip bottom content-class="cp-pu-tooltip">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    icon
                    :ripple="false"
                    @click="$emit('click')"
                >
                    <v-icon class="cp-pu-color">{{icon}}</v-icon>
                </v-btn>
            </template>
            <div class="d-flex align-center">
                <h1 class="pr-app-subtitle-dark ml-1">{{smsToolTip}}</h1>
            </div>
        </v-tooltip>
    </div>
</template>
<script>
export default {
    props: {
        icon: String,
        smsToolTip: String
    }
}
</script>
