<template>
    <div>
        <div class="d-flex justify-center">
            <v-card
                width="850px"
                elevation="0"
                color="transparent"
                class="pl-2 pr-2"
            >
                <div class="d-flex justify-center mt-3">
                    <div class="verify-email__image">
                        <v-img
                            width="100%"
                            height="100%"
                            src="/imgs_public/logos/logo_green.svg"
                            contain
                        ></v-img>
                    </div>
                </div>
                <ESkeletonFormComp
                    title="INGRESAR"
                >
                    <OFormLogincComp
                        :dataLogin="dataLogin"
                        :isMobile="isMobile"
                        :loadingBtn="loadingBtn"
                        @clickform="eConfirmForm"
                    />
                </ESkeletonFormComp>
                <div class="d-flex justify-center mt-4">
                    <MBtnNormalComp
                        :small="!isMobile"
                        :xSmall="isMobile"
                        to="/register"
                    >REGISTRARME</MBtnNormalComp>
                </div>
            </v-card>
        </div>
        <div class="space--block"></div>
        <CSmsErrorComp
            :smsErrors="smsErrors"
            :value="dialogErrorIsOpen"
            :actionErrorIsActivated="actionErrorIsActivated"
            @click="eCloseError()"
        />
        <CSmsActionComp
            :smsActions="smsActions"
            :value="frameAction"
            :isError="isError"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
        />
    </div>
</template>
<script>
import {
    MBtnNormalComp
} from "../../components/components_public/molecules";
import {
    CSmsActionComp,
    CSmsErrorComp
} from "../../components/components_public/cells";
import {
    OFormLogincComp
} from "../../components/components_public/organisms";
import {
    ESkeletonFormComp
} from "../../components/components_public/species";
import {
    responseServerMixin,
    dataGeneralPublicMixin
} from "../../mixins";
import {
    User
} from "../../models";
import { mapMutations, mapState } from "vuex";
export default {
    mixins: [
        responseServerMixin,
        dataGeneralPublicMixin
    ],
    components: {
        OFormLogincComp,
        ESkeletonFormComp,
        CSmsActionComp,
        CSmsErrorComp,
        MBtnNormalComp
    },
    data: () => ({
        dataLogin: {
            "username": null,
            "password": null
        },
        loadingBtn: false
    }),
    computed: {
        ...mapState("cartClientStore", ["pendingPurchase"])
    },
    methods: {
        ...mapMutations('userClientStore', ['saveHeader']),
        processErrorServer(error) {
            this.loadingBtn = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        async loginClient() {
            let md5 = require('md5');
            const parameters = {
                "username": this.dataLogin.username,
                "password": md5(this.dataLogin.password)
            }
            try {
                const response = await User.loginClient(parameters);
                localStorage.setItem('client_gatonet', JSON.stringify(response.data))
                let header = {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + response.data.token,
                }
                this.saveHeader(header);
                this.loadingBtn = false;
                this.$emit('eventlogin');
                setTimeout(() => {
                    if (!response.verified_mail)
                        this.$router.replace('/verify-email');
                    else {
                        if (this.pendingPurchase)
                            this.$router.replace('/cart');
                        else this.$router.replace('/account');
                    }
                }, 150);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eConfirmForm(isValidForm) {
            if (isValidForm) {
                setTimeout(() => {
                    this.loadingBtn = true;
                    this.loginClient();
                }, 150);
            } else
                this.mGDShowAction(["Formulario Erroneo", "Corrija los campos en rojo"], true);
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
    }
}
</script>