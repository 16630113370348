<template>
    <div class="d-flex" style="width:100%">
        <MTextFieldHeaderComp
            :label="label"
            :value="value"
            ref="search"
            :dark="true"
            :clearable="clearable"
            style="font-weight:300 !important"
            class="mr-1"
            @input="(e)=>(this.$emit('input', e))"
            @click="$emit('entersearch')"
        />
        <v-btn
            :width="sizeBtn"
            :max-width="sizeBtn"
            :min-width="sizeBtn"
            :height="sizeBtn"
            :min-height="sizeBtn"
            :max-height="sizeBtn"
            depressed
            fab
            class="cp-pu-background"
            :ripple="false"
            @click="$emit('entersearch')"
        >
            <v-icon small class="pr-cd-color">mdi-magnify</v-icon>
        </v-btn>        
    </div>
</template>
<script>
import {
    MTextFieldHeaderComp
} from "../../molecules";
export default {
    model: {
        prop: 'value',
        event: 'input'
    },
    components: {
        MTextFieldHeaderComp
    },
    props: {
        label: String,
        value: String,
        clearable: {
            type: Boolean,
            default: true
        }
    },
    data: () => ({
        sizeBtn: '32'
    }),
    methods: {
        activateFocus() {
            this.$refs.search.activateFocus();
        }
    }
}
</script>