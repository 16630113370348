import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from "../store/modules";
class Product {
    constructor(
        consider_time = null,
        product = null,
        description = null,
        type = null,
        price = null,
        time = null,
        quantity = null,
        price_cost = null,
        name_file = null,
        fk_categorie = null,
        necessary_info = null,
        info_guide = null,
        file = null,
        id_reserve = 0,
        balance_hold = null,
        instructions = null
    ) {
        this.consider_time = consider_time;
        this.product = product;
        this.description = description;
        this.type = type;
        this.price = price;
        this.time = time;
        this.quantity = quantity;
        this.price_cost = price_cost;
        this.name_file = name_file;
        this.fk_categorie = fk_categorie;
        this.necessary_info = necessary_info;
        this.info_guide = info_guide;
        this.file = file;
        this.id_reserve = id_reserve;
        this.balance_hold = balance_hold;
        this.instructions = instructions;
    }
    static async getProducts(page, search, enabled, webEnabled, idCategorie, stock)
    {
        const response = await axios.get(
            `${apiUrls.PRODUCT}list?page=${page}&search=${search}&enabled=${enabled}&web_enabled=${webEnabled}&fk_categorie=${idCategorie}&stock=${stock}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addProduct(parameters) {
        const apitoken = JSON.parse(localStorage.getItem("ecomerce_gatonet")).token;
        const header = {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json",
            "Authorization": "Bearer " + apitoken,
        }
        const response = await axios.post(
            `${apiUrls.PRODUCT}add`,
            parameters,
            { headers: header }
        );
        return response.data;
    }
    static async editProduct(parameters) {
        const response = await axios.put(
            `${apiUrls.PRODUCT}edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async changeStateProduct(parameters) {
        const response = await axios.patch(
            `${apiUrls.PRODUCT}change-state`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async changeWebStateProduct(parameters) {
        const response = await axios.patch(
            `${apiUrls.PRODUCT}change-state-web`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async deleteProduct(parameters) {
        const response = await axios.delete(
            `${apiUrls.PRODUCT}delete`,
            { data: parameters, headers: userStore.state.header }
        );
        return response.data;
    }
    static async updateImageProduct(parameters) {
        const apitoken = JSON.parse(localStorage.getItem("ecomerce_gatonet")).token;
        const header = {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json",
            "Authorization": "Bearer " + apitoken,
        }
        const response = await axios.post(
            `${apiUrls.PRODUCT}update-image`,
            parameters,
            { headers: header }
        );
        return response.data;
    }
    static async getInventory(page, search, idCategorie, stock, type, sald)
    {
        const response = await axios.get(
            `${apiUrls.PRODUCT}list-inventory?page=${page}&search=${search}&fk_categorie=${idCategorie}&stock=${stock}&type=${type}&sald=${sald}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async updateProductInventory(parameters) {
        const response = await axios.put(
            `${apiUrls.PRODUCT}update-inventory`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async getProductsSale(search, idCategorie, type, categorieUser)
    {
        const response = await axios.get(
            `${apiUrls.PRODUCT}list-sale?search=${search}&fk_categorie=${idCategorie}&type=${type}&categorie_user=${categorieUser}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async updateProductDigitalInventory(parameters) {
        const response = await axios.put(
            `${apiUrls.PRODUCT}update-product-digital`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async inventoryTotal(search, idCategorie, stock, type, sald)
    {
        const response = await axios.get(
            `${apiUrls.PRODUCT}inventory-total?search=${search}&fk_categorie=${idCategorie}&stock=${stock}&type=${type}&sald=${sald}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    //public
    static async listProductsPublic(page, search, idCategorie, type) {
        const response = await axios.get(
            `${apiUrls.PRODUCT}list-public?page=${page}&search=${search}&fk_categorie=${idCategorie}&type=${type}`
        );
        return response.data;
    }
    static async lastProducts() {
        const response = await axios.get(
            `${apiUrls.PRODUCT}last-products`
        );
        return response.data;
    }
    static async productsCategories(page, search, view, idCategorie) {
        const response = await axios.get(
            `${apiUrls.PRODUCT}products-categorie?page=${page}&search=${search}&view=${view}&fk_categorie=${idCategorie}`
        );
        return response.data;
    }
}
export default Product;