var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"products-digital__body product-public mt-2"},[_c('div',{staticClass:"product-public__img"},[_c('div',{staticClass:"product-public__img-container"},[_c('v-img',{attrs:{"src":_vm._f("imgNormal")(_vm.product.route_file),"width":"100%","height":"100%","contain":""}})],1)]),_c('div',{staticClass:"product-public__info"},[_c('h1',{staticClass:"pu-app-big-title-dark"},[_vm._v(_vm._s(_vm.product.product))]),_c('v-card',{staticClass:"o-card-recommendation__ornament mt-2",staticStyle:{"opacity":"0.5"},attrs:{"width":"100%","height":"5","elevation":"0"}}),_c('h1',{staticClass:"pu-app-subtitle-dark mt-2"},[_vm._v(_vm._s(_vm.product.description))]),_c('CFillInformationComp',{attrs:{"title":"Tipo","subtitle":_vm.product.type === 'd'
                        ? 'Digital'
                        : 'Físico'}}),_c('CFillInformationComp',{attrs:{"title":"Categoría","subtitle":_vm.product.categorie}}),_c('CFillInformationComp',{attrs:{"title":"Precio","subtitle":_vm.getPrice(_vm.product.price)}}),_c('CFillInformationComp',{attrs:{"title":"Tiempo entrega","subtitle":_vm.product.consider_time === 1
                        ? _vm.product.time : 'Inmediata'}}),(_vm.product.necessary_info == 1)?_c('div',[_c('h1',{staticClass:"pu-app-title-dark"},[_vm._v("Información requerida para la compra")]),_c('h1',{staticClass:"pu-app-subtitle-dark"},[_vm._v(" "+_vm._s(_vm.product.info_guide)+" ")]),_c('v-form',{ref:"formproduct",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('MTextAreaComp',{staticClass:"mt-3",attrs:{"label":"Información Requerida","dark":true,"background":"","styleTextField":"font-weight:300 !important","counter":200,"rules":[
                                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                                value => (`${value}`).length <= 200 || _vm.smsExecedCounter(200)
                            ]},model:{value:(_vm.info),callback:function ($$v) {_vm.info=$$v},expression:"info"}})],1)],1):_vm._e(),_c('MBtnNormalComp',{staticClass:"mt-1",on:{"click":function($event){return _vm.eSelectProduct()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-cart-outline")]),_vm._v(" AÑADIR CARRITO ")],1)],1)])]),(_vm.product.instructions != null)?_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"products-digital__body mt-2"},[_c('h1',{staticClass:"pu-app-title-dark"},[_vm._v("INSTRUCCIONES")]),_c('h1',{staticClass:"pu-app-subtitle-dark",staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(_vm.product.instructions)+" ")])])]):_vm._e(),_c('div',{staticClass:"space--block"}),_c('CSmsActionComp',{attrs:{"smsActions":_vm.smsActions,"value":_vm.frameAction,"isError":_vm.isError,"timeSuccessAction":_vm.timeSuccessAction},on:{"clickaccion":function($event){return _vm.mGDClearTimeOut()}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }