<template>
    <div class="pr-o-card-categorie animation-opacity">
        <div class="pr-o-card-categorie__header d-flex justify-center">
            <h1 class="pr-app-title-dark">
                {{product.product}}
            </h1>
        </div>
        <div class="d-flex pa-2">
            <PrCImageTableComp
                :path="product.route_file"
            />
            <div class="ml-2">
                <h1 class="pr-app-subtitle">
                    {{product.description}}
                </h1>
                <v-divider></v-divider>
                <PrCFillInformationComp
                    title="Precio"
                    :subtitle="product.price.toFixed(2)"
                />
                <PrCFillInformationComp
                    title="Cantidad"
                    :subtitle="`${product.quantity}`"
                >
                    <div
                        :class="getClassInventory()"
                        style="margin-top:0px"
                    ></div>
                </PrCFillInformationComp>
            </div>
        </div>
        <v-divider class="mb-1"></v-divider>
        <div class="pa-2 pt-1 d-flex justify-center flex-wrap">
            <PrCBtnCardComp
                icon="mdi-format-list-bulleted-square"
                @click="$emit('clickdetail')"
            />
            <PrCMenuQuantityComp
                :options="options"
                icon="mdi-check"
                @clickquantity="eSelectQuantity"
            />
            <PrCBtnCardComp
                icon="mdi-lead-pencil"
                @click="$emit('clickform', product)"
            />
        </div>
    </div>
</template>
<script>
import {
    PrCFillInformationComp,
    PrCBtnCardComp,
    PrCImageTableComp,
    PrCMenuQuantityComp
} from "../../cells";
export default {
    props: {
        product: Object
    },
    components: {
        PrCFillInformationComp,
        PrCBtnCardComp,
        PrCImageTableComp,
        PrCMenuQuantityComp
    },
    data: () => ({
        options: [1, 2, 3, 4, 5]
    }),
    methods: {
        getClassInventory() {
            if (this.product.quantity <= 5) return "categories__disabled ml-1";
            if (this.product.quantity <= 15) return "categories__warning ml-1";
            return "categories__enabled ml-1";
        },
        eSelectQuantity(quantity) {
            const parameters = {
                'product': this.product,
                'quantity': quantity
            }
            this.$emit('clickquantity', parameters);
        }
    }
}
</script>