<template>
    <div class="pr-o-table-categories animation-opacity">
        <table class="pr-o-table-categories__table">
            <thead>
                <tr>
                    <th>
                        <h1 class="pr-app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Nombre Completo</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Correo</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Celular</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Verificación</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Usuario</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Estado</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Motivo Ban</h1>
                    </th>
                    <th width="130">
                        <h1 class="pr-app-title-dark">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(user, index) in users"
                    :key="user.id"
                >
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{(index + 1)}}
                        </h1>
                    </td>
                    <td>
                        <text-highlight
                            class="pr-app-subtitle"
                            :queries="search"
                        >
                            {{user.client.full_name}}
                        </text-highlight>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{user.client.email}}
                        </h1>
                    </td>
                    <td>
                        <text-highlight
                            class="pr-app-subtitle"
                            :queries="search"
                        >
                            {{user.client.phone != null ? user.client.phone : "S/N"}}
                        </text-highlight>
                    </td>
                    <td>
                        <div class="d-flex align-center">
                            <div
                                :class="user.client.verify_email != null
                                    ? 'categories__enabled mr-1'
                                    : 'categories__disabled mr-1'"
                                style="margin-top:-2px"    
                            ></div>
                            <h1 class="pr-app-subtitle">
                                {{user.client.verify_email == null ? "No" : "Si"}}
                            </h1>
                        </div>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{user.username}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex align-center">
                            <div
                                :class="user.enabled === 1
                                    ? 'categories__enabled mr-1'
                                    : 'categories__disabled mr-1'"
                                style="margin-top:-2px"    
                            ></div>
                            <h1 class="pr-app-subtitle">
                                {{user.enabled === 1 ? "Hab." : "Inhab."}}
                            </h1>
                        </div>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{user.client.banned != null && user.enabled === 0
                                ? user.client.banned : "S/B"}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <PrCBtnToolTipTableComp
                            icon="mdi-cellphone-basic"
                            smsToolTip="Editar Celular"
                            @click="$emit('clickedit', user)"
                        />
                        <PrCBtnToolTipTableComp
                            icon="mdi-whatsapp"
                            smsToolTip="Conectar por whatsapp"
                            @click="$emit('clickwhatsapp', user)"
                        />
                        <PrCBtnToolTipTableComp
                            :icon="user.enabled === 1
                                ? 'mdi-eye-off'
                                : 'mdi-eye'"
                            :smsToolTip="user.enabled === 1
                                ? `Inhabilitar a: ${user.client.full_name}`
                                : `Habilitar a: ${user.client.full_name}`"
                            @click="$emit('clickstate', user)"
                        />
                    </td>
                </tr>
                <tr v-if="users.length === 0">
                    <td colspan="9">
                        <h1 class="pr-app-subtitle text-center">Sin Información</h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    PrCBtnToolTipTableComp
} from "../../cells";
import TextHighlight from 'vue-text-highlight';
export default {
    props: {
        users: Array,
        search: String
    },
    components: {
        PrCBtnToolTipTableComp,
        TextHighlight
    }
}
</script>