<template>
    <div>
        <div class="categories__header pa-2">
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    v-if="!isMobile"
                    :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                    :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                    @click="$emit('clickmini')"
                    class="mr-2"
                />
                <h1 class="pr-app-title">
                    {{`USUARIOS (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="categories__header-search">
                <PrMTextFieldHeaderComp
                    backgroundColor="white"
                    class="mr-1"
                    label="Buscar"
                    v-model="search"
                />
                <PrCBtnToolTipHeaderComp
                    icon="mdi-plus"
                    class="mr-1"
                    smsToolTip="Registrar categoría"
                    @click="eOpenForm()"
                />
                <PrCBtnToolTipHeaderComp
                    icon="mdi-database"
                    smsToolTip="Volver a la lista"
                    @click="eBackList()"
                />
            </div>
        </div>
        <PrMLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <PrCLoaderDataComp
                    v-if="dialogLoaderDataIsOpen"
                    class="mt-2"
                />
                <PrMWithoutInfoCardsComp
                    v-if="!dialogLoaderDataIsOpen && usersFilter.length===0 && isMobile"
                    class="ma-2"
                />
                <PrOTableUsersComp
                    v-if="!dialogLoaderDataIsOpen && !isMobile"
                    :users="usersFilter"
                    :search="searchFilter"
                    @clickedit="eOpenFormEdit"
                    @clickcredentials="eOpenFormUserEdit"
                    @clickstate="eConfirmChangeState"
                    @clickkey="eConfirmEnabledKeyUser"
                />
                <div
                    v-if="!dialogLoaderDataIsOpen && isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardUserComp
                        class="ma-2"
                        v-for="user in usersFilter"
                        :key="user.id"
                        :user="user"
                        :search="searchFilter"
                        @clickedit="eOpenFormEdit(user)"
                        @clickcredentials="eOpenFormUserEdit(user)"
                        @clickstate="eConfirmChangeState(user)"
                        @clickkey="eConfirmEnabledKeyUser(user)"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2">
                    <PrESkeletonFormComp
                        :title="indexUser===-1
                            ? 'REGISTRAR'
                            :  numberOperation === 1
                                ? 'EDITAR'
                                : 'CAMBIAR CUENTA'"
                    >
                        <PrOFormEmployeeComp
                            v-if="numberOperation === 0 || numberOperation === 1"
                            ref="formemployeecomp"
                            :dataEmployee="dataEmployee"
                            :formOnly="numberOperation === 0"
                            :isMobile="isMobile"
                            @clickform="eConfirmFormEmployee"
                        />
                        <PrOFormUserComp
                            v-if="numberOperation === 0 || numberOperation === 2"
                            ref="formusercomp"
                            :dataUser="dataUser"
                            :isMobile="isMobile"
                            @clickform="eConfirmForm"
                        />
                    </PrESkeletonFormComp>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <PrOGeneralDialogsComp
            :dialogErrorIsOpen="dialogErrorIsOpen"
            :smsErrors="smsErrors"
            :actionErrorIsActivated="actionErrorIsActivated"
            @clickerror="eCloseError()"
            :dialogConfirmationIsOpen="dialogConfirmationIsOpen"
            :smsConfirmations="smsConfirmations"
            @clickyes="eProcessOperation()"
            @clickno="eCloseConfirm()"
            :smsActions="smsActions"
            :frameAction="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
            :dialogOperationIsOpen="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMTextFieldHeaderComp,
    PrMWithoutInfoCardsComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCLoaderDataComp
} from "../../components/components_private/cells";
import {
    PrOCardUserComp,
    PrOTableUsersComp,
    PrOFormEmployeeComp,
    PrOGeneralDialogsComp,
    PrOFormUserComp
} from "../../components/components_private/organisms";
import {
    PrESkeletonFormComp
} from "../../components/components_private/species";
import {
    User,
    Employee
} from "../../models";
import {
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin
} from "../../mixins";
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin
    ],
    components: {
        PrMLineHeaderComp,
        PrCBtnToolTipHeaderComp,
        PrMTextFieldHeaderComp,
        PrCLoaderDataComp,
        PrMWithoutInfoCardsComp,
        PrOCardUserComp,
        PrOTableUsersComp,
        PrOFormEmployeeComp,
        PrESkeletonFormComp,
        PrOGeneralDialogsComp,
        PrOFormUserComp
    },
    data: () => ({
        users: [],
        numberOperation: 0,
        search: "",
        tab: 0,
        indexUser: -1,
        idUser: -1,
        dataUser: new User,
        dataEmployee: new Employee,
        idEmployee: -1,
        user: {}
    }),
    computed: {
        searchFilter: function() {
            return this.mRItIsEmpty(this.search) ? "" : this.search;
        },
        usersFilter: function() {
            if (this.mRItIsEmpty(this.search)) return this.users;
            return this.users.filter(user => {
                return user.employee.full_name.toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
            });
        }
    },
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        async getUsers() {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await User.getUsersEmployees();
                this.users = response;
                this.total = response.length;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eOpenForm() {
            this.indexUser = -1
            if (this.tab !== 0) {
                this.indexUser = -1;
                if (this.numberOperation === 1) {
                    this.dataEmployee = new Employee();
                    this.$refs.formemployeecomp.resetForm();
                }
                if (this.numberOperation === 2) {
                    this.dataUser = new User;
                    this.$refs.formusercomp.resetForm();
                }
            }
            this.numberOperation = 0;
            this.tab = 1;
        },
        eBackList() {
            if (this.tab !== 0) {
                this.indexUser = -1;
                if (this.numberOperation === 0 || this.numberOperation === 1) {
                    this.dataEmployee = new Employee();
                    this.$refs.formemployeecomp.resetForm();
                }
                if (this.numberOperation === 0 || this.numberOperation === 2) {
                    this.dataUser = new User;
                    this.$refs.formusercomp.resetForm();
                }
                this.tab = 0;
            }
        },
        eConfirmForm(isValidForm) {
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                let validForm = this.numberOperation === 0
                    ? isValidForm && this.$refs.formemployeecomp.validateForm()
                    : isValidForm;
                if (validForm) {
                    if (this.numberOperation === 0)
                        this.smsConfirmations = [`¿Está seguro(a) de registrar: ${this.dataEmployee.full_name}?`];
                    else this.smsConfirmations = [`¿Está seguro(a) de actualizar la cuenta de: ${this.user.employee.full_name}?`];
                    this.dialogConfirmationIsOpen = true;
                } else this.mGDShowError();
            }, time);
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        resetDataDirect() {
            this.numberOperation = 0;
            this.idUser = -1;
            this.indexUser = -1;
        },
        eCloseConfirm() {
            if (this.idUser !== -1 || this.numberOperation === 4)
                this.resetDataDirect();
            this.dialogConfirmationIsOpen = false;
        },
        async addUser() {
            let md5 = require('md5');
            const parameters = {
                "employee": this.dataEmployee,
                "user": {
                    "username": this.dataUser.username,
                    "password": md5(this.dataUser.password)
                }
            }
            try {
                const response = await User.addUserEmployee(parameters);
                this.users.unshift(response);
                this.total += 1;
                this.eBackList();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se registró el usuario"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async editEmployee() {
            try {
                const response = await Employee.editEmployee(this.dataEmployee);
                Object.assign(this.users[this.indexUser].employee, response);
                this.eBackList();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se editó el usuario"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async updateUser() {
            let md5 = require('md5');
            const parameters = {
                "id": this.dataUser.id,
                "username": this.dataUser.username,
                "password": md5(this.dataUser.password)
            }
            try {
                const response = await User.updateUser(parameters);
                Object.assign(this.users[this.indexUser], response);
                this.eBackList();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se editó el usuario"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async changeState() {
            try {
                const response = await User.changeState({id: this.idUser});
                this.users[this.indexUser].enabled = response;
                this.resetDataDirect();
                this.dialogOperationIsOpen = false;
                const textState = response === 0 ? 'inhabilitó' : 'habilitó'
                this.mGDShowAction(["Operación Exitosa", `Se ${textState} el usuario`]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async activateIpEmployee() {
            try {
                console.log(this.idEmployee);
                const response = await Employee.activateIp({id: this.idEmployee});
                if (JSON.parse(localStorage.getItem('ecomerce_gatonet_device'))) {
                    let usersDevice = JSON.parse(localStorage.getItem("ecomerce_gatonet_device"));
                    let existUser = false;
                    usersDevice.forEach(idUser => {
                        if (idUser == this.idEmployee)
                            existUser = true;
                    });
                    if (!existUser) {
                        usersDevice.push(this.idEmployee);
                        localStorage.setItem('ecomerce_gatonet_device', JSON.stringify(usersDevice)); 
                    };
                } else localStorage.setItem('ecomerce_gatonet_device', JSON.stringify([this.idEmployee]));
                Object.assign(this.users[this.indexUser].employee, response);
                this.resetDataDirect();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", `Se habilitó ventas para este dispositivo`]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eProcessOperation() {
            this.dialogConfirmationIsOpen = false;
            this.dialogOperationIsOpen = true;
            switch (this.numberOperation) {
                case 0:
                    this.addUser();
                    break;
                case 1:
                    this.editEmployee();
                    break;
                case 2:
                    this.updateUser();
                    break;
                case 3:
                    this.changeState();
                    break;
                case 4:
                    this.activateIpEmployee();
            }
        },
        eOpenFormEdit(user) {
            this.numberOperation = 1;
            this.indexUser = this.users.indexOf(user);
            this.dataEmployee = Object.assign({}, user.employee);
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        },
        eOpenFormUserEdit(user) {
            this.user = user;
            this.numberOperation = 2;
            this.indexUser = this.users.indexOf(user);
            this.dataUser = new User;
            this.dataUser.id = user.id;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        },
        eConfirmFormEmployee(isValidForm) {
            if (isValidForm) {
                this.smsConfirmations = [`¿Está seguro(a) de editar los datos de: ${this.dataEmployee.full_name}?`];
                this.dialogConfirmationIsOpen = true;
            } else this.mGDShowError();
        },
        eConfirmChangeState(user) {
            this.numberOperation = 3;
            this.idUser = user.id;
            this.indexUser = this.users.indexOf(user);
            const textState = user.enabled === 0 ? 'habilitar' : 'inhabilitar';
            this.smsConfirmations = [`¿Está seguro(a) de ${textState} a : ${user.employee.full_name}?`];
            this.dialogConfirmationIsOpen = true;
        },
        eConfirmEnabledKeyUser(user) {
            this.indexUser = this.users.indexOf(user);
            this.numberOperation = 4;
            this.idEmployee = user.employee.id;
            this.smsConfirmations = [`¿Está seguro(a) de habilitar ventas para: ${user.employee.full_name} en este dispositivo?`];
            this.dialogConfirmationIsOpen = true;
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategoryEmployee() === 'a') this.getUsers();
            else this.$router.replace('/admin/home');
        } else this.$router.replace('/login-admin');
    }
}
</script>