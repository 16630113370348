<template>
    <div class="pr-o-card-categorie animation-opacity">
        <div class="pr-o-card-categorie__header d-flex justify-center">
            <text-highlight
                class="pr-app-title-dark"
                :queries="search"
            >
                {{user.employee.full_name}}
            </text-highlight>
        </div>
        <div class="pa-2 pb-1">
            <PrCFillInformationComp
                title="Categoría"
                :subtitle="user.employee.category === 'a'
                    ? 'Administrador' : 'Vendedor'"
            />
            <PrCFillInformationComp
                title="Usuario"
                :subtitle="user.username"
            />
            <PrCFillInformationComp
                title="Estado"
                :subtitle="user.enabled === 1 ? 'Habilitado' : 'Inhabilitado'"
            >
                <div
                    :class="user.enabled === 1
                        ? 'categories__enabled ml-1'
                        : 'categories__disabled ml-1'"
                ></div>
            </PrCFillInformationComp>
            <PrCFillInformationComp
                title="Ip"
                :subtitle="getTextIp()"
            />
        </div>
        <v-divider class="mb-1"></v-divider>
        <div class="pa-2 pt-1 d-flex justify-center flex-wrap">
            <PrCBtnCardComp
                icon="mdi-pencil"
                @click="$emit('clickedit')"
            />
            <PrCBtnCardComp
                icon="mdi-account-key"
                @click="$emit('clickcredentials')"
            />
            <PrCBtnCardComp
                :icon="user.enabled === 1
                    ? 'mdi-eye-off'
                    : 'mdi-eye'"
                @click="$emit('clickstate')"
            />
            <PrCBtnCardComp
                icon="mdi-key-wireless"
                @click="$emit('clickkey')"
            />
        </div>
    </div>
</template>
<script>
import TextHighlight from 'vue-text-highlight';
import {
    PrCFillInformationComp,
    PrCBtnCardComp
} from "../../cells";
export default {
    props: {
        user: Object,
        search: String
    },
    components: {
        TextHighlight,
        PrCFillInformationComp,
        PrCBtnCardComp
    },
    methods: {
        getTextIp() {
            if (this.user.employee.category === 'a') return 'No Requiere';
            if (this.user.employee.ip === null) return 'Sin asignar';
            return this.user.employee.ip;
        }
    }
}
</script>