<template>
    <div>
        <div class="categories__header pa-2">
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    v-if="!isMobile"
                    :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                    :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                    class="mr-2"
                    @click="$emit('clickmini')"
                />
                <h1 class="pr-app-title">
                    {{`PRODUCTOS (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="categories__header-search">
                <PrOSearchHeaderComp
                    class="mr-1"
                    label="Buscar"
                    v-model="search"
                    @entersearch="eSearchProducts()"
                />
                <PrCBtnToolTipHeaderComp
                    icon="mdi-plus"
                    class="mr-1"
                    smsToolTip="Registrar categoría"
                    @click="eOpenForm()"
                />
                <PrCBtnToolTipHeaderComp
                    icon="mdi-database"
                    smsToolTip="Volver a la lista"
                    @click="eBackList()"
                />
            </div>
        </div>
        <PrMLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="d-flex justify-center flex-wrap pa-2">
                    <div class="pr-products__search">
                        <div class="pr-products__search-block">
                            <PrMSelectComp
                                label="Web"
                                v-model="valueWebEnabled"
                                :items="optionsWebEnabled"
                                itemValue="value"
                                background=""
                                itemText="text"
                                :hideDetails="true"
                                @change="eChangeWebState"
                            />
                        </div>
                        <div class="pr-products__search-block">
                            <PrMSelectComp
                                label="Estado"
                                v-model="valueEnabled"
                                :items="optionsEnabled"
                                itemValue="value"
                                background=""
                                itemText="text"
                                :hideDetails="true"
                                @change="eChangeState"
                            />
                        </div>
                    </div>
                    <div
                        :class="isMobile ? 'pr-products__search mt-2' : 'pr-products__search'"
                    >
                        <div class="pr-products__search-block">
                            <PrMSelectComp
                                label="Categoría"
                                v-model="idCategorie"
                                :items="categories"
                                background=""
                                itemValue="id"
                                itemText="categorie"
                                :hideDetails="true"
                                @change="eChangeCategorie"
                            />
                        </div>
                        <div class="pr-products__search-block">
                            <PrMSelectComp
                                label="Stock"
                                v-model="valueStock"
                                background=""
                                :items="optionsStock"
                                itemValue="value"
                                itemText="text"
                                :hideDetails="true"
                                @change="eChangeStock"
                            />
                        </div>
                    </div>
                </div>
                <v-divider></v-divider>
                <PrCLoaderDataComp
                    v-if="dialogLoaderDataIsOpen"
                    class="mt-2"
                />
                <PrMWithoutInfoCardsComp
                    v-if="!dialogLoaderDataIsOpen && products.length===0 && isMobile"
                    class="ma-2"
                />
                <PrOTableProductsComp
                    v-if="!dialogLoaderDataIsOpen && !isMobile"
                    :products="products"
                    :search="searchFilter"
                    @clickedit="eShowFormEdit"
                    @clickstate="eConfirmChangeState"
                    @clickwebstate="eConfirmChangeWebState"
                    @clickdelete="eDeleteProduct"
                    @clickimage="eOpenDialogImage"
                    @clickdetail="eShowDetailProduct"
                />
                <div
                    v-if="!dialogLoaderDataIsOpen && isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardProductComp
                        class="ma-2"
                        v-for="product in products"
                        :key="product.id"
                        :product="product"
                        :search="searchFilter"
                        @clickedit="eShowFormEdit(product)"
                        @clickstate="eConfirmChangeState(product)"
                        @clickwebstate="eConfirmChangeWebState(product)"
                        @clickdelete="eDeleteProduct(product)"
                        @clickimage="eOpenDialogImage(product)"
                        @clickdetail="eShowDetailProduct(product)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="isMobile && !dialogLoaderDataIsOpen && products.length > 0"
                ></v-divider>
                <PrSPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderDataIsOpen && products.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                    @clickpage="eSearchForPage"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2">
                    <PrESkeletonFormComp
                        :title="indexProduct===-1 ? 'REGISTRAR' : 'EDITAR'"
                    >
                        <PrOFormProductComp
                            ref="formproductcomp"
                            :dataProduct="dataProduct"
                            :indexProduct="indexProduct"
                            :isMobile="isMobile"
                            :reserves="reserves"
                            :categories="categoriesFilter"
                            @clickform="eConfirmForm"
                            @changefile="eChangeFile"
                        />
                    </PrESkeletonFormComp>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <PrSDialogDetailProductComp
            :value="dialogDetailIsOpen"
            :product="product"
            :categorieUser="categorieUser"
            @clickclose="dialogDetailIsOpen = false"
        />
        <PrOFomDialogImageComp
            :value="dialogImageIsOpen"
            :product="product"
            @changefile="eChangeFile"
            @clickclose="dialogImageIsOpen = false"
            @sendForm="eValidDialogImage()"
        />
        <PrOGeneralDialogsComp
            :dialogErrorIsOpen="dialogErrorIsOpen"
            :smsErrors="smsErrors"
            :actionErrorIsActivated="actionErrorIsActivated"
            @clickerror="eCloseError()"
            :dialogConfirmationIsOpen="dialogConfirmationIsOpen"
            :smsConfirmations="smsConfirmations"
            @clickyes="eProcessOperation()"
            @clickno="eCloseConfirm()"
            :smsActions="smsActions"
            :frameAction="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
            :dialogOperationIsOpen="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMSelectComp,
    PrMWithoutInfoCardsComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCLoaderDataComp
} from "../../components/components_private/cells";
import {
    PrOSearchHeaderComp,
    PrOTableProductsComp,
    PrOFormProductComp,
    PrOGeneralDialogsComp,
    PrOFomDialogImageComp,
    PrOCardProductComp
} from "../../components/components_private/organisms";
import {
    PrESkeletonFormComp,
    PrSPaginationComp,
    PrSDialogDetailProductComp
} from "../../components/components_private/species";
import {
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin
} from "../../mixins";
import {
    Product,
    Categorie,
    Reserve
} from "../../models";
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin
    ],
    components: {
        PrMLineHeaderComp,
        PrCBtnToolTipHeaderComp,
        PrOSearchHeaderComp,
        PrOTableProductsComp,
        PrMSelectComp,
        PrOFormProductComp,
        PrESkeletonFormComp,
        PrOGeneralDialogsComp,
        PrCLoaderDataComp,
        PrMWithoutInfoCardsComp,
        PrOFomDialogImageComp,
        PrOCardProductComp,
        PrSPaginationComp,
        PrSDialogDetailProductComp
    },
    data: () => ({
        optionsEnabled: [
            { value: 2, text: "Todos" },
            { value: 1, text: "Habilitados" },
            { value: 0, text: "Inhabilitados" }
        ],
        valueEnabled: 2,
        optionsWebEnabled: [
            { value: 2, text: "Todos" },
            { value: 1, text: "Habilitados" },
            { value: 0, text: "Inhabilitados" }
        ],
        valueWebEnabled: 2,
        categories: [{ id: 0, categorie: "Todos" }],
        idCategorie: 0,
        optionsStock: [
            { value: "t", text: "Todos" },
            { value: "b", text: "Bajo Inv." },
            { value: "m", text: "Medio Inv." },
            { value: "a", text: "Alto Inv." }
        ],
        valueStock: "t",
        search: "",
        dataProduct: new Product,
        products: [],
        tab: 0,
        indexProduct: -1,
        idProduct: -1,
        numberOperation: 0,
        dialogImageIsOpen: false,
        product: {},
        dialogDetailIsOpen: false,
        categorieUser: "",
        reserves: [
            { id: 0, name: "Ninguno" }
        ]
    }),
    computed: {
        categoriesFilter: function() {
            return this.categories.filter(categorie => {
                return categorie.id !== 0;
            });
        },
        searchFilter: function() {
            if (this.mRItIsEmpty(this.search)) return "";
            return this.search;
        }
    },
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        eCloseError() {
            this.mGDProcesAccess();
            if (this.idProduct !== -1) this.resetDataDirect();
            this.dialogErrorIsOpen = false;
        },
        resetDataDirect() {
            this.numberOperation = 0;
            this.idProduct = -1;
            this.indexProduct = -1;
        },
        eCloseConfirm() {
            if (this.idProduct !== -1) this.resetDataDirect();
            this.dialogConfirmationIsOpen = false;
        },
        eBackList() {
            if (this.tab !== 0) {
                this.indexProduct = -1;
                this.dataProduct = new Product;
                this.$refs.formproductcomp.resetForm();
                if (this.numberOperation === 0)
                    this.$refs.formproductcomp.resetFile();
                this.tab = 0;
            }
        },
        eOpenForm() {
            this.numberOperation = 0;
            this.tab = 1;
        },
        async getRequest() {
            this.dialogLoaderDataIsOpen = true;
            try {
                const categories = Categorie.getCategories();
                const products = Product.getProducts(1, "", 2, 2, 0, "t");
                const reserves = Reserve.getBalances();
                const responses = await Promise.all([categories, products, reserves]);
                responses[0].forEach(element => {
                    this.categories.push(element);
                });
                this.products = responses[1].data;
                this.lastPage = responses[1].last_page;
                this.currentPage = responses[1].current_page;
                this.total = responses[1].total;
                responses[2].forEach(element => {
                    this.reserves.push(element);
                });
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eConfirmForm(isValidForm) {
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                if (isValidForm) {
                    if (this.numberOperation === 0)
                        this.smsConfirmations = [`¿Está seguro(a) de registrar: ${this.dataProduct.product}?`];
                    else this.smsConfirmations = [`¿Está seguro(a) de editar: ${this.dataProduct.product}?`];
                    this.dialogConfirmationIsOpen = true;
                } else this.mGDShowError();
            }, time);
        },
        async addProduct() {
            if (this.dataProduct.type === 'd') this.dataProduct.quantity = 0;
            try {
                const response = await Product.addProduct(this.$objectToFD(this.dataProduct));
                this.products.unshift(response);
                this.total += 1;
                this.eBackList();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se registró el producto"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async editProduct() {
            try {
                const response = await Product.editProduct(this.dataProduct);
                Object.assign(this.products[this.indexProduct], response);
                this.eBackList();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se editó el producto"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async changeStateProduct() {
            try {
                const response = await Product.changeStateProduct({id: this.idProduct});
                this.products[this.indexProduct].enabled = response;
                const textState = response === 0 ? 'inhabilitó' : 'habilitó';
                this.resetDataDirect();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", `Se ${textState} el producto`]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async changeWebStateProduct() {
            try {
                const response = await Product.changeWebStateProduct({id: this.idProduct});
                this.products[this.indexProduct].web_enabled = response;
                const textState = response === 0 ? 'inhabilitó' : 'habilitó';
                this.resetDataDirect();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", `Se ${textState} para web el producto`]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async deleteProduct() {
            try {
                await Product.deleteProduct({id: this.idProduct});
                this.products.splice(this.indexProduct, 1);
                this.total -= 1;
                this.resetDataDirect();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", `Se eliminó el producto`]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async updateImageProduct() {
            const parameters = {
                "id": this.product.id,
                "name_file": this.dataProduct.name_file,
                "file": this.dataProduct.file
            }
            try {
                const response = await Product.updateImageProduct(this.$objectToFD(parameters));
                const index = this.products.indexOf(this.product);
                Object.assign(this.products[index], response);
                this.dataProduct = new Product;
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", `Se actualizó la imágen`]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eProcessOperation() {
            this.dialogConfirmationIsOpen = false;
            this.dialogOperationIsOpen = true;
            switch (this.numberOperation) {
                case 0:
                    this.addProduct();
                    break;
                case 1:
                    this.editProduct();
                    break;
                case 2:
                    this.changeStateProduct();
                    break;
                case 3:
                    this.changeWebStateProduct();
                    break;
                case 4:
                    this.deleteProduct();
                    break;
                case 5:
                    this.updateImageProduct();
            }
        },
        eShowFormEdit(product) {
            this.indexProduct = this.products.indexOf(product);
            this.dataProduct = Object.assign({}, product);
            this.numberOperation = 1;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        },
        eConfirmChangeState(product) {
            this.idProduct = product.id;
            this.indexProduct = this.products.indexOf(product);
            this.numberOperation = 2;
            const textState = product.enabled === 0 ? 'habilitar' : 'inhabilitar';
            this.smsConfirmations = [`¿Está seguro de ${textState} el producto: ${product.product}?`];
            this.dialogConfirmationIsOpen = true;
        },
        eConfirmChangeWebState(product) {
            this.idProduct = product.id;
            this.indexProduct = this.products.indexOf(product);
            this.numberOperation = 3;
            const textState = product.enabled === 0 ? 'habilitar para web' : 'inhabilitar para web';
            this.smsConfirmations = [`¿Está seguro de ${textState} el producto: ${product.product}?`];
            this.dialogConfirmationIsOpen = true;
        },
        eDeleteProduct(product) {
            this.idProduct = product.id;
            this.indexProduct = this.products.indexOf(product);
            this.numberOperation = 4;
            this.smsConfirmations = [`¿Está seguro de eliminar el producto: ${product.product}?`];
            this.dialogConfirmationIsOpen = true;
        },
        eChangeFile(parameters) {
            if (parameters.state) {
                this.dataProduct.file = parameters.file;
                this.dataProduct.name_file = `${new Date().getTime()}`;
            } else {
                this.dataProduct.file = null;
                this.dataProduct.name_file = null;
            }
        },
        eOpenDialogImage(product) {
            this.numberOperation = 5;
            this.product = product;
            this.dialogImageIsOpen = true;
        },
        eValidDialogImage() {
            if (this.dataProduct.file == null)
                this.mGDShowAction(["Error de Archivo", `Escoja una imágen`], "error");
            else {
                this.dialogImageIsOpen = false;
                this.smsConfirmations = [`¿Está seguro de actualizar la imágen de: ${this.product.product}?`];
                this.dialogConfirmationIsOpen = true;
            }
        },
        eShowDetailProduct(product) {
            this.product = product;
            this.dialogDetailIsOpen = true;
        },
         //metodos para buscar productos
        async getProducts(page, search, enabled, webEnabled, idCategorie, stock) {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Product.getProducts(page, search, enabled, webEnabled, idCategorie, stock);
                this.products = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getProducts(this.currentPage, this.search, this.valueEnabled, this.valueWebEnabled, this.idCategorie, this.valueStock);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mGDSearchForPage(page, this.mRIsInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getProducts(page, this.search, this.valueEnabled, this.valueWebEnabled, this.idCategorie, this.valueStock);
                }, time);
            }
        },
        eSearchProducts() {
            if (this.mRItIsEmpty(this.search)) this.search = "";
            this.getProducts(1, this.search, this.valueEnabled, this.valueWebEnabled, this.idCategorie, this.valueStock);
        },
        eChangeCategorie(idCategorie) {
            this.getProducts(1, this.search, this.valueEnabled, this.valueWebEnabled, idCategorie, this.valueStock);
        },
        eChangeState(enabled) {
            this.getProducts(1, this.search, enabled, this.valueWebEnabled, this.idCategorie, this.valueStock);
        },
        eChangeWebState(webEnabled) {
            this.getProducts(1, this.search, this.valueEnabled, webEnabled, this.idCategorie, this.valueStock);
        },
        eChangeStock(stock) {
            this.getProducts(1, this.search, this.valueEnabled, this.valueWebEnabled, this.idCategorie, stock);
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            this.categorieUser = this.mUserCategoryEmployee();
            if (this.mUserCategoryEmployee() === 'a') this.getRequest();
            else this.$router.replace('/admin/home');
        } else this.$router.replace('/login-admin');
    }
}
</script>