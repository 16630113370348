var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"formproduct",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.indexProduct === -1)?_c('div',{staticClass:"d-flex justify-center mb-6"},[_c('PrCPhotoFormComp',{ref:"photoformcomp",attrs:{"refImg":"fotografiaup","title":"Foto","subtitle":"Producto","fileFormat":"image/jpeg"},on:{"changephoto":_vm.eChangePhoto}})],1):_vm._e(),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Producto"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Producto' : '',"counter":50,"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => (`${value}`).length <= 50 || _vm.smsExecedCounter(50)
            ]},model:{value:(_vm.dataProduct.product),callback:function ($$v) {_vm.$set(_vm.dataProduct, "product", $$v)},expression:"dataProduct.product"}})],1),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Descripción"}},[_c('PrMTextAreaComp',{attrs:{"label":_vm.isMobile ? 'Descripción' : '',"counter":200,"rules":[
                value => (`${value}`).length <= 200 || _vm.smsExecedCounter(200)
            ]},model:{value:(_vm.dataProduct.description),callback:function ($$v) {_vm.$set(_vm.dataProduct, "description", $$v)},expression:"dataProduct.description"}})],1),(_vm.indexProduct === -1)?_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Tipo"}},[_c('PrMSelectComp',{attrs:{"label":_vm.isMobile ? 'Tipo' : '',"items":_vm.options,"itemValue":"value","itemText":"text","rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty
            ]},model:{value:(_vm.dataProduct.type),callback:function ($$v) {_vm.$set(_vm.dataProduct, "type", $$v)},expression:"dataProduct.type"}})],1):_vm._e(),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Categoría"}},[_c('PrMSelectComp',{attrs:{"label":_vm.isMobile ? 'Categoría' : '',"items":_vm.categories,"itemValue":"id","itemText":"categorie","rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty
            ]},model:{value:(_vm.dataProduct.fk_categorie),callback:function ($$v) {_vm.$set(_vm.dataProduct, "fk_categorie", $$v)},expression:"dataProduct.fk_categorie"}})],1),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Precio"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Precio' : '',"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => _vm.mRIsNumeric(value) || _vm.smsNotNumeric
            ]},model:{value:(_vm.dataProduct.price),callback:function ($$v) {_vm.$set(_vm.dataProduct, "price", $$v)},expression:"dataProduct.price"}})],1),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Precio Costo"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Precio Costo' : '',"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => _vm.mRIsNumeric(value) || _vm.smsNotNumeric
            ]},model:{value:(_vm.dataProduct.price_cost),callback:function ($$v) {_vm.$set(_vm.dataProduct, "price_cost", $$v)},expression:"dataProduct.price_cost"}})],1),(_vm.dataProduct.type === 'n')?_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Cantidad"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Cantidad' : '',"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => _vm.mRIsInteger(value) || _vm.smsNotInteger
            ]},model:{value:(_vm.dataProduct.quantity),callback:function ($$v) {_vm.$set(_vm.dataProduct, "quantity", $$v)},expression:"dataProduct.quantity"}})],1):_vm._e(),(_vm.indexProduct === -1)?_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Elección Saldo"}},[_c('PrMSelectComp',{attrs:{"label":_vm.isMobile ? 'Elección Saldo' : '',"items":_vm.reserves,"itemValue":"id","itemText":"name","rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty
            ]},model:{value:(_vm.dataProduct.id_reserve),callback:function ($$v) {_vm.$set(_vm.dataProduct, "id_reserve", $$v)},expression:"dataProduct.id_reserve"}})],1):_vm._e(),(_vm.indexProduct === -1 && _vm.dataProduct.id_reserve > 0)?_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Retención Saldo"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Retención Saldo' : '',"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => _vm.mRIsNumeric(value) || _vm.smsNotNumeric
            ]},model:{value:(_vm.dataProduct.balance_hold),callback:function ($$v) {_vm.$set(_vm.dataProduct, "balance_hold", $$v)},expression:"dataProduct.balance_hold"}})],1):_vm._e(),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Requiere Información"}},[_c('PrMSelectComp',{attrs:{"label":_vm.isMobile ? 'Requiere Información' : '',"items":_vm.optionsInfo,"itemValue":"value","itemText":"text","rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty
            ]},model:{value:(_vm.dataProduct.necessary_info),callback:function ($$v) {_vm.$set(_vm.dataProduct, "necessary_info", $$v)},expression:"dataProduct.necessary_info"}})],1),(_vm.dataProduct.necessary_info == 1)?_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Mensaje Info."}},[_c('PrMTextAreaComp',{attrs:{"label":_vm.isMobile ? 'Mensaje Info.' : '',"counter":200,"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => (`${value}`).length <= 200 || _vm.smsExecedCounter(200)
            ]},model:{value:(_vm.dataProduct.info_guide),callback:function ($$v) {_vm.$set(_vm.dataProduct, "info_guide", $$v)},expression:"dataProduct.info_guide"}})],1):_vm._e(),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Considerar Tiempo"}},[_c('PrMSelectComp',{attrs:{"label":_vm.isMobile ? 'Considerar Tiempo' : '',"items":_vm.optionsTime,"itemValue":"value","itemText":"text","rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty
            ]},model:{value:(_vm.dataProduct.consider_time),callback:function ($$v) {_vm.$set(_vm.dataProduct, "consider_time", $$v)},expression:"dataProduct.consider_time"}})],1),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Tiempo Entrega"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Tiempo Entrega' : '',"counter":20,"rules":[
                value => _vm.eValidTime(value, _vm.dataProduct.consider_time) || _vm.smsEmpty,
                value => (`${value}`).length <= 20 || _vm.smsExecedCounter(20)
            ]},on:{"click":function($event){return _vm.eValidateForm()}},model:{value:(_vm.dataProduct.time),callback:function ($$v) {_vm.$set(_vm.dataProduct, "time", $$v)},expression:"dataProduct.time"}})],1),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Instrucciones Web"}},[_c('PrMTextAreaComp',{attrs:{"label":_vm.isMobile ? 'Instrucciones Web' : '',"counter":500,"rules":[
                value => (`${value}`).length <= 200 || _vm.smsExecedCounter(500)
            ]},model:{value:(_vm.dataProduct.instructions),callback:function ($$v) {_vm.$set(_vm.dataProduct, "instructions", $$v)},expression:"dataProduct.instructions"}})],1),_c('div',{staticClass:"d-flex justify-center mt-1"},[_c('PrMBtnNormalComp',{on:{"click":function($event){return _vm.eValidateForm()}}},[_c('v-icon',[_vm._v("mdi-bookmark-check")]),_vm._v(" GUARDAR ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }