<template>
    <v-dialog
        persistent
        :value="value"
        width="340"
    >
        <v-card class="pa-0 ma-0">
            <div class="d-flex align-center pa-2 pr-cp-background">
                <h1 class="pr-app-title-dark">Ban Cliente</h1>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    x-small
                    depressed
                    color="red"
                    @click="$emit('clickclose')"
                >
                    <v-icon
                        class="pr-cd-color"
                    >mdi-close</v-icon>
                </v-btn>
            </div>
            
            <div class="pa-2">
                <PrCFillInformationComp
                    v-if="dataClient.id != undefined"
                    title="Cliente"
                    :subtitle="dataClient.full_name"
                />
                <v-form
                    v-model="valid"
                    lazy-validation
                    ref="forminhab"
                >
                    <PrMTextFieldComp
                        v-model="dataClient.banned"
                        label="Motivo Ban"
                        class="mt-3"
                        :counter="50"
                        :rules="[
                            value => !this.mRItIsEmpty(value) || smsEmpty,
                            value => (`${value}`).length <= 50 || smsExecedCounter(50)
                        ]"
                    />
                </v-form>
                <div class="d-flex justify-center mt-1">
                    <PrMBtnNormalComp
                        @click="eValidateForm()"
                    >
                        <v-icon>mdi-bookmark-check</v-icon>
                        GUARDAR
                    </PrMBtnNormalComp>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    PrMBtnNormalComp,
    PrMTextFieldComp
} from "../../molecules";
import {
    PrCFillInformationComp
} from "../../cells";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [rulesMixin],
    props: {
        dataClient: Object,
        value: Boolean
    },
    components: {
        PrMBtnNormalComp,
        PrMTextFieldComp,
        PrCFillInformationComp
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidateForm() {
            if (this.$refs.forminhab.validate())
                this.$emit('clickform', true);
            else this.$emit('clickform', false);
        },
        resetForm() {
            this.$refs.forminhab.resetValidation();
        }
    }
}
</script>