export default {
    methods: {
        mCryGetArray() {
            return ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k',
            'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x',
            'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        },
        mCryEncryptLetter(letter) {
            const letters = this.mCryGetArray();
            for (let i = 0; i < letters.length; i++) {
                if (letter == letters[i]) return (i + 7);
            }
            return letter;
        },
        mCryGenerateCodeEncrypt(text) {
            const firstPart = text.substring(0, text.length / 2);
            const secondPart = text.substring(text.length / 2, text.length);
            const newText = `${secondPart}${firstPart}`; 
            let messageEncript = "";
            for (let i = 0; i < newText.length; i++) {
                if (i === (newText.length - 1))
                    messageEncript = `${messageEncript}${this.mCryEncryptLetter(newText[i])}`;
                else messageEncript = `${messageEncript}${this.mCryEncryptLetter(newText[i])}|`;
            }
            return messageEncript;
        },
        mCryIntegerNumber(input) {
            if (isNaN(input)) return false;
            const number = parseFloat(input);
            return number%1 === 0;
        },
        mCryDecryptText(text) {
            const letters = this.mCryGetArray();
            const listInfo = text.split('|');
            let decode = "";
            for (let i = 0; i < listInfo.length; i++) {
                if (this.mCryIntegerNumber(listInfo[i])) {
                    const position = parseInt(listInfo[i]) - 7;
                    decode = `${decode}${letters[position]}`;
                } else decode = `${decode}${listInfo[i]}`;
            }
            let lengthDecode = decode.length;
            if (lengthDecode % 2 !== 0) lengthDecode += 1;
            const firstPart = decode.substring(0, lengthDecode / 2);
            const secondPart = decode.substring(lengthDecode / 2, decode.length);
            const decryptCode = `${secondPart}${firstPart}`;
            return decryptCode;
        }
    }
}