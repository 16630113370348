<template>
    <div class="o-table-purchases animation-opacity">
        <v-form
            v-model="valid"
            lazy-validation
            ref="formcart"
        >
            <table class="o-table-purchases__table">
                <thead>
                    <tr>
                        <th>
                            <h1 class="pr-app-title-dark text-center">N°</h1>
                        </th>
                        <th>
                            <h1 class="pu-app-title-dark">Producto</h1>
                        </th>
                        <th>
                            <h1 class="pr-app-title-dark">Info Adicional</h1>
                        </th>
                        <!--<th>
                            <h1 class="pr-app-title-dark">Disponible</h1>
                        </th>-->
                        <th>
                            <h1 class="pr-app-title-dark">Tipo</h1>
                        </th>
                        <th>
                            <h1 class="pr-app-title-dark">Precio</h1>
                        </th>
                        <th width="200">
                            <h1 class="pr-app-title-dark">Cantidad</h1>
                        </th>
                        <th width="120">
                            <h1 class="pr-app-title-dark">Total</h1>
                        </th>
                        <th width="120">
                            <h1 class="pr-app-title-dark">Quitar</h1>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(product, index) in products"
                        :key="product.id"
                    >
                        <td>
                            <h1 class="pr-app-subtitle cp-pu-color">
                                {{(index + 1)}}
                            </h1>
                        </td>
                        <td>
                            <h1 class="pr-app-subtitle cp-pu-color">
                                {{product.product.product}}
                            </h1>
                        </td>
                        <td>
                            <h1
                                class="pr-app-subtitle cp-pu-color"
                                style="white-space: pre-line;"
                            >
                                {{product.info != null
                                    ? product.info
                                    : "S/I"}}
                            </h1>
                        </td>
                        <!--<td>
                            <h1 class="pr-app-subtitle cp-pu-color">
                                {{product.product.quantity}}
                            </h1>
                        </td>-->
                        <td>
                            <h1 class="pr-app-subtitle cp-pu-color">
                                {{product.product.type === 'n' ? 'Físico' : 'Digital'}}
                            </h1>
                        </td>
                        <td>
                            <h1 class="pr-app-subtitle cp-pu-color text-right">
                                {{product.product.price.toFixed(2)}}
                            </h1>
                        </td>
                        <td>
                            <div class="d-flex align-center">
                                <v-btn
                                    small
                                    width="20"
                                    color="rgba(255,255,255,0.5)"
                                    max-width="20"
                                    min-width="20"
                                    class="mr-1"
                                    :ripple="false"
                                    @click="$emit('clickquantityleft', product)"
                                >
                                    <v-icon>mdi-menu-left</v-icon>
                                </v-btn>
                                <MTextFieldHeaderComp
                                    style="max-width:120px; min-width:120px;"
                                    :dark="true"
                                    v-model="product.quantity"
                                    :rules="[
                                        value => !mRItIsEmpty(value),
                                        value => mRIsInteger(value),
                                        value => parseInt(value) > 0,
                                        value => !mRExcedeLimite(value, product.product.quantity)
                                    ]"
                                />
                                <v-btn
                                    small
                                    width="20"
                                    color="rgba(255,255,255,0.5)"
                                    max-width="20"
                                    min-width="20"
                                    class="ml-1"
                                    :ripple="false"
                                    @click="$emit('clickquantityright', product)"
                                >
                                    <v-icon>mdi-menu-right</v-icon>
                                </v-btn>
                            </div>
                        </td>
                        <td>
                            <h1 class="pr-app-subtitle cp-pu-color text-right">
                                {{getTotal(product).toFixed(2)}}
                            </h1>
                        </td>
                        <td>
                            <div class="d-flex justify-center flex-wrap">
                                <CBtnToolTipTableComp
                                    icon="mdi-format-list-bulleted-square"
                                    :smsToolTip="`Ver detalle: ${product.product.product}`"
                                    @click="$emit('clickdetal', product)"
                                />
                                <CBtnToolTipTableComp
                                    icon="mdi-delete"
                                    :smsToolTip="`Quitar: ${product.product.product}`"
                                    @click="$emit('clickdelete', product)"
                                />
                            </div>
                        </td>
                    </tr>
                    <tr v-if="products.length === 0">
                        <td colspan="9">
                            <h1 class="pr-app-subtitle-dark text-center">Sin Productos</h1>
                        </td>
                    </tr>
                </tbody>
            </table>
        </v-form>
    </div>
</template>
<script>
import {
    MTextFieldHeaderComp
} from "../../molecules";
import {
    CBtnToolTipTableComp
} from "../../cells";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    components: {
        MTextFieldHeaderComp,
        CBtnToolTipTableComp
    },
    mixins: [
        rulesMixin
    ],
    props: {
        products: Array
    },
    data: () => ({
        valid: true
    }),
    methods: {
        getTotal(product) {
            if (this.mRItIsEmpty(product.quantity) || !this.mRIsInteger(product.quantity))
                return 0;
            if (parseInt(product.quantity) <= 0) return 0;
            const total = product.product.price * parseInt(product.quantity);
            return total;
        },
        validateForm() {
            return this.$refs.formcart.validate();
        }
    }
}
</script>