<template>
    <v-menu
        transition="slide-x-transition"
        bottom
        right
        offset-y
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                depressed
                v-bind="attrs"
                v-on="on"
                x-small
                outlined
                class="pr-cs-color"
                :ripple="false"
                @click="$emit('click')"
                style="margin:2px"
            >
                <PrMIconComp
                    :icon="icon"
                    classIcon="pr-cs-color"
                />
            </v-btn>
        </template>
        <v-card class="pa-1">
            <v-list-item
                v-for="(option, index) in options"
                :key="index"
                class="s-menu-pc__menu-option"
                @click="$emit('clickquantity', option)"
                :ripple="false"
            >
                <h1 class="pr-app-subtitle">{{`${option}`}}</h1>
            </v-list-item>
        </v-card>
    </v-menu>
</template>
<script>
import {
    PrMIconComp
} from "../molecules";
export default {
    components: {
        PrMIconComp
    },
    props: {
        options: Array,
        icon: String
    }
}
</script>