<template>
    <div style="height:100%">
        <div class="d-flex justify-center" style="height:100%">
            <div class="account__body">
                <div class="account__body-menu">
                    <CMenuAccountComp
                        :options="options"
                        ref="menuaccount"
                        @clicktab="eProcessElection"
                    />
                </div>
                <div class="account__menu-mobile">
                    <div class="account__tabs">
                        <div
                            class="account__tab"
                            v-for="(option, index) in options"
                            :key="index"
                            @click="eProcessElection(option.numberTab)"
                        >
                            <v-icon class="cp-pu-color">{{option.icon}}</v-icon>
                            <h1
                                v-if="!isMobile"
                                class="pu-app-title-dark cp-pu-color"
                            >{{option.title}}</h1>
                            <div
                                v-if="tab===option.numberTab"
                                class="account__tab-ornament"></div>
                        </div>
                    </div>
                </div>
                <div class="account__body-info">
                    <v-tabs-items
                        style="background:transparent !important; width:100%;"
                        v-model="tab"
                        :touchless="true"
                    >
                        <v-tab-item>
                            <div
                                v-if="client.id != undefined"
                                class="account__credentials"
                            >
                                <CFillInformationComp
                                    title="Nombre Completo"
                                    :subtitle="client.full_name"
                                />
                                <CFillInformationComp
                                    title="Correo Gmail"
                                    :subtitle="client.email"
                                />
                                <div class="d-flex flex-wrap">
                                    <MBtnNormalComp
                                        class="mt-2"
                                        @click="dialogUserIsOpen = true"
                                    >
                                        Cambiar contraseña
                                    </MBtnNormalComp>
                                </div>
                            </div>
                        </v-tab-item>
                        <v-tab-item>
                            <div class="account__info-header">
                                <CFillInformationComp
                                    title="Cantidad Compras"
                                    :subtitle="`${total}`"
                                />
                                <v-spacer></v-spacer>
                                <div class="d-flex account__info-dates">
                                    <MTextFieldComp
                                        label="Fecha Inicio"
                                        :dark="true"
                                        class="mr-2"
                                        type="date"
                                        v-model="dateOne"
                                        :clearable="false"
                                        background=""
                                        style="font-weight:300 !important"
                                        :hideDetails="true"
                                        @change="eChangeDateOne"
                                    />
                                    <MTextFieldComp
                                        label="Fecha Final"
                                        :dark="true"
                                        type="date"
                                        v-model="dateTwo"
                                        :clearable="false"
                                        background=""
                                        style="font-weight:300 !important"
                                        :hideDetails="true"
                                        @change="eChangeDateTwo"
                                    />
                                </div>
                            </div>
                            <CLoaderDataComp
                                :class="isMobile ? 'mt-2' : ''"
                                v-if="dialogLoaderDataIsOpen"
                            />
                            <MWithoutInfoCardsComp
                                class="ma-2"
                                v-if="!dialogLoaderDataIsOpen && purchases.length === 0 && isMobile"
                                title="Sin Compras"
                            />
                            <OTablePurchasesClientComp
                                v-if="!dialogLoaderDataIsOpen && !isMobile"
                                :purchases="purchases"
                                @clickdetail="eShowDetailPurchase"
                            />
                            <div
                                v-if="!dialogLoaderDataIsOpen && isMobile"
                                class="d-flex justify-center flex-wrap"
                            >
                                <OCardPurchaseComp
                                    class="ma-2"
                                    v-for="purchase in purchases"
                                    :key="purchase.id"
                                    :purchase="purchase"
                                    @clickdetail="eShowDetailPurchase(purchase)"
                                />
                            </div>
                            <SPaginationComp
                                v-if="!dialogLoaderDataIsOpen"
                                :class="isMobile ? 'ma-2' : 'mt-2 mb-2'"
                                :condicionGo="currentPage < lastPage"
                                :condicionBack="currentPage > 1"
                                :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                                @clickback="eChangePage(false)"
                                @clickgo="eChangePage(true)"
                                @clickpage="eSearchForPage"
                            />
                        </v-tab-item>
                        <v-tab-item>
                            <div
                                v-if="purchase.id != undefined"
                                class="account__purchase-header"
                            >
                                <CFillInformationComp
                                    title="Total Compra"
                                    :subtitle="`${mPUTotalDetail(purchase.product_sale).toFixed(2)} Bs.`"
                                />
                                <CFillInformationComp
                                    title="Fecha"
                                    :subtitle="purchase.date"
                                />
                                <CFillInformationComp
                                    title="Hora"
                                    :subtitle="purchase.hour"
                                />
                            </div>
                            <MWithoutInfoCardsComp
                                class="ma-2"
                                v-if="isMobile && purchase.product_sale.length === 0"
                                title="Elija una compra"
                            />
                            <OTableDetailSaleComp
                                v-if="!isMobile"
                                class="mt-2"
                                :delivered="purchase.delivered"
                                :detail="purchase.product_sale"
                                @clickdetail="eShowFillDetail"
                                @clickkeys="eShowDigitals"
                            />
                            <div
                                v-if="isMobile"
                                class="d-flex justify-center flex-wrap"
                            >
                                <OCardDetailProductComp
                                    class="ma-2"
                                    :delivered="purchase.delivered"
                                    v-for="product in purchase.product_sale"
                                    :key="product.id"
                                    :product="product"
                                    @clickdetail="eShowFillDetail(product)"
                                    @clickkeys="eShowDigitals(product)"
                                />
                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </div>
        </div>
        <div class="space--block"></div>
        <OFormUserComp
            :value="dialogUserIsOpen"
            :dataUser="dataUser"
            ref="formusercomp"
            @clickform="eValidForm"
            @clickclose="eCloseFormUser()"
        />
        <ODialogPurchaseComp
            :product="product"
            :value="dialogPurchaseIsOpen"
            @clickclose="dialogPurchaseIsOpen = false"
        />
        <ODialogDigitalsComp
            :product="product"
            :value="dialogDigitalsIsOpen"
            @clickclose="dialogDigitalsIsOpen = false"
        />
        <OGeneralDialogsComp
            :dialogErrorIsOpen="dialogErrorIsOpen"
            :smsErrors="smsErrors"
            :isError="isError"
            :actionErrorIsActivated="actionErrorIsActivated"
            @clickerror="eCloseError()"
            :dialogConfirmationIsOpen="dialogConfirmationIsOpen"
            :smsConfirmations="smsConfirmations"
            @clickyes="eProcessOperation()"
            @clickno="eCloseConfirm()"
            :smsActions="smsActions"
            :frameAction="frameAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
            :dialogOperationIsOpen="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    MBtnNormalComp,
    MTextFieldComp,
    MWithoutInfoCardsComp
} from "../../components/components_public/molecules";
import {
    CMenuAccountComp,
    CFillInformationComp,
    CLoaderDataComp
} from "../../components/components_public/cells";
import {
    OTablePurchasesClientComp,
    OFormUserComp,
    OGeneralDialogsComp,
    OTableDetailSaleComp,
    ODialogPurchaseComp,
    ODialogDigitalsComp,
    OCardPurchaseComp,
    OCardDetailProductComp
} from "../../components/components_public/organisms";
import {
    SPaginationComp
} from "../../components/components_public/species";
import { User, Sale } from '../../models';
import {
    dataGeneralPublicMixin,
    userPublicMixin,
    responseServerMixin,
    animationScrollMixin,
    purchasesMixin,
    datesMixin,
    rulesMixin
} from "../../mixins";
export default {
    mixins: [
        rulesMixin,
        dataGeneralPublicMixin,
        userPublicMixin,
        responseServerMixin,
        animationScrollMixin,
        purchasesMixin,
        datesMixin
    ],
    components: {
        CMenuAccountComp,
        OTablePurchasesClientComp,
        MBtnNormalComp,
        OFormUserComp,
        OGeneralDialogsComp,
        MTextFieldComp,
        CFillInformationComp,
        CLoaderDataComp,
        OTableDetailSaleComp,
        ODialogPurchaseComp,
        ODialogDigitalsComp,
        OCardPurchaseComp,
        OCardDetailProductComp,
        MWithoutInfoCardsComp,
        SPaginationComp
    },
    data: () => ({
        options: [
            {
                icon: "mdi-account",
                title: "Mi Cuenta",
                numberTab: 0
            }, {
                icon: "mdi-cart-variant",
                title: "Mis Compras",
                numberTab: 1
            }, {
                icon: "mdi-gamepad-square",
                title: "Detalle Compra",
                numberTab: 2
            }
        ],
        tab: 0,
        client: {},
        purchases: [],
        dialogUserIsOpen: false,
        dataUser: new User,
        dateOne: "",
        dateTwo: "",
        purchase: {
            delivered: 0,
            product_sale: []
        },
        dialogPurchaseIsOpen: false,
        product: {},
        dialogDigitalsIsOpen: false
    }),
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        eValidForm(isValidForm) {
            if (!isValidForm)
                this.mGDShowAction(["Opereción incorrecta", "Corrija los campos en rojo"], true);
            else {
                this.smsConfirmations = [`¿Está seguro de actualizar sus credenciales?`];
                this.dialogUserIsOpen = false;
                this.dialogConfirmationIsOpen = true;
            }
        },
        eCloseFormUser() {
            this.dataUser = new User;
            this.$refs.formusercomp.resetForm();
            this.dialogUserIsOpen = false;
        },
        eCloseConfirm() {
            this.dialogConfirmationIsOpen = false;
            this.dialogUserIsOpen = true;
        },
        async changeCredentialsUser() {
            let md5 = require('md5');
            const parameters = {
                'username': this.mUserUsername(),
                'password': md5(this.dataUser.password)
            }
            try {
                await User.changePassword(parameters);
                this.eCloseFormUser();
                this.mGDShowAction(["Opereción incorrecta", "Se actualizó su contraseña"]);
                this.dialogOperationIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eProcessOperation() {
            this.dialogConfirmationIsOpen = false;
            this.dialogOperationIsOpen = true;
            this.changeCredentialsUser();
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        eProcessElection(election) {
            this.purchase = {
                product_sale: []
            }
            this.tab = election;
        },
        async getPurchases(page, dateOne, dateTwo) {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Sale.purchasesClient(page, dateOne, dateTwo);
                this.purchases = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eChangeDateOne() {
            if (this.dateTwo === "" || this.dateOne === "")
                this.mGDShowAction(["Opereción incorrecta", "Selecciones las fechas porfavor"]);
            else {
                if (this.dateOne > this.dateTwo)
                    this.dateTwo = this.dateOne;
                this.getPurchases(1, this.dateOne, this.dateTwo);
            }
        },
        eChangeDateTwo() {
            if (this.dateTwo === "" || this.dateOne === "")
                this.mGDShowAction(["Opereción incorrecta", "Selecciones las fechas porfavor"]);
            else {
                if (this.dateOne > this.dateTwo)
                    this.dateOne = this.dateTwo;
                this.getPurchases(1, this.dateOne, this.dateTwo);
            }
        },
        eShowDetailPurchase(purchase) {
            if (!this.isMobile) this.$refs.menuaccount.changeTab(2);
            this.purchase = purchase;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 2;
            }, time);
        },
        eShowFillDetail(product) {
            this.product = product;
            this.dialogPurchaseIsOpen = true;
        },
        eShowDigitals(product) {
            this.product = product;
            this.dialogDigitalsIsOpen = true;
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getPurchases(this.currentPage, this.dateOne, this.dateTwo);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mGDSearchForPage(page, this.mRIsInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getPurchases(page, this.dateOne, this.dateTwo);
                }, time);
            }
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader()) {
            const client = JSON.parse(localStorage.getItem("client_gatonet"));
            this.client = client.client;
            this.dateOne = this.dateTwo = this.mDateSystem(false);
            this.getPurchases(1, "", "");
        } else this.$router.replace('/login-client');
    }
}
</script>