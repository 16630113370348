<template>
    <div
        class="c-card-home"
        v-intersect="onIntersect"
    >
        <div class="d-flex justify-center mb-2">
            <v-card
                width="100px"
                height="100px"
                class="rounded-0 mb-3"
                elevation="0"
                color="transparent"
            >
                <v-img
                    :src="option.src"
                    width="100%"
                    height="100%"
                ></v-img>
            </v-card>
        </div>
        <h1 class="pu-app-title-dark text-center">{{option.title}}</h1>
        <v-card
            width="100%"
            height="10px"
            style="opacity:0.3"
            elevation="0"
            class="cp-pu-background mt-1"
        ></v-card>
        <h1 class="pu-app-subtitle-dark text-center mt-2">
            {{option.subtitle}}
        </h1>
    </div>
</template>
<script>
export default {
    props: {
        option: Object,
        index: Number
    },
    data: () => ({
        isIntersecting: false,
        isMobile: false
    }),
    methods: {
        onIntersect (entries, observer) {
            if (entries[0].isIntersecting && !this.isMobile) {
                this.isIntersecting = entries[0].isIntersecting;
                const element = document.getElementsByClassName("c-card-home");
                element[this.index].style.transition = "800ms";
                element[this.index].style.paddingTop = "0px";
                element[this.index].style.opacity = "1";
            }
        },
        closeAnimation() {
            const element = document.getElementsByClassName("c-card-home");
            element[this.index].style.paddingTop = "0px";
            element[this.index].style.opacity = "1";
        }
    },
    mounted() {
        if (window.innerWidth <= 600) {
            this.isMobile = true;
            this.closeAnimation();
        }
    }
}
</script>