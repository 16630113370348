<template>
    <div class="pr-o-table-categories animation-opacity">
        <table class="pr-o-table-categories__table">
            <thead>
                <tr>
                    <th>
                        <h1 class="pr-app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Nombre Control</h1>
                    </th>
                    <th width="220px">
                        <h1 class="pr-app-title-dark">Apertura Bs.</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(data, index) in listCloseBox"
                    :key="data.id"
                >
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{(index + 1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{data.name}}
                        </h1>
                    </td>
                    <td>
                        <PrMTextFieldComp
                            style="width:220px"
                            v-model="data.beginning"
                            :rules="[
                                value => !mRItIsEmpty(value) || smsEmpty,
                                value => mRIsNumeric(value) || smsNotNumeric
                            ]"
                        />
                    </td>
                </tr>
                <tr v-if="listCloseBox.length === 0">
                    <td colspan="4">
                        <h1 class="pr-app-subtitle text-center">Sin Información</h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    PrMTextFieldComp
} from "../../molecules";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [rulesMixin],
    props: {
        listCloseBox: Array
    },
    components: {
        PrMTextFieldComp
    }
}
</script>