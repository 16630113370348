import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from "../store/modules";
class Publication {
    constructor(
        file = null,
        name_file = null
    ) {
        this.file = file;
        this.name_file = name_file;
    }
    static async getPublication()
    {
        const response = await axios.get(
            `${apiUrls.PUBLICATION}list`,
            //{ headers: userStore.state.header }
        );
        return response.data;
    }
    static async addPublication(parameters)
    {
        const apitoken = JSON.parse(localStorage.getItem("ecomerce_gatonet")).token;
        const header = {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json",
            "Authorization": "Bearer " + apitoken,
        }
        const response = await axios.post(
            `${apiUrls.PUBLICATION}add`,
            parameters,
            { headers: header }
        );
        return response.data;
    }
    static async deletePublication(parameters)
    {
        const response = await axios.delete(
            `${apiUrls.PUBLICATION}delete`,
            { data: parameters, headers: userStore.state.header }
        );
        return response.data;
    }
}
export default Publication;