<template>
    <v-dialog
        :value="value"
        persistent
        width="280"
    >
        <v-card color="#FAFAFA">
            <div class="pa-2 pb-1">
                <div class="d-flex justify-center">
                    <v-card
                        class="rounded-0"
                        color="transparent"
                        elevation="0"
                        width="120px"
                        height="120px"
                    >
                        <v-img
                            width="100%"
                            height="100%"
                            contain
                            src="/imgs_public/logos/logo.svg"
                        ></v-img>
                    </v-card>
                </div>
                <h1 class="pr-app-title text-center mt-2">
                    {{message}}
                </h1>
            </div>
            <v-progress-linear
                height="10"
                class="pr-cp-background"
                indeterminate
            ></v-progress-linear>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: "Realizando Operación"
        }
    }
}
</script>
