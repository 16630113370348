<template>
    <div class="d-flex justify-center">
        <div class="pr-e-skeleton-form">
            <div class="pr-e-skeleton-form__header">
                <h1 class="pr-app-title-dark text-center">
                    {{title}}
                </h1>
            </div>
            <div class="pr-e-skeleton-form__body">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: String
    }
}
</script>