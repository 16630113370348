import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from "../store/modules";
import { userClientStore } from "../store/modules";
class Sale {
    constructor(
        delivered = null,
        type_sale = null,
        type_coin = "e",
        note = null
    ) {
        this.delivered = delivered;
        this.type_sale = type_sale;
        this.type_coin = type_coin;
        this.note = note;
    }
    static async addSale(parameters) {
        const response = await axios.post(
            `${apiUrls.SALE}add`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async cancelSale(parameters) {
        const response = await axios.patch(
            `${apiUrls.SALE}cancel-sale`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async reportSale(page, dateOne, dateTwo, delivered, typeSale, typeCoin) {
        const response = await axios.get(
            `${apiUrls.SALE}report?page=${page}&date_one=${dateOne}&date_two=${dateTwo}&delivered=${delivered}&type_sale=${typeSale}&type_coin=${typeCoin}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async historyClient(page, fkClient, dateOne, dateTwo, enabled, typeSale) {
        const response = await axios.get(
            `${apiUrls.SALE}history-client?page=${page}&fk_client=${fkClient}&date_one=${dateOne}&date_two=${dateTwo}&enabled=${enabled}&type_sale=${typeSale}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async mySales(typeCoin) {
        const response = await axios.get(
            `${apiUrls.SALE}my-sales?type_coin=${typeCoin}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async disabledSales(page, dateOne, dateTwo, typeSale) {
        const response = await axios.get(
            `${apiUrls.SALE}disabled-sales?page=${page}&date_one=${dateOne}&date_two=${dateTwo}&type_sale=${typeSale}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async closeBox(idUser, typeSale) {
        const response = await axios.get(
            `${apiUrls.SALE}close-box?fk_user=${idUser}&type_sale=${typeSale}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async getSalesNotShipped(page, dateOne, dateTwo, search) {
        const response = await axios.get(
            `${apiUrls.SALE}sales-not-shipped?page=${page}&date_one=${dateOne}&date_two=${dateTwo}&search=${search}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async sendSale(parameters) {
        const response = await axios.patch(
            `${apiUrls.SALE}send-sale`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async getSalesWithPendingCodes(page, dateOne, dateTwo, search) {
        const response = await axios.get(
            `${apiUrls.SALE}sales-with-pending-codes?page=${page}&date_one=${dateOne}&date_two=${dateTwo}&search=${search}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }

    //methods client
    static async addSaleClient(parameters) {
        const response = await axios.post(
            `${apiUrls.SALE}add-sale-client`,
            parameters,
            { headers: userClientStore.state.header }
        );
        return response.data;
    }
    static async purchasesClient(page, dateOne, dateTwo) {
        const response = await axios.get(
            `${apiUrls.SALE}purchases-client?page=${page}&date_one=${dateOne}&date_two=${dateTwo}`,
            { headers: userClientStore.state.header }
        );
        return response.data;
    }
    static async addSaleClientQr(parameters) {
        const response = await axios.post(
            `${apiUrls.SALE}add-sale-qr`,
            parameters,
            { headers: userClientStore.state.header }
        );
        return response.data;
    }
    static reportPdf(dateOne, dateTwo, delivered, typeSale, typeCoin) {
        window.open(`${apiUrls.SALE}report-pdf?date_one=${dateOne}&date_two=${dateTwo}&delivered=${delivered}&type_sale=${typeSale}&type_coin=${typeCoin}`, '_blank')
    }
    static async reportForTypeSales(dateFrom, dateTo) {
        const response = await axios.get(
            `${apiUrls.SALE}report-for-type-sale?date_one=${dateFrom}&date_two=${dateTo}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default Sale;