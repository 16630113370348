<template>
    <div class="pr-o-table-categories animation-opacity">
        <table class="pr-o-table-categories__table">
            <thead>
                <tr>
                    <th>
                        <h1 class="pr-app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Producto</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Imágen</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Descripción</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Inventario</h1>
                    </th>
                    <th width="65">
                        <h1 class="pr-app-title-dark">Precio</h1>
                    </th>
                    <th width="50">
                        <h1 class="pr-app-title-dark">Cant.</h1>
                    </th>
                    <th width="65">
                        <h1 class="pr-app-title-dark">Costo</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Categoría</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(product, index) in products"
                    :key="product.id"
                >
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{(index + 1)}}
                        </h1>
                    </td>
                    <td>
                        <text-highlight
                            class="pr-app-subtitle"
                            :queries="search"
                        >
                            {{product.product}}
                        </text-highlight>
                    </td>
                    <td>
                        <div class="d-flex justify-center">
                            <PrCImageTableComp
                                :path="product.route_file"
                            />
                        </div>
                    </td>
                    <td>
                        <text-highlight
                            class="pr-app-subtitle"
                            :queries="search"
                        >
                            {{product.description == null
                                ? "S/D"
                                : product.description}}
                        </text-highlight>
                    </td>
                    <td>
                        <div class="d-flex justify-center align-center">
                            <div
                                :class="getClassInventory(product)"
                                style="margin-top:-2px"
                            ></div>
                            <h1 class="pr-app-subtitle ml-1">
                                {{getTextInventory(product)}}
                            </h1>
                        </div>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle text-right">
                            {{product.price.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle text-right">
                            {{product.quantity}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle text-right">
                            {{product.price_cost.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{product.categorie.categorie}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex justify-center align-center flex-wrap">
                            <PrCBtnToolTipTableComp
                                icon="mdi-pencil"
                                :smsToolTip="`Actualizar: ${product.product}`"
                                @click="$emit('clickedit', product)"
                            />
                            <PrCBtnToolTipTableComp
                                icon="mdi-format-list-bulleted-square"
                                :smsToolTip="`Ver detalle: ${product.product}`"
                                @click="$emit('clickdetail', product)"
                            />
                            <PrCBtnToolTipTableComp
                                v-if="isDigital"
                                icon="mdi-file-document"
                                :smsToolTip="`Inventario: ${product.product}`"
                                @click="$emit('clickinventory', product)"
                            />
                        </div>
                    </td>
                </tr>
                <tr v-if="products.length === 0">
                    <td colspan="10">
                        <h1 class="pr-app-subtitle text-center">Sin Información</h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    PrCBtnToolTipTableComp,
    PrCImageTableComp
} from "../../cells";
import TextHighlight from 'vue-text-highlight';
export default {
    props: {
        products: Array,
        search: String,
        isDigital: Boolean
    },
    components: {
        PrCBtnToolTipTableComp,
        TextHighlight,
        PrCImageTableComp
    },
    methods: {
        getTextInventory(product) {
            if (product.quantity <= 5) return "Bajo";
            if (product.quantity <= 15) return "Medio";
            return "Alto";
        },
        getClassInventory(product) {
            if (product.quantity <= 5) return "categories__disabled";
            if (product.quantity <= 15) return "categories__warning";
            return "categories__enabled";
        }
    }
}
</script>