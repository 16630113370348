<template>
    <div class="pr-o-table-categories animation-opacity">
        <table class="pr-o-table-categories__table">
            <thead>
                <tr>
                    <th>
                        <h1 class="pr-app-title-dark text-center">N°</h1>
                    </th>
                    <th width="200px">
                        <h1 class="pr-app-title-dark">Código</h1>
                    </th>
                    <th width="200px">
                        <h1 class="pr-app-title-dark">Descripción</h1>
                    </th>
                    <th width="200px">
                        <h1 class="pr-app-title-dark">Fecha Expiración</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Acción</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(code, index) in codes"
                    :key="code.id"
                >
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{(index + 1)}}
                        </h1>
                    </td>
                    <td>
                        <PrMTextFieldComp
                            style="width:200px"
                            v-model="code.code"
                            :singleLine="true"
                            :counter="50"
                            :rules="[
                                value => !mRItIsEmpty(value) || smsEmpty,
                                value => (`${value}`).length <= 50 || smsExecedCounter(50)
                            ]"
                        />
                    </td>
                    <td>
                        <PrMTextAreaComp
                            style="width:200px"
                            v-model="code.description"
                            :counter="200"
                            :rules="[
                                value => (`${value}`).length <= 200 || smsExecedCounter(50)
                            ]"
                        />
                    </td>
                    <td>
                        <PrMTextFieldComp
                            style="width:200px"
                            v-model="code.expiration"
                            type="date"
                            :rules="[
                                value => !mRItIsEmpty(value) || smsEmpty
                            ]"
                        />
                    </td>
                    <td>
                        <div class="d-flex align-center justify-center flex-wrap">
                            <PrCBtnToolTipTableComp
                                icon="mdi-delete"
                                smsToolTip="Eliminar Fila"
                                @click="$emit('clickdelete', index)"
                            />
                        </div>
                    </td>
                </tr>
                <tr v-if="codes.length === 0">
                    <td colspan="5">
                        <h1 class="pr-app-subtitle text-center">Sin Productos</h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    PrMTextFieldComp,
    PrMTextAreaComp
} from "../../molecules";
import {
    PrCBtnToolTipTableComp
} from "../../cells";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [rulesMixin],
    props: {
        codes: Array
    },
    components: {
        PrCBtnToolTipTableComp,
        PrMTextFieldComp,
        PrMTextAreaComp
    }
}
</script>