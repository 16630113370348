<template>
    <div class="sales">
        <div class="d-flex align-center pa-2">
            <PrCBtnToolTipHeaderComp
                :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                class="mr-2"
                @click="$emit('clickmini')"
            />
            <h1 class="pr-app-title">
                {{`CÓDIGOS A EXPIRAR (${total})`}}
            </h1>
        </div>
        <PrMLineHeaderComp />
        <PrCLoaderDataComp
            v-if="dialogLoaderDataIsOpen"
            class="mt-2"
        />
        <PrMWithoutInfoCardsComp
            v-if="!dialogLoaderDataIsOpen && codes.length===0 && isMobile"
            class="ma-2"
        />
        <PrOTableExpiredCodesComp
            v-if="!dialogLoaderDataIsOpen && !isMobile"
            :codes="codes"
            @clickwhatsapp="eOpenWhatsApp"
            @clickdetail="eOpenDetailProduct"
            @clickmail="eSendEmailClient"
        />
        <div
            v-if="!dialogLoaderDataIsOpen && isMobile"
            class="d-flex justify-center flex-wrap"
        >
            <PrOCardExpiredCodeComp
                class="ma-2"
                v-for="code in codes"
                :key="code.id"
                :code="code"
                @clickwhatsapp="eOpenWhatsApp(code)"
                @clickdetail="eOpenDetailProduct(code)"
            />
        </div>
        <v-divider
            class="mt-1"
            v-if="isMobile && !dialogLoaderDataIsOpen && codes.length > 0"
        ></v-divider>
        <PrSPaginationComp
            class="mt-2 mb-2"
            v-if="!dialogLoaderDataIsOpen && codes.length > 0"
            :condicionGo="currentPage < lastPage"
            :condicionBack="currentPage > 1"
            :titlePagination="'Pag. '+currentPage+' de '+lastPage"
            @clickback="eChangePage(false)"
            @clickgo="eChangePage(true)"
            @clickpage="eSearchForPage"
        />
        <PrSDialogDetailProductComp
            :value="dialogDetailIsOpen"
            :product="product"
            :categorieUser="categorieUser"
            @clickclose="dialogDetailIsOpen = false"
        />
        <PrCSmsErrorComp
            :smsErrors="smsErrors"
            :value="dialogErrorIsOpen"
            :actionErrorIsActivated="actionErrorIsActivated"
            @click="eCloseError()"
        />
        <PrCSmsActionComp
            :smsActions="smsActions"
            :value="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
        />
        <PrCLoaderOperationComp
            :value="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMWithoutInfoCardsComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCLoaderDataComp,
    PrCSmsErrorComp,
    PrCSmsActionComp,
    PrCLoaderOperationComp
} from "../../components/components_private/cells";
import {
    PrOTableExpiredCodesComp,
    PrOCardExpiredCodeComp
} from "../../components/components_private/organisms";
import {
    PrSDialogDetailProductComp,
    PrSPaginationComp
} from "../../components/components_private/species";
import {
    dataGeneralCompsMixin,
    datesMixin,
    userMixin,
    responseServerMixin,
    animationScrollMixin,
    rulesMixin
} from "../../mixins";
import {
    DigitalSale,
    Client
} from "../../models";
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        rulesMixin,
        dataGeneralCompsMixin,
        datesMixin,
        userMixin,
        responseServerMixin,
        animationScrollMixin
    ],
    components: {
        PrCBtnToolTipHeaderComp,
        PrMLineHeaderComp,
        PrCLoaderDataComp,
        PrMWithoutInfoCardsComp,
        PrOTableExpiredCodesComp,
        PrSDialogDetailProductComp,
        PrCSmsErrorComp,
        PrCSmsActionComp,
        PrSPaginationComp,
        PrOCardExpiredCodeComp,
        PrCLoaderOperationComp
    },
    data: () => ({
        codes: [],
        dialogDetailIsOpen: false,
        product: {},
        categorieUser: ""
    }),
    methods: {
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        async getExpiredCodes(page) {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await DigitalSale.getExpiredCodes(page);
                this.codes = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eOpenWhatsApp(code) {
            if (code.product_sale.sale.client.phone == null)
                this.mGDShowAction(["Operación Cancelada", "El cliente no tiene celular"], "error");
            else {
                let smsWhatsapp=`Saludos:%20${code.product_sale.sale.client.full_name}%20%0A`;
                smsWhatsapp = `${smsWhatsapp}Su cuenta de: ${code.product_sale.product.product}, vence`;
                smsWhatsapp = `${smsWhatsapp} el: ${this.mDateFormat(code.expiration)}, GRACIAS POR SU ATENCIÓN`;
                smsWhatsapp = `${smsWhatsapp} %0ADe: GATONET`;
                const phone = code.product_sale.sale.client.phone;
                window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${smsWhatsapp}`);
            }
        },
        eOpenDetailProduct(code) {
            this.product = code.product_sale.product;
            this.dialogDetailIsOpen = true;
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getExpiredCodes(this.currentPage);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mGDSearchForPage(page, this.mRIsInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getExpiredCodes(page);
                }, time);
            }
        },
        async eSendEmailClient(code) {
            if (code.product_sale.sale.client.email == '0')
                this.mGDShowAction(["Operación Denegada", `El codigo no está asignado a un cliente`], "error");
            else {
                this.dialogOperationIsOpen = true;
                const parameters = {
                    "full_name": code.product_sale.sale.client.full_name,
                    "email": code.product_sale.sale.client.email,
                    "text": `Su cuenta adquirida de: ${code.product_sale.product.product} expira
                        el: ${this.mDateFormat(code.expiration)}. GRACIAS POR SU ATENCIÓN`
                }
                try {
                    await Client.sendMessageClient(parameters);
                    this.dialogOperationIsOpen = false;
                    this.mGDShowAction(["Operación Exitosa", `Se envió el mensaje`]);
                } catch(error) {
                    this.processErrorServer(error);
                }
            }
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            this.categorieUser = this.mUserCategoryEmployee();
            if (this.mUserCategoryEmployee() === 'a')
                this.getExpiredCodes(1);
            else this.$router.replace('/admin/home');
        } else this.$router.replace('/login-admin');
    }
}
</script>