<template>
    <div>
        <div class="d-flex align-center pa-2">
            <PrCBtnToolTipHeaderComp
                v-if="!isMobile"
                :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                class="mr-2"
                @click="$emit('clickmini')"
            />
            <h1 class="pr-app-title">GATONET</h1>
        </div>
        <PrMLineHeaderComp />
        <div class="d-flex justify-center mt-2">
            <div>
                <div
                    class="d-flex"
                    v-for="(info, index) in informations"
                    :key="index"
                >
                    <div
                        class="sales__ornament mr-1"
                        style="margin-top: 6px"
                    ></div>
                    <h1 class="pr-app-subtitle">{{info}}</h1>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    PrMLineHeaderComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp
} from "../../components/components_private/cells";
export default {
    props: {
        mini: Boolean
    },
    components: {
        PrMLineHeaderComp,
        PrCBtnToolTipHeaderComp
    },
    data: () => ({
        isMobile: false,
        informations: [
            `Bienvenido al sistema de GATONET.`,
            `Cierre sesión despues de utilizar el sistema por seguridad.`,
            `Use el sistema con responsabilidad. Todas sus acciones serán almacenadas a una
                base de datos.`,
            `Lea los mensajes de confirmación antes de validar una operación en el sistema.`,
            `Cualquier problema comuniquese con el administrador de GATONET.`
        ]
    }),
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = false;
    }
}
</script>