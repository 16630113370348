<template>
    <div class="o-card-cart animation-opacity">
        <div class="o-card-cart__header">
            <h1 class="pu-app-title-dark">{{product.product.product}}</h1>
        </div>
        <div class="o-card-cart__body">
            <h1 class="pu-app-title-dark">
                Información Adicional
            </h1>
            <h1
                class="pr-app-subtitle-dark"
                style="white-space: pre-line;"
            >
                {{product.info != null
                    ? product.info
                    : "S/I"}}
            </h1>
            <CFillInformationComp
                title="Tipo"
                :subtitle="product.product.type === 'n' ? 'Físico' : 'Digital'"
            />
            <CFillInformationComp
                title="Precio"
                :subtitle="`${product.product.price.toFixed(2)}`"
            />
            <div class="d-flex align-center">
                <h1 class="pu-app-title-dark mr-2">
                    Cantidad:
                </h1>
                <v-btn
                    small
                    width="20"
                    color="rgba(255,255,255,0.5)"
                    max-width="20"
                    min-width="20"
                    class="mr-1"
                    :ripple="false"
                    @click="$emit('clickquantityleft')"
                >
                    <v-icon>mdi-menu-left</v-icon>
                </v-btn>
                <MTextFieldHeaderComp
                    :dark="true"
                    v-model="product.quantity"
                    :rules="[
                        value => !mRItIsEmpty(value),
                        value => mRIsInteger(value),
                        value => parseInt(value) > 0,
                        value => !mRExcedeLimite(value, product.product.quantity)
                    ]"
                />
                <v-btn
                    small
                    width="20"
                    color="rgba(255,255,255,0.5)"
                    max-width="20"
                    min-width="20"
                    class="ml-1"
                    :ripple="false"
                    @click="$emit('clickquantityright')"
                >
                    <v-icon>mdi-menu-right</v-icon>
                </v-btn>
            </div>
            <CFillInformationComp
                title="Total"
                :subtitle="`${getTotal().toFixed(2)}`"
            />
            <v-divider class="mt-1" dark></v-divider>
        </div>
        <div class="cf-pu-background d-flex justify-center pb-2">
            <MBtnNormalComp
                @click="$emit('clickdetal')"
                :xSmall="true"
                :small="false"
                class="mr-1"
            >
                <v-icon small>mdi-format-list-bulleted-square</v-icon>
            </MBtnNormalComp>
            <MBtnNormalComp
                @click="$emit('clickdelete')"
                :xSmall="true"
                :small="false"
            >
                <v-icon small>mdi-delete</v-icon>
            </MBtnNormalComp>
        </div>
    </div>
</template>
<script>
import {
    MBtnNormalComp,
    MTextFieldHeaderComp
} from "../../molecules";
import {
    CFillInformationComp
} from "../../cells";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [rulesMixin],
    components: {
        CFillInformationComp,
        MBtnNormalComp,
        MTextFieldHeaderComp
    },
    props: {
        product: Object
    },
    methods: {
        getTotal() {
            if (this.mRItIsEmpty(this.product.quantity) || !this.mRIsInteger(this.product.quantity))
                return 0;
            if (parseInt(this.product.quantity) <= 0) return 0;
            const total = this.product.product.price * parseInt(this.product.quantity);
            return total;
        }
    }
}
</script>