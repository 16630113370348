<template>
    <v-dialog
        persistent
        :value="value"
        width="800"
    >
        <v-card class="pa-0 ma-0">
            <div class="d-flex align-center pa-2 pr-cp-background">
                <h1 class="pr-app-title-dark">Códigos</h1>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    x-small
                    depressed
                    color="red"
                    @click="$emit('clickclose')"
                >
                    <v-icon
                        class="pr-cd-color"
                    >mdi-close</v-icon>
                </v-btn>
            </div>
            <PrOInfoSaleComp
                :sale="sale"
            />
            <PrMBtnNormalComp
                v-if="sale.id != undefined && sale.client.email != '0' && sale.client.phone != null"
                class="ml-2 mt-1"
                @click="$emit('clickwhatsapp')"
            >
                Cliente
                <v-icon small class="ml-1">mdi-whatsapp</v-icon>
            </PrMBtnNormalComp>
            <PrCLoaderDataComp
                v-if="loaderDataKeyIsOpen"
                class="mt-2 pb-4"
            />
            <PrOTableKeysProductSaleComp
                v-if="!loaderDataKeyIsOpen && !isMobile"
                :codes="codes"
            />
            <div
                v-if="!loaderDataKeyIsOpen && isMobile"
                class="d-flex justify-center flex-wrap"
            >
                <PrOCardKeyProductSaleComp
                    v-for="code in codes"
                    :key="code.id"
                    :code="code"
                    class="ma-2"
                />
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    PrMBtnNormalComp
} from "../molecules";
import {
    PrCLoaderDataComp
} from "../cells";
import {
    PrOTableKeysProductSaleComp,
    PrOInfoSaleComp,
    PrOCardKeyProductSaleComp
} from "../organisms";
export default {
    props: {
        codes: Array,
        value: Boolean,
        isMobile: Boolean,
        sale: Object,
        loaderDataKeyIsOpen: Boolean
    },
    components: {
        PrOTableKeysProductSaleComp,
        PrOInfoSaleComp,
        PrOCardKeyProductSaleComp,
        PrMBtnNormalComp,
        PrCLoaderDataComp
    }
}
</script>