<template>
    <div class="o-card-cart animation-opacity">
        <div class="o-card-cart__header">
            <h1 class="pu-app-title-dark">
                {{`Compra: ${mPUTotalDetail(purchase.product_sale).toFixed(2)} Bs.`}}
            </h1>
        </div>
        <div class="o-card-cart__body">
            <CFillInformationComp
                title="Hora"
                :subtitle="purchase.hour"
            />
            <CFillInformationComp
                title="Fecha"
                :subtitle="purchase.date"
            />
            <CFillInformationComp
                title="Transacción"
                :subtitle="purchase.type_sale === 'e'
                                ? 'Compra Internet' : 'Tienda'"
            />
            <CFillInformationComp
                title="Venta"
                :subtitle="purchase.enabled === 0
                    ? 'Anulada' : 'Realizada'"
            />
            <CFillInformationComp
                title="Entregado"
                :subtitle="getDeliveredPurchase()"
            />
            <v-divider class="mt-1" dark></v-divider>
        </div>
        <div class="cf-pu-background d-flex justify-center pb-2">
            <MBtnNormalComp
                @click="$emit('clickdetail')"
                :xSmall="true"
                :small="false"
                class="mr-1"
            >
                <v-icon small>mdi-format-list-bulleted-square</v-icon>
            </MBtnNormalComp>
        </div>
    </div>
</template>
<script>
import {
    MBtnNormalComp
} from "../../molecules";
import {
    CFillInformationComp
} from "../../cells";
import {
    purchasesMixin
} from "../../../../mixins";
export default {
    mixins: [purchasesMixin],
    components: {
        CFillInformationComp,
        MBtnNormalComp
    },
    props: {
        purchase: Object
    },
    methods: {
        getDeliveredPurchase() {
            if (this.purchase.delivered === 0) return 'No';
            for (let i = 0; i < this.purchase.product_sale.length; i++) {
                for (let k = 0; k < this.purchase.product_sale[i].digitals.length; k++) {
                    if (this.purchase.product_sale[i].digitals[k].this_slope === 1) return 'No';
                }
            }
            return 'Si';
        }
    }
}
</script>