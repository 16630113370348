<template>
    <div class="d-flex align-center">
        <h1 class="pu-app-title-dark mr-1">
            {{`${title}:`}}
        </h1>
        <text-highlight
            v-if="isSearch"
            class="pu-app-title-dark mr-1"
            :queries="search"
        >
            {{subtitle}}
        </text-highlight>
        <h1
            v-else
            class="pu-app-subtitle-dark"
        >
            {{subtitle}}
        </h1>
        <slot></slot>
    </div>
</template>
<script>
import TextHighlight from 'vue-text-highlight';
export default {
    components: {
        TextHighlight
    },
    props: {
        title: String,
        search: String,
        subtitle: String,
        isSearch: Boolean
    }
}
</script>