<template>
    <div
        class="o-thid-home space--block"
        v-intersect="onIntersect"
    >
        <div class="o-thid-home__products">
            <h1 class="pu-app-big-title-dark text-center">
                {{info.titleFirst}}
            </h1>
            <v-card
                width="100%"
                height="10px"
                elevation="0px"
                style="opacity:0.3"
                class="cp-pu-background mt-1"
            ></v-card>
            <div
                v-for="(column, index) in info.optionsProducts"
                :key="index"
            >
                <div class="d-flex mt-3">
                    <div class="o-thid-home__ornament mr-2"></div>
                    <h1 class="pu-app-title-dark">
                        {{column.title}}
                    </h1>
                </div>
                <h1 class="pu-app-subtitle-dark">
                    {{column.description}}
                </h1>
            </div>
        </div>
        <div class="o-thid-home__image">
            <v-img
                :src="info.imgCenter"
                width="100%"
                height="100%"
                contain
            ></v-img>
        </div>
        <div class="o-thid-home__payments">
            <h1 class="pu-app-big-title-dark text-center">
                {{info.titleSecond}}
            </h1>
            <v-card
                width="100%"
                height="10px"
                elevation="0px"
                style="opacity:0.3"
                class="cp-pu-background mt-1"
            ></v-card>
            <div
                class="d-flex align-center mt-3"
                v-for="(payment, index) in info.optionsPayments"
                :key="index"
            >
                <div class="o-thid-home__icon">
                    <v-img
                        :src="payment.src"
                        width="100%"
                        height="100%"
                    ></v-img>
                </div>
                <div class="ml-2">
                    <h1 class="pu-app-title-dark">{{payment.title}}</h1>
                    <h1 class="pu-app-subtitle-dark">
                        {{payment.subtitle}}
                    </h1>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        info: Object
    },
    data: () => ({
        isIntersecting: false,
        isMobile: false
    }),
    methods: {
        onIntersect (entries, observer) {
            if (entries[0].isIntersecting && !this.isMobile) {
                this.isIntersecting = entries[0].isIntersecting;
                const element = document.getElementsByClassName("o-thid-home");
                element[0].style.transition = "800ms";
                element[0].style.paddingTop = "0px";
                element[0].style.opacity = "1";
            }
        },
        closeAnimation() {
            const element = document.getElementsByClassName("o-thid-home");
            element[0].style.paddingTop = "0px";
            element[0].style.opacity = "1";
        }
    },
    mounted() {
        if (window.innerWidth <= 600) {
            this.isMobile = true;
            this.closeAnimation();
        }
    }
}
</script>