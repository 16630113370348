var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pr-o-card-categorie animation-opacity"},[_c('div',{staticClass:"pr-o-card-categorie__header d-flex justify-center"},[_c('text-highlight',{staticClass:"pr-app-title-dark",attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(_vm.product.product)+" ")])],1),_c('div',{staticClass:"d-flex pa-2"},[_c('PrCImageTableComp',{attrs:{"path":_vm.product.route_file}}),_c('div',{staticClass:"ml-2"},[_c('h1',{staticClass:"pr-app-subtitle"},[_vm._v(" "+_vm._s(_vm.product.description)+" ")]),_c('v-divider'),_c('PrCFillInformationComp',{attrs:{"title":"Inventario","subtitle":_vm.getTextInventory()}},[_c('div',{class:_vm.getClassInventory(),staticStyle:{"margin-top":"-2px"}})]),_c('PrCFillInformationComp',{attrs:{"title":"Precio","subtitle":_vm.product.price.toFixed(2)}}),_c('PrCFillInformationComp',{attrs:{"title":"Cantidad","subtitle":`${_vm.product.quantity}`}}),_c('PrCFillInformationComp',{attrs:{"title":"Costo","subtitle":_vm.product.price_cost.toFixed(2)}}),_c('PrCFillInformationComp',{attrs:{"title":"Estado","subtitle":_vm.product.enabled === 1 ? 'Si' : 'No'}},[_c('div',{class:_vm.product.enabled === 1
                        ? 'categories__enabled ml-1'
                        : 'categories__disabled ml-1'})]),_c('PrCFillInformationComp',{attrs:{"title":"Web","subtitle":_vm.product.web_enabled === 1 ? 'Si' : 'No'}},[_c('div',{class:_vm.product.web_enabled === 1
                        ? 'categories__enabled ml-1'
                        : 'categories__disabled ml-1',staticStyle:{"margin-top":"0px"}})])],1)],1),_c('v-divider',{staticClass:"mb-1"}),_c('div',{staticClass:"pa-2 pt-1 d-flex justify-center flex-wrap"},[_c('PrCBtnCardComp',{attrs:{"icon":"mdi-format-list-bulleted-square"},on:{"click":function($event){return _vm.$emit('clickdetail')}}}),_c('PrCBtnCardComp',{attrs:{"icon":"mdi-pencil"},on:{"click":function($event){return _vm.$emit('clickedit')}}}),_c('PrCBtnCardComp',{attrs:{"icon":"mdi-image-plus"},on:{"click":function($event){return _vm.$emit('clickimage')}}}),_c('PrCBtnCardComp',{attrs:{"icon":_vm.product.enabled === 1
                ? 'mdi-eye-off'
                : 'mdi-eye'},on:{"click":function($event){return _vm.$emit('clickstate')}}}),_c('PrCBtnCardComp',{attrs:{"icon":_vm.product.web_enabled === 1
                ? 'mdi-laptop-off'
                : 'mdi-laptop'},on:{"click":function($event){return _vm.$emit('clickwebstate')}}}),_c('PrCBtnCardComp',{attrs:{"icon":"mdi-delete"},on:{"click":function($event){return _vm.$emit('clickdelete')}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }