<template>
    <div class="ma-2">
        <h1 class="pr-app-title text-center">DETALLE</h1>
        <PrMWithoutInfoCardsComp
            v-if="detailSale.length===0"
            title="Sin Productos"
        />
        <div class="d-flex justify-center flex-wrap">
            <PrOCardDetailSaleMobileComp
                v-for="product in detailSale"
                :key="product.id"
                :product="product"
                @clickedit="$emit('clickedit', product)"
                @clickdelete="eDeleteProduct"
                @clickdetail="$emit('clickdetail', product)"
            />
        </div>
        <div class="d-flex mt-1">
            <v-spacer></v-spacer>
            <div class="d-flex">
                <h1 class="pr-app-title mr-1">TOTAL:</h1>
                <v-spacer></v-spacer>
                <h1 class="pr-app-title text-right">
                    {{mSaleTotalLocalStore(detailSale).toFixed(2)}}
                </h1>
            </div>
        </div>
        <div class="d-flex">
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <h1 class="pr-app-title mr-1">Cancelado:</h1>
                <v-spacer></v-spacer>
                <PrMTextFieldHeaderComp
                    style="width:120px; max-width:120px;"
                    v-model="cancelled"
                    :reverse="true"
                    :clearable="false"
                    :rules="[
                        value => !this.mRItIsEmpty(value),
                        value => this.mRIsNumeric(value)
                    ]"
                />
            </div>
        </div>
        <div class="d-flex mt-1">
            <v-spacer></v-spacer>
            <div class="d-flex">
                <h1 class="pr-app-title mr-1">Cambio:</h1>
                <v-spacer></v-spacer>
                <h1 class="pr-app-title text-right">
                    {{changeMoney.toFixed(2)}}
                </h1>
            </div>
        </div>
        <div class="d-flex justify-center mt-1">
            <PrCBtnDetailComp
                icon="mdi-window-maximize"
                colorBtn="blue"
                class="mr-1"
                smsToolTip="Limpiar Detalle"
                @click="eClearDetail()"
            />
            <PrCBtnDetailComp
                icon="mdi-cash"
                colorBtn="green"
                class="mr-1"
                smsToolTip="Registrar venta"
                @click="eGenerateProductSale(true)"
            />
            <PrCBtnDetailComp
                icon="mdi-account-cash"
                colorBtn="green"
                class="mr-1"
                smsToolTip="Registrar venta + cliente"
                @click="eGenerateProductSale(false)"
            />
            <PrCBtnDetailComp
                v-if="mUserCategoryEmployee() === 'a'"
                :icon="isStore ? 'mdi-warehouse' : 'mdi-account-edit'"
                colorBtn="orange"
                :smsToolTip="isStore
                    ? 'Cambiar a venta personal'
                    : 'Cambiar a venta por tienda'"
                @click="$emit('clickstore')"
            />
        </div>
    </div>
</template>
<script>
import {
    PrMTextFieldHeaderComp,
    PrMWithoutInfoCardsComp
} from "../molecules";
import {
    PrCBtnDetailComp
} from "../cells";
import {
    PrOCardDetailSaleMobileComp
} from "../organisms";
import {
    saleMixin,
    rulesMixin,
    userMixin
} from "../../../mixins";
export default {
    mixins: [
        saleMixin,
        rulesMixin,
        userMixin
    ],
    components: {
        PrOCardDetailSaleMobileComp,
        PrCBtnDetailComp,
        PrMTextFieldHeaderComp,
        PrMWithoutInfoCardsComp
    },
    props: {
        detailSale: Array,
        isStore: Boolean
    },
    data: () => ({
        cancelled: "0"
    }),
    computed: {
        changeMoney: function() {
            if (this.mRItIsEmpty(this.cancelled) || !this.mRIsNumeric(this.cancelled)) return 0;
            const change = this.mSaleTotalLocalStore(this.detailSale);
            return parseFloat(this.cancelled) - change;
        }
    },
    methods: {
        eClearDetail() {
            this.cancelled = "0";
            this.$emit('cleardetail');
        },
        eDeleteProduct(product) {
            const index = this.detailSale.indexOf(product);
            this.detailSale.splice(index, 1);
        },
        eGenerateProductSale(isDirect) {
            const productSale = [];
            this.detailSale.forEach(product => {
                productSale.push({
                    'product_id': product.product.id,
                    'quantity': product.quantity,
                    'price': product.price,
                    'id_reserve': product.id_reserve,
                    'balance_hold': product.balance_hold
                });
            });
            const parameters = {
                'isDirect': isDirect,
                'productSale': productSale
            }
            this.$emit('clicksale', parameters);
        }
    }
}
</script>