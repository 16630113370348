<template>
    <div class="o-card-recommendation">
        <div class="o-card-recommendation__image">
            <div class="o-card-recommendation__img">
                <v-img
                    :src="product.route_file|imgMiniature"
                    width="100%"
                    height="100%"
                ></v-img>
            </div>
        </div>
        <div class="o-card-recommendation__footer">
            <h2 class="pu-app-title-dark">{{product.product}}</h2>
            <h1 class="pu-app-big-title-dark text-center">
                {{getPriceProduct(product.price)}}
            </h1>
            <div class="text-center mb-1 mt-1">
                <MBtnNormalComp
                    :xSmall="isMobile"
                    :small="!isMobile"
                    class="mr-1"
                    className="cp-pu-background"
                    @click="$emit('selectproduct')"
                >
                    <v-icon small>mdi-cart</v-icon>
                </MBtnNormalComp>
                <MBtnNormalComp
                    :xSmall="isMobile"
                    :small="!isMobile"
                    className="cp-pu-background"
                    :to="{name: 'product', params: product}"
                >VER MAS</MBtnNormalComp>
            </div>
        </div>
    </div>
</template>
<script>
import {
    MBtnNormalComp
} from "../../molecules";
import {
    CFillInformationComp
} from "../../cells";
import {
    imgMiniature
} from "../../../../filters";
import { mapState } from "vuex";
export default {
    filters: {
        imgMiniature
    },
    props: {
        product: Object
    },
    components: {
        CFillInformationComp,
        MBtnNormalComp
    },
    computed: {
        ...mapState("settingStore", ["dollar", "isDollar"])
    },
    data: () => ({
        isMobile: false
    }),
    methods: {
        getPriceProduct(price) {
            if (this.isDollar) {
                const newPrice = price / this.dollar;
                return `${newPrice.toFixed(2)} $`;
            }
            return `${price.toFixed(2)} Bs.`;
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
    }
}
</script>