import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from "../store/modules";
class Categorie {
    constructor(
        categorie = null,
        choice = null,
        view = null
    ) {
        this.categorie = categorie;
        this.choice = choice;
        this.view = view;
    }
    static async getCategories() {
        const response = await axios.get(
            `${apiUrls.CATEGORIE}list`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addCategorie(parameters) {
        const response = await axios.post(
            `${apiUrls.CATEGORIE}add`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async editCategorie(parameters) {
        const response = await axios.put(
            `${apiUrls.CATEGORIE}edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async changeStateCategorie(parameters) {
        const response = await axios.patch(
            `${apiUrls.CATEGORIE}change-state`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async deleteCategorie(parameters) {
        const response = await axios.delete(
            `${apiUrls.CATEGORIE}delete`,
            { data: parameters, headers: userStore.state.header }
        );
        return response.data;
    }
    static async changeSeller(parameters) {
        const response = await axios.patch(
            `${apiUrls.CATEGORIE}change-seller`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    //public
    static async getListPublic(choice) {
        const response = await axios.get(
            `${apiUrls.CATEGORIE}list-public?choice=${choice}`
        );
        return response.data;
    }
    static async getViewListPublic(view) {
        const response = await axios.get(
            `${apiUrls.CATEGORIE}list-public-view?view=${view}`
        );
        return response.data;
    }
    static async getCategoriesSale(categorieUser) {
        const response = await axios.get(
            `${apiUrls.CATEGORIE}list-sales?categorie_user=${categorieUser}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default Categorie;