var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","value":_vm.value,"width":"360"}},[_c('v-card',{staticClass:"pa-0 ma-0"},[_c('div',{staticClass:"d-flex align-center pr-cp-background pa-2"},[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Entrega del producto")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","x-small":"","depressed":"","color":"red"},on:{"click":function($event){return _vm.$emit('clickclose')}}},[_c('v-icon',{staticClass:"pr-cd-color"},[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-form',{ref:"formdelivered",staticClass:"pa-2",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('PrMSelectComp',{staticClass:"mt-2",attrs:{"items":_vm.optionsDelivered,"itemText":"text","itemValue":"value","label":"Entregado","rules":[
                    value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty
                ]},model:{value:(_vm.dataSale.delivered),callback:function ($$v) {_vm.$set(_vm.dataSale, "delivered", $$v)},expression:"dataSale.delivered"}}),_c('PrMSelectComp',{staticClass:"mt-2",attrs:{"items":_vm.optionsTypesCoin,"itemText":"text","itemValue":"value","label":"Tipo Pago","rules":[
                    value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty
                ]},model:{value:(_vm.dataSale.type_coin),callback:function ($$v) {_vm.$set(_vm.dataSale, "type_coin", $$v)},expression:"dataSale.type_coin"}}),_c('PrMTextFieldComp',{staticClass:"mt-2",attrs:{"label":"Nota","counter":300,"rules":[
                    value => (`${value}`).length <= 300 || _vm.smsExecedCounter(300)
                ]},model:{value:(_vm.dataSale.note),callback:function ($$v) {_vm.$set(_vm.dataSale, "note", $$v)},expression:"dataSale.note"}})],1),_c('div',{staticClass:"text-center pb-3"},[_c('PrMBtnNormalComp',{on:{"click":function($event){return _vm.$emit('clickdelivered')}}},[_vm._v("SIGUIENTE")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }