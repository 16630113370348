<template>
    <v-form
        v-model="valid"
        lazy-validation
        ref="formemployee"
    >
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Nombre Completo"
        >
            <PrMTextFieldComp
                v-model="dataEmployee.full_name"
                :label="isMobile ? 'Nombre Completo' : ''"
                @click="eValidateForm()"
                :counter="50"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => (`${value}`).length <= 50 || smsExecedCounter(50)
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Categoría"
        >
            <PrMSelectComp
                v-model="dataEmployee.category"
                :label="isMobile ? 'Categoría' : ''"
                :items="items"
                itemValue="value"
                itemText="text"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty
                ]"
            />
        </PrCFillFormComp>
        <div
            class="d-flex justify-center mt-1"
            v-if="!formOnly"
        >
            <PrMBtnNormalComp
                @click="eValidateForm()"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                GUARDAR
            </PrMBtnNormalComp>
        </div>
    </v-form>
</template>
<script>
import {
    PrMTextFieldComp,
    PrMBtnNormalComp,
    PrMSelectComp
} from "../../molecules";
import {
    PrCFillFormComp
} from "../../cells";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        PrMTextFieldComp,
        PrCFillFormComp,
        PrMBtnNormalComp,
        PrMSelectComp
    },
    props: {
        dataEmployee: Object,
        isMobile: Boolean,
        formOnly: Boolean
    },
    data: () => ({
        valid: true,
        items: [
            { text: "Administrador", value: "a" },
            { text: "Vendedor", value: "v" }
        ]
    }),
    methods: {
        eValidateForm() {
            if (this.$refs.formemployee.validate())
                this.$emit('clickform', true);
            else this.$emit('clickform', false);
        },
        resetForm() {
            this.$refs.formemployee.resetValidation();
        },
        validateForm() {
            return this.$refs.formemployee.validate();
        }
    }
}
</script>