<template>
  <v-app>
      <router-view/>
  </v-app>
</template>
<style src="./sass_proyect/main.scss" lang="scss"></style>
<style lang="sass">
    @import '../node_modules/typeface-roboto/index.css'
</style>
<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
