import { mapMutations, mapState } from "vuex";
export default {
    computed: {
        ...mapState("userStore", ["existHeader"])
    },
    methods: {
        ...mapMutations("userStore", ["saveHeader", "activateHeader"]),
        mUserSaveHeader(dateNow) {
            if (JSON.parse(localStorage.getItem("ecomerce_gatonet"))) {
                const dateLocalStore = JSON.parse(localStorage.getItem("ecomerce_gatonet")).date;
                if (dateLocalStore < dateNow) {
                    localStorage.removeItem("ecomerce_gatonet");
                    return false;
                } else {
                    if (!this.existHeader) {
                        let apitoken = JSON.parse(localStorage.getItem("ecomerce_gatonet")).token;
                        let header = {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                            "Authorization": "Bearer " + apitoken,
                        }
                        this.saveHeader(header);
                        this.activateHeader();
                    }
                    return true;
                }
            }
            return false;
        },
        mUserNameEmployee() {
            const user = JSON.parse(localStorage.getItem("ecomerce_gatonet"));
            return user.employee.full_name;
        },
        mUserCategoryEmployee() {
            const user = JSON.parse(localStorage.getItem("ecomerce_gatonet"));
            return user.employee.category;
        }
    }
}