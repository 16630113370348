<template>
    <v-form
        v-model="valid"
        lazy-validation
        ref="formpublication"
    >
        <div
            class="d-flex justify-center mb-6"
        >
            <PrCPhotoFormComp
                refImg="fotografiaup"
                ref="photoformcomp"
                title="Foto"
                subtitle="Publicación"
                fileFormat="image/jpeg"
                @changephoto="eChangePhoto"
            />
        </div>
        <div class="d-flex justify-center mt-1">
            <PrMBtnNormalComp
                @click="$emit('clickfile')"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                GUARDAR
            </PrMBtnNormalComp>
        </div>
    </v-form>
</template>
<script>
import {
    PrMBtnNormalComp
} from "../../molecules";
import {
    PrCPhotoFormComp
} from "../../cells";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        PrMBtnNormalComp,
        PrCPhotoFormComp
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eChangePhoto(parameters) {
            this.$emit('changefile', parameters);
        },
        resetData() {
            this.$refs.photoformcomp.resetData();
        }
    }
}
</script>