<template>
    <div>
        <div class="d-flex justify-center">
            <div class="about__fond">
                <v-img
                    width="100%"
                    height="100%"
                    :src="isMobile
                        ? '/imgs_public/mobile/roblox_fond_m.jpg'
                        : '/imgs_public/pc/roblox_fond.jpg'"
                    
                ></v-img>
            </div>
        </div>
        <div class="d-flex justify-center space--block">
            <div class="c-image-home about__first">
                <div class="about__first-text">
                    <h1 class="pu-app-big-title">Quienes Somos?</h1>
                    <h1 class="pu-app-subtitle mt-2">
                        Somos GATONET un lugar confiable para todos los videojuegadores, ofrecemos recargas de tus videojuegos favoritos, al mejor precio, ofrecemos una calida comunidad gamer donde podras junto a nosotros explorar multiples videojuegos, PLATAFORMAS  y más, visítanos.
                    </h1>
                </div>
                <div class="about__first-info">
                    <div class="d-flex align-center">
                        <div>
                            <v-img
                                width="60px"
                                height="60px"
                                src="/imgs_public/pc/sale.png"
                            ></v-img>
                        </div>
                        <h1 class="pu-app-big-title-dark ml-2">Que Ofrecemos</h1>
                    </div>
                    <v-card
                        width="100%"
                        height="10px"
                        elevation="0px"
                        style="opacity:0.3"
                        class="cp-pu-background mt-2"
                    ></v-card>
                    <h1 class="pu-app-subtitle-dark mt-2">
                        Ofrecemos servicios digitales, streaming, videojuegos, accesorios gamer y mucho más.
                    </h1>
                    <h1 class="pu-app-subtitle-dark mt-2">
                        Contamos con una alta variedad de calidad de nuestros productos.
                    </h1>
                </div>
                <div class="about__first-info-two">
                    <div class="d-flex align-center">
                        <div>
                            <v-img
                                width="60px"
                                height="60px"
                                src="/imgs_public/pc/commitment.png"
                            ></v-img>
                        </div>
                        <h1 class="pu-app-big-title-dark ml-2">Compromiso</h1>
                    </div>
                    <v-card
                        width="100%"
                        height="10px"
                        elevation="0px"
                        style="opacity:0.3"
                        class="cp-pu-background mt-2"
                    ></v-card>
                    <h1 class="pu-app-subtitle-dark mt-2">
                        Tenemos un compromiso con todos los videojuegadores, de ofrecerles un buen servicio al mejor precio.
                    </h1>
                    <h1 class="pu-app-subtitle-dark mt-2">
                        Brindamos información mediante nuestro número de contacto a nuestros clientes.
                    </h1>
                </div>
            </div>
        </div>
        <div class="d-flex justify-center space--block">
            <CProductAboutComp
                :info="dataProduct"
            />
        </div>
        <div class="d-flex justify-center space--block">
            <CImagesAboutComp
                :info="dataImages"
            />
        </div>
        <div class="space--block"></div>
    </div>
</template>
<script>
import {
    CProductAboutComp,
    CImagesAboutComp
} from "../../components/components_public/cells";
export default {
    components: {
        CProductAboutComp,
        CImagesAboutComp
    },
    data: () => ({
        dataProduct: {
            title: "Productos",
            subtitle: `Nuestros productos físicos ofrecidos al público contemplan
                una gran calidad. Tenemos una gran variedad de productos GAMERS y distintos
                que se encuentran en nuestra sección FÍSICOS.`,
            subtitleTwo: `Tenemos una alta calidad y varidad de productos Dígitales
                las cuales tienen garantía para su uso seguro.`,
            src: "/imgs_public/pc/command.png"
        },
        dataImages: {
            fond: "/imgs_public/pc/headphones.jpg",
            srcOne: "/imgs_public/pc/minecraft.jpeg",
            titleOne: "Minecraft",
            srcTwo: "/imgs_public/pc/netflix.jpeg",
            titleTwo: "Netflix",
            srcThird: "/imgs_public/pc/roblox.jpeg",
            titleThird: "Roblox"
        },
        isMobile: false
    }),
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
    }
}
</script>