var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"formbox",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('h1',{staticClass:"pr-app-title text-center mb-4"},[_vm._v(" "+_vm._s(_vm.dataCloseBox.ranking != null ? _vm.dataCloseBox.ranking.name : "--")+" ")]),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Inicio"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Inicio' : '',"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => _vm.mRIsNumeric(value) || _vm.smsNotNumeric
            ]},model:{value:(_vm.dataCloseBox.beginning),callback:function ($$v) {_vm.$set(_vm.dataCloseBox, "beginning", $$v)},expression:"dataCloseBox.beginning"}})],1),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Fin"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Fin' : '',"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => _vm.mRIsNumeric(value) || _vm.smsNotNumeric
            ]},model:{value:(_vm.dataCloseBox.finished),callback:function ($$v) {_vm.$set(_vm.dataCloseBox, "finished", $$v)},expression:"dataCloseBox.finished"}})],1),_c('div',{staticClass:"d-flex justify-center mt-1"},[_c('PrMBtnNormalComp',{on:{"click":function($event){return _vm.eValidateForm()}}},[_c('v-icon',[_vm._v("mdi-bookmark-check")]),_vm._v(" GUARDAR ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }