import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from "../store/modules";
class Client {
    constructor(
        full_name = null,
        phone = null,
        email = null,
        banned = null,
        fk_user = null
    ) {
        this.full_name = full_name;
        this.phone = phone;
        this.email = email;
        this.banned = banned;
        this.fk_user = fk_user;
    }
    static async searchClients(search)
    {
        const response = await axios.get(
            `${apiUrls.CLIENT}search?search=${search}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async updatePhoneClient(parameters)
    {
        const response = await axios.put(
            `${apiUrls.CLIENT}update-phone`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async bannedClient(parameters)
    {
        const response = await axios.put(
            `${apiUrls.CLIENT}banned-client`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async sendMessageClient(parameters)
    {
        const response = await axios.post(
            `${apiUrls.CLIENT}send-mail`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default Client;