import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from "../store/modules";
class Recommendation {
    constructor(
        fk_product = null
    ) {
        this.fk_product = fk_product;
    }
    static async getRecommendations() {
        const response = await axios.get(
            `${apiUrls.RECOMMENDATION}list`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addRecommendation(parameters) {
        const response = await axios.post(
            `${apiUrls.RECOMMENDATION}add`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async deleteRecommendation(parameters) {
        const response = await axios.delete(
            `${apiUrls.RECOMMENDATION}delete`,
            { data: parameters, headers: userStore.state.header }
        );
        return response.data;
    }
    static async getRecommendationsPublic() {
        const response = await axios.get(
            `${apiUrls.RECOMMENDATION}list-public`,
        );
        return response.data;
    }
}
export default Recommendation;