<template>
    <div class="pr-o-card-categorie animation-opacity">
        <div class="pr-o-card-categorie__header d-flex justify-center">
            <h1 class="pr-app-title-dark">
                {{closeBox.ranking.name}}
            </h1>
        </div>
        <div class="pa-2 pb-1">
            <PrCFillInformationComp
                title="Fecha"
                :subtitle="mDateFormat(closeBox.day)"
            />
            <PrCFillInformationComp
                title="Inicio"
                :subtitle="closeBox.beginning.toFixed(2)"
            />
            <PrCFillInformationComp
                title="Fin"
                :subtitle="closeBox.finished.toFixed(2)"
            />
            <PrCFillInformationComp
                title="Diferencia"
                :subtitle="(closeBox.finished - closeBox.beginning).toFixed(2)"
            />
        </div>
        <v-divider class="mb-1"></v-divider>
        <div class="pa-2 pt-1 d-flex justify-center flex-wrap">
            <PrCBtnCardComp
                icon="mdi-pencil"
                @click="$emit('clickedit')"
            />
        </div>
    </div>
</template>
<script>
import {
    PrCFillInformationComp,
    PrCBtnCardComp
} from "../../cells";
import {
    datesMixin
} from "../../../../mixins";
export default {
    mixins: [datesMixin],
    props: {
        closeBox: Object
    },
    components: {
        PrCFillInformationComp,
        PrCBtnCardComp
    }
}
</script>