<template>
    <div
        class="c-image-home c-product-about"
        v-intersect="onIntersect"
    >
        <div class="c-product-about__info">
            <h1 class="pu-app-big-title-dark">
                {{info.title}}
            </h1>
            <v-card
                width="100%"
                height="10px"
                elevation="0px"
                style="opacity:0.3"
                class="cp-pu-background mt-2"
            ></v-card>
            <h1 class="pu-app-subtitle-dark mt-2">
                {{info.subtitle}}
            </h1>
            <h1 class="pu-app-subtitle-dark mt-3">
                {{info.subtitleTwo}}
            </h1>
        </div>
        <div class="c-product-about__img">
            <v-img
                :src="info.src"
                width="100%"
                height="100%"
            ></v-img>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        info: Object
    },
    data: () => ({
        isIntersecting: false,
        isMobile: false
    }),
    methods: {
        onIntersect (entries, observer) {
            if (entries[0].isIntersecting) {
                this.isIntersecting = entries[0].isIntersecting;
                const element = document.getElementsByClassName("c-product-about");
                element[0].style.transition = "800ms";
                element[0].style.paddingTop = "0px";
                element[0].style.opacity = "1";
            }
        },
        closeAnimation() {
            const element = document.getElementsByClassName("c-product-about");
            element[0].style.paddingTop = "0px";
            element[0].style.opacity = "1";
        }
    },
    mounted() {
        if (window.innerWidth <= 600) {
            this.isMobile = true;
            this.closeAnimation();
        }
    }
}
</script>