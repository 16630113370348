<template>
    <div class="o-card-product animation-opacity">
        <div class="o-card-product__image">
            <div class="o-card-product__img">
                <v-img
                    :src="product.route_file|imgMiniature"
                    width="100%"
                    height="100%"
                ></v-img>
            </div>
        </div>
        <!--<div
            v-else
            class="o-card-product__img"
        >
            <div class="d-flex justify-center">
                <div class="o-card-product__without-img">
                    <v-img
                        v-if="product != null"
                        src="imgs_public/header/logo.svg"
                        width="100%"
                        height="100%"
                        contain
                    ></v-img>
                </div>
            </div>
            <h1 class="pu-app-title-dark text-center mt-1">Sin Fotografía</h1>
        </div>-->
        <div class="o-card-product__footer">
            <div class="d-flex justify-center">
                <text-highlight
                    class="pu-app-title-dark"
                    :queries="search"
                >
                    {{product.product}}
                </text-highlight>
            </div>
            <h1 class="pu-app-big-title-dark text-center">
                {{getPriceProduct(product.price)}}
            </h1>
            <div class="text-center mb-1 mt-1">
                <MBtnNormalComp
                    :xSmall="isMobile"
                    :small="!isMobile"
                    class="mr-1"
                    className="cp-pu-background"
                    @click="$emit('selectproduct')"
                >
                    <v-icon small>mdi-cart</v-icon>
                </MBtnNormalComp>
                <MBtnNormalComp
                    :xSmall="isMobile"
                    :small="!isMobile"
                    className="cp-pu-background"
                    :to="{name: 'product', params: product}"
                >VER MAS</MBtnNormalComp>
            </div>
        </div>
    </div>
</template>
<script>
import {
    imgMiniature
} from "../../../../filters";
import {
    MBtnNormalComp
} from "../../molecules";
import TextHighlight from 'vue-text-highlight';
import {
    CFillInformationComp
} from "../../cells";
import { mapState } from "vuex";
export default {
    filters: {
        imgMiniature
    },
    components: {
        CFillInformationComp,
        MBtnNormalComp,
        TextHighlight
    },
    props: {
        product: Object,
        search: String
    },
    data: () => ({
        isMobile: false
    }),
    computed: {
        ...mapState("settingStore", ["dollar", "isDollar"])
    },
    methods: {
        getPriceProduct(price) {
            if (this.isDollar) {
                const newPrice = price / this.dollar;
                return `${newPrice.toFixed(2)} $`;
            }
            return `${price.toFixed(2)} Bs.`;
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
    }
}
</script>