<template>
    <div class="pr-o-table-categories animation-opacity">
        <table class="pr-o-table-categories__table">
            <thead>
                <tr>
                    <th>
                        <h1 class="pr-app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Nombre Completo</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Categoría</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Usuario</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Estado</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">IP</h1>
                    </th>
                    <th width="190">
                        <h1 class="pr-app-title-dark">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(user, index) in users"
                    :key="user.id"
                >
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{(index + 1)}}
                        </h1>
                    </td>
                    <td>
                        <text-highlight
                            class="pr-app-subtitle"
                            :queries="search"
                        >
                            {{user.employee.full_name}}
                        </text-highlight>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{user.employee.category === "a"
                                ? "Administrador" : "Vendedor"}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{user.username}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex align-center">
                            <div
                                :class="user.enabled === 1
                                    ? 'categories__enabled mr-1'
                                    : 'categories__disabled mr-1'"
                                style="margin-top:-2px"    
                            ></div>
                            <h1 class="pr-app-subtitle">
                                {{user.enabled === 1 ? "Si" : "No"}}
                            </h1>
                        </div>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{getTextIp(user)}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <PrCBtnToolTipTableComp
                            icon="mdi-pencil"
                            :smsToolTip="`Editar a: ${user.employee.full_name}`"
                            @click="$emit('clickedit', user)"
                        />
                        <PrCBtnToolTipTableComp
                            icon="mdi-account-key"
                            :smsToolTip="`Cambiar credenciales de: ${user.employee.full_name}`"
                            @click="$emit('clickcredentials', user)"
                        />
                        <PrCBtnToolTipTableComp
                            :icon="user.enabled === 1
                                ? 'mdi-eye-off'
                                : 'mdi-eye'"
                            :smsToolTip="user.enabled === 1
                                ? `Inhabilitar a: ${user.employee.full_name}`
                                : `Habilitar a: ${user.employee.full_name}`"
                            @click="$emit('clickstate', user)"
                        />
                        <PrCBtnToolTipTableComp
                            v-if="user.employee.category === 'v'"
                            icon="mdi-key-wireless"
                            :smsToolTip="`Habilitar a ${user.employee.full_name} para ventas en el dispositivo accesado`"
                            @click="$emit('clickkey', user)"
                        />
                    </td>
                </tr>
                <tr v-if="users.length === 0">
                    <td colspan="7">
                        <h1 class="pr-app-subtitle text-center">Sin Información</h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    PrCBtnToolTipTableComp
} from "../../cells";
import TextHighlight from 'vue-text-highlight';
export default {
    props: {
        users: Array,
        search: String
    },
    components: {
        PrCBtnToolTipTableComp,
        TextHighlight
    },
    methods: {
        getTextIp(user) {
            if (user.employee.category === 'a') return 'No Requiere';
            if (user.employee.ip === null) return 'Sin asignar';
            return user.employee.ip;
        }
    }
}
</script>