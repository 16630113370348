<template>
    <div class="pr-o-card-categorie animation-opacity">
        <div class="pr-o-card-categorie__header d-flex justify-center">
            <h1 class="pr-app-title-dark">
                {{data.name}}
            </h1>
        </div>
        <div class="pa-2 pb-1">
            <PrMTextFieldComp
                class="mt-2"
                label="Apertura Bs."
                v-model="data.beginning"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => mRIsNumeric(value) || smsNotNumeric
                ]"
            />
        </div>
    </div>
</template>
<script>
import {
    PrMTextFieldComp
} from "../../molecules";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [rulesMixin],
    props: {
        data: Object
    },
    components: {
        PrMTextFieldComp
    }
}
</script>