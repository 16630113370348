<template>
    <div class="pr-o-card-categorie animation-opacity">
        <div class="pr-o-card-categorie__header d-flex justify-center">
            <h1 class="pr-app-title-dark">
                {{product.product.product}}
            </h1>
        </div>
        <div class="pa-2 pb-1">
            <PrCFillInformationComp
                title="Tipo"
                :subtitle="product.product.type === 'd'
                    ? 'Digital' : 'Físico'"
            />
            <PrCFillInformationComp
                title="Por Saldo"
                :subtitle="product.product.id_reserve == 0
                    ? 'No' : 'Si'"
            />
            <PrCFillInformationComp
                title="Cantidad"
                :subtitle="`${product.quantity}`"
            />
            <PrCFillInformationComp
                title="Precio Unidad"
                :subtitle="product.price_unity.toFixed(2)"
            />
            <PrCFillInformationComp
                title="Total"
                :subtitle="(product.quantity * product.price_unity).toFixed(2)"
            />
            <PrCFillInformationComp
                v-if="categorieUser === 'a'"
                title="Precio Costo"
                :subtitle="product.price_cost.toFixed(2)"
            />
            <h1 class="pr-app-title">
                Información Extra
            </h1>
            <div v-if="product.info != null">
                <h1
                    class="pr-app-subtitle ml-2"
                    style="white-space: pre-line;"
                >
                    {{product.info != null
                        ? product.info : 'S/I'}}
                </h1>
            </div>
            <PrCFillInformationComp
                v-else
                title="Información"
                subtitle="S/I"
            />
        </div>
        <v-divider class="mb-1"></v-divider>
        <div class="pa-2 pt-1 d-flex justify-center flex-wrap">
            <PrCBtnCardComp
                icon="mdi-format-list-bulleted"
                @click="$emit('clickproduct')"
            />
            <PrCBtnCardComp
                v-if="isUserAdmin()
                    && product.product.type === 'd'
                    && product.id_reserve == 0"
                icon="mdi-cellphone-key"
                @click="$emit('clickkeys')"
            />
        </div>
    </div>
</template>
<script>
import {
    PrCFillInformationComp,
    PrCBtnCardComp
} from "../../cells";
export default {
    props: {
        product: Object,
        categorieUser: {
            type: String,
            defulat: "v"
        }
    },
    components: {
        PrCFillInformationComp,
        PrCBtnCardComp
    },
    methods: {
        isUserAdmin() {
            if (!JSON.parse(localStorage.getItem("ecomerce_gatonet"))) return false;
            const user = JSON.parse(localStorage.getItem("ecomerce_gatonet"));
            return user.employee.category === 'a';
        }
    }
}
</script>