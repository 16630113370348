<template>
    <div class="pr-o-table-categories animation-opacity">
        <table class="pr-o-table-categories__table">
            <thead>
                <tr>
                    <th>
                        <h1 class="pr-app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Producto</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Tipo</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Por Saldo</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Cantidad</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Precio Unidad</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Total</h1>
                    </th>
                    <th v-if="categorieUser === 'a'">
                        <h1 class="pr-app-title-dark">Precio Costo</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Información</h1>
                    </th>
                    <th width="100">
                        <h1 class="pr-app-title-dark">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(product, index) in detail"
                    :key="product.id"
                >
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{(index + 1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{product.product.product}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{product.product.type === 'd'
                                ? "Digital" : "Físico"}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{product.product.id_reserve == 0
                                ? "No" : "Si"}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{product.quantity}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{product.price_unity.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{(product.quantity * product.price_unity).toFixed(2)}}
                        </h1>
                    </td>
                    <td
                        v-if="categorieUser === 'a'"
                    >
                        <h1 class="pr-app-subtitle text-right">
                            {{product.price_cost.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1
                            class="pr-app-subtitle"
                            style="white-space: pre-line;"
                        >
                            {{product.info != null
                                ? product.info : "S/I"}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <PrCBtnToolTipTableComp
                            icon="mdi-format-list-bulleted"
                            :smsToolTip="`Ver información: ${product.product.product}`"
                            @click="$emit('clickproduct', product)"
                        />
                        <PrCBtnToolTipTableComp
                            v-if="isUserAdmin()
                                && product.product.type === 'd'
                                && product.id_reserve == 0"
                            icon="mdi-cellphone-key"
                            :smsToolTip="`Ver códigos de: ${product.product.product}`"
                            @click="$emit('clickkeys', product)"
                        />
                    </td>
                </tr>
                <tr v-if="detail.length === 0">
                    <td :colspan="categorieUser === 'a'
                        ? '8' : '7'">
                        <h1 class="pr-app-subtitle text-center">Sin Productos</h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    PrCBtnToolTipTableComp
} from "../../cells";
export default {
    props: {
        detail: Array,
        categorieUser: {
            type: String,
            defulat: "v"
        }
    },
    components: {
        PrCBtnToolTipTableComp
    },
    methods: {
        isUserAdmin() {
            if (!JSON.parse(localStorage.getItem("ecomerce_gatonet"))) return false;
            const user = JSON.parse(localStorage.getItem("ecomerce_gatonet"));
            return user.employee.category === 'a';
        }
    }
}
</script>