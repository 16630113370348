<template>
    <div>
        <OFondHomeComp
            :info="dataFond"
            :publications="publications"
        />
        <div class="d-flex justify-center">
            <div class="home__title d-flex">
                <h1 class="pu-app-big-title-dark">GATONET</h1>
                <h1 class="pu-app-big-subtitle-dark ml-3">Bolivia</h1>
                <div class="home__title-ornament"></div>
            </div>
        </div>
        <div class="d-flex justify-center space--block">
            <div class="home__info home__text">
                <h1 class="pu-app-title">BIENVENIDO!!!!</h1>
                <h1 class="pu-app-subtitle">
                    GATONET te da la bienvenida a nuestra página y te invita a conocer nuestros
                    productos físicos y digitales disponibles en nuestra empresa.
                </h1>
                <h1 class="pu-app-subtitle">
                    Registrate para realizar tus compras
                </h1>
            </div>
        </div>
        <div class="d-flex justify-center space--block">
            <div class="home__info">
                <h1 class="pu-app-big-title-dark">RECOMENDACIONES</h1>
            </div>
        </div>
        <div
            v-if="!dialogDataProduct"
            class="d-flex justify-center"
            :style="styleMobile"
        >
            <div class="home__recommendations">
                <OCardRecomendationComp
                    v-for="(recommendation, index) in recommendations"
                    :key="index"
                    :index="index"
                    :recommendation="recommendation"
                    @selectproduct="eSelectProduct(recommendation.product)"
                />
            </div>
        </div>
        <div
            v-if="dialogDataProduct"
            class="d-flex justify-center"
            :style="styleMobile"
        >
            <div class="home__recommendations" style="width:100%">
                <OCardSkeletonRecomendationComp
                    v-for="skeleton in skeletons"
                    :key="skeleton"
                />
            </div>
        </div>
        <div class="d-flex justify-center space--block">
            <div class="home__info">
                <h1 class="pu-app-big-title-dark">ULTIMOS OFRECIDOS</h1>
            </div>
        </div>
        <div
            v-if="!dialogDataProduct"
            class="d-flex justify-center"
            :style="styleMobile"
        >
            <div class="home__recommendations">
                <OCardLastProductComp
                    v-for="(product, index) in lastProducts"
                    :key="index"
                    :index="index"
                    :product="product"
                    @selectproduct="eSelectProduct(product)"
                />
            </div>
        </div>
        <div
            v-if="dialogDataProduct"
            class="d-flex justify-center"
            :style="styleMobile"
        >
            <div class="home__recommendations" style="width:100%">
                <OCardSkeletonRecomendationComp
                    v-for="skeleton in skeletonsLast"
                    :key="skeleton"
                />
            </div>
        </div>
        <div class="space--block"></div>
        <CSmsActionComp
            :smsActions="smsActions"
            :value="frameAction"
            :isError="isError"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
        />
    </div>
</template>
<script>
import {
    CSmsActionComp
} from "../../components/components_public/cells";
import {
    OFondHomeComp,
    OCardRecomendationComp,
    OCardSkeletonRecomendationComp,
    OCardLastProductComp
} from "../../components/components_public/organisms";
import { mapMutations, mapState } from "vuex";
import {
    Recommendation,
    Product,
    Publication
} from '../../models';
import {
    dataGeneralPublicMixin
} from "../../mixins";
export default {
    mixins: [dataGeneralPublicMixin],
    components: {
        OCardRecomendationComp,
        OFondHomeComp,
        OCardSkeletonRecomendationComp,
        OCardLastProductComp,
        CSmsActionComp
    },
    data: () => ({
        styleMobile: "",
        dataFond: {
            src: "/imgs_public/pc/home_fond.jpg",
            title: "GATONET",
            subtitle: "Compras en línea",
            info: ["Compras en línea", "Ventas Modernas", "Entretenimiento"],
            titleProduct: "Productos GAMERS",
            subtitleProduct: `Lorem ipsum dolor sit amet consectetur, adipisicing elit.`,
            srcProduct: "/imgs_public/pc/keyboard.png",
            srcEntertaiment: "/imgs_public/pc/movie.png",
            titleEntertaiment: "Entretenimiento",
            subtitleEntertaiment: `Lorem ipsum dolor sit amet consectetur, adipisicing elit.`
        },
        recommendations: [],
        skeletons: [0, 1, 2, 3, 4, 5, 6, 7],
        lastProducts: [],
        skeletonsLast: [0, 1, 2, 3],
        dialogDataProduct: false,
        publications: []
    }),
    computed: {
        ...mapState("cartClientStore", ["cart"])
    },
    methods: {
        ...mapMutations("cartClientStore", ["addCart"]),
        async getRequest() {
            this.dialogDataProduct = true;
            try {
                const publications = Publication.getPublication();
                const products = Product.lastProducts();
                const getRecommendations = Recommendation.getRecommendationsPublic();
                const responses = await Promise.all([getRecommendations, products, publications]);
                this.recommendations = responses[0];
                this.lastProducts = responses[1];
                this.publications = responses[2];
                this.dialogDataProduct = false;
            } catch(error) {
                console.log(error);
            }
        },
        existProductInCart(id) {
            for (let i = 0; i < this.cart.length; i++) {
                if (id === this.cart[i].product.id) return true;
            }
            return false;
        },
        eSelectProduct(product) {
            const paramters = {
                'product': product,
                'quantity': 1
            }
            if (product.quantity > 0) {
                if (!this.existProductInCart(product.id))
                    this.addCart(paramters);
                this.mGDShowAction([product.product, "Agregado al carrito"], false);
            } else this.mGDShowAction(["Operación Incorrecta", "Producto no disponible"], true);
        },
    },
    mounted() {
        if (window.innerWidth <= 600) this.styleMobile = "padding-top: 15px";
        this.getRequest();
    }
}
</script>