<template>
    <div class="pr-o-table-categories animation-opacity">
        <table class="pr-o-table-categories__table">
            <thead>
                <tr>
                    <th>
                        <h1 class="pr-app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Producto</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Imágen</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Descripción</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Precio</h1>
                    </th>
                    <th width="190">
                        <h1 class="pr-app-title-dark">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(recommendation, index) in recommendations"
                    :key="recommendation.id"
                >
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{(index + 1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{recommendation.product.product}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex justify-center">
                            <PrCImageTableComp
                                :path="recommendation.product.route_file"
                            />
                        </div>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{recommendation.product.description != null
                                ? recommendation.product.description
                                : "S/D"}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{recommendation.product.price.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex justify-center align-center flex-wrap">
                            <PrCBtnToolTipTableComp
                                icon="mdi-format-list-bulleted-square"
                                :smsToolTip="`Ver detalle: ${recommendation.product.product}`"
                                @click="$emit('clickdetail', recommendation)"
                            />
                            <PrCBtnToolTipTableComp
                                icon="mdi-delete"
                                :smsToolTip="`Eliminar recomendación de: ${recommendation.product.product}`"
                                @click="$emit('clickdelete', recommendation)"
                            />
                        </div>
                    </td>
                </tr>
                <tr v-if="recommendations.length === 0">
                    <td colspan="6">
                        <h1 class="pr-app-subtitle text-center">Sin Información</h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    PrCBtnToolTipTableComp,
    PrCImageTableComp
} from "../../cells";
export default {
    props: {
        recommendations: Array
    },
    components: {
        PrCBtnToolTipTableComp,
        PrCImageTableComp
    }
}
</script>