export default {
    methods: {
        mPUTotalDetail(detail) {
            let total = 0;
            for (let i = 0; i < detail.length; i++)
                total += (detail[i].quantity * detail[i].price_unity);
            return total;
        }
    }
}
