<template>
    <v-dialog
        persistent
        :value="value"
        width="360"
    >
        <v-card class="pa-0 ma-0">
            <div class="d-flex align-center pa-2 pr-cp-background">
                <h1 class="pr-app-title-dark">Información Venta</h1>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    x-small
                    depressed
                    color="red"
                    @click="$emit('clickclose')"
                >
                    <v-icon
                        class="pr-cd-color"
                    >mdi-close</v-icon>
                </v-btn>
            </div>
            <div
                v-if="sale.id != undefined"
                class="pa-2"
            >
                <PrCFillInformationComp
                    title="Moneda"
                    subtitle="Bolivianos"
                />
                <PrCFillInformationComp
                    title="Total Venta"
                    :subtitle="mSaleTotalDB(sale.product_sale).toFixed(2)"
                />
                <PrCFillInformationComp
                    title="Total Costo"
                    :subtitle="mSaleTotalCostDB(sale.product_sale).toFixed(2)"
                />
                <PrCFillInformationComp
                    title="Estimación Ganancia"
                    :subtitle="(mSaleTotalDB(sale.product_sale) - mSaleTotalCostDB(sale.product_sale)).toFixed(2)"
                />
                <PrCFillInformationComp
                    title="Entregado"
                    :subtitle="sale.delivered === 1 ? 'Si' : 'No'"
                >
                    <div
                        :class="sale.delivered === 1
                            ? 'categories__enabled ml-1'
                            : 'categories__disabled ml-1'"
                    ></div>
                </PrCFillInformationComp>
                <PrCFillInformationComp
                    title="Lugar Venta"
                    :subtitle="getTypeSale(sale)"
                />
                <PrCFillInformationComp
                    title="Pago"
                    :subtitle="getTextTypeCoin()"
                />
                <PrCFillInformationComp
                    title="Fecha"
                    :subtitle="sale.date"
                />
                <PrCFillInformationComp
                    title="Hora"
                    :subtitle="sale.hour"
                />
                <PrCFillInformationComp
                    title="Cliente"
                    :subtitle="sale.client.full_name"
                />
                <PrCFillInformationComp
                    title="Celular"
                    :subtitle="sale.client.phone != null
                        ? sale.client.phone : 'S/N'"
                />
                <PrCFillInformationComp
                    title="Email Cliente"
                    :subtitle="sale.client.email"
                />
                <PrCFillInformationComp
                    title="Registrador"
                    :subtitle="sale.user.person.full_name"
                />
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    PrCFillInformationComp
} from "../cells";
import {
    saleMixin
} from "../../../mixins";
export default {
    mixins: [saleMixin],
    components: {
        PrCFillInformationComp
    },
    props: {
        sale: Object,
        value: Boolean
    },
    methods: {
        getTypeSale(sale) {
            if (sale.type_sale === "e") return "Página web";
            if (sale.type_sale === "s") return "Tienda";
            return "Personal"
        },
        getTextTypeCoin() {
            if (this.sale.type_coin === "e") return "Efectivo";
            if (this.sale.type_coin === "q") return "Pago Qr";
            return "Tigo Money";
        }
    }
}
</script>