<template>
    <div>
        <div class="d-flex justify-center">
            <div class="c-image-home">
                <div class="register__info">
                    <div class="register__first-text">
                        <div
                            v-for="(info, index) in dataInfo"
                            :key="index"
                            class="d-flex mb-3"
                        >
                            <div class="o-thid-home__ornament mr-2"></div>
                            <h1 class="pu-app-subtitle-dark">
                                {{info}}
                            </h1>
                        </div>
                    </div>
                    <div class="register__img">
                        <v-img
                            width="100%"
                            height="100%"
                            src="/imgs_public/mobile/cart.svg"
                        ></v-img>
                    </div>
                </div>
                <div class="register__form">
                    <ESkeletonFormComp
                        title="REGISTRARSE"
                    >
                        <OFormRegisterComp
                            :dataClient="dataClient"
                            :dataUser="dataUser"
                            :isMobile="isMobile"
                            @clickform="eConfirmForm"
                        />
                    </ESkeletonFormComp>
                </div>
            </div>
        </div>
        <div class="space--block"></div>
        <CLoaderOperationComp
            :value="dialogOperationIsOpen"
            message="Registrando Cuenta"
        />
        <CSmsActionComp
            :smsActions="smsActions"
            :value="frameAction"
            :isError="true"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
        />
        <CSmsErrorComp
            :smsErrors="smsErrors"
            :value="dialogErrorIsOpen"
            :actionErrorIsActivated="actionErrorIsActivated"
            @click="eCloseError()"
        />
    </div>
</template>
<script>
import {
    CLoaderOperationComp,
    CSmsActionComp,
    CSmsErrorComp
} from "../../components/components_public/cells";
import {
    OFormRegisterComp
} from "../../components/components_public/organisms";
import {
    ESkeletonFormComp
} from "../../components/components_public/species";
import {
    Client,
    User
} from "../../models";
import {
    dataGeneralPublicMixin,
    responseServerMixin
} from "../../mixins";
export default {
    mixins: [
        dataGeneralPublicMixin,
        responseServerMixin
    ],
    components: {
        ESkeletonFormComp,
        OFormRegisterComp,
        CLoaderOperationComp,
        CSmsActionComp,
        CSmsErrorComp
    },
    data: () => ({
        dataClient: new Client,
        dataUser: new User,
        dataInfo: [
            `Envíe la información correcta por cada fila solicitada.`,
            `Una vez realice el registro debe verificar su registro mediante 'La verificación de su correo.'`,
            `Cualquier duda puede realizarla contactandose a nuestra linea de whatsapp proporcionada`
        ]
    }),
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        async addClient() {
            let md5 = require("md5");
            const parameters = {
                "client": this.dataClient,
                "user": {
                    "password": md5(this.dataUser.password)
                }
            }
            try {
                const response = await User.addUserClient(parameters);
                localStorage.setItem('client_gatonet', JSON.stringify(response))
                this.dialogOperationIsOpen = false;
                this.$emit('eventlogin');
                setTimeout(() => {
                    this.$router.replace('/verify-email');
                }, 200);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eConfirmForm(isValidForm) {
            if (isValidForm) {
                this.dialogOperationIsOpen = true;
                this.addClient();
            } else
                this.mGDShowAction(["Formulario Erroneo", "Corrija los campos en rojo"]);
        },
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        const client = JSON.parse(localStorage.getItem("client_gatonet"));
        if (client) {
            if (client.verify_email === null)
                this.$router.replace('/verify-email');
            else this.$router.replace('/account');
        }
    }
}
</script>