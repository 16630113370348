<template>
    <div>
        <div class="categories__header pa-2">
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    v-if="!isMobile"
                    :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                    :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                    class="mr-2"
                    @click="$emit('clickmini')"
                />
                <h1 class="pr-app-title">
                    {{`INVENTARIO DIGITAL (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="categories__header-search">
                <PrOSearchHeaderComp
                    class="mr-1"
                    label="Buscar"
                    v-model="search"
                    @entersearch="eSearchProducts()"
                />
                <PrCBtnToolTipHeaderComp
                    class="mr-1"
                    icon="mdi-microsoft-excel"
                    smsToolTip="Exportar Excel"
                    @click="eExportExcel()"
                />
                <PrCBtnToolTipHeaderComp
                    icon="mdi-database"
                    smsToolTip="Volver a la lista"
                    @click="eBackList()"
                />
            </div>
        </div>
        <PrMLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="d-flex justify-center flex-wrap pa-2">
                    <div class="pr-products__search">
                        <div class="pr-products__search-block">
                            <PrMSelectComp
                                label="Categoría"
                                v-model="idCategorie"
                                :items="categories"
                                background=""
                                itemValue="id"
                                itemText="categorie"
                                :hideDetails="true"
                                @change="eChangeCategorie"
                            />
                        </div>
                        <div class="pr-products__search-block">
                            <PrMSelectComp
                                label="Stock"
                                v-model="valueStock"
                                background=""
                                :items="optionsStock"
                                itemValue="value"
                                itemText="text"
                                :hideDetails="true"
                                @change="eChangeStock"
                            />
                        </div>
                    </div>
                </div>
                <v-divider></v-divider>
                <PrCLoaderDataComp
                    v-if="dialogLoaderDataIsOpen"
                    class="mt-2"
                />
                <PrMWithoutInfoCardsComp
                    v-if="!dialogLoaderDataIsOpen && products.length===0 && isMobile"
                    class="ma-2"
                />
                <PrOTableInventoryComp
                    v-if="!dialogLoaderDataIsOpen && !isMobile"
                    :products="products"
                    :isDigital="true"
                    :search="searchFilter"
                    @clickedit="eShowFormEdit"
                    @clickdetail="eShowDetailProduct"
                    @clickinventory="eShowInventory"
                />
                <div
                    v-if="!dialogLoaderDataIsOpen && isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardInventoryComp
                        class="ma-2"
                        v-for="product in products"
                        :key="product.id"
                        :product="product"
                        :isDigital="true"
                        :search="searchFilter"
                        @clickedit="eShowFormEdit(product)"
                        @clickdetail="eShowDetailProduct(product)"
                        @clickinventory="eShowInventory(product)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="isMobile && !dialogLoaderDataIsOpen && products.length > 0"
                ></v-divider>
                <PrSPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderDataIsOpen && products.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                    @clickpage="eSearchForPage"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="d-flex justify-center pa-2">
                    <PrESkeletonFormComp
                        title="ACTUALIZAR"
                    >
                        <div v-if="product.id != undefined">
                            <PrODialogInfoProductComp
                                :product="product"
                            />
                        </div>
                        <v-divider class="mt-1 mb-4"></v-divider>
                        <PrOFormInventoryComp
                            ref="formproductcomp"
                            :dataProduct="dataProduct"
                            @clickform="eConfirmForm"
                        />
                    </PrESkeletonFormComp>
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2 pb-0" v-if="product.id != undefined">
                    <PrCFillInformationComp
                        title="Producto"
                        :subtitle="product.product"
                    />
                    <PrCFillInformationComp
                        title="Categoría"
                        :subtitle="product.categorie.categorie"
                    />
                    <PrCFillInformationComp
                        title="Total códigos"
                        :subtitle="`${totalDigital}`"
                    />
                    <div>
                        <PrMBtnNormalComp
                            class="mt-1 mb-1"
                            @click="eOpenFormDigital()"
                        >
                            Agregar Código
                        </PrMBtnNormalComp>
                        <PrMBtnNormalComp
                            class="mt-1 mb-1 ml-1"
                            @click="eOpenFormDigitalPending()"
                        >
                            Código pendiente
                        </PrMBtnNormalComp>
                    </div>
                    <div>
                        <PrMBtnNormalComp
                            class="mt-1 mb-1"
                            @click="oOpenFormGroup()"
                        >
                            Códigos en grupo
                        </PrMBtnNormalComp>
                    </div>
                    <v-divider class="mt-1"></v-divider>
                </div>
                <PrCLoaderDataComp
                    v-if="dialogDataDigitalIsOpen"
                    class="mt-2"
                />
                <PrMWithoutInfoCardsComp
                    v-if="!dialogDataDigitalIsOpen && digitals.length===0 && isMobile"
                    class="ma-2"
                />
                <PrOTableDigitalComp
                    v-if="!dialogDataDigitalIsOpen && !isMobile"
                    :digitals="digitals"
                    @clickstate="eConfirmChangeState"
                    @clickedit="eOpenFormEditDigital"
                    @clickdelete="eConfirmDelete"
                />
                <div
                    v-if="!dialogDataDigitalIsOpen && isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardDigitalInventoryComp
                        class="ma-2"
                        v-for="digital in digitals"
                        :key="digital.id"
                        :digital="digital"
                        @clickstate="eConfirmChangeState(digital)"
                        @clickedit="eOpenFormEditDigital(digital)"
                        @clickdelete="eConfirmDelete(digital)"
                    />
                </div>
                <PrSPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogDataDigitalIsOpen && digitals.length > 0"
                    :condicionGo="currentPageDigital < lastPageDigital"
                    :condicionBack="currentPageDigital > 1"
                    :titlePagination="'Pag. '+currentPageDigital+' de '+lastPageDigital"
                    @clickback="eChangePageDigital(false)"
                    @clickgo="eChangePageDigital(true)"
                    @clickpage="eSearchForPageDigital"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2 pb-0" v-if="product.id != undefined">
                    <PrCFillInformationComp
                        title="Producto"
                        :subtitle="product.product"
                    />
                    <PrCFillInformationComp
                        title="Categoría"
                        :subtitle="product.categorie.categorie"
                    />
                    <PrMBtnNormalComp
                        class="mt-1 mb-1 ml-1"
                        @click="eNewFill()"
                    >
                        Nueva Fila
                    </PrMBtnNormalComp>
                </div>
                <v-form
                    v-model="valid"
                    lazy-validation
                    ref="formdigitals"
                >
                    <PrOTableGroupDigitalComp
                        v-if="!isMobile"
                        :codes="listGroupDigital"
                        @clickdelete="eKickDigital"
                    />
                    <div
                        v-if="isMobile"
                        class="d-flex justify-center flex-wrap"
                    >
                        <PrOCardGroupDigitalComp
                            class="ma-2"
                            v-for="(code, index) in listGroupDigital"
                            :key="index"
                            :index="index"
                            :code="code"
                            @clickdelete="eKickDigital(index)"
                        />
                    </div>
                    <div
                        v-if="listGroupDigital.length > 0"
                        class="d-flex justify-center pb-3"
                    >
                        <PrMBtnNormalComp
                            @click="eValidCodes()"
                        >REGISTRAR</PrMBtnNormalComp>
                    </div>
                </v-form>
            </v-tab-item>
        </v-tabs-items>
        <PrSDialogDetailDigitalPendingComp
            :value="dialogDigitalPending"
            ref="dialogdigitalpending"
            @clickclose="eCloseFormDigitalPending()"
            @clickform="eValidForm"
        />
        <PrSDialogDetailProductComp
            :value="dialogDetailIsOpen"
            :product="product"
            :categorieUser="categorieUser"
            @clickclose="dialogDetailIsOpen = false"
        />
        <PrOFormDigitalComp
            :value="dialogFormDigitalIsOpen"
            :dataDigital="dataDigital"
            :indexDigital="indexDigital"
            ref="formdigitalcomp"
            @clickform="eConfirmFormDigital"
            @clickclose="eCloseFormDigital()"
        />
        <PrOGeneralDialogsComp
            :dialogErrorIsOpen="dialogErrorIsOpen"
            :smsErrors="smsErrors"
            :actionErrorIsActivated="actionErrorIsActivated"
            @clickerror="eCloseError()"
            :dialogConfirmationIsOpen="dialogConfirmationIsOpen"
            :smsConfirmations="smsConfirmations"
            @clickyes="eProcessOperation()"
            @clickno="eCloseConfirm()"
            :smsActions="smsActions"
            :frameAction="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
            :dialogOperationIsOpen="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMSelectComp,
    PrMWithoutInfoCardsComp,
    PrMBtnNormalComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCLoaderDataComp,
    PrCFillInformationComp
} from "../../components/components_private/cells";
import {
    PrOSearchHeaderComp,
    PrOTableInventoryComp,
    PrOGeneralDialogsComp,
    PrOFormInventoryComp,
    PrOCardInventoryComp,
    PrODialogInfoProductComp,
    PrOTableDigitalComp,
    PrOFormDigitalComp,
    PrOCardDigitalInventoryComp,
    PrOTableGroupDigitalComp,
    PrOCardGroupDigitalComp
} from "../../components/components_private/organisms";
import {
    PrESkeletonFormComp,
    PrSPaginationComp,
    PrSDialogDetailProductComp,
    PrSDialogDetailDigitalPendingComp
} from "../../components/components_private/species";
import {
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin,
    cryptographyMixin
} from "../../mixins";
import {
    Product,
    Categorie,
    Digital
} from "../../models";
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin,
        cryptographyMixin
    ],
    components: {
        PrMLineHeaderComp,
        PrCBtnToolTipHeaderComp,
        PrOSearchHeaderComp,
        PrOTableInventoryComp,
        PrMSelectComp,
        PrOFormInventoryComp,
        PrESkeletonFormComp,
        PrOGeneralDialogsComp,
        PrCLoaderDataComp,
        PrMWithoutInfoCardsComp,
        PrOCardInventoryComp,
        PrSPaginationComp,
        PrODialogInfoProductComp,
        PrSDialogDetailProductComp,
        PrOTableDigitalComp,
        PrCFillInformationComp,
        PrMBtnNormalComp,
        PrOFormDigitalComp,
        PrOCardDigitalInventoryComp,
        PrSDialogDetailDigitalPendingComp,
        PrOTableGroupDigitalComp,
        PrOCardGroupDigitalComp
    },
    data: () => ({
        categories: [{ id: 0, categorie: "Todos" }],
        idCategorie: 0,
        optionsStock: [
            { value: "t", text: "Todos" },
            { value: "b", text: "Bajo Inv." },
            { value: "m", text: "Medio Inv." },
            { value: "a", text: "Alto Inv." }
        ],
        valueStock: "t",
        search: "",
        dataProduct: new Product,
        products: [],
        tab: 0,
        indexProduct: -1,
        product: {},
        dialogDetailIsOpen: false,
        numberOperation: 0,
        dialogDataDigitalIsOpen: false,
        digitals: [],
        lastPageDigital: 0,
        currentPageDigital: 0,
        totalDigital: 0,
        dialogFormDigitalIsOpen: false,
        dataDigital: new Digital,
        indexDigital: -1,
        idDigital: -1,
        dialogDigitalPending: false,
        quantityDigitalPending: 0,
        listGroupDigital: [],
        valid: true,
        categorieUser: ""
    }),
    computed: {
        categoriesFilter: function() {
            return this.categories.filter(categorie => {
                return categorie.id !== 0;
            });
        },
        searchFilter: function() {
            if (this.mRItIsEmpty(this.search)) return "";
            return this.search;
        }
    },
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
            this.eCloseConfirm();
        },
        async getRequest() {
            this.dialogLoaderDataIsOpen = true;
            try {
                const categories = Categorie.getCategories();
                const products = Product.getInventory(1, "", 0, "t", "d", 0);
                const responses = await Promise.all([categories, products]);
                responses[0].forEach(element => {
                    this.categories.push(element);
                });
                responses[1].data.forEach(element => {
                    element.new_quantity = null;
                    this.products.push(element);
                });
                //this.products = responses[1].data;
                this.lastPage = responses[1].last_page;
                this.currentPage = responses[1].current_page;
                this.total = responses[1].total;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eConfirmForm(isValidForm) {
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                if (isValidForm) {
                    this.smsConfirmations = [`¿Está seguro(a) de actualizar: ${this.dataProduct.product}?`];
                    this.dialogConfirmationIsOpen = true;
                } else this.mGDShowError();
            }, time);
        },
        eBackList() {
            if (this.tab === 1) {
                this.dataProduct = new Product;
                this.$refs.formproductcomp.resetForm();
            }
            if (this.numberOperation === 6) {
                this.tab = 2;
                this.numberOperation = 0;
            } else this.tab = 0;
        },
        eCloseConfirm() {
            if (this.numberOperation === 4)
                this.dialogDigitalPending = true;
            if (this.idDigital !== -1)
                this.resetDataDigital();
            this.dialogConfirmationIsOpen = false;
            if (this.numberOperation === 1)
                this.dialogFormDigitalIsOpen = true;
        },
        async updateInventory() {
            try {
                const response = await Product.updateProductDigitalInventory(this.dataProduct);
                response.new_quantity = null;
                Object.assign(this.products[this.indexProduct], response);
                this.eBackList();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se actualizó el producto"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async addDigitalPending()
        {
            const parameters = {
                'id_product': this.product.id,
                'quantity': this.quantityDigitalPending
            }
            try {
                const response = await Digital.addDigitalPending(parameters);
                response.forEach(element => {
                    this.digitals.unshift(element);
                });
                const index = this.products.indexOf(this.product);
                this.products[index].quantity += parseInt(this.quantityDigitalPending);
                this.dialogOperationIsOpen = false;
                this.numberOperation = 0;
                this.mGDShowAction(["Operación Exitosa", "Se registró los códigos pendientes"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async deleteDigitalPending()
        {
            const parameters = {
                id: this.idDigital,
                id_product: this.product.id
            };
            try {
                await Digital.deleteDigitalPending(parameters);
                this.digitals.splice(this.indexDigital, 1);
                const index = this.products.indexOf(this.product);
                this.products[index].quantity -= 1;
                this.resetDataDigital();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se eliminó el código"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        generateDetailCodes() {
            let detail = [];
            this.listGroupDigital.forEach(element => {
                const code = {
                    'id': element.id,
                    'code': this.mCryGenerateCodeEncrypt(element.code),
                    'description': element.description,
                    'expiration': element.expiration
                }
                detail.push(code);
            });
            return detail;
        },
        async addGroupDigitalsCode() {
            const parameters = {
                'id_product': this.product.id,
                'detail': this.generateDetailCodes()
            }
            try {
                const response = await Digital.addDigitalGroup(parameters);
                const index = this.products.indexOf(this.product);
                this.products[index].quantity += response.length;
                this.totalDigital += response.length;
                response.forEach(element => {
                    this.digitals.unshift(element);
                });
                this.eBackList();
                this.mGDShowAction(["Operación Exitosa", "Se registró los códigos"]);
                this.dialogOperationIsOpen = false;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eProcessOperation() {
            this.dialogConfirmationIsOpen = false;
            this.dialogOperationIsOpen = true;
            switch (this.numberOperation) {
                case 0:
                    this.updateInventory();
                    break;
                case 1:
                    this.addDigital();
                    break;
                case 2:
                    this.changeStateDigital();
                    break;
                case 3:
                    this.editDigital();
                    break;
                case 4:
                    this.addDigitalPending();
                    break;
                case 5:
                    this.deleteDigitalPending();
                    break;
                case 6:
                    this.addGroupDigitalsCode();
            }
        },
        eShowFormEdit(product) {
            this.numberOperation = 0;
            this.indexProduct = this.products.indexOf(product);
            this.dataProduct = Object.assign({}, product);
            this.product = product;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        },
        eShowDetailProduct(product) {
            this.product = product;
            this.dialogDetailIsOpen = true;
        },
         //metodos para buscar productos
        async getProducts(page, search, idCategorie, stock) {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Product.getInventory(page, search, idCategorie, stock, "d", 0);
                this.products = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getProducts(this.currentPage, this.search, this.idCategorie, this.valueStock);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mGDSearchForPage(page, this.mRIsInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getProducts(page, this.search, this.idCategorie, this.valueStock);
                }, time);
            }
        },
        eSearchProducts() {
            this.tab = 0;
            if (this.mRItIsEmpty(this.search)) this.search = "";
            this.getProducts(this.currentPage, this.search, this.idCategorie, this.valueStock);
        },
        eChangeCategorie(idCategorie) {
            this.getProducts(this.currentPage, this.search, idCategorie, this.valueStock);
        },
        eChangeStock(stock) {
            this.getProducts(this.currentPage, this.search, this.idCategorie, stock);
        },
        //digital inventario
        async getDigitals(page, id) {
            this.dialogDataDigitalIsOpen = true;
            try {
                const response = await Digital.getDigital(page, id);
                this.digitals = response.data;
                this.lastPageDigital = response.last_page;
                this.currentPageDigital = response.current_page;
                this.totalDigital = response.total;
                this.dialogDataDigitalIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async eShowInventory(product) {
            this.product = product;
            this.tab = 2;
            this.getDigitals(1, product.id);
        },
        eOpenFormDigital() {
            this.dataDigital.this_slope = 0;
            this.numberOperation = 1;
            this.dialogFormDigitalIsOpen = true;
        },
        eCloseFormDigital() {
            this.numberOperation = 0;
            this.dataDigital = new Digital;
            this.$refs.formdigitalcomp.resetForm();
            this.dialogFormDigitalIsOpen = false;
        },
        eConfirmFormDigital(isValidForm) {
            if (!isValidForm)
                this.mGDShowAction(["Operación Incorrecta", "Corrija los campos rojos"], "error");
            else {
                const textAction = this.indexDigital === -1 ? 'registrar' : 'editar';
                this.smsConfirmations = [`¿Está seguro de ${textAction} el código para ${this.product.product}?`];
                this.dialogFormDigitalIsOpen = false;
                this.dialogConfirmationIsOpen = true;
            }
        },
        generateDataDigital() {
            return {
                'code': this.mCryGenerateCodeEncrypt(this.dataDigital.code),
                'expiration': this.dataDigital.expiration,
                'description': this.dataDigital.description,
                'this_slope': this.dataDigital.this_slope,
                'fk_product': this.product.id
            }
        },
        async editDigital() {
            let dataDigital = this.generateDataDigital();
            dataDigital.id = this.dataDigital.id;
            try {
                const response = await Digital.editDigital(dataDigital);
                Object.assign(this.digitals[this.indexDigital], response);
                this.eCloseFormDigital();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se editó el código"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async changeStateDigital() {
            try {
                const response = await Digital.changeStateDigital({id: this.idDigital});
                this.digitals[this.indexDigital].enabled = response;
                const textState = response === 0 ? 'inhabilitó' : 'habilitó';
                this.resetDataDigital();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", `Se ${textState} el código`]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async addDigital() {
            try {
                const response = await Digital.addDigital(this.generateDataDigital());
                const index = this.products.indexOf(this.product);
                this.products[index].quantity += 1;
                this.digitals.unshift(response);
                this.totalDigital += 1;
                this.eCloseFormDigital();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se agregó el código"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        resetDataDigital() {
            this.idDigital = -1;
            this.indexDigital = -1;
            this.numberOperation = 0;
        },
        eConfirmChangeState(digital) {
            this.numberOperation = 2;
            this.indexDigital = this.digitals.indexOf(digital);
            this.idDigital = digital.id;
            const textState = digital.enabled === 0 ? 'habilitar' : 'inhabilitar';
            this.smsConfirmations = [`¿Está seguro de ${textState} el código de: ${this.product.product}?`];
            this.dialogConfirmationIsOpen = true;
        },
        eOpenFormEditDigital(digital) {
            this.numberOperation = 3;
            this.indexDigital = this.digitals.indexOf(digital);
            this.dataDigital = Object.assign({}, digital);
            this.dataDigital.code = this.mCryDecryptText(this.dataDigital.code);
            this.dialogFormDigitalIsOpen = true;
        },
        eChangePageDigital(isGo) {
            if (isGo) ++this.currentPageDigital;
            else --this.currentPageDigital;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getDigitals(this.currentPageDigital, this.product.id);
            }, time);
        },
        searchForPageDigital(page = 0, isInteger = false) {
            if (!isInteger) {
                const smsErrors = ["Página Erronea", "El N° debe ser un número entero"];
                this.mGDShowAction(smsErrors, "error");
                return false;
            }
            if (parseInt(page) > 0 && parseInt(page) <= this.lastPageDigital) {
                this.currentPageDigital = page;
                return true;
            }
            const smsErrorsNumber = this.lastPageDigital === 1
                ? "Solo tiene la página 1"
                : `El N° debe estar entre 1-${this.lastPageDigital}`;
            const smsErrors = ["Página Erronea", smsErrorsNumber];
            this.mGDShowAction(smsErrors, "error");
            return false;
            
        },
        eSearchForPageDigital(page) {
            const isNotValidPage = this.searchForPageDigital(page, this.mRIsInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getDigitals(page, this.product.id);
                }, time);
            }
        },
        eOpenFormDigitalPending() {
            this.numberOperation = 4;
            this.dialogDigitalPending = true;
        },
        eCloseFormDigitalPending() {
            this.numberOperation = 0;
            this.$refs.dialogdigitalpending.resetForm();
            this.dialogDigitalPending = false;
        },
        eValidForm(parameters) {
            if (!parameters.validForm)
                this.mGDShowAction(["Operación Exitosa", "Corrija la cantidad"], "error");
            else {
                this.quantityDigitalPending = parseInt(parameters.quantity);
                this.dialogDigitalPending = false;
                this.smsConfirmations = [`¿Está seguro(a) de registrar la cantidad: ${parameters.quantity}
                    de códigos pendientes para ${this.product.product}?`];
                this.dialogConfirmationIsOpen = true;
            }
        },
        eConfirmDelete(digital) {
            this.numberOperation = 5;
            this.idDigital = digital.id;
            this.indexDigital = this.digitals.indexOf(digital);
            this.smsConfirmations = ['¿Está seguro(a) de eliminar el código pendiente?'];
            this.dialogConfirmationIsOpen = true;
        },
        oOpenFormGroup() {
            this.numberOperation = 6;
            this.listGroupDigital = [
                {
                    code: null,
                    description: null,
                    expiration: null
                }
            ];
            this.tab = 3;
        },
        eNewFill() {
            this.listGroupDigital.push({
                code: null,
                description: null,
                expiration: null
            });
        },
        eKickDigital(index) {
            this.listGroupDigital.splice(index, 1);
        },
        eValidCodes() {
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                if (!this.$refs.formdigitals.validate()) this.mGDShowError();
                else {
                    this.smsConfirmations = [
                        `¿Está seguro(a) de registrar los códigos para:
                        ${this.product.product}?`
                    ];
                    this.dialogConfirmationIsOpen = true;
                }
            }, time);
        },
        async eExportExcel() {
            this.dialogOperationIsOpen = true;
            try {
                const response = await Product.inventoryTotal(this.search, this.idCategorie, this.valueStock, "d", 0);
                this.generateExcel(response);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        generateExcel(products) {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('productos');

            // Add data to the worksheet
            worksheet.columns = [
                { header: 'N°', key: 'col1', width: 10 },
                { header: 'PRODUCTO', key: 'col2', width: 30 },
                { header: 'DESCRIPCIÓN', key: 'col3', width: 30 },
                { header: 'PRECIO UNIDAD', key: 'col4', width: 20 },
                { header: 'CANTIDAD', key: 'col5', width: 20 },
                { header: 'PRECIO COSTO', key: 'col6', width: 20 },
                { header: 'CATEGORÍA', key: 'col7', width: 30 }
            ];

            const data = [];
            //{ col1: 'Value 1', col2: 'Value 2' }

            products.forEach((product, index) => {
                data.push({
                    col1: (index + 1),
                    col2: `${product.product}`,
                    col3: product.description == null
                        ? "S/D"
                        : product.description,
                    col4: product.price.toFixed(2),
                    col5: product.quantity,
                    col6: product.price_cost.toFixed(2),
                    col7: product.categorie.categorie
                });
            });

            worksheet.addRows(data);
            this.dialogOperationIsOpen = false;
            // Save the workbook
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'inventario_digital.xlsx');
            });
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            this.categorieUser = this.mUserCategoryEmployee();
            if (this.mUserCategoryEmployee() === 'a') this.getRequest();
            else this.$router.replace('/admin/home');
        } else this.$router.replace('/login-admin');
    }
}
</script>