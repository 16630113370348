<template>
    <v-menu
        transition="slide-x-transition"
        bottom
        left
        offset-y
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                depressed
                v-bind="attrs"
                v-on="on"
                fab
                x-small
                :ripple="false"
                @click="$emit('click')"
            >
                <v-icon class="pr-cs-color">{{icon}}</v-icon>
            </v-btn>
        </template>
        <v-card class="pa-1 cp-pu-background">
            <v-list-item
                v-for="(option, index) in options"
                :key="index"
                class="c-menu-options__option mb-1"
                :to="option.route"
                :ripple="false"
            >
                <v-icon class="cd-pu-color mr-2">{{option.icon}}</v-icon>
                <h1 class="pu-app-subtitle-dark">{{option.title}}</h1>
            </v-list-item>
            <v-list-item
                v-if="isLogin"
                class="c-menu-options__option mb-1"
                :ripple="false"
                @click="eCloseSesion()"
            >
                <v-icon class="cd-pu-color mr-2">mdi-account-arrow-right</v-icon>
                <h1 class="pu-app-subtitle-dark">Cerrar Sesión</h1>
            </v-list-item>
        </v-card>
    </v-menu>
</template>
<script>
import {
    User
} from "../../../models";
export default {
    props: {
        options: Array,
        icon: String,
        isLogin: Boolean
    },
    data: () => ({
        dialogOperationIsOpen: false
    }),
    methods: {
        async eCloseSesion() {
            this.dialogOperationIsOpen = true;
            try {
                await User.logoutClient();
                this.dialogOperationIsOpen = false;
                localStorage.removeItem("client_gatonet");
                this.$emit('eventlogin');
                setTimeout(() => {
                    this.$router.replace('/login-client');
                }, 150);
            } catch (error) {
                this.$emit('eventlogin');
                localStorage.removeItem("client_gatonet");
                setTimeout(() => {
                    this.$router.replace('/login-client');
                }, 150);
            }
        }
    }
}
</script>