<template>
    <div class="sales">
        <div class="d-flex align-center pa-2">
            <PrCBtnToolTipHeaderComp
                :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                class="mr-2"
                @click="$emit('clickmini')"
            />
            <h1 class="pr-app-title">
                VENDER
            </h1>
        </div>
        <PrMLineHeaderComp />
        <div class="sales__body mt-2">
            <div class="sales__products">
                <PrSySearchProductsSaleComp
                    ref="searchpoduct"
                    :products="products"
                    :categories="categories"
                    :indexDetail="indexDetail"
                    :dialogLoaderDataIsOpen="dialogLoaderDataIsOpen"
                    @entersearch="eSearchProducts"
                    @clickform="eAddProductDetail"
                    @clickselectproduct="eAddSelectProduct"
                    @clickbackproducts="eResetData()"
                    @clickdetail="eShowDetailProduct"
                    @inputsearch="eInputSearchProduct"
                />
            </div>
            <PrSCompletDetailSaleComp
                :detailSale="detailSale"
                :isStore="isStore"
                @clickstore="isStore = !isStore"
                @clickedit="eOpenFormEdit"
                @clicksale="eProcessSale"
                @cleardetail="eClearDetail()"
                @clickdetail="eShowDetailProductSale"
            />
        </div>
        <PrSDialogDetailProductComp
            :value="dialogDetailIsOpen"
            :product="product"
            :categorieUser="categorieUser"
            @clickclose="dialogDetailIsOpen = false"
        />
        <PrCOptionsDeliveredComp
            :value="dialogDeliveredIsOpen"
            :dataSale="dataSale"
            ref="optiondelivered"
            @clickclose="eCloseFormDelivered()"
            @clickdelivered="eSaveDelivered"
        />
        <PrSSearchClientSaleComp
            ref="searchclientcomp"
            :value="dialogClientIsOpen"
            @errorserver="eProccessErrorServer"
            @clickform="eValidNewClient"
            @clickclient="eSelectClient"
            @clickclose="dialogClientIsOpen = false"
        />
        <PrSDialogSaleClientComp
            :value="dialogSaleClient"
            :sale="saleMade"
            @clickclose="dialogSaleClient = false"
            @clickwhatsapp="eSendWhatsappClient()"
            @clickoutclient="dialogSaleClient = false"
        />
        <PrOGeneralDialogsComp
            :dialogErrorIsOpen="dialogErrorIsOpen"
            :smsErrors="smsErrors"
            :actionErrorIsActivated="actionErrorIsActivated"
            @clickerror="eCloseError()"
            :dialogConfirmationIsOpen="dialogConfirmationIsOpen"
            :smsConfirmations="smsConfirmations"
            @clickyes="addSale()"
            @clickno="eCloseSmsConfirm()"
            :smsActions="smsActions"
            :frameAction="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
            :dialogOperationIsOpen="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCOptionsDeliveredComp
} from "../../components/components_private/cells";
import {
    PrOGeneralDialogsComp
} from "../../components/components_private/organisms";
import {
    PrSCompletDetailSaleComp,
    PrSDialogDetailProductComp,
    PrSSearchClientSaleComp,
    PrSDialogSaleClientComp
} from "../../components/components_private/species";
import {
    PrSySearchProductsSaleComp
} from "../../components/components_private/systems";
import {
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin,
    saleMixin
} from "../../mixins";
import {
    Product,
    Categorie,
    Sale,
    Client,
    User,
    //Setting
} from "../../models";
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin,
        saleMixin
    ],
    components: {
        PrCBtnToolTipHeaderComp,
        PrMLineHeaderComp,
        PrSCompletDetailSaleComp,
        PrSySearchProductsSaleComp,
        PrOGeneralDialogsComp,
        PrSDialogDetailProductComp,
        PrCOptionsDeliveredComp,
        PrSSearchClientSaleComp,
        PrSDialogSaleClientComp
    },
    data: () => ({
        detailSale: [],
        productSale: [],
        isStore: true,
        products: [],
        categories: [{ id: 0, categorie: "Todos" }],
        indexDetail: -1,
        product: {},
        dialogDetailIsOpen: false,
        dataSale: new Sale,
        dataClient: new Client,
        dataUser: new User,
        with_client: false,
        new_client: false,
        dialogClientIsOpen: false,
        dialogDeliveredIsOpen: false,
        setTimeOutSearch: "",
        saleMade: {},
        dialogSaleClient: false,
        categorieUser: ""
    }),
    methods: {
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        async getRequest() {
            try {
                const categories = Categorie.getCategoriesSale(this.mUserCategoryEmployee());
                const responses = await Promise.all([categories]);
                responses[0].forEach(element => {
                    this.categories.push(element);
                });
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async eSearchProducts(parameters) {
            this.indexDetail = -1;
            //this.products = [];
            //this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Product.getProductsSale(parameters.search, parameters.idCategorie, parameters.type, this.mUserCategoryEmployee());
                this.products = response;
                //this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async eInputSearchProduct(parameters) {
            clearTimeout(this.setTimeOutSearch);
            this.setTimeOutSearch = setTimeout(() => {
                //if (this.search != "" && this.search != null)
                this.eSearchProducts(parameters);
            }, 200);
        },
        thereIsProductInDetail(id) {
            for (let i = 0; i < this.detailSale.length; i++) {
                if (this.detailSale[i].product.id === id) return true;
            }
            return false;
        },
        generateProductForm(parameters) {
            const priceValue = parseFloat(parameters.product.new_price);
            return {
                "product": parameters.product,
                "price": parseFloat(priceValue.toFixed(2)),
                'quantity': parseInt(parameters.product.new_quantity)
            }
        },
        addProductDetailOfForm(parameters) {
            if (this.thereIsProductInDetail(parameters.product.id))
                this.mGDShowAction(["Operación Denegada", "El producto ya se encuentra en el detalle"], "error");
            else {
                this.detailSale.push(this.generateProductForm(parameters));
                this.$refs.searchpoduct.resetData();
                this.mGDShowAction(["Operación Realizada", "Se agregó el producto"], "success");
            }
        },
        updateProductDetail(parameters) {
            const product = this.generateProductForm(parameters);
            Object.assign(this.detailSale[this.indexDetail], product);
            this.indexDetail = -1;
            this.$refs.searchpoduct.eBackListProducts();
            this.mGDShowAction(["Operación Realizada", "Se actualizó el producto"], "success");
        },
        eAddProductDetail(parameters) {
            if (!parameters.isValidForm)
                this.mGDShowAction(["Operación incorrecta", "Corrija los campos rojos"], "error");
            if (this.indexDetail === -1)
                this.addProductDetailOfForm(parameters);
            else this.updateProductDetail(parameters);
        },
        eAddSelectProduct(parameters) {
            if (this.thereIsProductInDetail(parameters.product.id))
                this.mGDShowAction(["Operación Denegada", "El producto ya se encuentra en el detalle"], "error");
            else {
                let product = parameters.product;
                product.new_quantity = `${parameters.quantity}`;
                product.new_price = `${parameters.product.price}`;
                const dataProduct = {
                    "product": product,
                    "price": parameters.product.price,
                    "quantity": parameters.quantity,
                    "id_reserve": parameters.product.id_reserve,
                    "balance_hold": parameters.product.balance_hold
                }
                this.detailSale.push(dataProduct);
                this.$refs.searchpoduct.resetData();
            }
        },
        eClearDetail() {
            this.detailSale = [];
        },
        eOpenFormEdit(product) {
            this.indexDetail = this.detailSale.indexOf(product);
            this.$refs.searchpoduct.assignProduct(product.product);
        },
        eResetData() {
            this.indexDetail = -1;
        },
        eShowDetailProduct(product) {
            this.product = Object.assign({}, product);
            this.dialogDetailIsOpen = true;
        },
        eShowDetailProductSale(product) {
            this.product = Object.assign({}, product.product);
            this.dialogDetailIsOpen = true;
        },
        showInfoConfirmationSale() {
            const total = this.mSaleTotalLocalStore(this.productSale);
            const nameClient = this.with_client
                ? this.dataClient.full_name : "S/N";
            const textDelivered = this.dataSale.delivered === 0 ? 'No' : 'Si';
            const textTypeSale = this.dataSale.type_sale === "t" ? "Tienda" : "Venta Personal";
            this.smsConfirmations = [`¿${this.mUserNameEmployee()}, está seguro(a) de registrar la venta:`,
                `Total Venta: ${total.toFixed(2)} Bs.`,
                `Cliente: ${nameClient}`,
                `Entregado: ${textDelivered}`,
                `Vendido de: ${textTypeSale}`];
            this.dialogConfirmationIsOpen = true;
        },
        //operaciones ventas
        eCloseSmsConfirm() {
            this.eCloseFormDelivered();
            this.dialogConfirmationIsOpen = false;
        },
        eCloseFormDelivered() {
            this.dataSale.delivered = null;
            this.dataSale.type_coin = "e";
            this.$refs.optiondelivered.resetForm();
            this.dialogDeliveredIsOpen = false;
        },
        eSaveDelivered() {
            if (this.dataSale.delivered == null)
                this.mGDShowAction(["Error de Elección", "Seleccione la entrega"], "error");
            else {
                this.dialogDeliveredIsOpen = false;
                if (this.with_client) this.dialogClientIsOpen = true;
                else this.showInfoConfirmationSale();
            }
        },
        resetDataSale() {
            this.detailSale = [];
            this.dataUser = new User;
            this.dataClient = new Client;
            this.dataSale = new Sale;
            this.productSale = [];
            this.$refs.optiondelivered.resetForm();
            this.new_client = false;
            this.with_client = false;
            this.products = [];
        },
        dataUserForm() {
            if (!this.with_client) return {};
            if (!this.new_client) return {};
            let md5 = require('md5');
            return {
                "username": this.dataClient.email,
                "password": md5(this.dataUser.password)
            }
        },
        async addSale() {
            this.dialogConfirmationIsOpen = false;
            this.dialogOperationIsOpen = true;
            const parameters = {
                "with_client": this.with_client,
                "new_client": this.new_client,
                "client": !this.with_client ? {} : this.dataClient,
                "user": this.dataUserForm(),
                "sale": {
                    "delivered": this.dataSale.delivered,
                    "type_sale": this.isStore ? "s" : "p",
                    "type_coin": this.dataSale.type_coin,
                    "note": this.dataSale.note
                },
                "product_sale": this.productSale
            }
            try {
                const response = await Sale.addSale(parameters);
                this.saleMade = response;
                //if (response.client.email != '0' && response.client.phone != null)
                this.dialogSaleClient = true;
                this.resetDataSale();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "La venta fue registrada"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eProcessSale(parameters) {
            if (this.detailSale.length > 0) {
                this.productSale = parameters.productSale;
                this.with_client = !parameters.isDirect;
                this.dialogDeliveredIsOpen = true;
            } else this.mGDShowAction(["Operación Denegada", "Agregue productos antes de vender"], "error");
        },
        eProccessErrorServer(error) {
            this.processErrorServer(error);
        },
        eValidNewClient(parameters) {
            if (!parameters.isValidForm)
                this.mGDShowAction(["Operación Incorrecta", "Corrija los datos en rojo"], "error");
            else {
                this.new_client = true;
                this.dialogClientIsOpen = false;
                this.$refs.searchclientcomp.resetData();
                this.dataClient = parameters.client;
                this.dataUser = parameters.user;
                this.showInfoConfirmationSale();
            }
        },
        eSelectClient(client) {
            this.new_client = false;
            this.dialogClientIsOpen = false;
            this.dataClient = Object.assign({}, client);
            this.$refs.searchclientcomp.resetData();
            this.showInfoConfirmationSale();
        },
        eSendWhatsappClient() {
            this.dialogSaleClient = false;
            const message = `${this.saleMade.client.full_name} muchas gracias por tu compra,
                que tengas un gran día. GATONET`;
            setTimeout(() => {
                window.open(`https://api.whatsapp.com/send?phone=${this.saleMade.client.phone}&text=${message}`);
            }, 300);
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.$router.replace('/admin/sale-mobile');
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            this.categorieUser = this.mUserCategoryEmployee();
            this.getRequest();
        } else this.$router.replace('/login-admin');
    }
}
</script>