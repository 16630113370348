<template>
    <v-btn
        depressed
        x-small
        outlined
        class="pr-cs-color"
        :ripple="false"
        @click="$emit('click')"
        style="margin:2px"
    >
        <PrMIconComp
            :icon="icon"
            classIcon="pr-cs-color"
        />
    </v-btn>
</template>
<script>
import { PrMIconComp } from "../molecules";
export default {
    components: {
        PrMIconComp
    },
    props: {
        icon: String
    }
}
</script>
