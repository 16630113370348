<template>
    <div class="pr-o-detail-sale">
        <table class="pr-o-detail-sale__table">
            <thead>
                <tr>
                    <th>
                        <h1 class="pr-app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Producto</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">P.U.</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Cant.</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Total</h1>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(product, index) in detailSale"
                    :key="product.product.id"
                >
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{(index + 1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{product.product.product}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle text-right">
                            {{product.price.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle text-right">
                            {{product.quantity}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle text-right">
                            {{(product.price * product.quantity).toFixed(2)}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <PrCMenuOptionsComp
                            icon="mdi-arrow-bottom-left-thick"
                            :options="options"
                            @clickedit="$emit('clickedit', product)"
                            @clickdelete="$emit('clickdelete', product)"
                            @clickdetail="$emit('clickdetail', product)"
                        />
                    </td>
                </tr>
                <tr v-if="detailSale.length === 0">
                    <td colspan="6">
                        <h1 class="pr-app-subtitle text-center">Sin Productos</h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    PrCMenuOptionsComp
} from "../../cells";
export default {
    props: {
        detailSale: Array
    },
    components: {
        PrCMenuOptionsComp
    },
    data: () => ({
        options: [
            {
                icon: "mdi-pencil",
                title: "Editar Producto",
                nameEmit: "clickedit"
            },
            {
                icon: "mdi-delete",
                title: "Quitar del detalle",
                nameEmit: "clickdelete"
            },
            {
                icon: "mdi-list-box",
                title: "Ver detalle",
                nameEmit: "clickdetail"
            }
        ]
    })
}
</script>