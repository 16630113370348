<template>
    <v-dialog
        persistent
        :value="value"
        width="800"
    >
        <v-card class="pa-0 ma-0">
            <div class="d-flex align-center pa-2 pr-cp-background">
                <h1 class="pr-app-title-dark">VENTA REALIZADA</h1>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    x-small
                    depressed
                    color="red"
                    @click="$emit('clickclose')"
                >
                    <v-icon
                        class="pr-cd-color"
                    >mdi-close</v-icon>
                </v-btn>
            </div>
            <div class="pa-2">
                <h1
                    v-if="eValidDataClient()"
                    class="pr-app-subtitle"
                >
                    {{getMessageClient()}}
                </h1>
                <h1
                    v-else
                    class="pr-app-subtitle"
                >
                    La venta registrada no tiene asignado códigos digitales. GRACIAS
                </h1>
                <div v-if="sale.id != undefined">
                    <div
                        v-for="product in sale.product_sale"
                        :key="product.product.product"
                    >
                        <PrCFillInformationComp
                            title="Producto"
                            :subtitle="product.product.product"
                        />
                        <div class="d-flex justify-center flex-wrap">
                            <PrOCardKeyProductSaleComp
                                v-for="code in product.digitals"
                                :key="code.id"
                                :code="code"
                                class="ma-2"
                                :isInfo="true"
                            />
                        </div>
                    </div>
                </div>
                <div class="text-center">
                    <PrMBtnNormalComp
                        v-if="eValidDataClient()"
                        class="mt-1"
                        @click="$emit('clickwhatsapp')"
                    >
                        Cliente
                        <v-icon small class="ml-1">mdi-whatsapp</v-icon>
                    </PrMBtnNormalComp>
                    <PrMBtnNormalComp
                        v-else
                        class="mt-1"
                        @click="$emit('clickoutclient')"
                    >
                        Aceptar
                    </PrMBtnNormalComp>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    PrMBtnNormalComp
} from "../molecules";
import {
    PrCFillInformationComp
} from "../cells";
import {
    PrOCardKeyProductSaleComp
} from "../organisms"
export default {
    props: {
        sale: Object,
        value: Boolean
    },
    components: {
        PrMBtnNormalComp,
        PrOCardKeyProductSaleComp,
        PrCFillInformationComp
    },
    methods: {
        getMessageClient() {
            if (this.sale.id == undefined) return "";
            const message = `${this.sale.client.full_name} muchas gracias por tu compra,
                que tengas un gran día. GATONET`;
            return message;
        },
        eValidDataClient() {
            if (this.sale.id == undefined) return false;
            if (this.sale.client.phone == null) return false;
            return true;
        }
    }
}
</script>