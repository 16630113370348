<template>
    <v-dialog
        :value="value"
        persistent
        width="300"
    >
        <v-card class="pa-0 ma-0">
            <div class="d-flex align-center pr-cp-background pa-2">
                <h1 class="pr-app-title-dark">Actualizar Imágen</h1>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    x-small
                    depressed
                    color="red"
                    @click="eCloseForm()"
                >
                    <v-icon
                        class="pr-cd-color"
                    >mdi-close</v-icon>
                </v-btn>
            </div>
            <v-divider></v-divider>
            <div v-if="product.id != undefined" class="pa-2">
                <PrCFillInformationComp
                    title="Producto"
                    :subtitle="product.product"
                />
            </div>
            <div class="d-flex justify-center pa-3 pl-2 pr-2 pt-1">
                <PrCPhotoFormComp
                    refImg="fotografiaup"
                    ref="photoformcomp"
                    title="Foto"
                    subtitle="Oficina"
                    fileFormat="image/jpeg"
                    @changephoto="eChangePhoto"
                />
            </div>
            <div class="text-center pb-2">
                <PrMBtnNormalComp
                    @click="$emit('sendForm')"
                >GUARDAR</PrMBtnNormalComp>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    PrMBtnNormalComp
} from "../../molecules";
import {
    PrCPhotoFormComp,
    PrCFillInformationComp
} from "../../cells";
export default {
    components: {
        PrCPhotoFormComp,
        PrMBtnNormalComp,
        PrCFillInformationComp
    },
    props: {
        value: Boolean,
        product: Object
    },
    methods: {
        resetPhoto() {
            this.$refs.photoformcomp.resetData();
        },
        eChangePhoto(parameters) {
            this.$emit('changefile', parameters);
        },
        eCloseForm() {
            this.$refs.photoformcomp.resetData();
            this.$emit('clickclose');
        }
    }
}
</script>