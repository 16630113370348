<template>
    <v-dialog
        persistent
        :value="value"
        width="320"
    >
        <v-card class="pa-0 ma-0">
            <div class="d-flex align-center pa-2 pr-cp-background">
                <h1 class="pr-app-title-dark">Formulario Producto</h1>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    x-small
                    depressed
                    color="red"
                    @click="$emit('clickclose')"
                >
                    <v-icon
                        class="pr-cd-color"
                    >mdi-close</v-icon>
                </v-btn>
            </div>
            <div class="pa-2">
                <div v-if="product.id != undefined">
                    <div class="d-flex justify-center">
                        <PrCImageTableComp
                            :path="product.route_file"
                        />
                    </div>
                    <h1 class="pr-app-title">{{product.product}}</h1>
                    <h1 class="pr-app-subtitle">{{product.description}}</h1>
                    <v-form
                        v-model="valid"
                        lazy-validation
                        ref="formproduct"
                    >
                        <PrCFillInformationComp
                            title="Precio"
                            :subtitle="product.price.toFixed(2)"
                        />
                        <PrMTextFieldComp
                            v-model="product.new_price"
                            :hideDetails="true"
                            class="mt-2"
                            label="Nuevo Precio"
                            :rules="[
                                value => !mRItIsEmpty(value) || smsEmpty,
                                value => mRIsNumeric(value) || smsNotNumeric,
                                value => !mRExcedeLimite(value, product.price) || 'No debe exceder al precio de venta'
                            ]"
                        />
                        <PrCFillInformationComp
                            class="mt-2"
                            title="Cantidad"
                            :subtitle="`${product.quantity}`"
                        >
                            <div
                                :class="getClassInventory(product)"
                                style="margin-top:0px"
                            ></div>
                        </PrCFillInformationComp>
                        <PrMTextFieldComp
                            v-model="product.new_quantity"
                            :hideDetails="true"
                            class="mt-2"
                            label="Nueva Cantidad"
                            :rules="[
                                value => !mRItIsEmpty(value) || smsEmpty,
                                value => mRIsInteger(value) || smsNotInteger,
                                value => !mRExcedeLimite(value, product.quantity) || 'No debe exceder al precio de venta'
                            ]"
                        />
                        <div class="text-center mt-2">
                            <PrMBtnNormalComp
                                icon="mdi-check"
                                @click="eValidateForm()"
                            >
                                {{indexDetail===-1 ? 'Agregar' : 'EDITAR'}}
                            </PrMBtnNormalComp>
                        </div>
                    </v-form>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    PrMTextFieldComp,
    PrMBtnNormalComp
} from "../../molecules";
import {
    PrCImageTableComp,
    PrCFillInformationComp,
} from "../../cells";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [rulesMixin],
    props: {
        product: Object,
        value: Boolean,
        indexDetail: Number
    },
    components: {
        PrCImageTableComp,
        PrCFillInformationComp,
        PrMTextFieldComp,
        PrMBtnNormalComp
    },
    data: () => ({
        valid: true
    }),
    methods: {
        getClassInventory(product) {
            if (product.id == undefined) return "categories__disabled ml-1";
            if (product.quantity <= 5) return "categories__disabled ml-1";
            if (product.quantity <= 15) return "categories__warning ml-1";
            return "categories__enabled ml-1";
        },
        eValidateForm() {
            if (this.$refs.formproduct.validate())
                this.$emit('clickform', true);
            else this.$emit('clickform', false);
        },
        resetForm() {
            this.$refs.formproduct.resetValidation();
        }
    }
}
</script>