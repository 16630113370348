<template>
    <div class="pr-o-table-categories animation-opacity">
        <table class="pr-o-table-categories__table">
            <thead>
                <tr>
                    <th>
                        <h1 class="pr-app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Categoría</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Activo Web</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Para Productos</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Vista</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Para Vendedor</h1>
                    </th>
                    <th width="190">
                        <h1 class="pr-app-title-dark">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(categorie, index) in categories"
                    :key="categorie.id"
                >
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{(index + 1)}}
                        </h1>
                    </td>
                    <td>
                        <text-highlight
                            class="pr-app-subtitle"
                            :queries="search"
                        >
                            {{categorie.categorie}}
                        </text-highlight>
                    </td>
                    <td>
                        <div class="d-flex align-center">
                            <div
                                :class="categorie.enabled === 1
                                    ? 'categories__enabled mr-1'
                                    : 'categories__disabled mr-1'"
                                style="margin-top:-2px"    
                            ></div>
                            <h1 class="pr-app-subtitle">
                                {{categorie.enabled === 1 ? "Si" : "No"}}
                            </h1>
                        </div>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{getCategorieType(categorie)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{getViews(categorie)}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex align-center">
                            <div
                                :class="categorie.is_seller === 1
                                    ? 'categories__enabled mr-1'
                                    : 'categories__disabled mr-1'"
                                style="margin-top:-2px"    
                            ></div>
                            <h1 class="pr-app-subtitle">
                                {{categorie.is_seller === 1 ? "SI" : "NO"}}
                            </h1>
                        </div>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <PrCBtnToolTipTableComp
                            icon="mdi-pencil"
                            :smsToolTip="`Editar: ${categorie.categorie}`"
                            @click="$emit('clickedit', categorie)"
                        />
                        <PrCBtnToolTipTableComp
                            :icon="categorie.enabled === 1
                                ? 'mdi-eye-off'
                                : 'mdi-eye'"
                            :smsToolTip="categorie.enabled === 1
                                ? `Inhabilitar: ${categorie.categorie}`
                                : `Habilitar: ${categorie.categorie}`"
                            @click="$emit('clickstate', categorie)"
                        />
                        <PrCBtnToolTipTableComp
                            icon="mdi-delete"
                            :smsToolTip="`Eliminar: ${categorie.categorie}`"
                            @click="$emit('clickdelete', categorie)"
                        />
                        <PrCBtnToolTipTableComp
                            :icon="categorie.is_seller === 1
                                ? 'mdi-account-off'
                                : 'mdi-account'"
                            :smsToolTip="categorie.is_seller === 1
                                ? `Inhabilitar categoría: ${categorie.categorie}, para vendedor`
                                : `Habilitar categoría: ${categorie.categorie}, para vendedor`"
                            @click="$emit('clickseller', categorie)"
                        />
                    </td>
                </tr>
                <tr v-if="categories.length === 0">
                    <td colspan="7">
                        <h1 class="pr-app-subtitle text-center">Sin Información</h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    PrCBtnToolTipTableComp
} from "../../cells";
import TextHighlight from 'vue-text-highlight';
export default {
    props: {
        categories: Array,
        search: String
    },
    components: {
        PrCBtnToolTipTableComp,
        TextHighlight
    },
    methods: {
        getCategorieType(categorie) {
            if (categorie.choice === "t") return "Todos";
            if (categorie.choice === "d") return "Digitales";
            return "Físicos";
        },
        getViews(categorie) {
            if (categorie.view === "c") return "Gift Card";
            if (categorie.view === "g") return "Digitales";
            if (categorie.view === "s") return "Streaming";
            if (categorie.view === "p") return "Pin Digital";
            return "Ninguno";
        }
    }
}
</script>