<template>
    <v-textarea
        :label="label"
        :dense="true"
        :counter="counter"
        height="80"
        outlined
        :rules="rules"
        background-color="white"
        :value="value"
        @input="e => this.$emit('input', e)"
        :clearable="clearable"
        @keyup.enter="$emit('click')"
        @click:append="$emit('clicksee')"
        ref="textarea"
    ></v-textarea>
</template>
<script>
export default {
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        label: String,
        value: [Number, String],
        rules: Array,
        counter: Number,
        clearable: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        activateFocus() {
            this.$refs.textarea.focus();
        }
    }
}
</script>