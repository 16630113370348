<template>
    <div class="o-table-purchases animation-opacity">
        <table class="o-table-purchases__table">
            <thead>
                <tr>
                    <th>
                        <h1 class="pr-app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="pu-app-title-dark">Total Compra (Bs.)</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Hora</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Fecha</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Transacción</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Venta</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Entregado</h1>
                    </th>
                    <th width="90">
                        <h1 class="pr-app-title-dark">Detalle</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(purchase, index) in purchases"
                    :key="purchase.id"
                >
                    <td>
                        <h1 class="pr-app-subtitle cp-pu-color">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle cp-pu-color text-right">
                            {{mPUTotalDetail(purchase.product_sale).toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle cp-pu-color text-center">
                            {{purchase.hour}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle cp-pu-color text-center">
                            {{purchase.date}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle cp-pu-color">
                            {{purchase.type_sale === 'e'
                                ? 'Compra Internet' : 'Tienda'}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle cp-pu-color">
                            {{purchase.enabled === 0
                                ? 'Anulada' : 'Realizada'}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle cp-pu-color">
                            {{getDeliveredPurchase(purchase)}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <CBtnToolTipTableComp
                            icon="mdi-format-list-bulleted-square"
                            smsToolTip="Detalle de Compra"
                            @click="$emit('clickdetail', purchase)"
                        />
                    </td>
                </tr>
                <tr v-if="purchases.length === 0">
                    <td colspan="8">
                        <h1 class="pr-app-subtitle text-center">Sin Compras</h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    CBtnToolTipTableComp
} from "../../cells";
import {
    purchasesMixin
} from "../../../../mixins";
export default {
    mixins: [purchasesMixin],
    props: {
        purchases: Array
    },
    components: {
        CBtnToolTipTableComp
    },
    methods: {
        getDeliveredPurchase(purchase) {
            if (purchase.delivered === 0) return 'No';
            for (let i = 0; i < purchase.product_sale.length; i++) {
                for (let k = 0; k < purchase.product_sale[i].digitals.length; k++) {
                    if (purchase.product_sale[i].digitals[k].this_slope === 1) return 'No';
                }
            }
            return 'Si';
        }
    }
}
</script>