<template>
    <div class="d-flex justify-center">
        <div class="e-skeleton-form">
            <div class="e-skeleton-form__header">
                <h1 class="pu-app-title-dark text-center">
                    {{title}}
                </h1>
            </div>
            <div class="e-skeleton-form__body">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: String,
        styleSkeleton: String
    }
}
</script>