<template>
    <div>
        <PrCSmsErrorComp
            :smsErrors="smsErrors"
            :value="dialogErrorIsOpen"
            :actionErrorIsActivated="actionErrorIsActivated"
            @click="$emit('clickerror')"
        />
        <PrCSmsConfirmationComp
            :smsConfirmations="smsConfirmations"
            :value="dialogConfirmationIsOpen"
            @clickyes="$emit('clickyes')"
            @clickno="$emit('clickno')"
        />
        <PrCSmsActionComp
            :smsActions="smsActions"
            :value="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="$emit('clickaccion')"
        />
        <PrCLoaderOperationComp
            :value="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrCSmsErrorComp,
    PrCSmsConfirmationComp,
    PrCSmsActionComp,
    PrCLoaderOperationComp
} from "../../cells";
export default {
    components: {
        PrCSmsErrorComp,
        PrCSmsConfirmationComp,
        PrCSmsActionComp,
        PrCLoaderOperationComp
    },
    props: {
        smsErrors: Array,
        dialogErrorIsOpen: Boolean,
        actionErrorIsActivated: Boolean,
        smsConfirmations: Array,
        dialogConfirmationIsOpen: Boolean,
        smsActions: Array,
        frameAction: Boolean,
        colorAction: String,
        timeSuccessAction: Number,
        dialogOperationIsOpen: Boolean
    }
}
</script>