<template>
    <div class="pr-o-card-categorie animation-opacity">
        <div class="pr-o-card-categorie__header d-flex justify-center">
            <h1 class="pr-app-title-dark">
                {{`Total Venta: ${mSaleTotalDB(sale.product_sale).toFixed(2)}`}}
            </h1>
        </div>
        <div class="pa-2 pb-1">
            <PrCFillInformationComp
                title="Cliente"
                :search="search"
                :isSearch="true"
                :subtitle="sale.client.full_name"
            />
            <PrCFillInformationComp
                title="Entregado"
                :subtitle="sale.delivered === 1 ? 'Si' : 'No'"
            >
                <div
                    :class="sale.delivered === 1
                        ? 'categories__enabled ml-1'
                        : 'categories__disabled ml-1'"
                ></div>
            </PrCFillInformationComp>
            <PrCFillInformationComp
                title="Lugar Venta"
                :subtitle="getTypeSale()"
            />
            <PrCFillInformationComp
                title="Pago"
                :subtitle="getTextTypeCoin()"
            />
            <PrCFillInformationComp
                title="Fecha"
                :subtitle="sale.date"
            />
            <PrCFillInformationComp
                title="Hora"
                :subtitle="sale.hour"
            />
            <PrCFillInformationComp
                title="Nota"
                :subtitle="sale.note == null
                    ? '-----'
                    : sale.note"
            />
        </div>
        <v-divider class="mb-1"></v-divider>
        <div class="pa-2 pt-1 d-flex justify-center flex-wrap">
            <PrCBtnCardComp
                v-if="!isInfo"
                icon="mdi-format-list-bulleted"
                @click="$emit('clickdetail')"
            />
            <PrCBtnCardComp
                icon="mdi-account-details"
                @click="$emit('clickinfo')"
            />
            <PrCBtnCardComp
                v-if="!isClient && !isSend && !isInfo"
                icon="mdi-eye-off"
                @click="$emit('clickstate')"
            />
            <PrCBtnCardComp
                v-if="isSend"
                icon="mdi-file-send"
                @click="$emit('clicksend')"
            />
            <PrCBtnCardComp
                v-if="isInfo"
                icon="mdi-cellphone-key"
                @click="$emit('clickkeys')"
            />
        </div>
    </div>
</template>
<script>
import {
    PrCFillInformationComp,
    PrCBtnCardComp
} from "../../cells";
import {
    saleMixin
} from "../../../../mixins";
export default {
    mixins: [saleMixin],
    props: {
        sale: Object,
        isClient: Boolean,
        isSend: Boolean,
        search: {
            type: String,
            default: ""
        },
        isInfo: Boolean
    },
    components: {
        PrCFillInformationComp,
        PrCBtnCardComp
    },
    methods: {
        getTypeSale() {
            if (this.sale.type_sale === "e") return "Página web";
            if (this.sale.type_sale === "s") return "Tienda";
            return "Personal"
        },
        getTextTypeCoin() {
            if (this.sale.type_coin === "e") return "Efectivo";
            if (this.sale.type_coin === "q") return "Pago Qr";
            return "Tigo Money";
        }
    }
}
</script>