export default {
    methods: {
        mAnimationScrollTime() {
            const positionYScroll = window.scrollY;
            if (positionYScroll === 0) return 0;
            if (positionYScroll <= 300) return 200;
            if (positionYScroll <= 600) return 400;
            if (positionYScroll <= 950) return 700;
            if (positionYScroll <= 1300) return 1000;
            if (positionYScroll <= 1650) return 1300;
            return 1600;
        },
        mAnimationScrollOptions(duration) {
            return {
                duration: duration,
                easing: "easeInOutCubic"
            }
        },
        mAnimationScrollGetTopNode() {
            const node = document.getElementById("position-scroll");
            return node.offsetTop;
        },
        mAnimationNative(duration) {
            var start = window.pageYOffset
            var startTime = 'now' in window.performance ? performance.now() : new Date().getTime()
            if ('requestAnimationFrame' in window === false) {
                window.scroll(0, 0)
                return
            }
            function scroll () {
                var now = 'now' in window.performance ? performance.now() : new Date().getTime()
                var time = Math.min(1, ((now - startTime) / duration))
                var timeFunction = time < 0.5 ? 2 * time * time : -1 + (4 - 2 * time) * time
                window.scroll(0, Math.ceil((timeFunction * (0 - start)) + start))
                if (window.pageYOffset === 0) {
                    return
                }
                requestAnimationFrame(scroll)
            }
            scroll()
        }
    }
}