<template>
    <div>
        <div class="categories__header pa-2">
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    v-if="!isMobile"
                    :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                    :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                    class="mr-2"
                    @click="$emit('clickmini')"
                />
                <h1 class="pr-app-title">
                    {{`VENTAS (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="categories__header-search">
                <PrMSelectComp
                    :items="optionsTypeCoin"
                    itemValue="value"
                    :hideDetails="true"
                    classSelect="text-field__header"
                    itemText="text"
                    v-model="typeCoin"
                    class="mr-1"
                    @change="eChangeTypeCoin"
                />
                <PrCBtnToolTipHeaderComp
                    icon="mdi-calculator"
                    class="mr-1"
                    :smsToolTip="calculatorIsOpen ? 'Cerrar Calculadora' : 'Abrir Calculadora'"
                    @click="calculatorIsOpen = !calculatorIsOpen"
                />
                <PrCBtnToolTipHeaderComp
                    icon="mdi-database"
                    smsToolTip="Volver a la lista"
                    @click="tab = 0"
                />
            </div>
        </div>
        <PrMLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="pa-2 pt-1 pb-0">
                    <PrCFillInformationComp
                        title="Cantidad Ventas"
                        :subtitle="`${total}`"
                    />
                    <PrCFillInformationComp
                        title="Moneda"
                        subtitle="Bolivianos"
                    />
                    <PrCFillInformationComp
                        title="Total Ventas"
                        :subtitle="totalSales.toFixed(2)"
                    />
                </div>
                <v-divider></v-divider>
                <PrCLoaderDataComp
                    v-if="dialogLoaderDataIsOpen"
                    class="mt-2"
                />
                <PrMWithoutInfoCardsComp
                    v-if="!dialogLoaderDataIsOpen && sales.length===0 && isMobile"
                    class="ma-2"
                />
                <PrOTableSalesComp
                    v-if="!dialogLoaderDataIsOpen && !isMobile"
                    :sales="sales"
                    :isClient="true"
                    @clickinfo="eShowInfoSale"
                    @clickdetail="eShowDetailSale"
                />
                <div
                    v-if="!dialogLoaderDataIsOpen && isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardSaleComp
                        class="ma-2"
                        :isClient="true"
                        v-for="sale in sales"
                        :key="sale.id"
                        :sale="sale"
                        @clickinfo="eShowInfoSale(sale)"
                        @clickdetail="eShowDetailSale(sale)"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <PrOInfoSaleComp
                    :sale="sale"
                />
                <PrOTableDetailSaleComp
                    v-if="!isMobile"
                    :detail="sale.product_sale"
                    :categorieUser="categorieUser"
                    @clickproduct="eInfoProduct"
                    @clickkeys="eShowKeysProductSale"
                />
                <div
                    v-if="isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardDetailSaleComp
                        class="ma-2"
                        v-for="product in sale.product_sale"
                        :key="product.id"
                        :categorieUser="categorieUser"
                        :product="product"
                        @clickproduct="eInfoProduct(product)"
                        @clickkeys="eShowKeysProductSale(product)"
                    />
                </div>
            </v-tab-item>
        </v-tabs-items>
        <PrOCalculatorComp
            :calculatorIsOpen="calculatorIsOpen"
        />
        <PrSDialogDetailReportSaleComp
            :value="dialogDetailReportIsOpen"
            :sale="sale"
            @clickclose="dialogDetailReportIsOpen = false"
        />
        <PrSDialogKeysProdutSaleComp
            :value="dialogKeysProductSale"
            :loaderDataKeyIsOpen="loaderDataKeyIsOpen"
            :codes="codes"
            :sale="sale"
            :isMobile="isMobile"
            @clickclose="dialogKeysProductSale = false"
            @clickwhatsapp="eOpenWhatsapp()"
        />
        <PrSDialogDetailProductComp
            :value="dialogDetailIsOpen"
            :product="product"
            :categorieUser="categorieUser"
            @clickclose="dialogDetailIsOpen = false"
        />
        <PrCSmsErrorComp
            :smsErrors="smsErrors"
            :value="dialogErrorIsOpen"
            :actionErrorIsActivated="actionErrorIsActivated"
            @click="eCloseError()"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMWithoutInfoCardsComp,
    PrMSelectComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCFillInformationComp,
    PrCLoaderDataComp,
    PrCSmsErrorComp
} from "../../components/components_private/cells";
import {
    PrOTableSalesComp,
    PrOCardSaleComp,
    PrOInfoSaleComp,
    PrOTableDetailSaleComp,
    PrOCardDetailSaleComp,
    PrOCalculatorComp
} from "../../components/components_private/organisms";
import {
    PrSDialogDetailReportSaleComp,
    PrSDialogDetailProductComp,
    PrSDialogKeysProdutSaleComp
} from "../../components/components_private/species";
import {
    Sale,
    DigitalSale
} from "../../models";
import {
    dataGeneralCompsMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin,
    saleMixin
} from "../../mixins";
export default {
    mixins: [
        dataGeneralCompsMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin,
        saleMixin
    ],
    props: {
        mini: Boolean
    },
    components: {
        PrCBtnToolTipHeaderComp,
        PrMLineHeaderComp,
        PrCFillInformationComp,
        PrCLoaderDataComp,
        PrMWithoutInfoCardsComp,
        PrOTableSalesComp,
        PrOCardSaleComp,
        PrOInfoSaleComp,
        PrOTableDetailSaleComp,
        PrOCardDetailSaleComp,
        PrSDialogDetailReportSaleComp,
        PrSDialogDetailProductComp,
        PrCSmsErrorComp,
        PrOCalculatorComp,
        PrSDialogKeysProdutSaleComp,
        PrMSelectComp
    },
    data: () => ({
        tab: 0,
        sales: [],
        sale: {
            product_sale: []
        },
        product: {},
        dialogDetailIsOpen: false,
        dialogDetailReportIsOpen: false,
        dialogDetailIsOpen: false,
        calculatorIsOpen: false,
        codes: [],
        dialogKeysProductSale: false,
        loaderDataKeyIsOpen: false,
        optionsTypeCoin: [
            { value: "a", text: "Todos" },
            { value: "e", text: "Efectivo" },
            { value: "q", text: "Pago Qr" },
            { value: "t", text: "Tigo Money" }
        ],
        typeCoin: "a",
        categorieUser: ""
    }),
    computed: {
        totalSales: function() {
            let total = 0;
            for (let i = 0; i < this.sales.length; i++)
                total += this.mSaleTotalDB(this.sales[i].product_sale);
            return total;
        }
    },
    methods: {
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        async getSales(typeCoin) {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Sale.mySales(typeCoin);
                this.sales = response;
                this.total = response.length;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eChangeTypeCoin(typeCoin) {
            this.getSales(typeCoin);
        },
        eShowInfoSale(sale) {
            this.sale = sale;
            this.dialogDetailReportIsOpen = true;
        },
        eShowDetailSale(sale) {
            this.sale = sale;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        },
        eInfoProduct(product) {
            this.product = product.product;
            this.dialogDetailIsOpen = true;
        },
        async getKeysProductSale(id, idProductSale) {
            try {
                const response = await DigitalSale.keysProductSale(id, idProductSale);
                this.codes = response;
                this.loaderDataKeyIsOpen = false;
            } catch(error) {
                this.dialogKeysProductSale = false;
                this.processErrorServer(error);
            }
        },
        eShowKeysProductSale(product) {
            this.loaderDataKeyIsOpen = true;
            this.dialogKeysProductSale = true;
            this.getKeysProductSale(this.sale.id, product.id);
        },
        eOpenWhatsapp() {
            window.open(`https://api.whatsapp.com/send?phone=${this.sale.client.phone}`);
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            this.categorieUser = this.mUserCategoryEmployee();
            this.getSales("a");
        } else this.$router.replace('/login-admin');
    }
}
</script>