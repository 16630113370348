export default {
    data: () => ({
        dialogOperationIsOpen: false,
        //sms loader data
        dialogLoaderDataIsOpen: false,
        //sms confirmacion
        dialogConfirmationIsOpen: false,
        smsConfirmations: [],
        //sms error
        noAccess: {
            goLogin: false,
            withoutAutorization: false
        },
        dialogErrorIsOpen: false,
        smsErrors: [],
        actionErrorIsActivated: false,
        //sms correcto
        smsActions: ["operacion correcta"],
        frameAction: false,
        colorAction: 'success',
        timeSuccessAction: 3000,
        timer: "",
        //data responsive
        isMobile: false,
        //paginacion
        currentPage: 0,
        lastPage: 0,
        total: 0
    }),
    methods: {
        mGDClearTimeOut() {
            clearTimeout(this.timer);
            this.timeSuccessAction = 3500;
            this.frameAction = false;
        },
        mGDProcesAccess() {
            if (this.noAccess.withoutAutorization) {
                if (this.noAccess.goLogin) {
                    localStorage.removeItem("ecomerce_gatonet");
                    this.$router.replace('/login-admin');
                } else this.$router.replace('/admin/home');
            }
        },
        mGDShowAction(smsActions = [], color = "success") {
            this.mGDClearTimeOut();
            setTimeout(() => {
                this.smsActions = smsActions;
                this.frameAction = true;
                this.colorAction = color;
                this.timer = setTimeout(() => {
                    this.frameAction = false;
                }, 3500);
            }, 150);
        },
        mGDSearchForPage(page = 0, isInteger = false) {
            if (!isInteger) {
                const smsErrors = ["Página Erronea", "El N° debe ser un número entero"];
                this.mGDShowAction(smsErrors, "error");
                return false;
            }
            if (parseInt(page) > 0 && parseInt(page) <= this.lastPage) {
                this.currentPage = page;
                return true;
            }
            const smsErrorsNumber = this.lastPage === 1
                ? "Solo tiene la página 1"
                : `El N° debe estar entre 1-${this.lastPage}`;
            const smsErrors = ["Página Erronea", smsErrorsNumber];
            this.mGDShowAction(smsErrors, "error");
            return false;
            
        },
        mGDShowError(smsErrors = ["Corrija todos los errores marcados con rojo"]) {
            this.smsErrors = smsErrors;
            this.actionErrorIsActivated = true;
            this.dialogErrorIsOpen = true;
        }
    }
}