<template>
    <div class="pr-o-table-categories animation-opacity">
        <table class="pr-o-table-categories__table">
            <thead>
                <tr>
                    <th>
                        <h1 class="pr-app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Código</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Descripción</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Fecha Exp.</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Pendiente</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Registrador</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Estado</h1>
                    </th>
                    <th width="190">
                        <h1 class="pr-app-title-dark">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(digital, index) in digitals"
                    :key="digital.id"
                >
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{(index + 1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{digital.this_slope === 1
                                ? "---"
                                : mCryDecryptText(digital.code) 
                            }}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{digital.description != null
                                ? digital.description
                                : "S/D"}}
                        </h1>
                    </td>
                    <td>
                        <h1
                            v-if="digital.this_slope === 1"
                            class="pr-app-subtitle"
                        >---</h1>
                        <div
                            v-else
                            class="d-flex align-center"
                        >
                            <h1 class="pr-app-subtitle">
                                {{mDateFormat(digital.expiration)}}
                            </h1>
                            <div
                                :class="digital.expired
                                    ? 'categories__disabled ml-1'
                                    : 'categories__enabled ml-1'"
                                style="margin-top:-2px"    
                            ></div>
                        </div>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{digital.this_slope === 1 ? 'SI' : 'NO'}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{digital.user.person.full_name}}
                        </h1>
                    </td>
                    <td>
                        <h1
                            v-if="digital.this_slope === 1"
                            class="pr-app-subtitle"
                        >---</h1>
                        <div
                            v-else
                            class="d-flex align-center"
                        >
                            <div
                                :class="digital.enabled === 1
                                    ? 'categories__enabled mr-1'
                                    : 'categories__disabled mr-1'"
                                style="margin-top:-2px"    
                            ></div>
                            <h1 class="pr-app-subtitle">
                                {{digital.enabled === 1 ? "Hab." : "Inhab."}}
                            </h1>
                        </div>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <PrCBtnToolTipTableComp
                            v-if="digital.this_slope === 1"
                            icon="mdi-delete"
                            :smsToolTip="`Eliminar código pendiente`"
                            @click="$emit('clickdelete', digital)"
                        />
                        <PrCBtnToolTipTableComp
                            v-if="digital.this_slope === 0"
                            icon="mdi-pencil"
                            :smsToolTip="`Editar código`"
                            @click="$emit('clickedit', digital)"
                        />
                        <PrCBtnToolTipTableComp
                            v-if="digital.this_slope === 0"
                            :icon="digital.enabled === 1
                                ? 'mdi-eye-off'
                                : 'mdi-eye'"
                            :smsToolTip="digital.enabled === 1
                                ? `Inhabilitar código`
                                : `Habilitar código`"
                            @click="$emit('clickstate', digital)"
                        />
                    </td>
                </tr>
                <tr v-if="digitals.length === 0">
                    <td colspan="8">
                        <h1 class="pr-app-subtitle text-center">Sin Información</h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    PrCBtnToolTipTableComp
} from "../../cells";
import {
    datesMixin,
    cryptographyMixin
} from "../../../../mixins";
export default {
    mixins: [
        datesMixin,
        cryptographyMixin
    ],
    props: {
        digitals: Array
    },
    components: {
        PrCBtnToolTipTableComp
    }
}
</script>