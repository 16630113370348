import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from "../store/modules";
class Digital {
    constructor(
        code = null,
        expiration = null,
        this_slope = null,
        description = null
    ) {
        this.code = code;
        this.expiration = expiration;
        this.this_slope = this_slope;
        this.description = description;
    }
    static async getDigital(page, id)
    {
        const response = await axios.get(
            `${apiUrls.DIGITAL}list?page=${page}&id=${id}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addDigital(parameters)
    {
        const response = await axios.post(
            `${apiUrls.DIGITAL}add`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async editDigital(parameters)
    {
        const response = await axios.put(
            `${apiUrls.DIGITAL}edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async changeStateDigital(parameters)
    {
        const response = await axios.patch(
            `${apiUrls.DIGITAL}change-state`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async getDigitalExpired(page, idProduct, enabled, expired)
    {
        const response = await axios.get(
            `${apiUrls.DIGITAL}list-expired?page=${page}&id_product=${idProduct}&enabled=${enabled}&expired=${expired}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async addDigitalPending(parameters)
    {
        const response = await axios.post(
            `${apiUrls.DIGITAL}add-pending`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async deleteDigitalPending(parameters)
    {
        const response = await axios.delete(
            `${apiUrls.DIGITAL}delete-pending`,
            { data: parameters, headers: userStore.state.header }
        );
        return response.data;
    }
    static async addDigitalGroup(parameters)
    {
        const response = await axios.post(
            `${apiUrls.DIGITAL}add-group`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default Digital;