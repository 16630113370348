<template>
    <v-card
        width="200px"
        height="160px"
        elevation="0"
    >
        <v-img
            v-if="path != null"
            :src="path|imgMiniature"
            width="100%"
            height="100%"
            contain
        ></v-img>
        <div v-else class="pr-c-image-table__text">
            <h1 class="pr-app-title-dark">Sin Foto</h1>
        </div>
    </v-card>
</template>
<script>
import {
    imgMiniature
} from "../../../filters";
export default {
    filters: {
        imgMiniature
    },
    props: {
        path: String
    }
}
</script>