<template>
    <v-dialog
        persistent
        :value="value"
        width="450"
    >
        <v-card class="pa-0 ma-0">
            <div class="d-flex align-center pa-2 pr-cp-background">
                <h1 class="pr-app-title-dark">Buscar Cliente</h1>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    x-small
                    depressed
                    color="red"
                    @click="$emit('clickclose')"
                >
                    <v-icon
                        class="pr-cd-color"
                    >mdi-close</v-icon>
                </v-btn>
            </div>
            <div class="pa-2">
                <PrOSearchHeaderComp
                    label="Buscar Cliente"
                    v-model="search"
                    @entersearch="eValidSearch()"
                />
                <v-card
                    width="100%"
                    class="rounded-0 pr-cp-background mt-2"
                    elevation="0"
                    height="10"
                ></v-card>
                <v-card
                    elevation="0"
                    width="100%"
                    min-height="200"
                >
                    <PrCLoaderDataComp
                        v-if="dialogLoaderDataIsOpen"
                        class="mt-2"
                    />
                    <PrMWithoutInfoCardsComp
                        v-if="!dialogLoaderDataIsOpen && clients.length===0"
                        :title="title"
                        class="mt-2"
                    />
                    <div v-if="!dialogLoaderDataIsOpen">
                        <div
                            v-for="client in clients"
                            :key="client.id"
                            class="mt-2 animation-opacity"
                        >
                            <div v-if="client.user.enabled === 1">
                                <v-hover
                                    v-slot="{ hover }"
                                >
                                    <v-card
                                        :color="!hover ? 'transparent' : 'rgba(0,0,0,0.05)'"
                                        class="pa-1"
                                        :ripple="false"
                                        width="100%"
                                        @click="$emit('clickclient', client)"
                                    >
                                        <text-highlight
                                            class="pr-app-title"
                                            :queries="searchFilter"
                                        >
                                            {{client.full_name}}
                                        </text-highlight>
                                        <text-highlight
                                            class="pr-app-title"
                                            :queries="searchFilter"
                                        >
                                            {{client.phone}}
                                        </text-highlight>
                                        <h1 class="pr-app-subtitle">{{client.email}}</h1>
                                    </v-card>
                                </v-hover>
                            </div>
                            <div v-else>
                                <v-card
                                    color="rgba(255, 123, 123, 0.4)"
                                    class="pa-1 d-flex"
                                    :ripple="false"
                                    width="100%"
                                >
                                    <div>
                                        <text-highlight
                                            class="pr-app-title"
                                            :queries="searchFilter"
                                        >
                                            {{client.full_name}}
                                        </text-highlight>
                                        <text-highlight
                                            class="pr-app-title"
                                            :queries="searchFilter"
                                        >
                                            {{client.phone}}
                                        </text-highlight>
                                        <h1 class="pr-app-subtitle">{{client.email}}</h1>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <v-menu
                                        transition="slide-x-transition"
                                        bottom
                                        right
                                        offset-y
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                depressed
                                                v-bind="attrs"
                                                v-on="on"
                                                icon
                                                small
                                                :ripple="false"
                                            >
                                                <PrMIconComp
                                                    icon="mdi-account-cancel"
                                                    classIcon="pr-cp-color"
                                                    :small="false"
                                                />
                                            </v-btn>
                                        </template>
                                        <v-card class="pa-2">
                                            <h1 class="pr-app-title">MOTIVO BAN</h1>
                                            <h1 class="pr-app-subtitle">
                                                {{client.banned}}
                                            </h1>
                                        </v-card>
                                    </v-menu>
                                </v-card>
                            </div>
                        </div>
                    </div>
                </v-card>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    PrMWithoutInfoCardsComp,
    PrMIconComp
} from "../molecules";
import {
    PrCLoaderDataComp
} from "../cells";
import {
    PrOSearchHeaderComp
} from "../organisms";
import {
    Client
} from "../../../models";
import {
    rulesMixin
} from "../../../mixins";
import TextHighlight from 'vue-text-highlight';
export default {
    mixins: [rulesMixin],
    props: {
        value: Boolean
    },
    components: {
        PrOSearchHeaderComp,
        PrMWithoutInfoCardsComp,
        PrCLoaderDataComp,
        TextHighlight,
        PrMIconComp
    },
    data: () => ({
        search: "",
        clients: [],
        dialogLoaderDataIsOpen: false,
        title: "Sin Datos"
    }),
    computed: {
        searchFilter: function() {
            if (this.mRItIsEmpty(this.search)) return "";
            return this.search;
        }
    },
    methods: {
        async eValidSearch() {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Client.searchClients(this.search);
                this.clients = response;
                this.title = "Sin Datos";
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.$emit('errorserver', error);
                this.title = "Error de Internet, revise su internet y vuelva a intentarlo";
                this.dialogLoaderDataIsOpen = false;
            }
        },
        resetData() {
            this.search = "";
            this.clients = [];
        }
    }
}
</script>