export default {
    namespaced: true,
    state: {
        dollar: 0,
        isDollar: false,
        qr: "",
        qr_alternate: "",
        whatsapp: ""
    },
    getters: {},
    mutations: {
        addDollar(state, payload) {
            state.dollar = payload;
        },
        addQr(state, payload) {
            state.qr = payload;
        },
        addQrAlternate(state, payload) {
            state.qr_alternate = payload;
        },
        changeDollar(state) {
            state.isDollar = !state.isDollar;
        },
        addWhatsapp(state, payload) {
            state.whatsapp = payload;
        }
    }
}