var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"formcategorie",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Categoría"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Categoría' : '',"counter":50,"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => (`${value}`).length <= 50 || _vm.smsExecedCounter(50)
            ]},on:{"click":function($event){return _vm.eValidateForm()}},model:{value:(_vm.dataCategorie.categorie),callback:function ($$v) {_vm.$set(_vm.dataCategorie, "categorie", $$v)},expression:"dataCategorie.categorie"}})],1),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Para Productos"}},[_c('PrMSelectComp',{attrs:{"items":_vm.optionsFilter,"itemValue":"value","itemText":"text","label":_vm.isMobile ? 'Para Productos' : '',"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty
            ]},model:{value:(_vm.dataCategorie.choice),callback:function ($$v) {_vm.$set(_vm.dataCategorie, "choice", $$v)},expression:"dataCategorie.choice"}})],1),(_vm.dataCategorie.choice == 'd')?_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Vista"}},[_c('PrMSelectComp',{attrs:{"items":_vm.optionsViews,"itemValue":"value","itemText":"text","label":_vm.isMobile ? 'Vista' : '',"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty
            ]},model:{value:(_vm.dataCategorie.view),callback:function ($$v) {_vm.$set(_vm.dataCategorie, "view", $$v)},expression:"dataCategorie.view"}})],1):_vm._e(),_c('div',{staticClass:"d-flex justify-center mt-1"},[_c('PrMBtnNormalComp',{on:{"click":function($event){return _vm.eValidateForm()}}},[_c('v-icon',[_vm._v("mdi-bookmark-check")]),_vm._v(" GUARDAR ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }