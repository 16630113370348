<template>
    <div>
        <v-speed-dial
            v-model="fab"
            :bottom="true"
            :right="true"
            fixed
            :direction="direction"
            :transition="transition"
        >
            <template v-slot:activator>
                <v-btn
                    v-model="fab"
                    color="green"
                    dark
                    fab
                    :ripple="false"
                    small
                >
                <v-icon v-if="fab">
                    mdi-close
                </v-icon>
                <v-icon v-else>
                    mdi-whatsapp
                </v-icon>
                </v-btn>
            </template>
            <div class="c-btn-whatsapp__person">
                <div class="c-btn-whatsapp__title d-flex justify-center align-center">
                    <v-img
                        src="/imgs_public/logos/logo_o.svg"
                        width="35px"
                        max-width="35px"
                    ></v-img>
                    <h1 class="pu-app-title-dark ml-2">
                        GATONET
                    </h1>
                </div>
                <div
                    class="c-btn-whatsapp__name d-flex"
                    @click="$emit('clickphone')"
                >
                    <div class="mr-1">
                        <v-icon color="#E1E6E6">mdi-whatsapp</v-icon>
                    </div>
                    <div>
                        <h1 class="pu-app-title-dark">
                            Atención al Cliente
                        </h1>
                        <div class="c-btn-whatsapp__ornament"></div>
                    </div>
                </div>
            </div>
        </v-speed-dial>
    </div>
</template>
<script>
export default {
    props: {
        setting: Object
    },
    data: () => ({
        fab: false,
        tabs: null,
        right: true,
        bottom: true,
        direction: 'top',
        transition: 'slide-y-reverse-transition',
    })
}
</script>
