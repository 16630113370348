import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from "../store/modules";
class CloseBox {
    constructor(
        beginning = null,
        finished = null,
        description = null,
        fk_ranking = null
    ) {
        this.beginning = beginning;
        this.finished = finished;
        this.description = description;
        this.fk_ranking = fk_ranking;
    }
    static async addCloseBox(parameters) {
        const response = await axios.post(
            `${apiUrls.CLOSE_BOX}add-open`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async listOpenClox() {
        const response = await axios.get(
            `${apiUrls.CLOSE_BOX}list-open-box`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async closeBox(parameters) {
        const response = await axios.put(
            `${apiUrls.CLOSE_BOX}close-box`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async list(page, dateOne, dateTwo) {
        const response = await axios.get(
            `${apiUrls.CLOSE_BOX}list?page=${page}&date_one=${dateOne}&date_two=${dateTwo}`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async editBox(parameters) {
        const response = await axios.put(
            `${apiUrls.CLOSE_BOX}edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async lastCloseBox() {
        const response = await axios.get(
            `${apiUrls.CLOSE_BOX}last-close-box`,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default CloseBox;