import { render, staticRenderFns } from "./ODialogAndInfoProductComp.vue?vue&type=template&id=3da4b460&"
import script from "./ODialogAndInfoProductComp.vue?vue&type=script&lang=js&"
export * from "./ODialogAndInfoProductComp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports