import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import {
  userStore,
  userClientStore,
  cartClientStore,
  settingStore
} from "./modules";

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    userStore,
    userClientStore,
    cartClientStore,
    settingStore
  }
})
