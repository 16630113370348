var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pr-o-table-categories animation-opacity"},[_c('table',{staticClass:"pr-o-table-categories__table"},[_c('thead',[_c('tr',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),(_vm.categorieUser === 'a')?_c('th',[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Precio Costo")])]):_vm._e(),_vm._m(7),_vm._m(8)])]),_c('tbody',[_vm._l((_vm.detail),function(product,index){return _c('tr',{key:product.id},[_c('td',[_c('h1',{staticClass:"pr-app-subtitle"},[_vm._v(" "+_vm._s((index + 1))+" ")])]),_c('td',[_c('h1',{staticClass:"pr-app-subtitle"},[_vm._v(" "+_vm._s(product.product.product)+" ")])]),_c('td',[_c('h1',{staticClass:"pr-app-subtitle"},[_vm._v(" "+_vm._s(product.product.type === 'd' ? "Digital" : "Físico")+" ")])]),_c('td',[_c('h1',{staticClass:"pr-app-subtitle"},[_vm._v(" "+_vm._s(product.product.id_reserve == 0 ? "No" : "Si")+" ")])]),_c('td',[_c('h1',{staticClass:"pr-app-subtitle"},[_vm._v(" "+_vm._s(product.quantity)+" ")])]),_c('td',[_c('h1',{staticClass:"pr-app-subtitle"},[_vm._v(" "+_vm._s(product.price_unity.toFixed(2))+" ")])]),_c('td',[_c('h1',{staticClass:"pr-app-subtitle"},[_vm._v(" "+_vm._s((product.quantity * product.price_unity).toFixed(2))+" ")])]),(_vm.categorieUser === 'a')?_c('td',[_c('h1',{staticClass:"pr-app-subtitle text-right"},[_vm._v(" "+_vm._s(product.price_cost.toFixed(2))+" ")])]):_vm._e(),_c('td',[_c('h1',{staticClass:"pr-app-subtitle",staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(product.info != null ? product.info : "S/I")+" ")])]),_c('td',{staticClass:"d-flex justify-center flex-wrap"},[_c('PrCBtnToolTipTableComp',{attrs:{"icon":"mdi-format-list-bulleted","smsToolTip":`Ver información: ${product.product.product}`},on:{"click":function($event){return _vm.$emit('clickproduct', product)}}}),(_vm.isUserAdmin()
                            && product.product.type === 'd'
                            && product.id_reserve == 0)?_c('PrCBtnToolTipTableComp',{attrs:{"icon":"mdi-cellphone-key","smsToolTip":`Ver códigos de: ${product.product.product}`},on:{"click":function($event){return _vm.$emit('clickkeys', product)}}}):_vm._e()],1)])}),(_vm.detail.length === 0)?_c('tr',[_c('td',{attrs:{"colspan":_vm.categorieUser === 'a'
                    ? '8' : '7'}},[_c('h1',{staticClass:"pr-app-subtitle text-center"},[_vm._v("Sin Productos")])])]):_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"pr-app-title-dark text-center"},[_vm._v("N°")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Producto")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Tipo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Por Saldo")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Cantidad")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Precio Unidad")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Total")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Información")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"100"}},[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Acciones")])])
}]

export { render, staticRenderFns }