var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"formsetting",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Dirección"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Dirección' : '',"counter":50,"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => (`${value}`).length <= 50 || _vm.smsExecedCounter(50)
            ]},model:{value:(_vm.dataSetting.address),callback:function ($$v) {_vm.$set(_vm.dataSetting, "address", $$v)},expression:"dataSetting.address"}})],1),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Correo Gmail"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Correo Gmail' : '',"counter":70,"rules":[
                value => !this.mRItIsEmpty(value) || _vm.smsEmpty,
                value => !this.mRHasSpaces(value) || _vm.smsWithSpaces,
                value => this.mIsGmail(value) || 'Debe enviar un correo gmail',
                value => (`${value}`).length <= 70 || _vm.smsExecedCounter(70)
            ]},model:{value:(_vm.dataSetting.email),callback:function ($$v) {_vm.$set(_vm.dataSetting, "email", $$v)},expression:"dataSetting.email"}})],1),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Celular"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Celular' : '',"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => _vm.mRIsInteger(value) || _vm.smsNotInteger
            ]},model:{value:(_vm.dataSetting.phone),callback:function ($$v) {_vm.$set(_vm.dataSetting, "phone", $$v)},expression:"dataSetting.phone"}})],1),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Facebook"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Facebook' : '',"counter":100,"rules":[
                value => (`${value}`).length <= 100 || _vm.smsExecedCounter(100)
            ]},model:{value:(_vm.dataSetting.facebook),callback:function ($$v) {_vm.$set(_vm.dataSetting, "facebook", $$v)},expression:"dataSetting.facebook"}})],1),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Instagram"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Instagram' : '',"counter":100,"rules":[
                value => (`${value}`).length <= 100 || _vm.smsExecedCounter(100)
            ]},model:{value:(_vm.dataSetting.instagram),callback:function ($$v) {_vm.$set(_vm.dataSetting, "instagram", $$v)},expression:"dataSetting.instagram"}})],1),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Tiktok"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Tiktok' : '',"counter":100,"rules":[
                value => (`${value}`).length <= 100 || _vm.smsExecedCounter(100)
            ]},model:{value:(_vm.dataSetting.tik_tock),callback:function ($$v) {_vm.$set(_vm.dataSetting, "tik_tock", $$v)},expression:"dataSetting.tik_tock"}})],1),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Cambio Dolar"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Cambio Dolar' : '',"rules":[
                value => !this.mRItIsEmpty(value) || _vm.smsEmpty,
                value => this.mRIsNumeric(value) || _vm.smsNotNumeric
            ]},model:{value:(_vm.dataSetting.change_dollar),callback:function ($$v) {_vm.$set(_vm.dataSetting, "change_dollar", $$v)},expression:"dataSetting.change_dollar"}})],1),_c('div',{staticClass:"d-flex justify-center mt-1"},[_c('PrMBtnNormalComp',{on:{"click":function($event){return _vm.eValidateForm()}}},[_c('v-icon',[_vm._v("mdi-bookmark-check")]),_vm._v(" GUARDAR ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }