<template>
    <div class="pr-o-card-categorie animation-opacity">
        <div class="pr-o-card-categorie__header d-flex justify-center">
            <h1 class="pr-app-title-dark">
                {{recommendation.product.product}}
            </h1>
        </div>
        <div class="d-flex pa-2">
            <PrCImageTableComp
                :path="recommendation.product.route_file"
            />
            <div class="ml-2">
                <h1 class="pr-app-subtitle">
                    {{recommendation.product.description}}
                </h1>
                <v-divider></v-divider>
                <PrCFillInformationComp
                    title="Precio"
                    :subtitle="recommendation.product.price.toFixed(2)"
                />
            </div>
        </div>
        <v-divider class="mb-1"></v-divider>
        <div class="pa-2 pt-1 d-flex justify-center flex-wrap">
            <PrCBtnCardComp
                icon="mdi-format-list-bulleted-square"
                @click="$emit('clickdetail')"
            />
            <PrCBtnCardComp
                icon="mdi-delete"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import {
    PrCFillInformationComp,
    PrCBtnCardComp,
    PrCImageTableComp
} from "../../cells";
export default {
    props: {
        recommendation: Object
    },
    components: {
        PrCFillInformationComp,
        PrCBtnCardComp,
        PrCImageTableComp
    }
}
</script>