<template>
    <v-card class="cs-pu-background animation-opacity">
        <h1 class="pr-app-subtitle-dark text-center">{{title}}</h1>
    </v-card>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: "SIN DATOS"
        }
    }
}
</script>