<template>
    <div class="pr-o-table-categories animation-opacity">
        <table class="pr-o-table-categories__table">
            <thead>
                <tr>
                    <th>
                        <h1 class="pr-app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Control</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Fecha</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Inicio</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Fin</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Diferencia</h1>
                    </th>
                    <th width="100">
                        <h1 class="pr-app-title-dark">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(closeBox, index) in closingBoxes"
                    :key="closeBox.id"
                >
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{(index + 1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{closeBox.ranking.name}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{mDateFormat(closeBox.day)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle text-right">
                            {{closeBox.beginning.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle text-right">
                            {{closeBox.finished.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle text-right">
                            {{(closeBox.finished - closeBox.beginning).toFixed(2)}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <PrCBtnToolTipTableComp
                            icon="mdi-pencil"
                            :smsToolTip="`Editar fila`"
                            @click="$emit('clickedit', closeBox)"
                        />
                    </td>
                </tr>
                <tr v-if="closingBoxes.length === 0">
                    <td colspan="6">
                        <h1 class="pr-app-subtitle text-center">Sin Información</h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    PrCBtnToolTipTableComp
} from "../../cells";
import {
    datesMixin
} from "../../../../mixins";
export default {
    mixins: [datesMixin],
    props: {
        closingBoxes: Array
    },
    components: {
        PrCBtnToolTipTableComp
    }
}
</script>