export default {
    namespaced: true,
    state: {
        cart: [],
        pendingPurchase: false
    },
    getters: {},
    mutations: {
        addCart(state, payload) {
            state.cart.push(payload);
        },
        editProduct(state, payload) {
            Object.assign(state.cart[payload.index], payload.product);
        },
        deleteProduct(state, payload) {
            state.cart.splice(payload, 1);
        },
        changePendingPurchase(state) {
            state.pendingPurchase = !state.pendingPurchase;
        },
        resetData(state) {
            state.cart = [];
            state.pendingPurchase = false;
        },
        updateQuantity(state, payload) {
            state.cart[payload.index].quantity = payload.quantity;
        }
    }
}