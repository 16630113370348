<template>
    <div class="close-box-day__body">
        <div class="d-flex align-center">
            <h1 class="pr-app-title text-right mr-2" style="min-width:50px">0.1:</h1>
            <PrMTextFieldHeaderComp
                v-model="tenCents"
                :singleLine="false"
                :reverse="true"
                :dark="true"
                :clearable="false"
            />
        </div>
        <div class="d-flex align-center mt-2">
            <h1 class="pr-app-title text-right mr-2" style="min-width:50px">0.2:</h1>
            <PrMTextFieldHeaderComp
                v-model="twentyCents"
                :singleLine="false"
                :reverse="true"
                :dark="true"
                :clearable="false"
            />
        </div>
        <div class="d-flex align-center mt-2">
            <h1 class="pr-app-title text-right mr-2" style="min-width:50px">0.5:</h1>
            <PrMTextFieldHeaderComp
                v-model="fiftyCents"
                :singleLine="false"
                :reverse="true"
                :dark="true"
                :clearable="false"
            />
        </div>
        <div class="d-flex align-center mt-2">
            <h1 class="pr-app-title text-right mr-2" style="min-width:50px">1:</h1>
            <PrMTextFieldHeaderComp
                v-model="oneBs"
                :singleLine="false"
                :reverse="true"
                :dark="true"
                :clearable="false"
            />
        </div>
        <div class="d-flex align-center mt-2">
            <h1 class="pr-app-title text-right mr-2" style="min-width:50px">2:</h1>
            <PrMTextFieldHeaderComp
                v-model="twoBs"
                :singleLine="false"
                :reverse="true"
                :dark="true"
                :clearable="false"
            />
        </div>
        <div class="d-flex align-center mt-2">
            <h1 class="pr-app-title text-right mr-2" style="min-width:50px">5:</h1>
            <PrMTextFieldHeaderComp
                v-model="fiveBs"
                :singleLine="false"
                :reverse="true"
                :dark="true"
                :clearable="false"
            />
        </div>
        <div class="d-flex align-center mt-2">
            <h1 class="pr-app-title text-right mr-2" style="min-width:50px">10:</h1>
            <PrMTextFieldHeaderComp
                v-model="tenBs"
                :singleLine="false"
                :reverse="true"
                :dark="true"
                :clearable="false"
            />
        </div>
        <div class="d-flex align-center mt-2">
            <h1 class="pr-app-title text-right mr-2" style="min-width:50px">20:</h1>
            <PrMTextFieldHeaderComp
                v-model="twentyBs"
                :singleLine="false"
                :reverse="true"
                :dark="true"
                :clearable="false"
            />
        </div>
        <div class="d-flex align-center mt-2">
            <h1 class="pr-app-title text-right mr-2" style="min-width:50px">50:</h1>
            <PrMTextFieldHeaderComp
                v-model="fiftyBs"
                :singleLine="false"
                :reverse="true"
                :dark="true"
                :clearable="false"
            />
        </div>
        <div class="d-flex align-center mt-2">
            <h1 class="pr-app-title text-right mr-2" style="min-width:50px">100:</h1>
            <PrMTextFieldHeaderComp
                v-model="oneHundredBs"
                :singleLine="false"
                :reverse="true"
                :dark="true"
                :clearable="false"
            />
        </div>
        <div class="close-box-day__body-footer">
            <h1 class="pr-app-title-dark">
                {{`Total: ${totalCalculator.toFixed(2)}`}}
            </h1>
        </div>
    </div>
</template>
<script>
import {
    PrMTextFieldHeaderComp
} from "../molecules";
import {
    rulesMixin
} from "../../../mixins";
export default {
    mixins: [
        rulesMixin
    ],
    components: {
        PrMTextFieldHeaderComp
    },
    data: () => ({
        tenCents: 0,
        twentyCents: 0,
        fiftyCents: 0,
        oneBs: 0,
        twoBs: 0,
        fiveBs: 0,
        tenBs: 0,
        twentyBs: 0,
        fiftyBs: 0,
        oneHundredBs: 0
    }),
    computed: {
        totalCalculator: function() {
            const tenCents = (!this.mRItIsEmpty(this.tenCents) && this.mRIsInteger(this.tenCents))
                ? (parseInt(this.tenCents) * 0.1) : 0;
            const twentyCents = (!this.mRItIsEmpty(this.twentyCents) && this.mRIsInteger(this.twentyCents))
                ? (parseInt(this.twentyCents) * 0.2) : 0;
            const fiftyCents = (!this.mRItIsEmpty(this.fiftyCents) && this.mRIsInteger(this.fiftyCents))
                ? (parseInt(this.fiftyCents) * 0.5) : 0;
            const oneBs = (!this.mRItIsEmpty(this.oneBs) && this.mRIsInteger(this.oneBs))
                ? parseInt(this.oneBs) : 0;
            const twoBs = (!this.mRItIsEmpty(this.twoBs) && this.mRIsInteger(this.twoBs))
                ? (parseInt(this.twoBs) * 2) : 0;
            const fiveBs = (!this.mRItIsEmpty(this.fiveBs) && this.mRIsInteger(this.fiveBs))
                ? (parseInt(this.fiveBs) * 5) : 0;
            const tenBs = (!this.mRItIsEmpty(this.tenBs) && this.mRIsInteger(this.tenBs))
                ? (parseInt(this.tenBs) * 10) : 0;
            const twentyBs = (!this.mRItIsEmpty(this.twentyBs) && this.mRIsInteger(this.twentyBs))
                ? (parseInt(this.twentyBs) * 20) : 0;
            const fiftyBs = (!this.mRItIsEmpty(this.fiftyBs) && this.mRIsInteger(this.fiftyBs))
                ? (parseInt(this.fiftyBs) * 50) : 0;
            const oneHundredBs = (!this.mRItIsEmpty(this.oneHundredBs) && this.mRIsInteger(this.oneHundredBs))
                ? (parseInt(this.oneHundredBs) * 100) : 0;
            return tenCents + twentyCents + fiftyCents + oneBs + twoBs + fiveBs + tenBs + twentyBs + fiftyBs + oneHundredBs;
        }
    },
    methods: {
        resetData() {
            this.tenCents = this.twentyCents = this.fiftyCents = 0;
            this.oneBs = this.twoBs = this.fiveBs = 0;
            this.tenBs = this.twentyBs = this.fiftyBs = this.oneHundredBs = 0;
        }
    }
}
</script>