<template>
    <div class="pr-o-card-categorie animation-opacity">
        <div class="pr-o-card-categorie__header d-flex justify-center">
            <text-highlight
                class="pr-app-title-dark"
                :queries="search"
            >
                {{product.product}}
            </text-highlight>
        </div>
        <div class="d-flex pa-2">
            <PrCImageTableComp
                :path="product.route_file"
            />
            <div class="ml-2">
                <text-highlight
                    class="pr-app-subtitle"
                    :queries="search"
                >
                    {{product.description}}
                </text-highlight>
                <v-divider></v-divider>
                <PrCFillInformationComp
                    title="Inventario"
                    :subtitle="getTextInventory()"
                >
                    <div
                        :class="getClassInventory()"
                        style="margin-top:-2px"
                    ></div>
                </PrCFillInformationComp>
                <PrCFillInformationComp
                    title="Precio"
                    :subtitle="product.price.toFixed(2)"
                />
                <PrCFillInformationComp
                    title="Cantidad"
                    :subtitle="`${product.quantity}`"
                />
                <PrCFillInformationComp
                    title="Costo"
                    :subtitle="product.price_cost.toFixed(2)"
                />
                <PrCFillInformationComp
                    title="Categoría"
                    :subtitle="product.categorie.categorie"
                />
            </div>
        </div>
        <v-divider class="mb-1"></v-divider>
        <div class="pa-2 pt-1 d-flex justify-center flex-wrap">
            <PrCBtnCardComp
                icon="mdi-format-list-bulleted-square"
                @click="$emit('clickdetail')"
            />
            <PrCBtnCardComp
                icon="mdi-pencil"
                @click="$emit('clickedit')"
            />
            <PrCBtnCardComp
                v-if="isDigital"
                icon="mdi-file-document"
                @click="$emit('clickinventory')"
            />
        </div>
    </div>
</template>
<script>
import TextHighlight from 'vue-text-highlight';
import {
    PrCFillInformationComp,
    PrCBtnCardComp,
    PrCImageTableComp
} from "../../cells";
export default {
    props: {
        product: Object,
        search: String,
        isDigital: Boolean
    },
    components: {
        TextHighlight,
        PrCFillInformationComp,
        PrCBtnCardComp,
        PrCImageTableComp
    },
    methods: {
        getTextInventory() {
            if (this.product.quantity <= 5) return "Bajo";
            if (this.product.quantity <= 15) return "Medio";
            return "Alto";
        },
        getClassInventory() {
            if (this.product.quantity <= 5) return "categories__disabled ml-1";
            if (this.product.quantity <= 15) return "categories__warning ml-1";
            return "categories__enabled ml-1";
        }
    }
}
</script>