<template>
    <v-form
        v-model="valid"
        lazy-validation
        ref="formuser"
    >
        <CFillFormComp
            :isMobile="isMobile"
            title="Nombre Completo"
        >
            <MTextFieldComp
                v-model="dataClient.full_name"
                :label="isMobile ? 'Nombre Completo' : ''"
                :dark="isMobile"
                :background="isMobile ? '' : 'white'"
                :styleTextField="isMobile
                    ? 'font-weight:300 !important'
                    : ''"
                :counter="50"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => (`${value}`).length <= 50 || smsExecedCounter(50)
                ]"
            />
        </CFillFormComp>
        <CFillFormComp
            :isMobile="isMobile"
            title="Celular"
        >
            <MTextFieldComp
                v-model="dataClient.phone"
                :dark="isMobile"
                :background="isMobile ? '' : 'white'"
                :styleTextField="isMobile
                    ? 'font-weight:300 !important'
                    : ''"
                :label="isMobile ? 'Celular' : ''"
                :counter="15"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => !mRHasSpaces(value) || smsWithSpaces,
                    value => (`${value}`).length <= 15 || smsExecedCounter(15)
                ]"
            />
        </CFillFormComp>
        <CFillFormComp
            :isMobile="isMobile"
            title="Correo GMAIL"
        >
            <MTextFieldComp
                v-model="dataClient.email"
                :dark="isMobile"
                :background="isMobile ? '' : 'white'"
                :styleTextField="isMobile
                    ? 'font-weight:300 !important'
                    : ''"
                :label="isMobile ? 'Correo GMAIL' : ''"
                :counter="50"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => !mRHasSpaces(value) || smsWithSpaces,
                    value => mIsGmail(value) || 'Debe enviar un correo gmail',
                    value => (`${value}`).length <= 50 || smsExecedCounter(50)
                ]"
            />
        </CFillFormComp>
        <CFillFormComp
            :isMobile="isMobile"
            title="Contraseña"
        >
            <MTextFieldComp
                :dark="isMobile"
                :background="isMobile ? '' : 'white'"
                :styleTextField="isMobile
                    ? 'font-weight:300 !important'
                    : ''"
                v-model="dataUser.password"
                :label="isMobile ? 'Contraseña' : ''"
                :type="showPassword ? 'text' : 'password'"
                :icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @clicksee="showPassword = !showPassword"
                :counter="20"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => !mRHasSpaces(value) || smsWithSpaces,
                    value => (`${value}`).length >= 5 || smsMinCounter(5),
                    value => (`${value}`).length <= 20 || smsExecedCounter(20)
                ]"
            />
        </CFillFormComp>
        <CFillFormComp
            :isMobile="isMobile"
            title="Repita Contraseña"
        >
            <MTextFieldComp
                :dark="isMobile"
                :background="isMobile ? '' : 'white'"
                :styleTextField="isMobile
                    ? 'font-weight:300 !important'
                    : ''"
                v-model="dataUser.repit_password"
                :label="isMobile ? 'Repita Contraseña' : ''"
                :type="showPassword ? 'text' : 'password'"
                :icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @clicksee="showPassword = !showPassword"
                :counter="20"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => !mRHasSpaces(value) || smsWithSpaces,
                    value => (`${value}`).length >= 5 || smsMinCounter(5),
                    value => !mRDifferentData(value, dataUser.password) || 'La contraseña no coincide',
                    value => (`${value}`).length <= 20 || smsExecedCounter(20)
                ]"
            />
        </CFillFormComp>
        <div class="d-flex justify-center mt-1">
            <PrMBtnNormalComp
                className="cp-pu-color"
                @click="eValidateForm()"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                REGISTRARME
            </PrMBtnNormalComp>
        </div>
    </v-form>
</template>
<script>
import {
    CFillFormComp
} from "../../cells";
import {
    MTextFieldComp
} from "../../molecules";
import {
    PrMBtnNormalComp
} from "../../../components_private/molecules";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [
        rulesMixin
    ],
    components: {
        CFillFormComp,
        MTextFieldComp,
        PrMBtnNormalComp
    },
    props: {
        isMobile: Boolean,
        dataClient: Object,
        dataUser: Object
    },
    data: () => ({
        showPassword: false,
        valid: true
    }),
    methods: {
        eValidateForm() {
            if (this.$refs.formuser.validate())
                this.$emit('clickform', true);
            else this.$emit('clickform', false);
        },
        resetForm() {
            this.showPassword = false;
            this.$refs.formuser.resetValidation();
        }
    }
}
</script>