<template>
    <v-form
        v-model="valid"
        lazy-validation
        ref="formbox"
    >
        <h1 class="pr-app-title text-center mb-4">
            {{dataCloseBox.ranking != null
                ? dataCloseBox.ranking.name
                : "--"
            }}
        </h1>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Inicio"
        >
            <PrMTextFieldComp
                :label="isMobile ? 'Inicio' : ''"
                v-model="dataCloseBox.beginning"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => mRIsNumeric(value) || smsNotNumeric
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Fin"
        >
            <PrMTextFieldComp
                :label="isMobile ? 'Fin' : ''"
                v-model="dataCloseBox.finished"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => mRIsNumeric(value) || smsNotNumeric
                ]"
            />
        </PrCFillFormComp>
        <div class="d-flex justify-center mt-1">
            <PrMBtnNormalComp
                @click="eValidateForm()"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                GUARDAR
            </PrMBtnNormalComp>
        </div>
    </v-form>
</template>
<script>
import {
    PrMTextFieldComp,
    PrMBtnNormalComp
} from "../../molecules";
import {
    PrCFillFormComp
} from "../../cells";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        PrMTextFieldComp,
        PrCFillFormComp,
        PrMBtnNormalComp
    },
    props: {
        dataCloseBox: Object,
        isMobile: Boolean
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidateForm() {
            if (this.$refs.formbox.validate())
                this.$emit('clickform', true);
            else this.$emit('clickform', false);
        },
        resetForm() {
            this.$refs.formbox.resetValidation();
        }
    }
}
</script>