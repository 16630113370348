<template>
    <div class="pr-s-search-product-sale">
        <div class="pr-s-search-product-sale__header">
            <div class="pa-2 pt-3 d-flex align-center">
                <PrOSearchHeaderComp
                    label="Buscar Producto"
                    v-model="search"
                    @entersearch="eValidSearch()"
                    @input="eSendLetter"
                />
                <v-card
                    :width="sizeFilters"
                    :min-width="sizeFilters"
                    class="rounded-0 mr-1 ml-1"
                    elevation="0"
                    color="transparent"
                >
                    <PrMSelectComp
                        :items="categories"
                        itemValue="id"
                        itemText="categorie"
                        label="Categorías"
                        classSelect="text-field__header"
                        background=""
                        v-model="idCategorie"
                        :hideDetails="true"
                    />
                </v-card>
                <v-card
                    :width="sizeFilters"
                    :min-width="sizeFilters"
                    class="rounded-0"
                    elevation="0"
                    color="transparent"
                >
                    <PrMSelectComp
                        :items="optionsType"
                        itemValue="value"
                        itemText="text"
                        label="Tipo"
                        classSelect="text-field__header"
                        background=""
                        v-model="valueType"
                        :hideDetails="true"
                    />
                </v-card>
                <PrCBtnToolTipHeaderComp
                    icon="mdi-database"
                    smsToolTip="Volver a los productos"
                    class="ml-1"
                    @click="eBackListProducts()"
                />
            </div>
            <v-card
                width="100%"
                class="rounded-0 pr-cp-background"
                elevation="0"
                height="10"
            ></v-card>
        </div>
        <div class="pr-s-search-product-sale__body">
            <v-tabs-items
                style="background:transparent !important"
                v-model="tab"
                :touchless="true"
            >
                <v-tab-item>
                    <PrCLoaderDataComp
                        v-if="dialogLoaderDataIsOpen"
                        class="mt-2"
                    />
                    <PrMWithoutInfoCardsComp
                        v-if="!dialogLoaderDataIsOpen && products.length === 0"
                        title="Sin productos"
                    />
                    <PrOProductSelectComp
                        v-for="product in products"
                        :key="product.id"
                        :product="product"
                        :search="searchFilter"
                        @clickquantity="e => $emit('clickselectproduct', e)"
                        @clickdetail="$emit('clickdetail', product)"
                        @clickform="eOpenFormProduct(product)"
                    />
                </v-tab-item>
                <v-tab-item>
                    <PrOFormProductSaleComp
                        :product="product"
                        @clickform="eSendProduct"
                    />
                </v-tab-item>
            </v-tabs-items>
        </div>
    </div>
</template>
<script>
import {
    PrMSelectComp,
    PrMWithoutInfoCardsComp
} from "../molecules";
import {
    PrCImageTableComp,
    PrCLoaderDataComp,
    PrCFillInformationComp,
    PrCBtnCardComp,
    PrCMenuQuantityComp,
    PrCBtnToolTipHeaderComp
} from "../cells";
import {
    PrOSearchHeaderComp,
    PrOProductSelectComp,
    PrOFormProductSaleComp
} from "../organisms";
import {
    rulesMixin
} from "../../../mixins";
export default {
    mixins: [
        rulesMixin
    ],
    components: {
        PrOSearchHeaderComp,
        PrCImageTableComp,
        PrMSelectComp,
        PrMWithoutInfoCardsComp,
        PrCLoaderDataComp,
        PrCFillInformationComp,
        PrCBtnCardComp,
        PrCMenuQuantityComp,
        PrOProductSelectComp,
        PrCBtnToolTipHeaderComp,
        PrOFormProductSaleComp
    },
    props: {
        products: Array,
        categories: Array,
        dialogLoaderDataIsOpen: Boolean,
        indexDetail: Number
    },
    computed: {
        searchFilter: function() {
            if (this.mRItIsEmpty(this.search)) return "";
            return this.search;
        }
    },
    data: () => ({
        search: "",
        idCategorie: 0,
        sizeFilters: "160",
        optionsType: [
            { value: "t", text: "Todos" },
            { value: "n", text: "Físicos" },
            { value: "d", text: "Digitales" }
        ],
        valueType: "t",
        product: {},
        tab: 0
    }),
    methods: {
        eValidSearch() {
            this.tab = 0;
            if (this.mRItIsEmpty(this.search)) this.search = "";
            const parameters = {
                'search': this.search,
                'type': this.valueType,
                'idCategorie': this.idCategorie
            }
            this.$emit('entersearch', parameters);
        },
        eSendLetter(letter) {
            this.tab = 0;
            if (this.mRItIsEmpty(this.search)) this.search = "";
            const parameters = {
                'search': letter,
                'type': this.valueType,
                'idCategorie': this.idCategorie
            }
            this.$emit('inputsearch', parameters);
        },
        resetData() {
            this.idCategorie = 0;
            this.valueType = "t";
            this.search = "";
            this.product = {},
            this.tab = 0;
        },
        eOpenFormProduct(product) {
            this.product = Object.assign({}, product);
            this.product.new_quantity = null;
            this.product.new_price = null;
            this.tab = 1;
        },
        eBackListProducts() {
            this.tab = 0;
            this.$emit('clickbackproducts');
            setTimeout(() => {
                this.product = {}
            }, 150);
        },
        eSendProduct(isValidForm) {
            const parameters = {
                "isValidForm": isValidForm,
                "product": this.product
            }
            this.$emit('clickform', parameters);
        },
        assignProduct(product) {
            this.product = Object.assign({}, product);
            this.tab = 1;
        }
    }
}
</script>