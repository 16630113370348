<template>
    <div>
        <div class="d-flex justify-center">
            <div class="c-image-home">
                <h1 class="pu-app-title-dark">LISTA DE COMPRAS</h1>
                <h1 class="pu-app-subtitle-dark">Expresado en Bolivianos</h1>
                <h1 class="pu-app-subtitle-dark">
                    {{`Total Productos: ${cart.length}`}}
                </h1>
                <OTableCartComp
                    v-if="!isMobile"
                    class="mt-2"
                    :products="cart"
                    ref="tablecartcomp"
                    @clickdelete="eDeleteProduct"
                    @clickdetal="eShowDetailProduct"
                    @clickquantityleft="eLessQuantity"
                    @clickquantityright="eAddQuantity"
                />
                <MWithoutInfoCardsComp
                    class="mt-2"
                    v-if="cart.length === 0 && isMobile"
                    title="Sin Productos"
                />
                <v-form
                    v-model="valid"
                    lazy-validation
                    ref="formcartcomp"
                    @submit.prevent="eCompletePurchase()"
                    v-if="cart.length > 0 && isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <OCardCartComp
                        class="ma-2"
                        v-for="product in cart"
                        :key="product.id"
                        :product="product"
                        @clickdelete="eDeleteProduct(product)"
                        @clickdetal="eShowDetailProduct(product)"
                        @clickquantityleft="eLessQuantity(product)"
                        @clickquantityright="eAddQuantity(product)"
                    />
                </v-form>
                <div class="d-flex mt-2">
                    <v-spacer></v-spacer>
                    <CFillInformationComp
                        title="Total Compra"
                        :subtitle="getPurchases().toFixed(2)"
                    />
                </div>
                <div class="d-flex justify-center mt-2">
                    <MBtnNormalComp
                        @click="eCompletePurchase()"
                    >REALIZAR PEDIDO</MBtnNormalComp>
                </div>
            </div>
        </div>
        <div class="space--block"></div>
        <ODialogDetailProductComp
            :product="product"
            :value="dialogDetailProductIsOpen"
            @clickclose="dialogDetailProductIsOpen = false"
        />
        <ODialogPurchaseQrComp
            :isMobile="isMobile"
            :value="dialogOrderIsOpen"
            @clickclose="dialogOrderIsOpen = false"
            @sendpurchase="eSavePurchase()"
            @dowloadqr="eDownloadQr"
        />
        <CSmsErrorComp
            :smsErrors="smsErrors"
            :value="dialogErrorIsOpen"
            :actionErrorIsActivated="actionErrorIsActivated"
            @click="eCloseError()"
        />
        <CSmsActionComp
            :smsActions="smsActions"
            :value="frameAction"
            :isError="isError"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
        />
        <CLoaderOperationComp
            :value="dialogOperationIsOpen"
        />
        <CMessagePurchaseComp
            :value="dialogMessage"
            :dataSend="dataSend"
            :isMobile="isMobile"
            @clickclose="dialogMessage = false"
            @clickwhatsapp="eSendWhatsapp()"
        />
    </div>
</template>
<script>
import {
    MBtnNormalComp,
    MWithoutInfoCardsComp
} from "../../components/components_public/molecules";
import {
    CFillInformationComp,
    CSmsErrorComp,
    CSmsActionComp,
    CLoaderOperationComp,
    CMessagePurchaseComp
} from "../../components/components_public/cells";
import {
    OTableCartComp,
    ODialogDetailProductComp,
    OCardCartComp,
    ODialogPurchaseQrComp
} from "../../components/components_public/organisms";
import { mapState, mapMutations } from "vuex";
import {
    rulesMixin,
    dataGeneralPublicMixin,
    responseServerMixin,
    userPublicMixin
} from "../../mixins";
import {
    Sale,
    Setting
} from "../../models";
export default {
    mixins: [
        rulesMixin,
        dataGeneralPublicMixin,
        responseServerMixin,
        userPublicMixin
    ],
    components: {
        OTableCartComp,
        CFillInformationComp,
        MBtnNormalComp,
        CSmsErrorComp,
        CSmsActionComp,
        CLoaderOperationComp,
        ODialogDetailProductComp,
        OCardCartComp,
        MWithoutInfoCardsComp,
        ODialogPurchaseQrComp,
        CMessagePurchaseComp
    },
    computed: {
        ...mapState("cartClientStore", ["cart"]),
        ...mapState("settingStore", ["whatsapp"])
    },
    data: () => ({
        isUser: false,
        dialogDetailProductIsOpen: false,
        product: {},
        valid: true,
        dialogOrderIsOpen: false,
        dialogMessage: false,
        dataSend: []
    }),
    methods: {
        ...mapMutations("cartClientStore", ["changePendingPurchase", "deleteProduct", "resetData", "updateQuantity"]),
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        validDetailPurchase() {
            for (let i = 0; i < this.cart.length; i++)
                if (this.mRItIsEmpty(this.cart[i].quantity)
                    || !this.mRIsInteger(this.cart[i].quantity)
                    || parseInt(this.cart[i].quantity) <= 0) return false;
            return true;
        },
        getPurchases() {
            if (!this.validDetailPurchase()) return 0;
            let total = 0;
            for (let i = 0; i < this.cart.length; i++)
                total += (this.cart[i].quantity * this.cart[i].product.price)
            return total;
        },
        generateProductSale() {
            let productSale = [];
            for (let i = 0; i < this.cart.length; i++)
                productSale.push({
                    'quantity': parseInt(this.cart[i].quantity),
                    'product': this.cart[i].product.product,
                    'product_id': this.cart[i].product.id,
                    'info': this.cart[i].info
                });
            return productSale;
        },
        async addSaleClient(productSale) {
            try {
                await Sale.addSaleClient({product_sale: productSale});
                this.resetData();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Transacción realizada", "Venta realizada"], false);
            } catch(error) {
                this.dialogOperationIsOpen = false;
                this.processErrorServer(error);
            }
        },
        validateForm() {
            if (!this.isMobile) return this.$refs.tablecartcomp.validateForm();
            return this.$refs.formcartcomp.validate();
        },
        eCompletePurchase() {
            if (!this.validateForm() || this.cart.length === 0) {
                if (this.cart.length === 0)
                    this.mGDShowAction(["Opereción Incorrecta", "Agregue productos al detalle"], true);
                else this.mGDShowAction(["Opereción Incorrecta", "Corrijas las cantidad en rojo"], true);
            }
            else if (!this.isUser) {
                this.changePendingPurchase();
                setTimeout(() => {
                    this.$router.replace('/login-client');
                }, 150);
            } else {
                this.dialogOrderIsOpen = true;
                /*const productSale = this.generateProductSale();
                this.addSaleClient(productSale);*/
            }
        },
        eDeleteProduct(product) {
            const index = this.cart.indexOf(product);
            this.deleteProduct(index);
        },
        eShowDetailProduct(product) {
            this.product = Object.assign({}, product.product);
            this.dialogDetailProductIsOpen = true;
        },
        async addSaleClientQr(productSale) {
            try {
                await Sale.addSaleClientQr({product_sale: productSale});
                this.resetData();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Solicitud de compra", "Realizada con éxito"], false);
                this.dialogMessage = true;
            } catch(error) {
                this.dialogOperationIsOpen = false;
                this.processErrorServer(error);
            }
        },
        eSavePurchase() {
            this.dialogOrderIsOpen = false;
            this.dialogOperationIsOpen = true;
            const productSale = this.generateProductSale();
            this.dataSend = productSale;
            this.addSaleClientQr(productSale);
        },
        eDownloadQr(qr) {
            Setting.dowloadQr(qr);
        },
        eLessQuantity(product) {
            if (!this.mRItIsEmpty(product.quantity) && this.mRIsInteger(product.quantity))
            {
                if (parseInt(product.quantity) > 1) {
                    const index = this.cart.indexOf(product);
                    const newQuantity = parseInt(product.quantity) - 1;
                    this.updateQuantity({index: index, quantity: newQuantity});
                }
            }
        },
        eAddQuantity(product) {
            if (!this.mRItIsEmpty(product.quantity) && this.mRIsInteger(product.quantity))
            {
                if (parseInt(product.quantity) > 0) {
                    const index = this.cart.indexOf(product);
                    const newQuantity = parseInt(product.quantity) + 1;
                    this.updateQuantity({index: index, quantity: newQuantity});
                }
            }
        },
        eSendWhatsapp() {
            const client = JSON.parse(localStorage.getItem("client_gatonet")).client;
            let smsWhatsapp = `Lista%20Pedido%20${client.full_name}:%0A-----------------------%0A`;
            for(let i = 0; i < this.dataSend.length; i++) {
                smsWhatsapp = smsWhatsapp + (i+1) + '.-%20' + this.dataSend[i].product + ' (' + this.dataSend[i].quantity + ')' + '%0A'
            }
            smsWhatsapp = smsWhatsapp + '-----------------------%0A';
            this.dialogMessage = false;
            setTimeout(() => {
                window.open("https://api.whatsapp.com/send?phone=591" + this.whatsapp + "&text="+smsWhatsapp);
            }, 250);
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader())
            if (JSON.parse(localStorage.getItem("client_gatonet"))) this.isUser = true;
        else this.$router.replace('/login-client');
    }
}
</script>