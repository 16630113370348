<template>
    <div class="pr-o-table-categories animation-opacity">
        <table class="pr-o-table-categories__table">
            <thead>
                <tr>
                    <th>
                        <h1 class="pr-app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Nombre</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Saldo</h1>
                    </th>
                    <th width="30"></th>
                    <th width="190">
                        <h1 class="pr-app-title-dark">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(reserve, index) in reserves"
                    :key="reserve.id"
                >
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{(index + 1)}}
                        </h1>
                    </td>
                    <td>
                        <text-highlight
                            class="pr-app-subtitle"
                            :queries="search"
                        >
                            {{reserve.name}}
                        </text-highlight>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{reserve.sald.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex justify-center">
                            <div
                                :class="reserve.sald > 0
                                    ? 'categories__enabled'
                                    : 'categories__disabled'"
                                style="margin-top:-2px"    
                            ></div>
                        </div>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <PrCBtnToolTipTableComp
                            icon="mdi-pencil"
                            :smsToolTip="`Editar: ${reserve.name}`"
                            @click="$emit('clickedit', reserve)"
                        />
                        <PrCBtnToolTipTableComp
                            icon="mdi-plus"
                            :smsToolTip="`Agregar Saldo: ${reserve.name}`"
                            @click="$emit('clickaddsald', reserve)"
                        />
                    </td>
                </tr>
                <tr v-if="reserves.length === 0">
                    <td colspan="5">
                        <h1 class="pr-app-subtitle text-center">Sin Información</h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    PrCBtnToolTipTableComp
} from "../../cells";
import TextHighlight from 'vue-text-highlight';
export default {
    props: {
        reserves: Array,
        search: String
    },
    components: {
        PrCBtnToolTipTableComp,
        TextHighlight
    }
}
</script>