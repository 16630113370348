<template>
    <v-dialog
        persistent
        :value="value"
        width="360"
    >
        <v-card class="pa-0 ma-0">
            <div class="d-flex align-center pr-cp-background pa-2">
                <h1 class="pr-app-title-dark">Entrega del producto</h1>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    x-small
                    depressed
                    color="red"
                    @click="$emit('clickclose')"
                >
                    <v-icon
                        class="pr-cd-color"
                    >mdi-close</v-icon>
                </v-btn>
            </div>
            <v-divider></v-divider>
            <v-form
                v-model="valid"
                lazy-validation
                ref="formdelivered"
                class="pa-2"
            >
                <PrMSelectComp
                    class="mt-2"
                    :items="optionsDelivered"
                    v-model="dataSale.delivered"
                    itemText="text"
                    itemValue="value"
                    label="Entregado"
                    :rules="[
                        value => !mRItIsEmpty(value) || smsEmpty
                    ]"
                />
                <PrMSelectComp
                    class="mt-2"
                    :items="optionsTypesCoin"
                    v-model="dataSale.type_coin"
                    itemText="text"
                    itemValue="value"
                    label="Tipo Pago"
                    :rules="[
                        value => !mRItIsEmpty(value) || smsEmpty
                    ]"
                />
                <PrMTextFieldComp
                    class="mt-2"
                    v-model="dataSale.note"
                    label="Nota"
                    :counter="300"
                    :rules="[
                        value => (`${value}`).length <= 300 || smsExecedCounter(300)
                    ]"
                />
            </v-form>
            <div class="text-center pb-3">
                <PrMBtnNormalComp
                    @click="$emit('clickdelivered')"
                >SIGUIENTE</PrMBtnNormalComp>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    PrMSelectComp,
    PrMBtnNormalComp,
    PrMTextFieldComp
} from "../molecules";
import {
    rulesMixin
} from "../../../mixins";
export default {
    mixins: [rulesMixin],
    components: {
        PrMSelectComp,
        PrMBtnNormalComp,
        PrMTextFieldComp
    },
    props: {
        value: Boolean,
        dataSale: Object
    },
    data: () => ({
        valid: true,
        optionsDelivered: [
            { value: 1, text: "SI" },
            { value: 0, text: "NO" }
        ],
        optionsTypesCoin: [
            { value: "e", text: "Efectivo" },
            { value: "q", text: "Pago Qr" },
            { value: "t", text: "Tigo Money" }
        ]
    }),
    methods: {
        resetForm() {
            this.$refs.formdelivered.resetValidation();
        }
    }
}
</script>