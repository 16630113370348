<template>
    <v-dialog
        persistent
        :value="value"
        width="360"
    >
        <div class="o-form-user">
            <div class="d-flex align-center pa-2 cp-pu-background">
                <h1 class="pr-app-title-dark">Cambiar Contraseña</h1>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    x-small
                    depressed
                    color="white"
                    @click="$emit('clickclose')"
                >
                    <v-icon
                        color="red"
                    >mdi-close</v-icon>
                </v-btn>
            </div>
            <div class="pa-2 pb-3">
                <v-form
                    v-model="valid"
                    lazy-validation
                    ref="formuser"
                >
                    <MTextFieldComp
                        :dark="true"
                        background=""
                        class="mt-3"
                        styleTextField="font-weight:300 !important"
                        v-model="dataUser.password"
                        label="Contraseña"
                        :type="showPassword ? 'text' : 'password'"
                        :icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        @clicksee="showPassword = !showPassword"
                        :counter="20"
                        :rules="[
                            value => !mRItIsEmpty(value) || smsEmpty,
                            value => !mRHasSpaces(value) || smsWithSpaces,
                            value => (`${value}`).length >= 5 || smsMinCounter(5),
                            value => (`${value}`).length <= 20 || smsExecedCounter(20)
                        ]"
                    />
                    <MTextFieldComp
                        :dark="true"
                        background=""
                        class="mt-2"
                        styleTextField="font-weight:300 !important"
                        v-model="dataUser.repit_password"
                        label="Repita Contraseña"
                        :type="showPassword ? 'text' : 'password'"
                        :icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        @clicksee="showPassword = !showPassword"
                        :counter="20"
                        :rules="[
                            value => !mRItIsEmpty(value) || smsEmpty,
                            value => !mRHasSpaces(value) || smsWithSpaces,
                            value => (`${value}`).length >= 5 || smsMinCounter(5),
                            value => !mRDifferentData(value, dataUser.password) || 'La contraseña no coincide',
                            value => (`${value}`).length <= 20 || smsExecedCounter(20)
                        ]"
                    />
                    <div class="d-flex justify-center mt-1">
                        <PrMBtnNormalComp
                            className="cp-pu-color"
                            @click="eValidateForm()"
                        >
                            <v-icon>mdi-bookmark-check</v-icon>
                            ACTUALIZAR
                        </PrMBtnNormalComp>
                    </div>
                </v-form>
            </div>
        </div>
    </v-dialog>
</template>
<script>
import {
    MTextFieldComp
} from "../../molecules";
import {
    PrMBtnNormalComp
} from "../../../components_private/molecules";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [
        rulesMixin
    ],
    components: {
        MTextFieldComp,
        PrMBtnNormalComp
    },
    props: {
        isMobile: Boolean,
        dataUser: Object,
        value: Boolean
    },
    data: () => ({
        showPassword: false,
        valid: true
    }),
    methods: {
        eValidateForm() {
            if (this.$refs.formuser.validate())
                this.$emit('clickform', true);
            else this.$emit('clickform', false);
        },
        resetForm() {
            this.showPassword = false;
            this.$refs.formuser.resetValidation();
        }
    }
}
</script>