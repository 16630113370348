<template>
    <div>
        <div class="d-flex justify-center">
            <div class="products-digital__body">
                <div class="products-digital__header">
                    <h1 class="pu-app-title-dark">
                        {{`GAMES (${total})`}}
                    </h1>
                    <v-spacer></v-spacer>
                    <div class="products__search">
                        <OSearchHeaderComp
                            label="Buscar Producto"
                            v-model="search"
                            @entersearch="eSearchProduct()"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-center">
            <div class="products-digital__body">
                <div class="products__categories">
                    <div class="d-flex">
                        <MBtnNormalComp
                            v-for="categorie in categories"
                            :key="categorie.id"
                            :small="!isMobile"
                            :xSmall="isMobile"
                            class="mr-2"
                            @click="eSearchCategorie(categorie)"
                        >
                            {{categorie.categorie}}
                        </MBtnNormalComp>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!dialogLoaderDataIsOpen && products.length === 0 && !errorServer">
            <div class="d-flex justify-center animation-opacity mt-3">
                <v-card
                    width="130px"
                    height="130px"
                    elevation="0"
                    color="transparent"
                >
                    <v-img
                        width="100%"
                        height="100%"
                        src="/imgs_public/pc/empty_card.png"
                    ></v-img>
                </v-card>
            </div>
            <h1 class="pu-app-title-dark text-center mt-2 animation-opacity">Sin Productos Disponibles</h1>
        </div>
        <div
            v-if="errorServer && !dialogLoaderDataIsOpen"
            class="d-flex justify-center animation-opacity"
        >
            <div class="home__second">
                <div class="d-flex justify-center">
                    <v-card
                        width="110px"
                        height="110px"
                        elevation="0"
                        color="transparent"
                    >
                        <v-img
                            width="100%"
                            height="100%"
                            src="/imgs_public/pc/wifi.png"
                        ></v-img>
                    </v-card>
                </div>
                <h1 class="pu-app-title-dark text-center">
                    Perdida en la conexión de internet. Refresque la página o
                    intente de nuevo la operación.
                </h1>
                <div class="d-flex justify-center mt-2">
                    <MBtnNormalComp
                        @click="refreshProducts()"
                    >INTENTAR DE NUEVO</MBtnNormalComp>
                </div>
            </div>
        </div>
        <div
            class="d-flex justify-center"
            v-if="dialogLoaderDataIsOpen"
        >
            <div class="products__cards">
                <div class="d-flex justify-center flex-wrap">
                    <OCardProductSkeletonComp
                        v-for="index in numberElementsSkeleton"
                        :key="index"
                    />
                </div>
            </div>
        </div>
        <div
            class="d-flex justify-center"
            v-else
        >
            <div class="products__cards">
                <div class="d-flex justify-center flex-wrap">
                    <OCardProductComp
                        v-for="product in products"
                        :key="product.id"
                        :product="product"
                        :search="searchFilter"
                        @selectproduct="eSelectProduct(product)"
                    />
                </div>
            </div>
        </div>
        <div
            v-if="!dialogLoaderDataIsOpen && products.length > 0"
            class="d-flex justify-center"
        >
            <div class="products-digital__body">
                <SPaginationComp
                    class="mt-2 mb-2"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                    @clickpage="eSearchForPage"
                />
            </div>
        </div>
        <div class="mt-6"></div>
        <ODialogAndInfoProductComp
            :value="dialogAndInfo"
            :isMobile="isMobile"
            :product="product"
            @closeform="dialogAndInfo = false"
            @clickaddinfo="eAddInfo"
        />
        <CSmsActionComp
            :smsActions="smsActions"
            :value="frameAction"
            :isError="isError"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
        />
    </div>
</template>
<script>
import {
    MBtnNormalComp
} from "../../components/components_public/molecules";
import {
    CSmsActionComp
} from "../../components/components_public/cells";
import {
    OCardProductComp,
    OSearchHeaderComp,
    OCardProductSkeletonComp,
    ODialogAndInfoProductComp
} from "../../components/components_public/organisms";
import {
    SPaginationComp
} from "../../components/components_public/species";
import {
    Product,
    Categorie
} from "../../models";
import { mapMutations, mapState } from "vuex";
import {
    rulesMixin,
    animationScrollMixin,
    dataGeneralPublicMixin
} from "../../mixins";
export default {
    mixins: [
        rulesMixin,
        animationScrollMixin,
        dataGeneralPublicMixin
    ],
    components: {
        OCardProductComp,
        CSmsActionComp,
        MBtnNormalComp,
        OSearchHeaderComp,
        OCardProductSkeletonComp,
        SPaginationComp,
        ODialogAndInfoProductComp
    },
    data: () => ({
        products: [],
        numberElementsSkeleton: 8,
        errorServer: false,
        isMobile: false,
        search: "",
        dialogAndInfo: false,
        sendProduct: {},
        categories: [
            {id: 0, categorie: "Todos" }
        ],
        idCategorie: 0,
        product: {}
    }),
    computed: {
        ...mapState("cartClientStore", ["cart"]),
        searchFilter: function() {
            if (this.mRItIsEmpty(this.search)) return "";
            return this.search;
        }
    },
    methods: {
        ...mapMutations("cartClientStore", ["addCart"]),
        async getProducts(page, search, idCategorie) {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Product.productsCategories(page, search, "g", idCategorie);
                this.products = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.dialogLoaderDataIsOpen = false;
                this.errorServer = true;
            }
        },
        async getRequest(page, search, idCategorie) {
            this.dialogLoaderDataIsOpen = true;
            try {
                const products = Product.productsCategories(page, search, "g", idCategorie);
                const categories = Categorie.getViewListPublic("g");
                const responses = await Promise.all([products, categories]);
                this.products = responses[0].data;
                this.lastPage = responses[0].last_page;
                this.currentPage = responses[0].current_page;
                this.total = responses[0].total;
                responses[1].forEach(element => {
                    this.categories.push(element);
                });
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.dialogLoaderDataIsOpen = false;
                this.errorServer = true;
            }
        },
        refreshProducts() {
            this.getProducts(this.currentPage, this.search, this.idCategorie);
        },
        clearTimeOut() {
            clearTimeout(this.timer);
            this.timeSuccessAction = 3500;
            this.frameAction = false;
        },
        existProductInCart(id) {
            for (let i = 0; i < this.cart.length; i++) {
                if (id === this.cart[i].product.id) return true;
            }
            return false;
        },
        eSelectProduct(product) {
            this.product = product;
            this.sendProduct = {
                'product': product,
                'quantity': 1,
                "info": null
            }
            if (product.quantity > 0) {
                if (product.necessary_info == 1)
                    this.dialogAndInfo = true;
                else {
                    if (!this.existProductInCart(product.id))
                        this.addCart(this.sendProduct);
                    this.mGDShowAction([product.product, "Agregado al carrito"], false);
                }
            } else this.mGDShowAction(["Operación Denegada", "Producto no disponible"], true);
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getProducts(this.current_page, this.search, this.idCategorie);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mGDSearchForPage(page, this.mRIsInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getProducts(page, this.search, this.idCategorie);
                }, time);
            }
        },
        eSearchProduct() {
            if (this.mRItIsEmpty(this.search)) this.search = "";
            this.getProducts(1, this.search, this.idCategorie);
        },
        eAddInfo(paramters) {
            if (!paramters.form)
                this.mGDShowAction(["Operación incorrecta", "Llene la información requerida"], true);    
            else {
                this.sendProduct.info = paramters.info;
                this.dialogAndInfo = false;
                if (!this.existProductInCart(this.sendProduct.product.id))
                    this.addCart(this.sendProduct);
                this.mGDShowAction([this.sendProduct.product.product, "Agregado al carrito"], false);
            }
        },
        eSearchCategorie(categorie) {
            this.idCategorie = categorie.id;
            this.getProducts(1, this.search, categorie.id);
        }
    },
    mounted() {
        if (window.innerWidth <= 600) {
            this.isMobile = true;
            this.numberElementsSkeleton = 4;
        }
        this.getRequest(1, "", 0);
    }
}
</script>