<template>
    <v-form
        v-model="valid"
        lazy-validation
        ref="formclient"
    >
        <PrMTextFieldComp
            v-model="dataClient.full_name"
            label="Nombre Completo"
            @click="eValidateForm()"
            :counter="50"
            :rules="[
                value => !this.mRItIsEmpty(value) || smsEmpty,
                value => (`${value}`).length <= 50 || smsExecedCounter(50)
            ]"
        />
        <PrMTextFieldComp
            v-model="dataClient.email"
            label="Correo GMAIL"
            class="mt-2"
            :counter="50"
            :rules="[
                value => !this.mRItIsEmpty(value) || smsEmpty,
                value => !this.mRHasSpaces(value) || smsWithSpaces,
                value => this.mIsGmail(value) || 'Debe enviar un correo gmail',
                value => (`${value}`).length <= 50 || smsExecedCounter(50)
            ]"
        />
        <PrMTextFieldComp
            v-model="dataClient.phone"
            label="Celular"
            :counter="15"
            :rules="[
                value => !this.mRItIsEmpty(value) || smsEmpty,
                value => !this.mRHasSpaces(value) || smsWithSpaces,
                value => (`${value}`).length <= 15 || smsExecedCounter(15)
            ]"
        />
        <PrMTextFieldComp
            v-model="dataUser.password"
            label="Contraseña"
            :type="showPassword ? 'text' : 'password'"
            :icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            class="mt-2"
            @clicksee="showPassword = !showPassword"
            :counter="20"
            :rules="[
                value => !mRItIsEmpty(value) || smsEmpty,
                value => !mRHasSpaces(value) || smsWithSpaces,
                value => (`${value}`).length >= 5 || smsMinCounter(5),
                value => (`${value}`).length <= 20 || smsExecedCounter(20)
            ]"
        />
        <PrMTextFieldComp
            v-model="dataUser.repit_password"
            label="Repita Contraseña"
            class="mt-2"
            :type="showPassword ? 'text' : 'password'"
            :icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            @clicksee="showPassword = !showPassword"
            :counter="20"
            :rules="[
                value => !mRItIsEmpty(value) || smsEmpty,
                value => !mRHasSpaces(value) || smsWithSpaces,
                value => (`${value}`).length >= 5 || smsMinCounter(5),
                value => !mRDifferentData(value, dataUser.password) || 'La contraseña no coincide',
                value => (`${value}`).length <= 20 || smsExecedCounter(20)
            ]"
        />
        <div class="d-flex justify-center mt-1">
            <PrMBtnNormalComp
                @click="eValidateForm()"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                GUARDAR
            </PrMBtnNormalComp>
        </div>
    </v-form>
</template>
<script>
import {
    PrMTextFieldComp,
    PrMBtnNormalComp,
    PrMSelectComp
} from "../../molecules";
import {
    PrCFillFormComp
} from "../../cells";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        PrMTextFieldComp,
        PrCFillFormComp,
        PrMBtnNormalComp,
        PrMSelectComp
    },
    props: {
        dataClient: Object,
        dataUser: Object
    },
    data: () => ({
        valid: true,
        showPassword: false
    }),
    methods: {
        eValidateForm() {
            if (this.$refs.formclient.validate())
                this.$emit('clickform', true);
            else this.$emit('clickform', false);
        },
        resetForm() {
            this.$refs.formclient.resetValidation();
            this.showPassword = false;
        }
    }
}
</script>