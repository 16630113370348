<template>
    <v-form
        v-model="valid"
        lazy-validation
        ref="formcategorie"
    >
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Categoría"
        >
            <PrMTextFieldComp
                v-model="dataCategorie.categorie"
                :label="isMobile ? 'Categoría' : ''"
                @click="eValidateForm()"
                :counter="50"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => (`${value}`).length <= 50 || smsExecedCounter(50)
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Para Productos"
        >
            <PrMSelectComp
                v-model="dataCategorie.choice"
                :items="optionsFilter"
                itemValue="value"
                itemText="text"
                :label="isMobile ? 'Para Productos' : ''"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            v-if="dataCategorie.choice == 'd'"
            :isMobile="isMobile"
            title="Vista"
        >
            <PrMSelectComp
                v-model="dataCategorie.view"
                :items="optionsViews"
                itemValue="value"
                itemText="text"
                :label="isMobile ? 'Vista' : ''"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty
                ]"
            />
        </PrCFillFormComp>
        <div class="d-flex justify-center mt-1">
            <PrMBtnNormalComp
                @click="eValidateForm()"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                GUARDAR
            </PrMBtnNormalComp>
        </div>
    </v-form>
</template>
<script>
import {
    PrMTextFieldComp,
    PrMBtnNormalComp,
    PrMSelectComp
} from "../../molecules";
import {
    PrCFillFormComp
} from "../../cells";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        PrMTextFieldComp,
        PrCFillFormComp,
        PrMBtnNormalComp,
        PrMSelectComp
    },
    props: {
        dataCategorie: Object,
        isMobile: Boolean
    },
    data: () => ({
        valid: true,
        optionsFilter: [
            {
                value: "d",
                text: "Digital"
            }, {
                value: "f",
                text: "Físico"
            }
        ],
        optionsViews: [
            {
                value: "c",
                text: "Gift Card"
            }, {
                value: "g",
                text: "Games"
            }, {
                value: "s",
                text: "Streaming"
            }, {
                value: "p",
                text: "Pin Digital"
            }
        ]
    }),
    methods: {
        eValidateForm() {
            if (this.$refs.formcategorie.validate())
                this.$emit('clickform', true);
            else this.$emit('clickform', false);
        },
        resetForm() {
            this.$refs.formcategorie.resetValidation();
        }
    }
}
</script>