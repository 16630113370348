<template>
    <div>
        <div class="categories__header pa-2">
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    v-if="!isMobile"
                    :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                    :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                    class="mr-2"
                    @click="$emit('clickmini')"
                />
                <h1 class="pr-app-title">
                    {{`INVENTARIO SALDO (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="categories__header-search">
                <PrOSearchHeaderComp
                    class="mr-1"
                    label="Buscar"
                    v-model="search"
                    @entersearch="eSearchProducts()"
                />
                <PrCBtnToolTipHeaderComp
                    class="mr-1"
                    icon="mdi-microsoft-excel"
                    smsToolTip="Exportar Excel"
                    @click="eExportExcel()"
                />
                <PrCBtnToolTipHeaderComp
                    icon="mdi-database"
                    smsToolTip="Volver a la lista"
                    @click="eBackList()"
                />
            </div>
        </div>
        <PrMLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="d-flex justify-center flex-wrap pa-2">
                    <div class="pr-products__search">
                        <div class="pr-products__search-block">
                            <PrMSelectComp
                                label="Categoría"
                                v-model="idCategorie"
                                :items="categories"
                                background=""
                                itemValue="id"
                                itemText="categorie"
                                :hideDetails="true"
                                @change="eChangeCategorie"
                            />
                        </div>
                        <div class="pr-products__search-block">
                            <PrMSelectComp
                                label="Stock"
                                v-model="valueStock"
                                background=""
                                :items="optionsStock"
                                itemValue="value"
                                itemText="text"
                                :hideDetails="true"
                                @change="eChangeStock"
                            />
                        </div>
                    </div>
                </div>
                <v-divider></v-divider>
                <PrCLoaderDataComp
                    v-if="dialogLoaderDataIsOpen"
                    class="mt-2"
                />
                <PrMWithoutInfoCardsComp
                    v-if="!dialogLoaderDataIsOpen && products.length===0 && isMobile"
                    class="ma-2"
                />
                <PrOTableInventoryComp
                    v-if="!dialogLoaderDataIsOpen && !isMobile"
                    :products="products"
                    :search="searchFilter"
                    @clickedit="eShowFormEdit"
                    @clickdetail="eShowDetailProduct"
                />
                <div
                    v-if="!dialogLoaderDataIsOpen && isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardInventoryComp
                        class="ma-2"
                        v-for="product in products"
                        :key="product.id"
                        :product="product"
                        :search="searchFilter"
                        @clickedit="eShowFormEdit(product)"
                        @clickdetail="eShowDetailProduct(product)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="isMobile && !dialogLoaderDataIsOpen && products.length > 0"
                ></v-divider>
                <PrSPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderDataIsOpen && products.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                    @clickpage="eSearchForPage"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2">
                    <PrESkeletonFormComp
                        title="ACTUALIZAR"
                    >
                        <div v-if="product.id != undefined">
                            <PrODialogInfoProductComp
                                :product="product"
                            />
                        </div>
                        <v-divider class="mt-1 mb-4"></v-divider>
                        <PrOFormInventoryComp
                            ref="formproductcomp"
                            :dataProduct="dataProduct"
                            :isMobile="isMobile"
                            @clickform="eConfirmForm"
                        />
                    </PrESkeletonFormComp>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <PrSDialogDetailProductComp
            :value="dialogDetailIsOpen"
            :product="product"
            :categorieUser="categorieUser"
            @clickclose="dialogDetailIsOpen = false"
        />
        <PrOGeneralDialogsComp
            :dialogErrorIsOpen="dialogErrorIsOpen"
            :smsErrors="smsErrors"
            :actionErrorIsActivated="actionErrorIsActivated"
            @clickerror="eCloseError()"
            :dialogConfirmationIsOpen="dialogConfirmationIsOpen"
            :smsConfirmations="smsConfirmations"
            @clickyes="eProcessOperation()"
            @clickno="dialogConfirmationIsOpen=false"
            :smsActions="smsActions"
            :frameAction="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
            :dialogOperationIsOpen="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMSelectComp,
    PrMWithoutInfoCardsComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCLoaderDataComp,
} from "../../components/components_private/cells";
import {
    PrOSearchHeaderComp,
    PrOTableInventoryComp,
    PrOGeneralDialogsComp,
    PrOFormInventoryComp,
    PrOCardInventoryComp,
    PrODialogInfoProductComp
} from "../../components/components_private/organisms";
import {
    PrESkeletonFormComp,
    PrSPaginationComp,
    PrSDialogDetailProductComp
} from "../../components/components_private/species";
import {
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin
} from "../../mixins";
import {
    Product,
    Categorie
} from "../../models";
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin
    ],
    components: {
        PrMLineHeaderComp,
        PrCBtnToolTipHeaderComp,
        PrOSearchHeaderComp,
        PrOTableInventoryComp,
        PrMSelectComp,
        PrOFormInventoryComp,
        PrESkeletonFormComp,
        PrOGeneralDialogsComp,
        PrCLoaderDataComp,
        PrMWithoutInfoCardsComp,
        PrOCardInventoryComp,
        PrSPaginationComp,
        PrODialogInfoProductComp,
        PrSDialogDetailProductComp
    },
    data: () => ({
        categories: [{ id: 0, categorie: "Todos" }],
        idCategorie: 0,
        optionsStock: [
            { value: "t", text: "Todos" },
            { value: "b", text: "Bajo Inv." },
            { value: "m", text: "Medio Inv." },
            { value: "a", text: "Alto Inv." }
        ],
        valueStock: "t",
        search: "",
        dataProduct: new Product,
        products: [],
        tab: 0,
        indexProduct: -1,
        product: {},
        dialogDetailIsOpen: false,
        categorieUser: ""
    }),
    computed: {
        categoriesFilter: function() {
            return this.categories.filter(categorie => {
                return categorie.id !== 0;
            });
        },
        searchFilter: function() {
            if (this.mRItIsEmpty(this.search)) return "";
            return this.search;
        }
    },
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        async getRequest() {
            this.dialogLoaderDataIsOpen = true;
            try {
                const categories = Categorie.getCategories();
                const products = Product.getInventory(1, "", 0, "t", "d", 1);
                const responses = await Promise.all([categories, products]);
                responses[0].forEach(element => {
                    this.categories.push(element);
                });
                responses[1].data.forEach(element => {
                    element.new_quantity = null;
                    this.products.push(element);
                });
                //this.products = responses[1].data;
                this.lastPage = responses[1].last_page;
                this.currentPage = responses[1].current_page;
                this.total = responses[1].total;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eConfirmForm(isValidForm) {
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                if (isValidForm) {
                    this.smsConfirmations = [`¿Está seguro(a) de actualizar: ${this.dataProduct.product}?`];
                    this.dialogConfirmationIsOpen = true;
                } else this.mGDShowError();
            }, time);
        },
        eBackList() {
            if (this.tab != 0) {
                this.dataProduct = new Product;
                this.$refs.formproductcomp.resetForm();
                this.tab = 0;
            }
        },
        async updateInventory() {
            try {
                const response = await Product.updateProductInventory(this.dataProduct);
                response.new_quantity = null;
                Object.assign(this.products[this.indexProduct], response);
                this.eBackList();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se actualizó el producto"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eProcessOperation() {
            this.dialogConfirmationIsOpen = false;
            this.dialogOperationIsOpen = true;
            this.updateInventory();
        },
        eShowFormEdit(product) {
            this.indexProduct = this.products.indexOf(product);
            this.dataProduct = Object.assign({}, product);
            this.product = product;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        },
        eShowDetailProduct(product) {
            this.product = product;
            this.dialogDetailIsOpen = true;
        },
         //metodos para buscar productos
        async getProducts(page, search, idCategorie, stock) {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Product.getInventory(page, search, idCategorie, stock, "d", 1);
                this.products = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getProducts(this.currentPage, this.search, this.idCategorie, this.valueStock);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mGDSearchForPage(page, this.mRIsInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getProducts(page, this.search, this.idCategorie, this.valueStock);
                }, time);
            }
        },
        eSearchProducts() {
            this.tab = 0;
            if (this.mRItIsEmpty(this.search)) this.search = "";
            this.getProducts(1, this.search, this.idCategorie, this.valueStock);
        },
        eChangeCategorie(idCategorie) {
            this.getProducts(1, this.search, idCategorie, this.valueStock);
        },
        eChangeStock(stock) {
            this.getProducts(1, this.search, this.idCategorie, stock);
        },
        async eExportExcel() {
            this.dialogOperationIsOpen = true;
            try {
                const response = await Product.inventoryTotal(this.search, this.idCategorie, this.valueStock, "d", 1);
                this.generateExcel(response);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        generateExcel(products) {
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('productos');

            // Add data to the worksheet
            worksheet.columns = [
                { header: 'N°', key: 'col1', width: 10 },
                { header: 'PRODUCTO', key: 'col2', width: 30 },
                { header: 'DESCRIPCIÓN', key: 'col3', width: 30 },
                { header: 'PRECIO UNIDAD', key: 'col4', width: 20 },
                { header: 'CANTIDAD', key: 'col5', width: 20 },
                { header: 'PRECIO COSTO', key: 'col6', width: 20 },
                { header: 'CATEGORÍA', key: 'col7', width: 30 }
            ];

            const data = [];
            //{ col1: 'Value 1', col2: 'Value 2' }

            products.forEach((product, index) => {
                data.push({
                    col1: (index + 1),
                    col2: `${product.product}`,
                    col3: product.description == null
                        ? "S/D"
                        : product.description,
                    col4: product.price.toFixed(2),
                    col5: product.quantity,
                    col6: product.price_cost.toFixed(2),
                    col7: product.categorie.categorie
                });
            });

            worksheet.addRows(data);
            this.dialogOperationIsOpen = false;
            // Save the workbook
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'inventario_saldos.xlsx');
            });
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            this.categorieUser = this.mUserCategoryEmployee();
            if (this.mUserCategoryEmployee() === 'a') this.getRequest();
            else this.$router.replace('/admin/home');
        } else this.$router.replace('/login-admin');
    }
}
</script>