<template>
    <div class="pr-o-card-categorie animation-opacity">
        <div class="d-flex justify-center pt-3">
            <PrCImagePublicationComp
                :path="publication.route_file"
            />
        </div>
        <div class="pa-2 pt-1 d-flex justify-center flex-wrap">
            <PrCBtnCardComp
                icon="mdi-delete"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import {
    PrCBtnCardComp,
    PrCImagePublicationComp
} from "../../cells";
export default {
    props: {
        publication: Object
    },
    components: {
        PrCImagePublicationComp,
        PrCBtnCardComp
    }
}
</script>