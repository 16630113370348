<template>
    <div
        :style="!calculatorIsOpen ? 'right:-100%' : 'right: 0px'"
        class="close-box-day__calculator pa-1"
    >
        <div class="close-box-day__block">
            <div class="d-flex justify-center align-center">
                <PrCBtnToolTipTableComp
                    icon="mdi-refresh"
                    smsToolTip="Refrescar Campos"
                    @click="eResetDataCalculator()"
                />
                <h1 class="pr-app-title ml-1 text-center">Calcular</h1>
            </div>
            <v-divider class="mb-1"></v-divider>
            <PrCCalculatorComp
                ref="calculatorcomp"
            />
        </div>
    </div>
</template>
<script>
import {
    PrCBtnToolTipTableComp,
    PrCCalculatorComp
} from "../../cells";
export default {
    props: {
        calculatorIsOpen: Boolean
    },
    components: {
        PrCBtnToolTipTableComp,
        PrCCalculatorComp
    },
    methods: {
        eResetDataCalculator() {
            this.$refs.calculatorcomp.resetData();
        }
    }
}
</script>