var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"formbalance",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.numberOperation === 0 || _vm.numberOperation === 1)?_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Nombre"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Nombre' : '',"counter":50,"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => (`${value}`).length <= 50 || _vm.smsExecedCounter(50)
            ]},on:{"click":function($event){return _vm.eValidateForm()}},model:{value:(_vm.dataBalance.name),callback:function ($$v) {_vm.$set(_vm.dataBalance, "name", $$v)},expression:"dataBalance.name"}})],1):_vm._e(),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Saldo"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Saldo' : '',"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => _vm.mRIsNumeric(value) || _vm.smsNotNumeric,
            ]},on:{"click":function($event){return _vm.eValidateForm()}},model:{value:(_vm.dataBalance.sald),callback:function ($$v) {_vm.$set(_vm.dataBalance, "sald", $$v)},expression:"dataBalance.sald"}})],1),_c('div',{staticClass:"d-flex justify-center mt-1"},[_c('PrMBtnNormalComp',{on:{"click":function($event){return _vm.eValidateForm()}}},[_c('v-icon',[_vm._v("mdi-bookmark-check")]),_vm._v(" GUARDAR ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }