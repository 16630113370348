<template>
    <v-dialog
        persistent
        :value="value"
        width="340"
    >
        <v-card class="pa-0 ma-0">
            <div class="d-flex align-center pa-2 pr-cp-background">
                <h1 class="pr-app-title-dark">Cambiar Celular</h1>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    x-small
                    depressed
                    color="red"
                    @click="$emit('clickclose')"
                >
                    <v-icon
                        class="pr-cd-color"
                    >mdi-close</v-icon>
                </v-btn>
            </div>
            <div class="pa-2">
                <v-form
                    v-model="valid"
                    lazy-validation
                    ref="formphone"
                >
                    <PrMTextFieldComp
                        v-model="dataClient.phone"
                        label="Celular"
                        class="mt-2"
                        :counter="15"
                        :rules="[
                            value => !this.mRItIsEmpty(value) || smsEmpty,
                            value => !this.mRHasSpaces(value) || smsWithSpaces,
                            value => (`${value}`).length <= 15 || smsExecedCounter(15)
                        ]"
                    />
                </v-form>
                <div class="d-flex justify-center mt-1">
                    <PrMBtnNormalComp
                        @click="eValidateForm()"
                    >
                        <v-icon>mdi-bookmark-check</v-icon>
                        GUARDAR
                    </PrMBtnNormalComp>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    PrMBtnNormalComp,
    PrMTextFieldComp
} from "../../molecules";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [rulesMixin],
    props: {
        dataClient: Object,
        value: Boolean
    },
    components: {
        PrMBtnNormalComp,
        PrMTextFieldComp
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidateForm() {
            if (this.$refs.formphone.validate())
                this.$emit('clickform', true);
            else this.$emit('clickform', false);
        },
        resetForm() {
            this.$refs.formphone.resetValidation();
        }
    }
}
</script>