<template>
    <v-dialog
        persistent
        :value="value"
        width="700"
    >
        <v-card class="pa-0 ma-0 cs-pu-background">
            <div class="d-flex align-center pa-2 cf-pu-background">
                <h1 class="pr-app-title-dark">
                    SOLICITAR COMPRA
                </h1>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    x-small
                    depressed
                    color="red"
                    @click="$emit('clickclose')"
                >
                    <v-icon
                        class="pr-cd-color"
                    >mdi-close</v-icon>
                </v-btn>
            </div>
            <div class="pa-2">
                <h1 class="pu-app-subtitle-dark">
                    Estimado cliente realice el pago por nuestro Qr y envíe el comprobante de
                    pago a nuestro contacto de whatsapp. GRACIAS
                </h1>
                <div class="d-flex">
                    <MBtnNormalComp
                        :xSmall="isMobile"
                        :small="!isMobile"
                        class="mb-1 mt-1"
                        :outlined="false"
                        className="cp-pu-background"
                        @click="$emit('dowloadqr', 1)"
                    >
                        <v-icon small class="mr-1">mdi-package-down</v-icon>
                        DESCARGAR QR 1
                    </MBtnNormalComp>
                    <MBtnNormalComp
                        :xSmall="isMobile"
                        :small="!isMobile"
                        class="mb-1 mt-1 ml-1"
                        :outlined="false"
                        className="cp-pu-background"
                        @click="$emit('dowloadqr', 0)"
                    >
                        <v-icon small class="mr-1">mdi-package-down</v-icon>
                        DESCARGAR QR 2
                    </MBtnNormalComp>
                </div>
                <div class="d-flex justify-center flex-wrap mt-2">
                    <v-card
                        width="260px"
                        height="260px"
                        elevation="0"
                        class="cf-pu-background"
                    >
                        <v-img
                            v-if="qr != ''"
                            :src="qr|imgNormal"
                            width="100%"
                            height="100%"
                            contain
                        ></v-img>
                    </v-card>
                    <v-card
                        v-if="qr_alternate != ''"
                        width="260px"
                        height="260px"
                        elevation="0"
                        :class="isMobile ? 'cf-pu-background mt-2'
                            : 'cf-pu-background ml-3'"
                    >
                        <v-img
                            :src="qr_alternate|imgNormal"
                            width="100%"
                            height="100%"
                            contain
                        ></v-img>
                    </v-card>
                </div>
                <div class="d-flex justify-center pt-3 pb-1">
                    <MBtnNormalComp
                        :xSmall="isMobile"
                        :small="!isMobile"
                        :outlined="false"
                        className="cp-pu-background"
                        @click="$emit('sendpurchase')"
                    >
                        ENVIAR PEDIDO
                    </MBtnNormalComp>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    imgNormal
} from "../../../../filters";
import {
    MBtnNormalComp
} from "../../molecules";
import { mapState } from "vuex";
export default {
    components: {
        MBtnNormalComp
    },
    filters: {
        imgNormal
    },
    props: {
        value: Boolean,
        isMobile: Boolean
    },
    computed: {
        ...mapState("settingStore", ["qr", "qr_alternate"])
    }
}
</script>