import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from "../store/modules";
class Employee {
    constructor(
        full_name = null,
        category = null
    ) {
        this.full_name = full_name;
        this.category = category;
    }
    static async editEmployee(parameters) {
        const response = await axios.put(
            `${apiUrls.EMPLOYEE}edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async activateIp(parameters)
    {
        const response = await axios.put(
            `${apiUrls.EMPLOYEE}activate-ip`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
}
export default Employee;