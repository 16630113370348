<template>
    <v-dialog
        persistent
        :value="value"
        width="800"
    >
        <v-card class="pa-0 ma-0">
            <div class="d-flex align-center pa-2 pr-cp-background">
                <h1 class="pr-app-title-dark">Códigos Enviados</h1>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    x-small
                    depressed
                    color="red"
                    @click="$emit('clickclose')"
                >
                    <v-icon
                        class="pr-cd-color"
                    >mdi-close</v-icon>
                </v-btn>
            </div>
            <PrOInfoSaleComp
                :sale="sale"
            />
            <PrMBtnNormalComp
                class="ml-2 mt-1"
                @click="$emit('clickwhatsapp')"
            >
                Cliente
                <v-icon small class="ml-1">mdi-whatsapp</v-icon>
            </PrMBtnNormalComp>
            <PrOTableDigitalAloneComp
                v-if="!isMobile"
                :codes="codes"
            />
            <div
                v-else
                class="d-flex justify-center flex-wrap"
            >
                <PrOCardDigitalAloneComp
                    v-for="code in codes"
                    :key="code.id"
                    :code="code"
                    class="ma-2"
                />
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    PrMBtnNormalComp
} from "../molecules";
import {
    PrOTableDigitalAloneComp,
    PrOInfoSaleComp,
    PrOCardDigitalAloneComp
} from "../organisms";
export default {
    props: {
        codes: Array,
        value: Boolean,
        isMobile: Boolean,
        sale: Object
    },
    components: {
        PrOTableDigitalAloneComp,
        PrOInfoSaleComp,
        PrOCardDigitalAloneComp,
        PrMBtnNormalComp
    }
}
</script>