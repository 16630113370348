<template>
    <div class="pr-o-table-categories animation-opacity">
        <table class="pr-o-table-categories__table">
            <thead>
                <tr>
                    <th>
                        <h1 class="pr-app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Cliente</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Total</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Entregado</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Lugar Venta</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Pago</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Fecha</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Productos</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Nota</h1>
                    </th>
                    <th width="190">
                        <h1 class="pr-app-title-dark">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(sale, index) in sales"
                    :key="sale.id"
                >
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{(index + 1)}}
                        </h1>
                    </td>
                    <td>
                        <text-highlight
                            class="pr-app-subtitle"
                            :queries="search"
                        >
                            {{sale.client.full_name}}
                        </text-highlight>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle text-right">
                            {{mSaleTotalDB(sale.product_sale).toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex align-center">
                            <div
                                :class="sale.delivered === 1
                                    ? 'categories__enabled mr-1'
                                    : 'categories__disabled mr-1'"
                                style="margin-top:-2px"    
                            ></div>
                            <h1 class="pr-app-subtitle">
                                {{sale.delivered === 1 ? "Si" : "No"}}
                            </h1>
                        </div>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{getTypeSale(sale)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle text-center">
                            {{getTextTypeCoin(sale)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle text-center">
                            {{`${sale.hour} ${sale.date}`}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle text-center">
                            {{textProducts(sale)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{sale.note == null ? '-----' : sale.note}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <PrCBtnToolTipTableComp
                            v-if="!isInfo"
                            icon="mdi-format-list-bulleted"
                            smsToolTip="Ver detalle venta"
                            @click="$emit('clickdetail', sale)"
                        />
                        <PrCBtnToolTipTableComp
                            icon="mdi-account-details"
                            smsToolTip="Ver información venta"
                            @click="$emit('clickinfo', sale)"
                        />
                        <PrCBtnToolTipTableComp
                            v-if="!isClient && !isSend && !isInfo"
                            icon="mdi-eye-off"
                            smsToolTip="Inhabilitar Venta"
                            @click="$emit('clickstate', sale)"
                        />
                        <PrCBtnToolTipTableComp
                            v-if="isSend"
                            icon="mdi-file-send"
                            smsToolTip="Venta enviada"
                            @click="$emit('clicksend', sale)"
                        />
                        <PrCBtnToolTipTableComp
                            v-if="isInfo"
                            icon="mdi-cellphone-key"
                            smsToolTip="Códigos pendientes"
                            @click="$emit('clickkeys', sale)"
                        />
                    </td>
                </tr>
                <tr v-if="sales.length === 0">
                    <td colspan="10">
                        <h1 class="pr-app-subtitle text-center">Sin Ventas</h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    PrCBtnToolTipTableComp
} from "../../cells";
import {
    saleMixin
} from "../../../../mixins";
import TextHighlight from 'vue-text-highlight';
export default {
    mixins: [ saleMixin ],
    props: {
        sales: Array,
        isClient: Boolean,
        isSend: Boolean,
        search: {
            type: String,
            default: ""
        },
        isInfo: Boolean
    },
    components: {
        PrCBtnToolTipTableComp,
        TextHighlight
    },
    methods: {
        getTypeSale(sale) {
            if (sale.type_sale === "e") return "Página web";
            if (sale.type_sale === "s") return "Tienda";
            return "Personal";
        },
        getTextTypeCoin(sale) {
            if (sale.type_coin === "e") return "Efectivo";
            if (sale.type_coin === "q") return "Pago Qr";
            return "Tigo Money";
        },
        textProducts(sale) {
            if (sale.product_sale.length == 1)
                return `${sale.product_sale[0].product.product}`;
            let text = "";
            for (let i = 0; i < sale.product_sale.length; i++) {
                if ((i + 1) == sale.product_sale.length)
                    text += `${sale.product_sale[i].product.product}, `;
                else text += `${sale.product_sale[i].product.product}`;
            }
            return text;
        }
    }
}
</script>