var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","value":_vm.value,"width":"320"}},[_c('v-card',{staticClass:"pa-0 ma-0"},[_c('div',{staticClass:"d-flex align-center pa-2 pr-cp-background"},[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Formulario Producto")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","x-small":"","depressed":"","color":"red"},on:{"click":function($event){return _vm.$emit('clickclose')}}},[_c('v-icon',{staticClass:"pr-cd-color"},[_vm._v("mdi-close")])],1)],1),_c('div',{staticClass:"pa-2"},[(_vm.product.id != undefined)?_c('div',[_c('div',{staticClass:"d-flex justify-center"},[_c('PrCImageTableComp',{attrs:{"path":_vm.product.route_file}})],1),_c('h1',{staticClass:"pr-app-title"},[_vm._v(_vm._s(_vm.product.product))]),_c('h1',{staticClass:"pr-app-subtitle"},[_vm._v(_vm._s(_vm.product.description))]),_c('v-form',{ref:"formproduct",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('PrCFillInformationComp',{attrs:{"title":"Precio","subtitle":_vm.product.price.toFixed(2)}}),_c('PrMTextFieldComp',{staticClass:"mt-2",attrs:{"hideDetails":true,"label":"Nuevo Precio","rules":[
                            value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                            value => _vm.mRIsNumeric(value) || _vm.smsNotNumeric,
                            value => !_vm.mRExcedeLimite(value, _vm.product.price) || 'No debe exceder al precio de venta'
                        ]},model:{value:(_vm.product.new_price),callback:function ($$v) {_vm.$set(_vm.product, "new_price", $$v)},expression:"product.new_price"}}),_c('PrCFillInformationComp',{staticClass:"mt-2",attrs:{"title":"Cantidad","subtitle":`${_vm.product.quantity}`}},[_c('div',{class:_vm.getClassInventory(_vm.product),staticStyle:{"margin-top":"0px"}})]),_c('PrMTextFieldComp',{staticClass:"mt-2",attrs:{"hideDetails":true,"label":"Nueva Cantidad","rules":[
                            value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                            value => _vm.mRIsInteger(value) || _vm.smsNotInteger,
                            value => !_vm.mRExcedeLimite(value, _vm.product.quantity) || 'No debe exceder al precio de venta'
                        ]},model:{value:(_vm.product.new_quantity),callback:function ($$v) {_vm.$set(_vm.product, "new_quantity", $$v)},expression:"product.new_quantity"}}),_c('div',{staticClass:"text-center mt-2"},[_c('PrMBtnNormalComp',{attrs:{"icon":"mdi-check"},on:{"click":function($event){return _vm.eValidateForm()}}},[_vm._v(" "+_vm._s(_vm.indexDetail===-1 ? 'Agregar' : 'EDITAR')+" ")])],1)],1)],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }