export default {
    methods: {
        mDateFormat(date) {
            if (date === null) return null;
            const [year, month, day] = date.split("-");
            return `${day}/${month}/${year}`;
        },
        mDateSystem(type_format) {
            let today = new Date();
            let dd = today.getDate();
            let mm = today.getMonth() + 1;
            let yyyy = today.getFullYear();
            if (dd < 10) dd = "0" + dd;
            if (mm < 10) mm = "0" + mm;
            if (type_format) return (today = dd + "/" + mm + "/" + yyyy);
            return (today = yyyy + "-" + mm + "-" + dd);
        },
        mDateYearSystem() {
            let today = new Date();
            return today.getFullYear();
        },
        mDateCurrentDaySystem() {
            const today = new Date();
            const weekly_day = today.getDay();
            switch (weekly_day) {
                case 0: return 'Domingo'
                case 1: return 'Lunes'
                case 2: return 'Martes'
                case 3: return 'Miercoles'
                case 4: return 'Jueves'
                case 5: return 'Viernes'
                case 6: return 'Sábado'
            }
        },
        mDateFormatFile(date) {
            if (date === null) return null;
            const [year, month, day] = date.split("-");
            return `${day}_${month}_${year}`;
        }
    }
}
