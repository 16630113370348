<template>
    <v-form
        v-model="valid"
        lazy-validation
        ref="formlogin"
    >
        <CFillFormComp
            :isMobile="isMobile"
            title="Correo GMAIL"
        >
            <MTextFieldComp
                v-model="dataLogin.username"
                :dark="isMobile"
                :class="isMobile ? 'mt-5' : ''"
                :background="isMobile ? '' : 'white'"
                :styleTextField="isMobile
                    ? 'font-weight:300 !important'
                    : ''"
                :label="isMobile ? 'Correo GMAIL' : ''"
                :counter="50"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => !mRHasSpaces(value) || smsWithSpaces,
                    value => mIsGmail(value) || 'Debe enviar un correo gmail',
                    value => (`${value}`).length <= 50 || smsExecedCounter(50)
                ]"
            />
        </CFillFormComp>
        <CFillFormComp
            :isMobile="isMobile"
            title="Contraseña"
        >
            <MTextFieldComp
                :dark="isMobile"
                :background="isMobile ? '' : 'white'"
                :styleTextField="isMobile
                    ? 'font-weight:300 !important'
                    : ''"
                v-model="dataLogin.password"
                :label="isMobile ? 'Contraseña' : ''"
                :type="showPassword ? 'text' : 'password'"
                :icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                @clicksee="showPassword = !showPassword"
                @click="eValidateForm()"
                :counter="20"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => !mRHasSpaces(value) || smsWithSpaces,
                    value => (`${value}`).length >= 5 || smsMinCounter(5),
                    value => (`${value}`).length <= 20 || smsExecedCounter(20)
                ]"
            />
        </CFillFormComp>
        <div class="d-flex justify-center mt-1">
            <PrMBtnNormalComp
                className="cp-pu-color"
                :small="!isMobile"
                :xSmall="isMobile"
                :loading="loadingBtn"
                @click="eValidateForm()"
            >
                ingresar
            </PrMBtnNormalComp>
        </div>
    </v-form>
</template>
<script>
import {
    CFillFormComp
} from "../../cells";
import {
    MTextFieldComp
} from "../../molecules";
import {
    PrMBtnNormalComp
} from "../../../components_private/molecules";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [
        rulesMixin
    ],
    components: {
        CFillFormComp,
        MTextFieldComp,
        PrMBtnNormalComp
    },
    props: {
        isMobile: Boolean,
        dataLogin: Object,
        loadingBtn: Boolean
    },
    data: () => ({
        showPassword: false,
        valid: true
    }),
    methods: {
        eValidateForm() {
            if (this.$refs.formlogin.validate())
                this.$emit('clickform', true);
            else this.$emit('clickform', false);
        },
        resetForm() {
            this.showPassword = false;
            this.$refs.formlogin.resetValidation();
        }
    }
}
</script>