<template>
    <div
        class="c-image-home c-images-about"
        v-intersect="onIntersect"
    >
        <div class="c-images-about__first">
            <div class="c-images-about__big-img">
                <v-img
                    :src="info.fond"
                    gradient="to top right, rgba(0, 0, 0, 0.4), rgba(0, 0, 9, 0.1)"
                    width="100%"
                    height="100%"
                ></v-img>
            </div>
            <div class="c-images-about__others-img">
                <v-img
                    :src="info.srcOne"
                    gradient="to top right, rgba(0, 0, 0, 0.4), rgba(0, 0, 9, 0.1)"
                    width="100%"
                    height="100%"
                    class="d-flex align-center"
                >
                    <h1 class="pu-app-big-title-dark text-center">
                        {{info.titleOne}}
                    </h1>
                </v-img>
            </div>
        </div>
        <div class="c-images-about__second">
            <div class="c-images-about__others-img-two">
                <v-img
                    :src="info.srcTwo"
                    gradient="to top right, rgba(0, 0, 0, 0.4), rgba(0, 0, 9, 0.1)"
                    width="100%"
                    height="100%"
                    class="d-flex align-center"
                >
                    <h1 class="pu-app-big-title-dark text-center">
                        {{info.titleTwo}}
                    </h1>
                </v-img>
            </div>
            <div class="c-images-about__others-img-thrid">
                <v-img
                    :src="info.srcThird"
                    gradient="to top right, rgba(0, 0, 0, 0.4), rgba(0, 0, 9, 0.1)"
                    width="100%"
                    height="100%"
                    class="d-flex align-center"
                >
                    <h1 class="pu-app-big-title-dark text-center">
                        {{info.titleThird}}
                    </h1>
                </v-img>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        info: Object
    },
    data: () => ({
        isIntersecting: false,
        isMobile: false
    }),
    methods: {
        onIntersect (entries, observer) {
            if (entries[0].isIntersecting) {
                this.isIntersecting = entries[0].isIntersecting;
                const element = document.getElementsByClassName("c-images-about");
                element[0].style.transition = "800ms";
                element[0].style.paddingTop = "0px";
                element[0].style.opacity = "1";
            }
        },
        closeAnimation() {
            const element = document.getElementsByClassName("c-images-about");
            element[0].style.paddingTop = "0px";
            element[0].style.opacity = "1";
        }
    },
    mounted() {
        if (window.innerWidth <= 600) {
            this.isMobile = true;
            this.closeAnimation();
        }
    }
}
</script>