<template>
    <div class="o-fond-home">
        <div class="o-fond-home__body">
            <div class="o-fond-home__body-image">
                <v-carousel
                    hide-delimiters
                    height="100%"
                    cycle
                >
                    <v-carousel-item
                        v-for="(product, i) in publications"
                        :key="i"
                        :src="product.route_file|imgNormal"
                    >
                    </v-carousel-item>
                </v-carousel>
            </div>
        </div>
    </div>
</template>
<script>
import {
    MBtnNormalComp
} from "../../molecules";
import {
    imgNormal
} from "../../../../filters";
export default {
    filters: {
        imgNormal
    },
    components: {
        MBtnNormalComp
    },
    props: {
        info: Object,
        publications: Array
    }
}
</script>