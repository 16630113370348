<template>
    <div>
        <div class="d-flex align-center pa-2">
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    v-if="!isMobile"
                    :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                    :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                    class="mr-2"
                    @click="$emit('clickmini')"
                />
                <h1 class="pr-app-title">
                    {{`VENTAS`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <PrCBtnToolTipHeaderComp
                icon="mdi-database"
                smsToolTip="Volver a la lista"
                @click="eBackTab()"
            />
        </div>
        <PrMLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="d-flex justify-center flex-wrap pa-2">
                    <div
                        :class="isMobile ? 'pr-products__search mt-2' : 'pr-products__search'"
                    >
                        <div class="pr-products__search-block">
                            <PrMTextFieldComp
                                label="Fecha Inicio"
                                v-model="dateOne"
                                background=""
                                type="date"
                                :clearable="false"
                                :hideDetails="true"
                                @change="eChangeDateOne"
                            />
                        </div>
                        <div class="pr-products__search-block">
                            <PrMTextFieldComp
                                label="Fecha Fin"
                                v-model="dateTwo"
                                background=""
                                type="date"
                                :clearable="false"
                                :hideDetails="true"
                                @change="eChangeDateTwo"
                            />
                        </div>
                    </div>
                </div>
                <v-divider></v-divider>
                <PrCLoaderDataComp
                    v-if="dialogLoaderDataIsOpen"
                    class="mt-2"
                />
                <div class="pa-2" v-if="!dialogLoaderDataIsOpen">
                    <div class="d-flex align-center">
                        <div style="width: 180px">
                            <h1 class="pr-app-title text-right">TOTAL EFECTIVO:</h1>
                        </div>
                        <div style="width: 80px" class="ml-2 text-right">
                            <h1 class="pr-app-subtitle">{{totalCash.toFixed(2)}}</h1>
                        </div>
                        <PrCBtnToolTipHeaderComp
                            class="ml-1"
                            icon="mdi-eye"
                            smsToolTip="Mostrar Ventas Efectivo"
                            @click="eShowSales('e')"
                        />
                    </div>
                    <div class="d-flex align-center mt-1">
                        <div style="width: 180px">
                            <h1 class="pr-app-title text-right">TOTAL QR:</h1>
                        </div>
                        <div style="width: 80px" class="ml-2 text-right">
                            <h1 class="pr-app-subtitle">{{totalQr.toFixed(2)}}</h1>
                        </div>
                        <PrCBtnToolTipHeaderComp
                            class="ml-1"
                            icon="mdi-eye"
                            smsToolTip="Mostrar Ventas Qr"
                            @click="eShowSales('q')"
                        />
                    </div>
                    <div class="d-flex align-center mt-1">
                        <div style="width: 180px">
                            <h1 class="pr-app-title text-right">TOTAL TIGO MONEY:</h1>
                        </div>
                        <div style="width: 80px" class="ml-2 text-right">
                            <h1 class="pr-app-subtitle">{{totalTigoMoney.toFixed(2)}}</h1>
                        </div>
                        <PrCBtnToolTipHeaderComp
                            class="ml-1"
                            icon="mdi-eye"
                            smsToolTip="Mostrar Ventas Tigo Money"
                            @click="eShowSales('t')"
                        />
                    </div>
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2 pt-1 pb-0">
                    <PrCFillInformationComp
                        title="Cantidad Ventas"
                        :subtitle="`${total}`"
                    />
                    <PrCFillInformationComp
                        title="Moneda"
                        subtitle="Bolivianos"
                    />
                    <PrCFillInformationComp
                        title="Total Venta"
                        :subtitle="totalSale.toFixed(2)"
                    />
                    <PrCFillInformationComp
                        title="Total Costo"
                        :subtitle="totalCost.toFixed(2)"
                    />
                    <PrCFillInformationComp
                        title="Estimación Ganancia"
                        :subtitle="(totalSale - totalCost).toFixed(2)"
                    />
                </div>
                <v-divider></v-divider>
                <PrCLoaderDataComp
                    v-if="dialogLoaderDataIsOpen"
                    class="mt-2"
                />
                <PrMWithoutInfoCardsComp
                    v-if="!dialogLoaderDataIsOpen && sales.length===0 && isMobile"
                    class="ma-2"
                />
                <PrOTableSalesComp
                    v-if="!dialogLoaderDataIsOpen && !isMobile"
                    :sales="sales"
                    :isClient="true"
                    @clickinfo="eShowInfoSale"
                    @clickdetail="eShowDetailSale"
                />
                <div
                    v-if="!dialogLoaderDataIsOpen && isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardSaleComp
                        class="ma-2"
                        v-for="sale in sales"
                        :key="sale.id"
                        :sale="sale"
                        :isClient="true"
                        @clickinfo="eShowInfoSale(sale)"
                        @clickdetail="eShowDetailSale(sale)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="isMobile && !dialogLoaderDataIsOpen && sales.length > 0"
                ></v-divider>
                <PrSPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderDataIsOpen && sales.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                    @clickpage="eSearchForPage"
                />
            </v-tab-item>
            <v-tab-item>
                <PrOInfoSaleComp
                    :sale="sale"
                />
                <PrOTableDetailSaleComp
                    v-if="!isMobile"
                    :detail="sale.product_sale"
                    :categorieUser="categorieUser"
                    @clickproduct="eInfoProduct"
                />
                <div
                    v-if="isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardDetailSaleComp
                        class="ma-2"
                        v-for="product in sale.product_sale"
                        :key="product.id"
                        :categorieUser="categorieUser"
                        :product="product"
                        @clickproduct="eInfoProduct(product)"
                    />
                </div>
            </v-tab-item>
        </v-tabs-items>
        <PrSDialogDetailReportSaleComp
            :value="dialogDetailReportIsOpen"
            :sale="sale"
            @clickclose="dialogDetailReportIsOpen = false"
        />
        <PrSDialogDetailProductComp
            :value="dialogDetailIsOpen"
            :product="product"
            :categorieUser="categorieUser"
            @clickclose="dialogDetailIsOpen = false"
        />
        <PrCSmsErrorComp
            :smsErrors="smsErrors"
            :value="dialogErrorIsOpen"
            :actionErrorIsActivated="actionErrorIsActivated"
            @click="eCloseError()"
        />
        <PrCSmsActionComp
            :smsActions="smsActions"
            :value="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMTextFieldComp,
    PrMWithoutInfoCardsComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCSmsErrorComp,
    PrCSmsActionComp,
    PrCLoaderDataComp,
    PrCFillInformationComp
} from "../../components/components_private/cells";
import {
    PrOTableSalesComp,
    PrOCardSaleComp,
    PrOInfoSaleComp,
    PrOTableDetailSaleComp,
    PrOCardDetailSaleComp
} from "../../components/components_private/organisms";
import {
    PrSPaginationComp,
    PrSDialogDetailReportSaleComp,
    PrSDialogDetailProductComp
} from "../../components/components_private/species";
import {
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin,
    saleMixin
} from "../../mixins";
import {
    Sale
} from "../../models";
export default {
    components: {
        PrCBtnToolTipHeaderComp,
        PrMLineHeaderComp,
        PrMTextFieldComp,
        PrCSmsErrorComp,
        PrCSmsActionComp,
        PrCLoaderDataComp,
        PrCFillInformationComp,
        PrMWithoutInfoCardsComp,
        PrOTableSalesComp,
        PrOCardSaleComp,
        PrSPaginationComp,
        PrSDialogDetailReportSaleComp,
        PrOInfoSaleComp,
        PrOTableDetailSaleComp,
        PrOCardDetailSaleComp,
        PrSDialogDetailProductComp
    },
    mixins: [
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin,
        saleMixin
    ],
    props: {
        mini: Boolean
    },
    data: () => ({
        categorieUser: "",
        dateOne: "",
        dateTwo: "",
        sales: [],
        tab: 0,
        totalCash: 0,
        totalTigoMoney: 0,
        totalQr: 0,
        totalSaleCancel: 0,
        totalSale: 0,
        totalCost: 0,
        sale: {
            product_sale: []
        },
        dialogDetailReportIsOpen: false,
        dialogDetailIsOpen: false,
        product: {},
        typeSale: ''
    }),
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        async getReportForTypeSales(dateOne, dateTwo) {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Sale.reportForTypeSales(dateOne, dateTwo);
                this.totalCash = response.total_cash;
                this.totalQr = response.total_qr;
                this.totalTigoMoney = response.total_tigo_money;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eBackTab() {
            if (this.tab === 3) {
                this.tab = 2;
                return;
            }
            if (this.tab === 2) {
                this.tab = 1;
                return;
            }
            this.totalSale = 0;
            this.totalCost = 0;
            this.total = 0;
            this.tab = 0;
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        eChangeDateOne() {
            if (this.dateTwo === "" || this.dateOne === "")
                this.mGDShowAction(["Opereción incorrecta", "Seleccione las fechas porfavor"], "error");
            else {
                if (this.dateOne > this.dateTwo)
                    this.dateTwo = this.dateOne;
                this.getReportForTypeSales(this.dateOne, this.dateTwo);
            }
        },
        eChangeDateTwo() {
            if (this.dateTwo === "" || this.dateOne === "")
                this.mGDShowAction(["Opereción incorrecta", "Seleccione las fechas porfavor"], "error");
            else {
                if (this.dateOne > this.dateTwo)
                    this.dateOne = this.dateTwo;
                this.getReportForTypeSales(this.dateOne, this.dateTwo);
            }
        },
        async getSales(page, dateOne, dateTwo, typeCoin) {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Sale.reportSale(page, dateOne, dateTwo, 2, 't', typeCoin);
                this.sales = response.data;
                this.total = response.total;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.totalSale = response.total_sale;
                this.totalCost = response.total_cost;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eShowInfoSale(sale) {
            this.sale = sale;
            this.dialogDetailReportIsOpen = true;
        },
        eInfoProduct(product) {
            this.product = product.product;
            this.dialogDetailIsOpen = true;
        },
        eShowDetailSale(sale) {
            this.sale = sale;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 2;
            }, time);
        },
        eShowSales(typeSale) {
            this.typeSale = typeSale;
            this.getSales(1, this.dateOne, this.dateTwo, typeSale);
            this.tab = 1;
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getSales(this.currentPage, this.dateOne, this.dateTwo, this.typeSale);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mGDSearchForPage(page, this.mRIsInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getSales(page, this.dateOne, this.dateTwo, this.typeSale);
                }, time);
            }
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            this.categorieUser = this.mUserCategoryEmployee();
            this.dateOne = this.dateTwo = this.mDateSystem(false);
            if (this.mUserCategoryEmployee() === 'a')
                this.getReportForTypeSales("", "");
            else this.$router.replace('/admin/home');
        } else this.$router.replace('/login-admin');
    }
}
</script>