<template>
    <div>
        <div class="categories__header pa-2">
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    v-if="!isMobile"
                    :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                    :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                    class="mr-2"
                    @click="$emit('clickmini')"
                />
                <h1 class="pr-app-title">
                    {{`CLIENTES (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="categories__header-search">
                <PrOSearchHeaderComp
                    backgroundColor="white"
                    class="mr-1"
                    label="Buscar"
                    v-model="search"
                    @entersearch="eSearchClients()"
                />
                <PrCBtnToolTipHeaderComp
                    icon="mdi-database"
                    smsToolTip="Volver a la lista"
                    @click="eBackList()"
                />
            </div>
        </div>
        <PrMLineHeaderComp />
        <PrCLoaderDataComp
            v-if="dialogLoaderDataIsOpen"
            class="mt-2"
        />
        <PrMWithoutInfoCardsComp
            v-if="!dialogLoaderDataIsOpen && users.length===0 && isMobile"
            class="ma-2"
        />
        <PrOTableClientsComp
            v-if="!dialogLoaderDataIsOpen && !isMobile"
            :users="users"
            :search="searchFilter"
            @clickstate="eConfirmChangeState"
            @clickedit="eOpenFormPhone"
            @clickwhatsapp="eOpenWhatsappClient"
        />
        <div
            v-if="!dialogLoaderDataIsOpen && isMobile"
            class="d-flex justify-center flex-wrap"
        >
            <PrOCardClientComp
                class="ma-2"
                v-for="user in users"
                :key="user.id"
                :user="user"
                :search="searchFilter"
                @clickedit="eOpenFormPhone(user)"
                @clickstate="eConfirmChangeState(user)"
                @clickwhatsapp="eOpenWhatsappClient(user)"
            />
        </div>
        <v-divider
            class="mt-1"
            v-if="isMobile && !dialogLoaderDataIsOpen && users.length > 0"
        ></v-divider>
        <PrSPaginationComp
            class="mt-2 mb-2"
            v-if="!dialogLoaderDataIsOpen && users.length > 0"
            :condicionGo="currentPage < lastPage"
            :condicionBack="currentPage > 1"
            :titlePagination="'Pag. '+currentPage+' de '+lastPage"
            @clickback="eChangePage(false)"
            @clickgo="eChangePage(true)"
            @clickpage="eSearchForPage"
        />
        <PrODialogPhoneClientComp
            :value="dialogPhoneIsOpen"
            ref="dialogphone"
            :dataClient="dataClient"
            @clickform="eConfirmForm"
            @clickclose="eCloseFormPhone()"
        />
        <PrODialogInhabClientComp
            :value="dialogInhabIsOpen"
            ref="dialoginhab"
            :dataClient="dataInhab"
            @clickform="eConfirmFormInhab"
            @clickclose="eCloseFormInhab()"
        />
        <PrOGeneralDialogsComp
            :dialogErrorIsOpen="dialogErrorIsOpen"
            :smsErrors="smsErrors"
            :actionErrorIsActivated="actionErrorIsActivated"
            @clickerror="eCloseError()"
            :dialogConfirmationIsOpen="dialogConfirmationIsOpen"
            :smsConfirmations="smsConfirmations"
            @clickyes="eProcessOperation()"
            @clickno="eCloseConfirmations()"
            :smsActions="smsActions"
            :frameAction="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
            :dialogOperationIsOpen="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMWithoutInfoCardsComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCLoaderDataComp
} from "../../components/components_private/cells";
import {
    PrOCardClientComp,
    PrOGeneralDialogsComp,
    PrOTableClientsComp,
    PrOSearchHeaderComp,
    PrODialogPhoneClientComp,
    PrODialogInhabClientComp
} from "../../components/components_private/organisms";
import {
    PrESkeletonFormComp,
    PrSPaginationComp
} from "../../components/components_private/species";
import {
    User,
    Client
} from "../../models";
import {
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin
} from "../../mixins";
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin
    ],
    components: {
        PrMLineHeaderComp,
        PrCBtnToolTipHeaderComp,
        PrCLoaderDataComp,
        PrMWithoutInfoCardsComp,
        PrOCardClientComp,
        PrOTableClientsComp,
        PrESkeletonFormComp,
        PrOGeneralDialogsComp,
        PrSPaginationComp,
        PrOSearchHeaderComp,
        PrODialogPhoneClientComp,
        PrODialogInhabClientComp
    },
    data: () => ({
        users: [],
        search: "",
        indexUser: -1,
        idUser: -1,
        dataClient: new Client,
        dataInhab: new Client,
        dialogPhoneIsOpen: false,
        dialogInhabIsOpen: false,
        numberOperation: 0
    }),
    computed: {
        searchFilter: function() {
            return this.mRItIsEmpty(this.search) ? "" : this.search;
        }
    },
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        async getClients(page, search) {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await User.getUsersClients(page, search);
                this.users = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
            this.eCloseConfirmations();
        },
        eConfirmChangeState(user) {
            this.indexUser = this.users.indexOf(user);
            if (user.enabled === 0) {
                this.idUser = user.id;
                this.numberOperation = 0;
                const textState = user.enabled === 0 ? 'habilitar' : 'inhabilitar';
                this.smsConfirmations = [`¿Está seguro de ${textState} al cliente: ${user.client.full_name}?`];
                this.dialogConfirmationIsOpen = true;
            } else {
                this.numberOperation = 2;
                this.dataInhab = Object.assign({}, user.client);
                this.dataInhab.fk_user = user.id;
                this.dialogInhabIsOpen = true;
            }
        },
        async changeState() {
            try {
                const response = await User.changeState({id: this.idUser});
                this.users[this.indexUser].enabled = response;
                this.dialogOperationIsOpen = false;
                const textState = response === 0 ? 'inhabilitó' : 'habilitó'
                this.mGDShowAction(["Operación Exitosa", `Se ${textState} el cliente`]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async editPhone() {
            try {
                const response = await Client.updatePhoneClient(this.dataClient);
                this.users[this.indexUser].client.phone = response;
                this.eCloseFormPhone();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", `Se actualizó el celular`]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async bannedClient() {
            try {
                const response = await Client.bannedClient(this.dataInhab);
                this.users[this.indexUser].enabled = 0;
                Object.assign(this.users[this.indexUser].client, response);
                this.eCloseFormInhab();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", `Se baneó al cliente`]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eProcessOperation() {
            this.dialogConfirmationIsOpen = false;
            this.dialogOperationIsOpen = true;
            switch (this.numberOperation) {
                case 0:
                    this.changeState();
                    break;
                case 1:
                    this.editPhone();
                    break;
                case 2:
                    this.bannedClient();
            }
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getClients(this.currentPage, this.search);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mGDSearchForPage(page, this.mRIsInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getClients(page, this.search);
                }, time);
            }
        },
        eSearchClients() {
            if (this.mRItIsEmpty(this.search)) this.search = "";
            this.getClients(1, this.search);
        },
        eOpenWhatsappClient(user) {
            if (user.client.phone == null)
                this.mGDShowAction(["Operación Cancelada", "El cliente no tiene número registrado"], "error");
            else {
                const phone = user.client.phone;
                window.open(`https://api.whatsapp.com/send?phone=${phone}`);
            }
        },
        eOpenFormPhone(user) {
            this.numberOperation = 1;
            this.dataClient = Object.assign({}, user.client);
            this.indexUser = this.users.indexOf(user);
            this.dialogPhoneIsOpen = true;
        },
        eCloseConfirmations() {
            this.dialogConfirmationIsOpen = false;
            if (this.numberOperation === 1)
                this.dialogPhoneIsOpen = true;
            if (this.numberOperation === 2)
                this.dialogInhabIsOpen = true;
        },
        eConfirmForm(isValidForm) {
            if (!isValidForm)
                this.mGDShowAction(["Operación Incorrecta", `Corrija el celular`], "error");
            else {
                this.dialogPhoneIsOpen = false;
                this.smsConfirmations = [`¿Está seguro de actualizar el celular del cliente
                    ${this.dataClient.full_name}?`];
                this.dialogConfirmationIsOpen = true;
            }
        },
        eCloseFormPhone() {
            this.indexUser = -1;
            this.dataClient = new Client;
            this.$refs.dialogphone.resetForm();
            this.dialogPhoneIsOpen = false;
        },
        eConfirmFormInhab(isValidForm) {
            if (!isValidForm)
                this.mGDShowAction(["Operación Incorrecta", `Corrija el motivo de ban`], "error");
            else {
                this.dialogInhabIsOpen = false;
                this.smsConfirmations = [`¿Está seguro de inhabilitar al cliente: 
                    ${this.dataInhab.full_name}?`];
                this.dialogConfirmationIsOpen = true;
            }
        },
        eCloseFormInhab() {
            this.indexUser = -1;
            this.dataInhab = new Client;
            this.$refs.dialoginhab.resetForm();
            this.dialogInhabIsOpen = false;
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategoryEmployee() === 'a') this.getClients(1, "");
            else this.$router.replace('/admin/home');
        } else this.$router.replace('/login-admin');
    }
}
</script>