<template>
    <v-dialog
        persistent
        :value="value"
        width="400"
    >
        <v-card class="pa-0 ce-pu-background">
            <div
                class="cd-pu-background d-flex justify-center pa-2">
                <h1 class="pr-app-text--space pr-app-title">ERROR</h1>
            </div>
            <div class="d-flex justify-center pt-2">
                <v-icon x-large class="cd-pu-color animation-icon-rotate" color="#d82323">mdi-close-circle</v-icon>
            </div>
            <div class="d-block pa-2">
                <h1
                    class="pr-app-subtitle-dark text-center"
                    v-for="(error, index) in smsErrors" :key="index"
                >
                    {{error}}
                </h1>
            </div>
            <div v-if="actionErrorIsActivated" class="text-center pb-2">
                <v-btn
                    small
                    :ripple="false"
                    outlined
                    class="cd-pu-color"
                    @click="$emit('click')"
                >
                    ACEPTAR
                </v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: {
        value: Boolean,
        smsErrors: Array,
        actionErrorIsActivated: Boolean
    }
}
</script>
