<template>
    <div>
        <div class="categories__header pa-2">
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    v-if="!isMobile"
                    :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                    :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                    class="mr-2"
                    @click="$emit('clickmini')"
                />
                <h1 class="pr-app-title">
                    VENTAS ANULADAS
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="categories__header-search">
                <PrMSelectComp
                    class="text-field__header mr-1"
                    :items="optionsTypeSale"
                    itemValue="value"
                    itemText="text"
                    background=""
                    v-model="valueTypeSale"
                    @change="eChangeTypeSale"
                />
                <PrCBtnToolTipHeaderComp
                    icon="mdi-database"
                    smsToolTip="Volver a la lista"
                    @click="tab = 0"
                />
            </div>
        </div>
        <PrMLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="d-flex justify-center flex-wrap pa-2">
                    <div class="pr-products__search">
                        <div class="pr-products__search-block">
                            <PrMTextFieldComp
                                label="Fecha Inicio"
                                v-model="dateOne"
                                background=""
                                type="date"
                                :clearable="false"
                                :hideDetails="true"
                                @change="eChangeDateOne"
                            />
                        </div>
                        <div class="pr-products__search-block">
                            <PrMTextFieldComp
                                label="Fecha Fin"
                                v-model="dateTwo"
                                background=""
                                type="date"
                                :clearable="false"
                                :hideDetails="true"
                                @change="eChangeDateTwo"
                            />
                        </div>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="pa-2 pt-1 pb-0">
                    <PrCFillInformationComp
                        title="Moneda"
                        subtitle="Bolivianos"
                    />
                    <PrCFillInformationComp
                        title="Cantidad Ventas"
                        :subtitle="`${total}`"
                    />
                    <PrCFillInformationComp
                        title="Total Ventas"
                        :subtitle="totalSale.toFixed(2)"
                    />
                    <PrCFillInformationComp
                        title="Total Costo"
                        :subtitle="totalCost.toFixed(2)"
                    />
                </div>
                <v-divider></v-divider>
                <PrCLoaderDataComp
                    v-if="dialogLoaderDataIsOpen"
                    class="mt-2"
                />
                <PrMWithoutInfoCardsComp
                    v-if="!dialogLoaderDataIsOpen && sales.length===0 && isMobile"
                    class="ma-2"
                />
                <PrOTableSalesComp
                    v-if="!dialogLoaderDataIsOpen && !isMobile"
                    :sales="sales"
                    :isClient="true"
                    @clickinfo="eShowInfoSale"
                    @clickdetail="eShowDetailSale"
                />
                <div
                    v-if="!dialogLoaderDataIsOpen && isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardSaleComp
                        class="ma-2"
                        :isClient="true"
                        v-for="sale in sales"
                        :key="sale.id"
                        :sale="sale"
                        @clickinfo="eShowInfoSale(sale)"
                        @clickdetail="eShowDetailSale(sale)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="isMobile && !dialogLoaderDataIsOpen && sales.length > 0"
                ></v-divider>
                <PrSPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderDataIsOpen && sales.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                    @clickpage="eSearchForPage"
                />
            </v-tab-item>
            <v-tab-item>
                <PrOInfoSaleComp
                    :sale="sale"
                />
                <PrOTableDetailSaleComp
                    v-if="!isMobile"
                    :detail="sale.product_sale"
                    :categorieUser="categorieUser"
                    @clickproduct="eInfoProduct"
                    @clickkeys="eShowKeysProductSale"
                />
                <div
                    v-if="isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardDetailSaleComp
                        class="ma-2"
                        v-for="product in sale.product_sale"
                        :key="product.id"
                        :categorieUser="categorieUser"
                        :product="product"
                        @clickproduct="eInfoProduct(product)"
                        @clickkeys="eShowKeysProductSale(product)"
                    />
                </div>
            </v-tab-item>
        </v-tabs-items>
        <PrSDialogDetailReportSaleComp
            :value="dialogDetailReportIsOpen"
            :sale="sale"
            @clickclose="dialogDetailReportIsOpen = false"
        />
        <PrSDialogDetailProductComp
            :value="dialogDetailIsOpen"
            :product="product"
            :categorieUser="categorieUser"
            @clickclose="dialogDetailIsOpen = false"
        />
        <PrSDialogKeysProdutSaleComp
            :value="dialogKeysProductSale"
            :loaderDataKeyIsOpen="loaderDataKeyIsOpen"
            :codes="codes"
            :sale="sale"
            :isMobile="isMobile"
            @clickclose="dialogKeysProductSale = false"
            @clickwhatsapp="eOpenWhatsapp()"
        />
        <PrCSmsErrorComp
            :smsErrors="smsErrors"
            :value="dialogErrorIsOpen"
            :actionErrorIsActivated="actionErrorIsActivated"
            @click="eCloseError()"
        />
        <PrCSmsActionComp
            :smsActions="smsActions"
            :value="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMWithoutInfoCardsComp,
    PrMTextFieldComp,
    PrMSelectComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCFillInformationComp,
    PrCLoaderDataComp,
    PrCSmsErrorComp,
    PrCSmsActionComp
} from "../../components/components_private/cells";
import {
    PrOTableSalesComp,
    PrOCardSaleComp,
    PrOInfoSaleComp,
    PrOTableDetailSaleComp,
    PrOCardDetailSaleComp
} from "../../components/components_private/organisms";
import {
    PrSPaginationComp,
    PrSDialogDetailReportSaleComp,
    PrSDialogDetailProductComp,
    PrSDialogKeysProdutSaleComp
} from "../../components/components_private/species";
import {
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin
} from "../../mixins";
import {
    Sale,
    DigitalSale
} from "../../models";
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin
    ],
    components: {
        PrCBtnToolTipHeaderComp,
        PrMLineHeaderComp,
        PrCFillInformationComp,
        PrCLoaderDataComp,
        PrMWithoutInfoCardsComp,
        PrMTextFieldComp,
        PrOTableSalesComp,
        PrOCardSaleComp,
        PrSPaginationComp,
        PrSDialogDetailReportSaleComp,
        PrSDialogDetailProductComp,
        PrOInfoSaleComp,
        PrOTableDetailSaleComp,
        PrOCardDetailSaleComp,
        PrMSelectComp,
        PrCSmsErrorComp,
        PrCSmsActionComp,
        PrSDialogKeysProdutSaleComp
    },
    data: () => ({
        tab: 0,
        dateOne: "",
        dateTwo: "",
        optionsTypeSale: [
            { value: "t", text: "Todos" },
            { value: "s", text: "Tienda" },
            { value: "e", text: "Página Web" },
            { value: "p", text: "Personal" }
        ],
        valueTypeSale: "t",
        sales: [],
        totalSale: 0,
        totalCost: 0,
        sale: {
            product_sale: []
        },
        dialogDetailReportIsOpen: false,
        product: {},
        dialogDetailIsOpen: false,
        codes: [],
        dialogKeysProductSale: false,
        loaderDataKeyIsOpen: false,
        categorieUser: ""
    }),
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        async getSales(page, dateOne, dateTwo, typeSale) {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Sale.disabledSales(page, dateOne, dateTwo, typeSale);
                this.sales = response.data;
                this.currentPage = response.current_page;
                this.lastPage = response.last_page;
                this.total = response.total;
                this.totalSale = response.total_sale;
                this.totalCost = response.total_cost;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getSales(this.currentPage, this.dateOne, this.dateTwo, this.valueTypeSale);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mGDSearchForPage(page, this.mRIsInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getSales(this.currentPage, this.dateOne, this.dateTwo, this.valueTypeSale);
                }, time);
            }
        },
        eShowInfoSale(sale) {
            this.sale = sale;
            this.dialogDetailReportIsOpen = true;
        },
        eShowDetailSale(sale) {
            this.sale = sale;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        },
        eInfoProduct(product) {
            this.product = product.product;
            this.dialogDetailIsOpen = true;
        },
        eChangeDateOne() {
            if (this.dateTwo === "" || this.dateOne === "")
                this.mGDShowAction(["Opereción incorrecta", "Seleccione las fechas porfavor"], "error");
            else {
                if (this.dateOne > this.dateTwo)
                    this.dateTwo = this.dateOne;
                this.getSales(1, this.dateOne, this.dateTwo, this.valueTypeSale);
            }
        },
        eChangeDateTwo() {
            if (this.dateTwo === "" || this.dateOne === "")
                this.mGDShowAction(["Opereción incorrecta", "Seleccione las fechas porfavor"], "error");
            else {
                if (this.dateOne > this.dateTwo)
                    this.dateOne = this.dateTwo;
                this.getSales(1, this.dateOne, this.dateTwo, this.valueTypeSale);
            }
        },
        eChangeTypeSale(typeSale) {
            this.tab = 0;
            this.getSales(1, this.dateOne, this.dateTwo, typeSale);
        },
        async getKeysProductSale(id, idProductSale) {
            try {
                const response = await DigitalSale.keysProductSale(id, idProductSale);
                this.codes = response;
                this.loaderDataKeyIsOpen = false;
            } catch(error) {
                this.dialogKeysProductSale = false;
                this.processErrorServer(error);
            }
        },
        eShowKeysProductSale(product) {
            this.loaderDataKeyIsOpen = true;
            this.dialogKeysProductSale = true;
            this.getKeysProductSale(this.sale.id, product.id);
        },
        eOpenWhatsapp() {
            window.open(`https://api.whatsapp.com/send?phone=${this.sale.client.phone}`);
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            this.categorieUser = this.mUserCategoryEmployee();
            this.dateOne = this.dateTwo = this.mDateSystem(false);
            if (this.mUserCategoryEmployee() === 'a')
                this.getSales(1, "", "", "t");
            else this.$router.replace('/admin/home');
        } else this.$router.replace('/login-admin');
    }
}
</script>