<template>
    <v-dialog
        persistent
        :value="value"
        width="500"
    >
        <v-card class="pa-0 ma-0">
            <div class="d-flex align-center pa-2 pr-cp-background">
                <h1 class="pr-app-title-dark">Seleccionar Cliente</h1>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    x-small
                    depressed
                    color="red"
                    @click="eCloseDialog()"
                >
                    <v-icon
                        class="pr-cd-color"
                    >mdi-close</v-icon>
                </v-btn>
            </div>
            <v-tabs-items
                style="background:transparent !important"
                v-model="tab"
                :touchless="true"
            >
                <v-tab-item>
                    <div class="pa-2">
                        <div class="d-flex">
                            <PrOSearchHeaderComp
                                label="Buscar Cliente"
                                v-model="search"
                                @entersearch="eValidSearch()"
                            />
                            <PrCBtnToolTipHeaderComp
                                icon="mdi-plus"
                                class="ml-1"
                                smsToolTip="Registrar cliente"
                                @click="tab = 1"
                            />
                        </div>
                        <v-card
                            width="100%"
                            class="rounded-0 pr-cp-background mt-2"
                            elevation="0"
                            height="10"
                        ></v-card>
                        <v-card
                            elevation="0"
                            width="100%"
                            min-height="200"
                        >
                            <PrCLoaderDataComp
                                v-if="dialogLoaderDataIsOpen"
                                class="mt-2"
                            />
                            <PrMWithoutInfoCardsComp
                                v-if="!dialogLoaderDataIsOpen && clients.length===0"
                                :title="title"
                                class="mt-2"
                            />
                            <div v-if="!dialogLoaderDataIsOpen">
                                <div
                                    v-for="client in clients"
                                    :key="client.id"
                                    class="mt-2 animation-opacity"
                                >
                                    <div v-if="client.user.enabled === 1">
                                        <v-hover
                                            v-slot="{ hover }"
                                        >
                                            <v-card
                                                :color="!hover ? 'transparent' : 'rgba(0,0,0,0.05)'"
                                                class="pa-1"
                                                :ripple="false"
                                                width="100%"
                                                @click="$emit('clickclient', client)"
                                            >
                                                <text-highlight
                                                    class="pr-app-title"
                                                    :queries="searchFilter"
                                                >
                                                    {{client.full_name}}
                                                </text-highlight>
                                                <text-highlight
                                                    class="pr-app-title"
                                                    :queries="searchFilter"
                                                >
                                                    {{client.phone}}
                                                </text-highlight>
                                                <h1 class="pr-app-subtitle">{{client.email}}</h1>
                                            </v-card>
                                        </v-hover>
                                    </div>
                                    <div v-else>
                                        <v-card
                                            color="rgba(255, 123, 123, 0.4)"
                                            class="pa-1 d-flex"
                                            :ripple="false"
                                            width="100%"
                                        >
                                            <div>
                                                <text-highlight
                                                    class="pr-app-title"
                                                    :queries="searchFilter"
                                                >
                                                    {{client.full_name}}
                                                </text-highlight>
                                                <text-highlight
                                                    class="pr-app-title"
                                                    :queries="searchFilter"
                                                >
                                                    {{client.phone}}
                                                </text-highlight>
                                                <h1 class="pr-app-subtitle">{{client.email}}</h1>
                                            </div>
                                            <v-spacer></v-spacer>
                                            <v-menu
                                                transition="slide-x-transition"
                                                bottom
                                                right
                                                offset-y
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                        depressed
                                                        v-bind="attrs"
                                                        v-on="on"
                                                        icon
                                                        small
                                                        :ripple="false"
                                                    >
                                                        <PrMIconComp
                                                            icon="mdi-account-cancel"
                                                            classIcon="pr-cp-color"
                                                            :small="false"
                                                        />
                                                    </v-btn>
                                                </template>
                                                <v-card class="pa-2">
                                                    <h1 class="pr-app-title">MOTIVO BAN</h1>
                                                    <h1 class="pr-app-subtitle">
                                                        {{client.banned}}
                                                    </h1>
                                                </v-card>
                                            </v-menu>
                                        </v-card>
                                    </div>
                                </div>
                            </div>
                        </v-card>
                    </div>
                </v-tab-item>
                <v-tab-item>
                    <div class="pa-2">
                        <PrMBtnNormalComp
                            @click="eBackListClient()"
                        >VOLVER</PrMBtnNormalComp>
                        <PrOFormClientComp
                            class="mt-4"
                            ref="formclientcomp"
                            :dataClient="dataClient"
                            :dataUser="dataUser"
                            @clickform="eValidForm"
                        />
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    PrMWithoutInfoCardsComp,
    PrMBtnNormalComp,
    PrMIconComp
} from "../molecules";
import {
    PrCLoaderDataComp,
    PrCBtnToolTipHeaderComp
} from "../cells";
import {
    PrOSearchHeaderComp,
    PrOFormClientComp
} from "../organisms";
import {
    Client,
    User
} from "../../../models";
import TextHighlight from 'vue-text-highlight';
import {
    rulesMixin
} from "../../../mixins";
export default {
    mixins: [rulesMixin],
    props: {
        value: Boolean
    },
    components: {
        PrOSearchHeaderComp,
        PrMWithoutInfoCardsComp,
        PrCLoaderDataComp,
        PrCBtnToolTipHeaderComp,
        PrOFormClientComp,
        PrMBtnNormalComp,
        TextHighlight,
        PrMIconComp
    },
    data: () => ({
        search: "",
        clients: [],
        dialogLoaderDataIsOpen: false,
        title: "Sin Datos",
        tab: 0,
        dataClient: new Client,
        dataUser: new User
    }),
    computed: {
        searchFilter: function() {
            if (this.mRItIsEmpty(this.search)) return "";
            return this.search;
        }
    },
    methods: {
        async eValidSearch() {
            if (this.mRItIsEmpty(this.search)) this.search = "";
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Client.searchClients(this.search);
                this.clients = response;
                this.title = "Sin Datos";
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.$emit('errorserver', error);
                this.title = "Error de Internet, revise su internet y vuelva a intentarlo";
                this.dialogLoaderDataIsOpen = false;
            }
        },
        eBackListClient() {
            this.tab = 0;
            this.dataClient = new Client;
            this.dataUser = new User;
            this.$refs.formclientcomp.resetForm();
        },
        resetData() {
            this.search = "";
            this.clients = [];
            if (this.tab !== 0)
                this.eBackListClient();
        },
        eValidForm(isValidForm) {
            const parameters = {
                "client": this.dataClient,
                "user": this.dataUser,
                "isValidForm": isValidForm
            }
            this.$emit('clickform', parameters);
        },
        eCloseDialog() {
            this.resetData();
            this.$emit('clickclose');
        }
    }
}
</script>