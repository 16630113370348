<template>
    <div>
        <div class="categories__header pa-2">
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    v-if="!isMobile"
                    :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                    :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                    class="mr-2"
                    @click="$emit('clickmini')"
                />
                <h1 class="pr-app-title">
                    {{`VENTAS PENDIENTES (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="categories__header-search">
                <PrOSearchHeaderComp
                    class="mr-1"
                    label="Buscar Cliente"
                    v-model="search"
                    @entersearch="eSearchClient()"
                />
                <PrCBtnToolTipHeaderComp
                    icon="mdi-database"
                    smsToolTip="Volver a la lista"
                    @click="tab = 0"
                />
            </div>
        </div>
        <PrMLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="d-flex justify-center flex-wrap pa-2">
                    <div class="pr-products__search">
                        <div class="pr-products__search-block">
                            <PrMTextFieldComp
                                label="Fecha Inicio"
                                v-model="dateOne"
                                background=""
                                type="date"
                                :clearable="false"
                                :hideDetails="true"
                                @change="eChangeDateOne"
                            />
                        </div>
                        <div class="pr-products__search-block">
                            <PrMTextFieldComp
                                label="Fecha Fin"
                                v-model="dateTwo"
                                background=""
                                type="date"
                                :clearable="false"
                                :hideDetails="true"
                                @change="eChangeDateTwo"
                            />
                        </div>
                    </div>
                </div>
                <v-divider></v-divider>
                <PrCLoaderDataComp
                    v-if="dialogLoaderDataIsOpen"
                    class="mt-2"
                />
                <PrMWithoutInfoCardsComp
                    v-if="!dialogLoaderDataIsOpen && sales.length===0 && isMobile"
                    class="ma-2"
                />
                <PrOTableSalesComp
                    v-if="!dialogLoaderDataIsOpen && !isMobile"
                    :isInfo="true"
                    :sales="sales"
                    :search="searchFilter"
                    @clickinfo="eShowInfoSale"
                    @clickkeys="eGetKeysSale"
                />
                <div
                    v-if="!dialogLoaderDataIsOpen && isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardSaleComp
                        class="ma-2"
                        :isInfo="true"
                        :search="searchFilter"
                        v-for="sale in sales"
                        :key="sale.id"
                        :sale="sale"
                        @clickinfo="eShowInfoSale(sale)"
                        @clickkeys="eGetKeysSale(sale)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="isMobile && !dialogLoaderDataIsOpen && sales.length > 0"
                ></v-divider>
                <PrSPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderDataIsOpen && sales.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                    @clickpage="eSearchForPage"
                />
            </v-tab-item>
            <v-tab-item>
                <PrOInfoSaleComp
                    :sale="sale"
                />
                <PrCLoaderDataComp
                    v-if="dialogLoaderDataCodeIsOpen"
                    class="mt-2"
                />
                <PrMWithoutInfoCardsComp
                    v-if="!dialogLoaderDataCodeIsOpen && codes.length===0 && isMobile"
                    class="ma-2"
                />
                <v-form
                    v-model="valid"
                    lazy-validation
                    ref="formdigitals"
                >
                    <PrOTableDetailSaleCodeComp
                        v-if="!dialogLoaderDataCodeIsOpen && !isMobile"
                        :codes="codes"
                        @clickproduct="eInfoProduct"
                    />
                    <div
                        v-if="!dialogLoaderDataCodeIsOpen && isMobile"
                        class="d-flex justify-center flex-wrap"
                    >
                        <PrOCardDetailSaleCodeComp
                            class="ma-2"
                            v-for="code in codes"
                            :key="code.id"
                            :code="code"
                            @clickproduct="eInfoProduct(product)"
                        />
                    </div>
                    <div
                        v-if="codes.length > 0"
                        class="d-flex justify-center pb-3"
                    >
                        <PrMBtnNormalComp
                            @click="eValidCodes()"
                        >REGISTRAR</PrMBtnNormalComp>
                    </div>
                </v-form>
            </v-tab-item>
        </v-tabs-items>
        <PrSDialogDetailReportSaleComp
            :value="dialogDetailReportIsOpen"
            :sale="sale"
            @clickclose="dialogDetailReportIsOpen = false"
        />
        <PrSDialogDetailProductComp
            :value="dialogDetailIsOpen"
            :product="product"
            :categorieUser="categorieUser"
            @clickclose="dialogDetailIsOpen = false"
        />
        <PrSDialogCodesDbComp
            :value="dialogCodesUpdateIsOpen"
            :codes="codesDb"
            :sale="sale"
            :isMobile="isMobile"
            @clickclose="dialogCodesUpdateIsOpen = false"
            @clickwhatsapp="eOpenWhatsappClient()"
        />
        <PrOGeneralDialogsComp
            :dialogErrorIsOpen="dialogErrorIsOpen"
            :smsErrors="smsErrors"
            :actionErrorIsActivated="actionErrorIsActivated"
            @clickerror="eCloseError()"
            :dialogConfirmationIsOpen="dialogConfirmationIsOpen"
            :smsConfirmations="smsConfirmations"
            @clickyes="eProcessOperation()"
            @clickno="dialogConfirmationIsOpen = false"
            :smsActions="smsActions"
            :frameAction="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
            :dialogOperationIsOpen="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMTextFieldComp,
    PrMWithoutInfoCardsComp,
    PrMBtnNormalComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCLoaderDataComp,
} from "../../components/components_private/cells";
import {
    PrOGeneralDialogsComp,
    PrOTableSalesComp,
    PrOCardSaleComp,
    PrOInfoSaleComp,
    PrOSearchHeaderComp,
    PrOTableDetailSaleCodeComp,
    PrOCardDetailSaleCodeComp
} from "../../components/components_private/organisms";
import {
    PrSPaginationComp,
    PrSDialogDetailReportSaleComp,
    PrSDialogDetailProductComp,
    PrSDialogCodesDbComp
} from "../../components/components_private/species";
import {
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin,
    cryptographyMixin
} from "../../mixins";
import { Sale, DigitalSale } from '../../models';
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin,
        cryptographyMixin
    ],
    components: {
        PrMBtnNormalComp,
        PrMLineHeaderComp,
        PrMTextFieldComp,
        PrMWithoutInfoCardsComp,
        PrSPaginationComp,
        PrSDialogDetailReportSaleComp,
        PrSDialogDetailProductComp,
        PrCBtnToolTipHeaderComp,
        PrCLoaderDataComp,
        PrOGeneralDialogsComp,
        PrOTableSalesComp,
        PrOCardSaleComp,
        PrOInfoSaleComp,
        PrOSearchHeaderComp,
        PrOTableDetailSaleCodeComp,
        PrSDialogCodesDbComp,
        PrOCardDetailSaleCodeComp
    },
    data: () => ({
        tab: 0,
        search: "",
        dateOne: "",
        dateTwo: "",
        sales: [],
        dialogDetailReportIsOpen: false,
        sale: {},
        product: {},
        dialogDetailIsOpen: false,
        dialogLoaderDataCodeIsOpen: false,
        codes: [],
        valid: true,
        codesDb: [],
        dialogCodesUpdateIsOpen: false,
        categorieUser: ""
    }),
    computed: {
        searchFilter: function() {
            if (this.mRItIsEmpty(this.search)) return "";
            return this.search;
        }
    },
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        async getSalesWithPendingCodes(page, dateOne, dateTwo, search) {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Sale.getSalesWithPendingCodes(page, dateOne, dateTwo, search);
                this.sales = response.data;
                this.currentPage = response.current_page;
                this.lastPage = response.last_page;
                this.total = response.total;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eSearchClient() {
            this.tab = 0;
            if (this.mRItIsEmpty(this.search)) this.search = "";
            this.getSalesWithPendingCodes(1, this.dateOne, this.dateTwo, this.search);
        },
        eChangeDateOne() {
            if (this.dateTwo === "" || this.dateOne === "")
                this.mGDShowAction(["Opereción incorrecta", "Seleccione las fechas porfavor"], "error");
            else {
                if (this.dateOne > this.dateTwo)
                    this.dateTwo = this.dateOne;
                this.getSalesWithPendingCodes(1, this.dateOne, this.dateTwo, this.search);
            }
        },
        eChangeDateTwo() {
            if (this.dateTwo === "" || this.dateOne === "")
                this.mGDShowAction(["Opereción incorrecta", "Seleccione las fechas porfavor"], "error");
            else {
                if (this.dateOne > this.dateTwo)
                    this.dateOne = this.dateTwo;
                this.getSalesWithPendingCodes(1, this.dateOne, this.dateTwo, this.search);
            }
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getSalesWithPendingCodes(this.currentPage, this.dateOne, this.dateTwo, this.search);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mGDSearchForPage(page, this.mRIsInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getSalesWithPendingCodes(page, this.dateOne, this.dateTwo, this.search);
                }, time);
            }
        },
        eShowInfoSale(sale) {
            this.sale = sale;
            this.dialogDetailReportIsOpen = true;
        },
        async getCodesSale() {
            this.codes = [];
            this.dialogLoaderDataCodeIsOpen = true;
            try {
                const response = await DigitalSale.getCodePendingSale(this.sale.id);
                response.forEach(element => {
                    element.code = null;
                    element.expiration = null;
                    element.description = null;
                    this.codes.push(element);
                });
                this.dialogLoaderDataCodeIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eGetKeysSale(sale) {
            this.sale = sale;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
                this.getCodesSale();
            }, time);
        },
        eInfoProduct(code) {
            this.product = code.product_sale.product;
            this.dialogDetailIsOpen = true;
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        eValidCodes() {
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                if (!this.$refs.formdigitals.validate()) this.mGDShowError();
                else {
                    this.smsConfirmations = [
                        `¿Está seguro(a) de registrar los códigos pendientes
                        de la venta de: ${this.sale.client.full_name}?`
                    ];
                    this.dialogConfirmationIsOpen = true;
                }
            }, time);
        },
        generateDetailCodes() {
            let detail = [];
            this.codes.forEach(element => {
                const code = {
                    'id': element.id,
                    'code': this.mCryGenerateCodeEncrypt(element.code),
                    'description': element.description,
                    'expiration': element.expiration
                }
                detail.push(code);
            });
            return detail;
        },
        async updateCodesPending() {
            const parameters = {
                'id_client': this.sale.client.id,
                'detail': this.generateDetailCodes()
            }
            try {
                const response = await DigitalSale.updateCodesPending(parameters);
                this.codesDb = response;
                const index = this.sales.indexOf(this.sale);
                this.sales.splice(index, 1);
                this.mGDShowAction(["Operación Exitosa", "Se envió los códigos"]);
                this.dialogOperationIsOpen = false;
                this.tab = 0;
                this.dialogCodesUpdateIsOpen = true;
            } catch (error) {
                this.processErrorServer(error);
            }
        },
        eProcessOperation() {
            this.dialogConfirmationIsOpen = false;
            this.dialogOperationIsOpen = true;
            this.updateCodesPending();
        },
        eOpenWhatsappClient() {
            if (this.sale.client.phone == null)
                this.mGDShowAction(["Operación Cancelada", "El cliente no tiene número registrado"], "error");
            else {
                const phone = this.sale.client.phone;
                window.open(`https://api.whatsapp.com/send?phone=${phone}`);
            }
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            this.categorieUser = this.mUserCategoryEmployee();
            if (this.mUserCategoryEmployee() === 'a')
                this.getSalesWithPendingCodes(1, "", "", "");
            else this.$router.replace('/admin/home');
        } else this.$router.replace('/login-admin');
    }
}
</script>