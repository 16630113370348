<template>
    <div class="o-menu-public-pc">
        <div class="o-menu-public-pc__list">
            <router-link
                to="/"
                class="mr-4 d-flex align-center"
                style="text-decoration:none"
            >
                <v-img
                    src="/imgs_public/logos/logo_o.svg"
                    width="30px"
                    max-width="30px"
                ></v-img>
                <h1 class="pu-app-title-dark ml-2">GATONET</h1>
            </router-link>
            <div class="d-flex align-center">
                <router-link
                    class="o-menu-public-pc__items mr-3"
                    v-for="(option, index) in menu"
                    :key="index"
                    :to="option.route"
                >
                    <v-icon class="cp-pu-color">{{option.icon}}</v-icon>
                    <h1 class="pu-text-menu-pc ml-1">{{option.title}}</h1>
                    <div class="o-menu-public-pc__items-line"></div>
                </router-link>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <v-card
                    height="32px"
                    width="32px"
                    class="mr-2"
                    elevation="0"
                    :ripple="false"
                    color="black"
                    style="overflow:hidden"
                    @click="eChangeTypeCoin()"
                >
                    <v-img
                        width="100%"
                        height="100%"
                        :src="!isDollar
                            ? '/imgs_public/logos/bolivia.png'
                            : '/imgs_public/logos/eeuu.jpg'
                        "
                    ></v-img>
                </v-card>
                <div class="d-flex align-center">
                    <CMenuOptionsComp
                        :options="eGetOptions()"
                        :isLogin="isLogin"
                        icon="mdi-account"
                        @eventlogin="$emit('eventlogin')"
                    />
                    <h1
                        v-if="isLogin"
                        class="pu-text-menu-pc ml-2"
                    >
                        {{getEmailClient()}}
                    </h1>
                    <router-link style="text-decoration:none" to="/cart">
                        <div
                            class="o-menu-public-pc__cart ml-2"
                        >
                            <v-icon class="cp-pu-color">mdi-cart</v-icon>
                            <h1 class="text-center">
                                {{cart.length}}
                            </h1>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    CMenuOptionsComp
} from "../../cells";
import { mapMutations, mapState } from "vuex";
export default {
    components: {
        CMenuOptionsComp
    },
    props: {
        menu: Array,
        isLogin: Boolean
    },
    computed: {
        ...mapState("cartClientStore", ["cart"]),
        ...mapState("settingStore", ["isDollar"])
    },
    methods: {
        ...mapMutations("settingStore", ["changeDollar"]),
        getEmailClient() {
            const client = JSON.parse(localStorage.getItem("client_gatonet"));
            return client.client.email;
        },
        eGetOptions() {
            if (this.isLogin)
                return [
                    {
                        title: "Mi Cuenta",
                        icon: "mdi-account",
                        route: "/account"
                    }
                ]
            else return [
                {
                    title: "Iniciar Sesión",
                    icon: "mdi-account",
                    route: "/login-client"
                }, {
                    title: "Registrarme",
                    icon: "mdi-account-edit",
                    route: "/register"
                }
            ]
        },
        eChangeTypeCoin() {
            this.changeDollar();
        }
    }
}
</script>