<template>
    <div class="pr-o-card-categorie animation-opacity">
        <div class="pr-o-card-categorie__header d-flex justify-center">
            <text-highlight
                class="pr-app-title-dark"
                :queries="search"
            >
                {{user.client.full_name}}
            </text-highlight>
        </div>
        <div class="pa-2 pb-1">
            <PrCFillInformationComp
                title="Correo"
                :subtitle="user.client.email"
            />
            <PrCFillInformationComp
                title="Celular"
                :subtitle="user.client.phone != null ? user.client.phone : 'S/N'"
            />
            <PrCFillInformationComp
                title="Verificación"
                :subtitle="user.client.verify_email == null ? 'No' : 'Si'"
            >
                <div
                    :class="user.client.verify_email != null
                        ? 'categories__enabled ml-1'
                        : 'categories__disabled ml-1'"
                    style="margin-top:-2px"
                ></div>
            </PrCFillInformationComp>
            <PrCFillInformationComp
                title="Usuario"
                :subtitle="user.username"
            />
            <PrCFillInformationComp
                title="Estado"
                :subtitle="user.enabled === 1 ? 'Habilitado' : 'Inhabilitado'"
            >
                <div
                    :class="user.enabled === 1
                        ? 'categories__enabled ml-1'
                        : 'categories__disabled ml-1'"
                    style="margin-top:-2px"
                ></div>
            </PrCFillInformationComp>
            <PrCFillInformationComp
                title="Motivo Ban"
                :subtitle="user.client.banned != null && user.enabled === 0
                    ? user.client.banned : 'S/B'"
            />
        </div>
        <v-divider class="mb-1"></v-divider>
        <div class="pa-2 pt-1 d-flex justify-center flex-wrap">
            <PrCBtnCardComp
                icon="mdi-cellphone-basic"
                @click="$emit('clickedit')"
            />
            <PrCBtnCardComp
                icon="mdi-whatsapp"
                @click="$emit('clickwhatsapp')"
            />
            <PrCBtnCardComp
                :icon="user.enabled === 1
                    ? 'mdi-eye-off'
                    : 'mdi-eye'"
                @click="$emit('clickstate')"
            />
        </div>
    </div>
</template>
<script>
import TextHighlight from 'vue-text-highlight';
import {
    PrCFillInformationComp,
    PrCBtnCardComp
} from "../../cells";
export default {
    props: {
        user: Object,
        search: String
    },
    components: {
        TextHighlight,
        PrCFillInformationComp,
        PrCBtnCardComp
    }
}
</script>