<template>
    <div class="pr-s-complet-detail-sale">
        <div class="pr-s-complet-detail-sale__header pr-cp-background pa-1">
            <h1 class="pr-app-title-dark text-center">DETALLE</h1>
        </div>
        <div class="pr-s-complet-detail-sale__body">
            <PrODetailSaleComp
                :detailSale="detailSale"
                @clickedit="e => this.$emit('clickedit', e)"
                @clickdelete="eDeleteProduct"
                @clickdetail="e => this.$emit('clickdetail', e)"
            />
        </div>
        <div class="pr-s-complet-detail-sale__footer">
            <v-card
                width="100%"
                class="rounded-0 pr-cp-background"
                elevation="0"
                height="10"
            ></v-card>
            <div class="pa-2">
                <div class="pr-4">
                    <div class="d-flex">
                        <h1 class="pr-app-title">TOTAL:</h1>
                        <v-spacer></v-spacer>
                        <h1 class="pr-app-title text-right">
                            {{mSaleTotalLocalStore(detailSale).toFixed(2)}}
                        </h1>
                    </div>
                    <div class="d-flex">
                        <h1 class="pr-app-title">Cancelado:</h1>
                        <v-spacer></v-spacer>
                        <PrMTextFieldHeaderComp
                            style="width:120px; max-width:120px;"
                            v-model="cancelled"
                            :reverse="true"
                            :clearable="false"
                            :rules="[
                                value => !this.mRItIsEmpty(value),
                                value => this.mRIsNumeric(value)
                            ]"
                        />
                    </div>
                    <div class="d-flex">
                        <h1 class="pr-app-title">Cambio:</h1>
                        <v-spacer></v-spacer>
                        <h1 class="pr-app-title text-right">
                            {{changeMoney.toFixed(2)}}
                        </h1>
                    </div>
                </div>
                <v-divider class="mb-2"></v-divider>
                <div class="d-flex justify-center">
                    <PrCBtnDetailComp
                        icon="mdi-window-maximize"
                        colorBtn="blue"
                        class="mr-1"
                        smsToolTip="Limpiar Detalle"
                        @click="eClearDetail()"
                    />
                    <PrCBtnDetailComp
                        icon="mdi-cash"
                        colorBtn="green"
                        class="mr-1"
                        smsToolTip="Registrar venta"
                        @click="eGenerateProductSale(true)"
                    />
                    <PrCBtnDetailComp
                        icon="mdi-account-cash"
                        colorBtn="green"
                        class="mr-1"
                        smsToolTip="Registrar venta + cliente"
                        @click="eGenerateProductSale(false)"
                    />
                    <PrCBtnDetailComp
                        v-if="mUserCategoryEmployee() === 'a'"
                        :icon="isStore ? 'mdi-warehouse' : 'mdi-account-edit'"
                        colorBtn="orange"
                        :smsToolTip="isStore
                            ? 'Cambiar a venta personal'
                            : 'Cambiar a venta por tienda'"
                        @click="$emit('clickstore')"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    PrMTextFieldHeaderComp
} from "../molecules";
import {
    PrCBtnDetailComp
} from "../cells";
import {
    PrODetailSaleComp
} from "../organisms";
import {
    saleMixin,
    rulesMixin,
    userMixin
} from "../../../mixins";
export default {
    mixins: [
        saleMixin,
        rulesMixin,
        userMixin
    ],
    components: {
        PrODetailSaleComp,
        PrCBtnDetailComp,
        PrMTextFieldHeaderComp
    },
    props: {
        detailSale: Array,
        isStore: Boolean
    },
    data: () => ({
        cancelled: "0"
    }),
    computed: {
        changeMoney: function() {
            if (this.mRItIsEmpty(this.cancelled) || !this.mRIsNumeric(this.cancelled)) return 0;
            const change = this.mSaleTotalLocalStore(this.detailSale);
            return parseFloat(this.cancelled) - change;
        }
    },
    methods: {
        eClearDetail() {
            this.cancelled = "0";
            this.$emit('cleardetail');
        },
        eDeleteProduct(product) {
            const index = this.detailSale.indexOf(product);
            this.detailSale.splice(index, 1);
        },
        eGenerateProductSale(isDirect) {
            const productSale = [];
            this.detailSale.forEach(product => {
                productSale.push({
                    'product_id': product.product.id,
                    'quantity': product.quantity,
                    'price': product.price,
                    'id_reserve': product.id_reserve,
                    'balance_hold': product.balance_hold
                });
            });
            const parameters = {
                'isDirect': isDirect,
                'productSale': productSale
            }
            this.$emit('clicksale', parameters);
        }
    }
}
</script>