<template>
    <div>
        <div class="categories__header pa-2">
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    v-if="!isMobile"
                    :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                    :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                    class="mr-2"
                    @click="$emit('clickmini')"
                />
                <h1 class="pr-app-title">
                    {{`SALDOS (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="categories__header-search">
                <PrMTextFieldHeaderComp
                    backgroundColor="white"
                    class="mr-1"
                    label="Buscar"
                    v-model="search"
                />
                <PrCBtnToolTipHeaderComp
                    icon="mdi-plus"
                    class="mr-1"
                    smsToolTip="Registrar nuevo campo"
                    @click="eOpenForm()"
                />
                <PrCBtnToolTipHeaderComp
                    icon="mdi-database"
                    smsToolTip="Volver a la lista"
                    @click="eBackList()"
                />
            </div>
        </div>
        <PrMLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <PrCLoaderDataComp
                    v-if="dialogLoaderDataIsOpen"
                    class="mt-2"
                />
                <PrMWithoutInfoCardsComp
                    v-if="!dialogLoaderDataIsOpen && reserves.length===0 && isMobile"
                    class="ma-2"
                />
                <PrOTableBalanceComp
                    v-if="!dialogLoaderDataIsOpen && !isMobile"
                    :search="searchFilter"
                    :reserves="listFilter"
                    @clickedit="eOpenEditReserve"
                    @clickaddsald="eOpenAddSald"
                />
                <div
                    v-if="!dialogLoaderDataIsOpen && isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardBalanceComp
                        class="ma-2"
                        v-for="reserve in listFilter"
                        :key="reserve.id"
                        :reserve="reserve"
                        :search="searchFilter"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2">
                    <PrESkeletonFormComp
                        :title="numberOperation === 0
                            ? 'REGISTRAR'
                            : numberOperation === 1
                            ? 'EDITAR' : 'AÑADIR'"
                    >
                        <PrOFormBalanceComp
                            ref="formbalancecomp"
                            :dataBalance="dataBalance"
                            :isMobile="isMobile"
                            :numberOperation="numberOperation"
                            @clickform="eConfirmForm"
                        />
                    </PrESkeletonFormComp>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <PrOGeneralDialogsComp
            :dialogErrorIsOpen="dialogErrorIsOpen"
            :smsErrors="smsErrors"
            :actionErrorIsActivated="actionErrorIsActivated"
            @clickerror="eCloseError()"
            :dialogConfirmationIsOpen="dialogConfirmationIsOpen"
            :smsConfirmations="smsConfirmations"
            @clickyes="eProcessOperation()"
            @clickno="dialogConfirmationIsOpen = false"
            :smsActions="smsActions"
            :frameAction="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
            :dialogOperationIsOpen="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMTextFieldHeaderComp,
    PrMWithoutInfoCardsComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCLoaderDataComp
} from "../../components/components_private/cells";
import {
    PrOTableBalanceComp,
    PrOCardBalanceComp,
    PrOFormBalanceComp,
    PrOGeneralDialogsComp
} from "../../components/components_private/organisms";
import {
    PrESkeletonFormComp
} from "../../components/components_private/species";
import {
    Reserve
} from "../../models";
import {
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin
} from "../../mixins";
export default {
    mixins: [
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin
    ],
    props: {
        mini: Boolean
    },
    components: {
        PrMLineHeaderComp,
        PrMTextFieldHeaderComp,
        PrCBtnToolTipHeaderComp,
        PrCLoaderDataComp,
        PrMWithoutInfoCardsComp,
        PrOTableBalanceComp,
        PrOCardBalanceComp,
        PrOFormBalanceComp,
        PrESkeletonFormComp,
        PrOGeneralDialogsComp
    },
    data: () => ({
        search: "",
        reserves: [],
        tab: 0,
        indexBalance: -1,
        dataBalance: new Reserve,
        numberOperation: 0
    }),
    computed: {
        searchFilter: function() {
            if (this.mRItIsEmpty(this.search)) return "";
            return this.search;
        },
        listFilter: function() {
            if (this.mRItIsEmpty(this.search)) return this.reserves;
            return this.reserves.filter(reserve => {
                return reserve.name.toLowerCase().indexOf(this.search) !== -1
            });
        }
    },
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        async getBalances() {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Reserve.getBalances();
                this.reserves = response;
                this.total = response.length;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eBackList() {
            if (this.tab !== 0) {
                this.indexBalance = -1;
                this.dataBalance = new Reserve;
                this.$refs.formbalancecomp.resetForm();
                this.tab = 0;
            }
        },
        eOpenForm() {
            this.numberOperation = 0;
            if (this.tab !== 0) {
                this.indexBalance = -1;
                this.dataBalance = new Reserve;
                this.$refs.formbalancecomp.resetForm();
            }
            this.tab = 1;
        },
        eConfirmForm(isValidForm) {
            if (isValidForm) {
                if (this.numberOperation === 0)
                    this.smsConfirmations = [`¿Está seguro(a) de registrar: ${this.dataBalance.name}?`];
                if (this.numberOperation === 1)
                    this.smsConfirmations = [`¿Está seguro(a) de editar: ${this.dataBalance.name}?`];
                if (this.numberOperation === 2)
                    this.smsConfirmations = [`¿Está seguro(a) de añadir saldo a: ${this.dataBalance.name}?`];
                this.dialogConfirmationIsOpen = true;
            } else this.mGDShowError();
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        async addBalance() {
            try {
                const response = await Reserve.addBalance(this.dataBalance);
                this.reserves.unshift(response);
                this.total += 1;
                this.eBackList();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se agregó el saldo"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async editBalance() {
            try {
                const response = await Reserve.editBalance(this.dataBalance);
                Object.assign(this.reserves[this.indexBalance], response);
                this.eBackList();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se edití el saldo"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async addSaldBalance() {
            try {
                const response = await Reserve.addSaldBalance(this.dataBalance);
                Object.assign(this.reserves[this.indexBalance], response);
                this.eBackList();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se añadió el saldo"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eProcessOperation() {
            this.dialogConfirmationIsOpen = false;
            this.dialogOperationIsOpen = true;
            switch(this.numberOperation) {
                case 0:
                    this.addBalance();
                    break;
                case 1:
                    this.editBalance();
                    break;
                case 2:
                    this.addSaldBalance();
                    break;
            }
        },
        eOpenEditReserve(reserve) {
            this.indexBalance = this.reserves.indexOf(reserve);
            this.numberOperation = 1;
            this.dataBalance = Object.assign({}, reserve);
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        },
        eOpenAddSald(reserve) {
            this.indexBalance = this.reserves.indexOf(reserve);
            this.numberOperation = 2;
            this.dataBalance = new Reserve;
            this.dataBalance.id = reserve.id;
            this.dataBalance.name = reserve.name;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategoryEmployee() === 'a') this.getBalances();
            else this.$router.replace('/admin/home');
        } else this.$router.replace('/login-admin');
    }
}
</script>