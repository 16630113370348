import Vue from 'vue'
import VueRouter from 'vue-router'
import { animationScrollMixin } from '../mixins'

Vue.use(VueRouter)

import {
  Home,
  Register,
  VerifyEMail,
  LoginClient,
  Account,
  ProductsClient,
  Cart,
  ProductsDigital,
  About,
  ErrorEmail,
  ProductPublic,
  Giftcards,
  Games,
  Streaming,
  TermsUse,
  Pines
} from "../views/public";

import {
  HomePrivate,
  Categories,
  Users,
  Products,
  ProductInventory,
  Clients,
  Sales,
  DigitalInventory,
  ExpirationCodes,
  ReportSale,
  HistoryClientSale,
  MySales,
  DisabledSale,
  CloseBoxDay,
  CloseBoxGeneral,
  SaleMobile,
  Settings,
  RecommendationsA,
  Publication,
  SendSales,
  SendCodes,
  ExpiredCode,
  Rankings,
  OpenBoxDay,
  Balances,
  InventorySald,
  ReportDaySale
} from "../views/admin";

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/admin",
      redirect: "/admin/sales"
    },
    {
      path: "/",
      component: () => import("../views/public/AppPublic"),
      children: [
        {
          path: "/",
          name: "home",
          component: Home
        }, {
          path: "/register",
          name: "register",
          component: Register
        }, {
          path: "/verify-email",
          name: "verify-email",
          component: VerifyEMail
        }, {
          path: "/login-client",
          name: "login-client",
          component: LoginClient
        }, {
          path: "/account",
          name: "account",
          component: Account
        }, {
          path: "/products",
          name: "products-client",
          component: ProductsClient
        }, {
          path: "/cart",
          name: "cart",
          component: Cart
        }, {
          path: "/digital-products",
          name: "digital-products",
          component: ProductsDigital
        }, {
          path: "/about",
          name: "about",
          component: About
        }, {
          path: "/error-email",
          name: "error-email",
          component: ErrorEmail
        }, {
          path: "/product",
          name: "product",
          component: ProductPublic
        }, {
          path: "/gift-cards",
          name: "gift-cards",
          component: Giftcards
        }, {
          path: "/games",
          name: "games",
          component: Games
        }, {
          path: "/streaming",
          name: "streaming",
          component: Streaming
        }, {
          path: "/terms-use",
          name: "terms-use",
          component: TermsUse
        }, {
          path: "/pines",
          name: "pines",
          component: Pines
        }
      ]
    },
    {
      path: "/admin",
      component: () => import("../views/admin/AppPrivate"),
      children: [
        {
          path: "/admin/home",
          name: "home-private",
          component: HomePrivate
        }, {
          path: "/admin/categories",
          name: "categories",
          component: Categories
        }, {
          path: "/admin/users",
          name: "users",
          component: Users
        }, {
          path: "/admin/products",
          name: "products",
          component: Products
        }, {
          path: "/admin/inventory",
          name: "inventory",
          component: ProductInventory
        }, {
          path: "/admin/clients",
          name: "clients",
          component: Clients
        }, {
          path: "/admin/sales",
          name: "sales",
          component: Sales
        }, {
          path: "/admin/digital-inventory",
          name: "digital-inventory",
          component: DigitalInventory
        }, {
          path: "/admin/expiration-codes",
          name: "expiration-codes",
          component: ExpirationCodes
        }, {
          path: "/admin/report-sale",
          name: "report-sale",
          component: ReportSale
        }, {
          path: "/admin/history-client",
          name: "history-client",
          component: HistoryClientSale
        }, {
          path: "/admin/my-sales",
          name: "my-sales",
          component: MySales
        }, {
          path: "/admin/disabled-sales",
          name: "disabled-sales",
          component: DisabledSale
        }, {
          path: "/admin/close-box-day",
          name: "close-box-day",
          component: CloseBoxDay
        }, {
          path: "/admin/close-box",
          name: "close-box",
          component: CloseBoxGeneral
        }, {
          path: "/admin/sale-mobile",
          name: "sale-mobile",
          component: SaleMobile
        }, {
          path: "/admin/settings",
          name: "settings",
          component: Settings
        }, {
          path: "/admin/recommendations",
          name: "recommendations",
          component: RecommendationsA
        }, {
          path: "/admin/publications",
          name: "publications",
          component: Publication
        }, {
          path: "/admin/send-sales",
          name: "send-sales",
          component: SendSales
        }, {
          path: "/admin/send-codes",
          name: "send-codes",
          component: SendCodes
        }, {
          path: "/admin/expired-codes",
          name: "expired-codes",
          component: ExpiredCode
        }, {
          path: "/admin/rankings",
          name: "rankings",
          component: Rankings
        }, {
          path: "/admin/open-box",
          name: "open-box",
          component: OpenBoxDay
        }, {
          path: "/admin/balances",
          name: "balances",
          component: Balances
        }, {
          path: "/admin/inventory-sald",
          name: "inventory-sald",
          component: InventorySald
        }, {
          path: "/admin/report-day-sale",
          name: "report-day-sale",
          component: ReportDaySale
        }
      ]
    },
    {
      path: '/login-admin',
      component: () => import('../views/login_admin/Login')
    }
  ]
});
router.beforeEach((to, from, next) => {
  const time = animationScrollMixin.methods.mAnimationScrollTime();
  animationScrollMixin.methods.mAnimationNative(time);
  setTimeout(() => {
    next();
  }, time);
})
export default router;