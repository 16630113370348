var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pr-o-table-categories animation-opacity"},[_c('table',{staticClass:"pr-o-table-categories__table"},[_vm._m(0),_c('tbody',[_vm._l((_vm.codes),function(code,index){return _c('tr',{key:code.id},[_c('td',[_c('h1',{staticClass:"pr-app-subtitle"},[_vm._v(" "+_vm._s((index + 1))+" ")])]),_c('td',[_c('h1',{staticClass:"pr-app-subtitle"},[_vm._v(" "+_vm._s(code.product_sale.product.product)+" ")])]),_c('td',[_c('PrMTextFieldComp',{staticStyle:{"width":"200px"},attrs:{"singleLine":true,"counter":50,"rules":[
                            value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                            value => (`${value}`).length <= 50 || _vm.smsExecedCounter(50)
                        ]},model:{value:(code.code),callback:function ($$v) {_vm.$set(code, "code", $$v)},expression:"code.code"}})],1),_c('td',[_c('PrMTextAreaComp',{staticStyle:{"width":"200px"},attrs:{"counter":200,"rules":[
                            value => (`${value}`).length <= 200 || _vm.smsExecedCounter(50)
                        ]},model:{value:(code.description),callback:function ($$v) {_vm.$set(code, "description", $$v)},expression:"code.description"}})],1),_c('td',[_c('PrMTextFieldComp',{staticStyle:{"width":"200px"},attrs:{"type":"date","rules":[
                            value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty
                        ]},model:{value:(code.expiration),callback:function ($$v) {_vm.$set(code, "expiration", $$v)},expression:"code.expiration"}})],1),_c('td',[_c('div',{staticClass:"d-flex align-center justify-center flex-wrap"},[_c('PrCBtnToolTipTableComp',{attrs:{"icon":"mdi-format-list-bulleted","smsToolTip":`Ver información: ${code.product_sale.product.product}`},on:{"click":function($event){return _vm.$emit('clickproduct', code)}}})],1)])])}),(_vm.codes.length === 0)?_c('tr',[_vm._m(1)]):_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_c('h1',{staticClass:"pr-app-title-dark text-center"},[_vm._v("N°")])]),_c('th',[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Producto")])]),_c('th',{attrs:{"width":"200px"}},[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Código")])]),_c('th',{attrs:{"width":"200px"}},[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Descripción")])]),_c('th',{attrs:{"width":"200px"}},[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Fecha Expiración")])]),_c('th',[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Acción")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"6"}},[_c('h1',{staticClass:"pr-app-subtitle text-center"},[_vm._v("Sin Productos")])])
}]

export { render, staticRenderFns }