<template>
    <div>
        <div class="categories__header pa-2">
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    v-if="!isMobile"
                    :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                    :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                    class="mr-2"
                    @click="$emit('clickmini')"
                />
                <h1 class="pr-app-title">
                    {{`VENTAS (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="categories__header-search">
                <PrMSelectComp
                    :items="optionsTypeCoin"
                    itemValue="value"
                    :hideDetails="true"
                    classSelect="text-field__header"
                    itemText="text"
                    v-model="typeCoin"
                    class="mr-1"
                    @change="eChangeTypeCoin"
                />
                <PrCBtnToolTipHeaderComp
                    class="mr-1"
                    icon="mdi-file-pdf-box"
                    smsToolTip="Exportar PDF"
                    @click="eExportReport()"
                />
                <PrCBtnToolTipHeaderComp
                    icon="mdi-database"
                    smsToolTip="Volver a la lista"
                    @click="tab = 0"
                />
            </div>
        </div>
        <PrMLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="d-flex justify-center flex-wrap pa-2">
                    <div class="pr-products__search">
                        <div class="pr-products__search-block">
                            <PrMSelectComp
                                label="Con entrega"
                                v-model="valueDelivered"
                                :items="optionsDelivered"
                                itemValue="value"
                                background=""
                                itemText="text"
                                :hideDetails="true"
                                @change="eChangeDelivered"
                            />
                        </div>
                        <div class="pr-products__search-block">
                            <PrMSelectComp
                                label="Lugar Venta"
                                v-model="valueTypeSale"
                                :items="optionsTypeSale"
                                itemValue="value"
                                background=""
                                itemText="text"
                                :hideDetails="true"
                                @change="eChangeTypeSale"
                            />
                        </div>
                    </div>
                    <div
                        :class="isMobile ? 'pr-products__search mt-2' : 'pr-products__search'"
                    >
                        <div class="pr-products__search-block">
                            <PrMTextFieldComp
                                label="Fecha Inicio"
                                v-model="dateOne"
                                background=""
                                type="date"
                                :clearable="false"
                                :hideDetails="true"
                                @change="eChangeDateOne"
                            />
                        </div>
                        <div class="pr-products__search-block">
                            <PrMTextFieldComp
                                label="Fecha Fin"
                                v-model="dateTwo"
                                background=""
                                type="date"
                                :clearable="false"
                                :hideDetails="true"
                                @change="eChangeDateTwo"
                            />
                        </div>
                    </div>
                </div>
                <v-divider></v-divider>
                <div class="pa-2 pt-1 pb-0">
                    <PrCFillInformationComp
                        title="Cantidad Ventas"
                        :subtitle="`${total}`"
                    />
                    <PrCFillInformationComp
                        title="Moneda"
                        subtitle="Bolivianos"
                    />
                    <PrCFillInformationComp
                        title="Total Venta"
                        :subtitle="totalSale.toFixed(2)"
                    />
                    <PrCFillInformationComp
                        title="Total Costo"
                        :subtitle="totalCost.toFixed(2)"
                    />
                    <PrCFillInformationComp
                        title="Estimación Ganancia"
                        :subtitle="(totalSale - totalCost).toFixed(2)"
                    />
                </div>
                <v-divider></v-divider>
                <PrCLoaderDataComp
                    v-if="dialogLoaderDataIsOpen"
                    class="mt-2"
                />
                <PrMWithoutInfoCardsComp
                    v-if="!dialogLoaderDataIsOpen && sales.length===0 && isMobile"
                    class="ma-2"
                />
                <PrOTableSalesComp
                    v-if="!dialogLoaderDataIsOpen && !isMobile"
                    :sales="sales"
                    @clickinfo="eShowInfoSale"
                    @clickdetail="eShowDetailSale"
                    @clickstate="eConfirmCancelSale"
                />
                <div
                    v-if="!dialogLoaderDataIsOpen && isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardSaleComp
                        class="ma-2"
                        v-for="sale in sales"
                        :key="sale.id"
                        :sale="sale"
                        @clickinfo="eShowInfoSale(sale)"
                        @clickdetail="eShowDetailSale(sale)"
                        @clickstate="eConfirmCancelSale(sale)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="isMobile && !dialogLoaderDataIsOpen && sales.length > 0"
                ></v-divider>
                <PrSPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderDataIsOpen && sales.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                    @clickpage="eSearchForPage"
                />
            </v-tab-item>
            <v-tab-item>
                <PrOInfoSaleComp
                    :sale="sale"
                />
                <PrOTableDetailSaleComp
                    v-if="!isMobile"
                    :detail="sale.product_sale"
                    :categorieUser="categorieUser"
                    @clickproduct="eInfoProduct"
                    @clickkeys="eShowKeysProductSale"
                />
                <div
                    v-if="isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardDetailSaleComp
                        class="ma-2"
                        v-for="product in sale.product_sale"
                        :key="product.id"
                        :categorieUser="categorieUser"
                        :product="product"
                        @clickproduct="eInfoProduct(product)"
                        @clickkeys="eShowKeysProductSale(product)"
                    />
                </div>
            </v-tab-item>
        </v-tabs-items>
        <PrSDialogDetailReportSaleComp
            :value="dialogDetailReportIsOpen"
            :sale="sale"
            @clickclose="dialogDetailReportIsOpen = false"
        />
        <PrSDialogDetailProductComp
            :value="dialogDetailIsOpen"
            :product="product"
            :categorieUser="categorieUser"
            @clickclose="dialogDetailIsOpen = false"
        />
        <PrSDialogKeysProdutSaleComp
            :value="dialogKeysProductSale"
            :loaderDataKeyIsOpen="loaderDataKeyIsOpen"
            :codes="codes"
            :sale="sale"
            :isMobile="isMobile"
            @clickclose="dialogKeysProductSale = false"
            @clickwhatsapp="eOpenWhatsapp()"
        />
        <PrOGeneralDialogsComp
            :dialogErrorIsOpen="dialogErrorIsOpen"
            :smsErrors="smsErrors"
            :actionErrorIsActivated="actionErrorIsActivated"
            @clickerror="eCloseError()"
            :dialogConfirmationIsOpen="dialogConfirmationIsOpen"
            :smsConfirmations="smsConfirmations"
            @clickyes="eProcessOperation()"
            @clickno="dialogConfirmationIsOpen = false"
            :smsActions="smsActions"
            :frameAction="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
            :dialogOperationIsOpen="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMSelectComp,
    PrMTextFieldComp,
    PrMWithoutInfoCardsComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCLoaderDataComp,
    PrCFillInformationComp
} from "../../components/components_private/cells";
import {
    PrOTableSalesComp,
    PrOTableDetailSaleComp,
    PrOInfoSaleComp,
    PrOGeneralDialogsComp,
    PrOCardSaleComp,
    PrOCardDetailSaleComp
} from "../../components/components_private/organisms";
import {
    PrSDialogDetailReportSaleComp,
    PrSDialogDetailProductComp,
    PrSPaginationComp,
    PrSDialogKeysProdutSaleComp
} from "../../components/components_private/species";
import {
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin,
    saleMixin
} from "../../mixins";
import {
  DigitalSale,
    Sale
} from "../../models";
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin,
        saleMixin
    ],
    components: {
        PrMLineHeaderComp,
        PrCBtnToolTipHeaderComp,
        PrMSelectComp,
        PrMTextFieldComp,
        PrCLoaderDataComp,
        PrMWithoutInfoCardsComp,
        PrOTableSalesComp,
        PrSDialogDetailReportSaleComp,
        PrOTableDetailSaleComp,
        PrSDialogDetailProductComp,
        PrOInfoSaleComp,
        PrOGeneralDialogsComp,
        PrCFillInformationComp,
        PrOCardSaleComp,
        PrSPaginationComp,
        PrOCardDetailSaleComp,
        PrSDialogKeysProdutSaleComp
    },
    data: () => ({
        tab: 0,
        optionsDelivered: [
            { value: 2, text: "Todos" },
            { value: 1, text: "Entregado" },
            { value: 0, text: "No Entregado" }
        ],
        valueDelivered: 2,
        optionsTypeSale: [
            { value: "t", text: "Todos" },
            { value: "s", text: "Tienda" },
            { value: "e", text: "Página Web" },
            { value: "p", text: "Personal" }
        ],
        valueTypeSale: "t",
        dateOne: "",
        dateTwo: "",
        sales: [],
        dialogDetailReportIsOpen: false,
        sale: {
            product_sale: []
        },
        product: {},
        dialogDetailIsOpen: false,
        idSale: -1,
        indexSale: -1,
        totalSaleCancel: 0,
        totalSale: 0,
        totalCost: 0,
        codes: [],
        dialogKeysProductSale: false,
        loaderDataKeyIsOpen: false,
        optionsTypeCoin: [
            { value: "a", text: "Todos" },
            { value: "e", text: "Efectivo" },
            { value: "q", text: "Pago Qr" },
            { value: "t", text: "Tigo Money" }
        ],
        typeCoin: "a",
        categorieUser: ""
    }),
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        async getSales(page, dateOne, dateTwo, delivered, typeSale, typeCoin) {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Sale.reportSale(page, dateOne, dateTwo, delivered, typeSale, typeCoin);
                this.sales = response.data;
                this.total = response.total;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.totalSale = response.total_sale;
                this.totalCost = response.total_cost;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eChangeDateOne() {
            if (this.dateTwo === "" || this.dateOne === "")
                this.mGDShowAction(["Opereción incorrecta", "Seleccione las fechas porfavor"], "error");
            else {
                if (this.dateOne > this.dateTwo)
                    this.dateTwo = this.dateOne;
                this.getSales(1, this.dateOne, this.dateTwo, this.valueDelivered, this.valueTypeSale, this.typeCoin);
            }
        },
        eChangeDateTwo() {
            if (this.dateTwo === "" || this.dateOne === "")
                this.mGDShowAction(["Opereción incorrecta", "Seleccione las fechas porfavor"], "error");
            else {
                if (this.dateOne > this.dateTwo)
                    this.dateOne = this.dateTwo;
                this.getSales(1, this.dateOne, this.dateTwo, this.valueDelivered, this.valueTypeSale, this.typeCoin);
            }
        },
        eShowInfoSale(sale) {
            this.sale = sale;
            this.dialogDetailReportIsOpen = true;
        },
        eShowDetailSale(sale) {
            this.sale = sale;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        },
        eInfoProduct(product) {
            this.product = product.product;
            this.dialogDetailIsOpen = true;
        },
        async cancelSale() {
            try {
                await Sale.cancelSale({id: this.idSale});
                this.sales.splice(this.indexSale, 1);
                this.totalSale -= this.totalSaleCancel;
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Opereción Realizada", "Se anuló la venta"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eProcessOperation() {
            this.dialogConfirmationIsOpen = false;
            this.dialogOperationIsOpen = true;
            this.cancelSale();
        },
        eConfirmCancelSale(sale) {
            this.totalSaleCancel = this.mSaleTotalDB(sale.product_sale);
            this.idSale = sale.id;
            this.indexSale = this.sales.indexOf(sale);
            this.smsConfirmations = [`¿Está seguro de anular la venta de:`,
                `Cliente: ${sale.client.full_name}`,
                `Total: ${this.totalSaleCancel.toFixed(2)} Bs.`,
                `Fecha: ${sale.hour} ${sale.date}`
            ];
            this.dialogConfirmationIsOpen = true;
        },
        eChangeDelivered(delivered) {
            this.getSales(1, this.dateOne, this.dateTwo, delivered, this.valueTypeSale, this.typeCoin);
        },
        eChangeTypeSale(typeSale) {
            this.getSales(1, this.dateOne, this.dateTwo, this.valueDelivered, typeSale, this.typeCoin);
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getSales(this.currentPage, this.dateOne, this.dateTwo, this.valueDelivered, this.valueTypeSale, this.typeCoin);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mGDSearchForPage(page, this.mRIsInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getSales(page, this.dateOne, this.dateTwo, this.valueDelivered, this.valueTypeSale, this.typeCoin);
                }, time);
            }
        },
        eChangeTypeCoin(typeCoin) {
            this.getSales(1, this.dateOne, this.dateTwo, this.valueDelivered, this.valueTypeSale, typeCoin);
        },
        async getKeysProductSale(id, idProductSale) {
            try {
                const response = await DigitalSale.keysProductSale(id, idProductSale);
                this.codes = response;
                this.loaderDataKeyIsOpen = false;
            } catch(error) {
                this.dialogKeysProductSale = false;
                this.processErrorServer(error);
            }
        },
        eShowKeysProductSale(product) {
            this.loaderDataKeyIsOpen = true;
            this.dialogKeysProductSale = true;
            this.getKeysProductSale(this.sale.id, product.id);
        },
        eOpenWhatsapp() {
            window.open(`https://api.whatsapp.com/send?phone=${this.sale.client.phone}`);
        },
        eExportReport() {
            Sale.reportPdf(this.dateOne, this.dateTwo, this.valueDelivered, this.valueTypeSale, this.typeCoin)
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            this.categorieUser = this.mUserCategoryEmployee();
            this.dateOne = this.dateTwo = this.mDateSystem(false);
            if (this.mUserCategoryEmployee() === 'a')
                this.getSales(1, "", "", 2, "t", "a");
            else this.$router.replace('/admin/home');
        } else this.$router.replace('/login-admin');
    }
}
</script>