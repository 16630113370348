<template>
    <div class="d-flex justify-center">
        <div class="c-image-home">
            <div class="d-flex justify-center mb-4 mt-1">
                <v-card
                    width="120"
                    height="120"
                    color="transparent"
                >
                    <v-img
                        src="/imgs_public/pc/error_email.png"
                        width="100%"
                        height="100%"
                    ></v-img>
                </v-card>
            </div>
            <div class="register__first-text">
                <div
                    v-for="(info, index) in dataInfo"
                    :key="index"
                    class="d-flex mb-3"
                >
                    <div class="o-thid-home__ornament mr-2"></div>
                    <h1 class="pu-app-subtitle-dark">
                        {{info}}
                    </h1>
                </div>
            </div>
            <MBtnNormalComp
                to="/verify-email"
            >VERIFICAR CORREO</MBtnNormalComp>
            <div class="space--block"></div>
        </div>
    </div>
</template>
<script>
import {
    MBtnNormalComp
} from "../../components/components_public/molecules";
export default {
    data: () => ({
        dataInfo: [
            `La ruta a la cual intenta acceder no es válida. Porfavor revise su correo y presiones click en el enlace.`,
            'Si no puede realizar la verificación de correo mediante el link, comuniquese con nosotros mediante nuestro número de contacto.',
            `Porfavor presione en el botón de abajo para volver a la página de verificación de correo.`
        ]
    }),
    components: {
        MBtnNormalComp
    }
}
</script>