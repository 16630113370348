<template>
    <div class="o-table-purchases animation-opacity">
        <table class="o-table-purchases__table">
            <thead>
                <tr>
                    <th>
                        <h1 class="pr-app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="pu-app-title-dark">Producto</h1>
                    </th>
                    <th>
                        <h1 class="pu-app-title-dark">Tipo</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Cantidad</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Precio</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Total</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Información</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Envío</h1>
                    </th>
                    <th width="100">
                        <h1 class="pr-app-title-dark">Acciones</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(product, index) in detail"
                    :key="product.id"
                >
                    <td>
                        <h1 class="pr-app-subtitle cp-pu-color text-center">
                            {{(index+1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle cp-pu-color text-right">
                            {{product.product.product}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle cp-pu-color text-right">
                            {{product.product.type === 'd' ? 'Digital' : 'Físico'}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle cp-pu-color text-right">
                            {{product.quantity}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle cp-pu-color text-right">
                            {{product.price_unity.toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle cp-pu-color text-right">
                            {{(product.quantity * product.price_unity).toFixed(2)}}
                        </h1>
                    </td>
                    <td>
                        <h1
                            class="pr-app-subtitle cp-pu-color"
                            style="white-space: pre-line;"
                        >
                            {{product.info != null
                                ? product.info
                                : 'S/I'}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle cp-pu-color text-right">
                            {{getTextSend(product)}}
                        </h1>
                    </td>
                    <td class="d-flex justify-center flex-wrap">
                        <CBtnToolTipTableComp
                            icon="mdi-format-list-bulleted-square"
                            smsToolTip="Detalle Producto"
                            @click="$emit('clickdetail', product)"
                        />
                        <CBtnToolTipTableComp
                            v-if="product.digitals.length > 0"
                            icon="mdi-gamepad-variant"
                            smsToolTip="Claves obtenidas"
                            @click="$emit('clickkeys', product)"
                        />
                    </td>
                </tr>
                <tr v-if="detail.length === 0">
                    <td colspan="9">
                        <h1 class="pr-app-subtitle text-center">Elija una compra</h1>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    CBtnToolTipTableComp
} from "../../cells";
export default {
    props: {
        detail: Array,
        delivered: Number
    },
    components: {
        CBtnToolTipTableComp
    },
    methods: {
        getTextSend(product) {
            if (product.product.type === "n" && this.delivered === 1) return "Entregado";
            for (let k = 0; k < product.digitals.length; k++) {
                if (product.digitals[k].this_slope === 1) return 'Pendiente';
            }
            return "Entregado";
        }
    }
}
</script>