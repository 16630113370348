<template>
    <div class="d-flex">
        <v-app-bar
            fixed
            class="o-menu-public-mobile"
            height="55"
        >
            <div class="d-flex align-center" style="width:100%">
                <router-link
                    class="d-flex align-center"
                    to="/"
                    style="text-decoration:none !important"
                >
                    <v-img
                        src="/imgs_public/logos/logo_o.svg"
                        width="30px"
                        class="mr-2"
                    ></v-img>
                    <h1 class="pu-app-title-dark">GATONET</h1>
                </router-link>
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <v-card
                        height="32px"
                        width="32px"
                        class="mr-2"
                        elevation="0"
                        :ripple="false"
                        color="black"
                        style="overflow:hidden"
                        @click="eChangeTypeCoin()"
                    >
                        <v-img
                            width="100%"
                            height="100%"
                            :src="!isDollar
                                ? '/imgs_public/logos/bolivia.png'
                                : '/imgs_public/logos/eeuu.jpg'
                            "
                        ></v-img>
                    </v-card>
                    <CMenuOptionsComp
                        :options="eGetOptions()"
                        :isLogin="isLogin"
                        icon="mdi-account"
                        @eventlogin="$emit('eventlogin')"
                    />
                    <router-link style="text-decoration:none" to="/cart">
                        <div
                            class="o-menu-public-pc__cart ml-2"
                        >
                            <v-icon class="cp-pu-color">mdi-cart</v-icon>
                            <h1 class="text-center">
                                {{cart.length}}
                            </h1>
                        </div>
                    </router-link>
                    <v-app-bar-nav-icon
                        @click="drawer=true"
                        class="cd-pu-color"
                    ></v-app-bar-nav-icon>
                </div>
            </div>
        </v-app-bar>
        <v-navigation-drawer
            v-model="drawer"
            fixed
            temporary
            width="360"
            class="cf-pu-background"
        >
            <v-toolbar class="o-menu-public-mobile__header">
                <div class="d-flex align-center">
                    <h1
                        v-if="isLogin"
                        class="pu-text-menu-pc ml-2"
                    >
                        {{getEmailClient()}}
                    </h1>
                    <h1 v-else class="pu-text-menu-pc">
                        GATONET
                    </h1>
                </div>
                <v-spacer></v-spacer>
                <v-icon @click="drawer=!drawer" color="white">
                    mdi-close-circle
                </v-icon>
            </v-toolbar>
            <v-list nav class="pb-0 pt-2 pb-1">
                <v-list-item-group color="primary">
                    <v-list-item
                        @click="drawer=!drawer"
                        to="/"
                    >
                        <v-list-item-icon class="mr-1">
                            <v-icon small class="cp-pu-color">mdi-home</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            <h1 class="pu-text-menu-pc">Inicio</h1>
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
            <v-list nav class="pb-0 pt-0">
                <div
                    v-for="(option, index) in menu"
                    :key="index"
                >
                    <v-list-item-group
                        color="primary"
                    >
                        <v-list-item
                            :to="option.route"
                            @click="drawer=!drawer"
                            class="mb-1"
                        >
                            <v-list-item-icon class="mr-1">
                                <v-icon small class="cp-pu-color">{{option.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                <h1 class="pu-text-menu-pc">{{option.title}}</h1>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </div>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
import {
    CMenuOptionsComp
} from "../../cells";
import { mapState, mapMutations } from "vuex";
export default {
    components: {
        CMenuOptionsComp
    },
    props: {
        menu: Array,
        isLogin: Boolean
    },
    data: () => ({
        drawer: false
    }),
    computed: {
        ...mapState("cartClientStore", ["cart"]),
        ...mapState("settingStore", ["isDollar"])
    },
    methods: {
        ...mapMutations("settingStore", ["changeDollar"]),
        getEmailClient() {
            const client = JSON.parse(localStorage.getItem("client_gatonet"));
            return client.client.email;
        },
        eGetOptions() {
            if (this.isLogin)
                return [
                    {
                        title: "Mi Cuenta",
                        icon: "mdi-account",
                        route: "/account"
                    }
                ]
            else return [
                {
                    title: "Iniciar Sesión",
                    icon: "mdi-account",
                    route: "/login-client"
                }, {
                    title: "Registrarme",
                    icon: "mdi-account-edit",
                    route: "/register"
                }
            ]
        },
        eChangeTypeCoin() {
            this.changeDollar();
        }
    }
}
</script>
