<template>
    <div class="pr-o-card-categorie animation-opacity">
        <div class="pr-o-card-categorie__header d-flex justify-center">
            <h1 class="pr-app-title-dark">
                {{digital.product.product}}
            </h1>
        </div>
        <div class="pa-2 pb-1">
            <PrCFillInformationComp
                title="Código"
                :subtitle="digital.this_slope === 1
                    ? 'Pendiente'
                    : mCryDecryptText(digital.code)"
            />
            <h1
                v-if="digital.this_slope === 0"
                class="pr-app-subtitle"
            >
                {{digital.description != null
                    ? digital.description
                    : "S/D"}}
            </h1>
            <PrCFillInformationComp
                v-if="digital.this_slope === 1"
                title="Expiración"
                subtitle="---"
            />
            <PrCFillInformationComp
                v-else
                title="Expiración"
                :subtitle="mDateFormat(digital.expiration)"
            >
                <div
                    :class="!digital.expired
                        ? 'categories__enabled ml-1'
                        : 'categories__disabled ml-1'"
                ></div>
            </PrCFillInformationComp>
            <PrCFillInformationComp
                title="Registrador"
                :subtitle="digital.user.person.full_name"
            />
            <PrCFillInformationComp
                v-if="digital.this_slope === 1"
                title="Estado"
                subtitle="---"
            />
            <PrCFillInformationComp
                v-else
                title="Estado"
                :subtitle="digital.enabled === 1 ? 'Habilitado'
                    : 'Inhabilitado'"
            >
                <div
                    :class="digital.enabled === 1
                        ? 'categories__enabled ml-1'
                        : 'categories__disabled ml-1'"
                    style="margin-top:0px"
                ></div>
            </PrCFillInformationComp>
        </div>
        <v-divider class="mb-1"></v-divider>
        <div class="pa-2 pt-1 d-flex justify-center flex-wrap">
            <PrCBtnCardComp
                icon="mdi-format-list-bulleted-square"
                @click="$emit('clickdetail')"
            />
            <PrCBtnCardComp
                v-if="digital.this_slope === 0"
                :icon="digital.enabled === 1
                    ? 'mdi-eye-off'
                    : 'mdi-eye'"
                @click="$emit('clickstate')"
            />
            <PrCBtnCardComp
                v-else
                icon="mdi-delete"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import {
    PrCFillInformationComp,
    PrCBtnCardComp
} from "../../cells";
import {
    datesMixin,
    cryptographyMixin
} from "../../../../mixins";
export default {
    mixins: [
        datesMixin,
        cryptographyMixin
    ],
    props: {
        digital: Object
    },
    components: {
        PrCFillInformationComp,
        PrCBtnCardComp
    }
}
</script>