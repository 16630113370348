<template>
    <div class="pr-o-card-categorie animation-opacity">
        <div class="pr-o-card-categorie__header d-flex justify-center">
            <text-highlight
                class="pr-app-title-dark"
                :queries="search"
            >
                {{reserve.name}}
            </text-highlight>
        </div>
        <div class="pa-2 pb-1">
            <PrCFillInformationComp
                title="Saldo"
                :subtitle="reserve.sald.toFixed(2)"
            >
                <div
                    :class="reserve.sald > 0
                        ? 'categories__enabled ml-1'
                        : 'categories__disabled ml-1'"
                ></div>
            </PrCFillInformationComp>
        </div>
        <v-divider class="mb-1"></v-divider>
        <div class="pa-2 pt-1 d-flex justify-center flex-wrap">
            <PrCBtnCardComp
                icon="mdi-pencil"
                @click="$emit('clickedit')"
            />
            <PrCBtnCardComp
                icon="mdi-plus"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import TextHighlight from 'vue-text-highlight';
import {
    PrCFillInformationComp,
    PrCBtnCardComp
} from "../../cells";
export default {
    props: {
        reserve: Object,
        search: String
    },
    components: {
        TextHighlight,
        PrCFillInformationComp,
        PrCBtnCardComp
    }
}
</script>