<template>
    <div class="d-flex align-center">
        <h1 :class="isDark ? 'pr-app-title-dark mr-1' : 'pr-app-title mr-1'">
            {{`${title}:`}}
        </h1>
        <text-highlight
            v-if="isSearch"
            :class="isDark ? 'pr-app-subtitle-dark mr-1' : 'pr-app-subtitle mr-1'"
            :queries="search"
        >
            {{subtitle}}
        </text-highlight>
        <h1
            v-else
            :class="isDark ? 'pr-app-subtitle-dark mr-1' : 'pr-app-subtitle'"
        >
            {{subtitle}}
        </h1>
        <slot></slot>
    </div>
</template>
<script>
import TextHighlight from 'vue-text-highlight';
export default {
    components: {
        TextHighlight
    },
    props: {
        title: String,
        search: String,
        subtitle: String,
        isDark: Boolean,
        isSearch: Boolean
    }
}
</script>