<template>
    <div class="pr-o-card-categorie animation-opacity">
        <div class="pr-o-card-categorie__header d-flex justify-center">
            <h1 class="pr-app-title-dark">
                {{(index + 1)}}
            </h1>
        </div>
        <div class="pa-2 pb-1">
            <PrMTextFieldComp
                v-model="code.code"
                label="Código"
                class="mt-2"
                :counter="50"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => (`${value}`).length <= 50 || smsExecedCounter(50)
                ]"
            />
            <PrMTextAreaComp
                v-model="code.description"
                :counter="200"
                label="Descripción"
                class="mt-2"
                :rules="[
                    value => (`${value}`).length <= 200 || smsExecedCounter(50)
                ]"
            />
            <PrMTextFieldComp
                v-model="code.expiration"
                type="date"
                label="Fecha Expiración"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty
                ]"
            />
        </div>
    </div>
</template>
<script>
import {
    PrMTextFieldComp,
    PrMTextAreaComp
} from "../../molecules";
import {
    PrCBtnToolTipTableComp
} from "../../cells";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [rulesMixin],
    components: {
        PrMTextFieldComp,
        PrMTextAreaComp,
        PrCBtnToolTipTableComp
    },
    props: {
        index: Number,
        code: Object
    }
}
</script>