<template>
    <v-dialog
        persistent
        :value="value"
        width="360"
    >
        <v-card class="pa-0 ma-0">
            <div class="d-flex align-center pa-2 pr-cp-background">
                <h1 class="pr-app-title-dark">Códigos en Espera</h1>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    x-small
                    depressed
                    color="red"
                    @click="$emit('clickclose')"
                >
                    <v-icon
                        class="pr-cd-color"
                    >mdi-close</v-icon>
                </v-btn>
            </div>
            <div class="pa-2">
                <v-form
                    v-model="valid"
                    lazy-validation
                    ref="formdigital"
                >
                    <PrMTextFieldComp
                        class="mt-2"
                        v-model="quantity"
                        label="Cantidad"
                        @click="eValidateForm()"
                        :rules="[
                            value => !this.mRItIsEmpty(value) || smsEmpty,
                            value => this.mRIsInteger(value) || smsNotInteger
                        ]"
                    />
                </v-form>
                <div class="text-center">
                    <PrMBtnNormalComp
                        @click="eValidateForm()"
                    >
                        GUARDAR
                    </PrMBtnNormalComp>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    PrMTextFieldComp,
    PrMBtnNormalComp
} from "../molecules";
import {
    rulesMixin
} from "../../../mixins";
export default {
    props: {
        value: Boolean
    },
    mixins: [
        rulesMixin
    ],
    components: {
        PrMTextFieldComp,
        PrMBtnNormalComp
    },
    data: () => ({
        quantity: null,
        valid: true
    }),
    methods: {
        eValidateForm() {
            const parameters = {
                validForm: this.$refs.formdigital.validate(),
                quantity: this.quantity
            }
            this.$emit('clickform', parameters);
        },
        resetForm() {
            this.quantity = null;
            this.$refs.formdigital.resetValidation();
        }
    }
}
</script>