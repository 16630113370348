<template>
    <div
        @click="$emit('clickaccion')"
        style="cursor:pointer"
    >
        <v-snackbar
            :value="value"
            right
            :bottom="true"
            :timeout="timeSuccessAction"
            :content-class="isError
                ? 'pr-c-sms-action ce-pu-background'
                : 'pr-c-sms-action cg-pu-background'"
        >
            <div class="d-block">
                <h1
                    class="pr-app-title-dark mt-1"
                    v-for="(text, index) in smsActions"
                    :key="index"
                >{{text}}</h1>
            </div>
        </v-snackbar>
    </div>
</template>
<script>
export default {
    props: {
        value: Boolean,
        smsActions: Array,
        isError: Boolean,
        timeSuccessAction: Number
    }
}
</script>
