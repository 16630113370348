<template>
    <div class="d-flex">
        <v-app-bar
            fixed
            class="pr-s-menu-mobile"
            height="40px"
            elevation="2"
        >
            <div class="d-flex align-center" style="width:100%">
                <div class="d-flex align-center">
                    <v-img
                        src="/imgs_public/logos/logo_green.svg"
                        :height="sizeLogo"
                        :max-height="sizeLogo"
                        :width="sizeLogo"
                        :max-width="sizeLogo"
                    ></v-img>
                    <h1 class="pr-app-title-dark ml-2">Gatonet</h1>
                </div>
                <v-spacer></v-spacer>
                <div class="d-flex align-center">
                    <div v-if="codes.length > 0" class="ml-2">
                        <v-menu
                            transition="slide-x-transition"
                            bottom
                            right
                            offset-y
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    depressed
                                    v-bind="attrs"
                                    v-on="on"
                                    icon
                                    small
                                    :ripple="false"
                                >
                                    <PrMIconComp
                                        icon="mdi-bell-plus"
                                        color="red"
                                        :small="false"
                                    />
                                </v-btn>
                            </template>
                            <v-card width="400">
                                <v-card
                                    elevation="0"
                                    class="rounded-0 pa-1 pr-cs-background d-flex align-center"
                                >
                                    <h1 class="pr-app-title-dark">
                                        {{codes.length == 1
                                            ? `Tiene 1 código a vencer`
                                            : `Tiene ${codes.length} códigos a vencer`
                                        }}
                                    </h1>
                                </v-card>
                                <v-card
                                    height="180px"
                                    class="pa-1"
                                    style="overflow: auto"
                                >
                                    <v-card
                                        v-for="code in codes"
                                        :key="code.id"
                                        class="pa-1 pt-0 mt-1"
                                    >
                                        <h1 class="pr-app-title">{{code.product_sale.product.product}}</h1>
                                        <h1 class="pr-app-subtitle">
                                            {{mDateFormat(code.expiration)}}
                                        </h1>
                                        <h1 class="pr-app-subtitle">
                                            {{code.product_sale.sale.client.full_name}}
                                        </h1>
                                    </v-card>
                                </v-card>
                            </v-card>
                        </v-menu>
                    </div>
                    <v-app-bar-nav-icon
                        class="pr-s-menu-mobile__menu"
                        @click="drawer=true"
                    ></v-app-bar-nav-icon>
                </div>
            </div>
        </v-app-bar>
        <v-navigation-drawer
            v-model="drawer"
            fixed
            temporary
            width="300"
            class="pr-s-menu-mobile__navegation"
        >
            <v-card
                class="cf-pu-background ma-0 rounded-0 d-flex justify-center align-center"
                elevation="0"
                min-height="40"
            >
                <v-img
                    src="/imgs_public/logos/logo_green.svg"
                    :height="sizeLogo"
                    :max-height="sizeLogo"
                    :width="sizeLogo"
                    :max-width="sizeLogo"
                ></v-img>
                <h1 class="ml-3 pr-app-title-dark">Gatonet</h1>
            </v-card>
            <v-list nav class="pb-0 pt-0 mt-2">
                <div
                    v-for="(option, index) in menu"
                    :key="index"
                >
                    <v-list-group
                        v-if="option.submenu.length>0"
                        :ripple="false"
                        color="white"
                        no-action
                    >
                        <template v-slot:activator>
                            <v-list-item
                                dense
                                :ripple="false"
                                class="ma-0 pl-0"
                            >
                                <v-icon class="pr-s-menu-pc__list-icon mr-2">{{option.icon}}</v-icon>
                                <h1 class="pr-app-subtitle-dark">{{option.title}}</h1>
                            </v-list-item>
                        </template>
                        <div
                            v-for="(child, subindex) in option.submenu"
                            :key="subindex"
                            class="pl-7 mb-3"
                        >
                            <v-list-item
                                :to="child.route"
                                dense
                                class="pr-s-menu-pc__list-option "
                                :ripple="false"
                                active-class="pr-s-menu-pc__list-option-activate"
                            >
                                <v-icon class="pr-s-menu-pc__list-icon mr-2">{{child.icon}}</v-icon>
                                <h1 class="pr-app-subtitle-dark">{{child.title}}</h1>
                            </v-list-item>
                        </div>
                    </v-list-group>
                    <v-list-item
                        v-else
                        dense
                        :to="option.route"
                        :ripple="false"
                        :class="(index+1)==menu.length
                            ? 'pr-s-menu-pc__list-option'
                            : 'pr-s-menu-pc__list-option mb-2'"
                        active-class="pr-s-menu-pc__list-option-activate"
                    >
                        <v-icon class="pr-s-menu-pc__list-icon mr-2">{{option.icon}}</v-icon>
                        <h1 class="pr-app-subtitle-dark">{{option.title}}</h1>
                    </v-list-item>
                </div>
                <v-list-item-group>
                    <v-list-item
                        class="mb-1"
                        :ripple="false"
                        @click="eCloseSesion()"
                    >
                        <v-list-item-icon class="mr-2">
                            <v-icon class="pr-s-menu-mobile__option-icon" color="#ffffffce">mdi-close</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            <h1 class="pr-app-subtitle-dark">Cerrar Sesión</h1>
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
        <PrCLoaderOperationComp
            smsLoaderOperation="Cerrando Sesión"
            :value="dialogLoaderOperation"
        />
    </div>
</template>
<script>
import {
    PrCLoaderOperationComp
} from "../cells";
import {
    PrMIconComp
} from "../molecules";
import {
    User
} from "../../../models";
import {
    datesMixin
} from "../../../mixins";
export default {
    mixins: [
        datesMixin
    ],
    components: {
        PrMIconComp,
        PrCLoaderOperationComp
    },
    props: {
        menu: Array,
        codes: Array
    },
    data: () => ({
        drawer: false,
        sizeLogo: "30",
        dialogLoaderOperation: false
    }),
    methods: {
        async eCloseSesion() {
            this.dialogOperationIsOpen = true;
            try {
                await User.logoutEmployee();
                this.dialogOperationIsOpen = false;
                localStorage.removeItem("ecomerce_gatonet");
                setTimeout(() => {
                    this.$router.replace('/login-admin');
                }, 150);
            } catch (error) {
                localStorage.removeItem("ecomerce_gatonet");
                this.$router.replace('/login-admin');
            }
        }
    }
}
</script>
