<template>
    <v-tabs
        vertical
        background-color="transparent"
        color="#11b108"
        v-model="tab"
    >
        <v-tab
            :ripple="false"
            style="text-transform:none !important;"
            active-class="c-menu-account__tab"
            v-for="(option, index) in options"
            :key="index"
            @click="$emit('clicktab', option.numberTab)"
        >
            <div class="d-flex align-center" style="width:100%">
                <v-icon class="mr-1 cd-pu-color">{{option.icon}}</v-icon>
                <h1 class="pu-app-title-dark">{{option.title}}</h1>
            </div>
        </v-tab>
    </v-tabs>
</template>
<script>
export default {
    props: {
        options: Array
    },
    data: () => ({
        tab: 0
    }),
    methods: {
        changeTab(value) {
            this.tab = value;
        }
    }
}
</script>