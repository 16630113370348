<template>
    <div class="animation-opacity d-flex align-center">
        <div class="d-flex align-center">
            <div class="mr-1">
                <OBtnCircleToolTipComp
                        :disabled="!condicionBack"
                        smsToolTip="Página Anterior"
                        @click="$emit('clickback')">
                        <v-icon color="white" small>mdi-chevron-left</v-icon>
                </OBtnCircleToolTipComp>
            </div>
            <OBtnCircleToolTipComp
                    :disabled="!condicionGo"
                    smsToolTip="Página Siguiente"
                    @click="$emit('clickgo')">
                    <v-icon color="white" small>mdi-chevron-right</v-icon>
            </OBtnCircleToolTipComp>
            <h1 class="pr-app-subtitle-dark ml-2">{{titlePagination}}</h1>
        </div>
        <v-spacer></v-spacer>
        <OSearchHeaderComp
            style="max-width:98px"
            label="Pag."
            :clearable="false"
            v-model="page"
            @entersearch="eEmitInput()"
        />
    </div>
</template>
<script>
import {
    OBtnCircleToolTipComp,
    OSearchHeaderComp
} from "../organisms";
export default {
    components: {
        OBtnCircleToolTipComp,
        OSearchHeaderComp
    },
    props: {
        titlePagination: String,
        condicionBack: Boolean,
        condicionGo: Boolean
    },
    data: () => ({
        page: ""
    }),
    methods: {
        eEmitInput() {
            this.$emit('clickpage', this.page);
        }
    }
}
</script>
