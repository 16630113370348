<template>
    <v-form
        v-model="valid"
        lazy-validation
        ref="formproduct"
    >
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Precio"
        >
            <PrMTextFieldComp
                v-model="dataProduct.price"
                :label="isMobile ? 'Precio' : ''"
                @click="eValidateForm()"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => mRIsNumeric(value) || smsNotNumeric
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Precio Costo"
        >
            <PrMTextFieldComp
                v-model="dataProduct.price_cost"
                :label="isMobile ? 'Precio Costo' : ''"
                @click="eValidateForm()"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => mRIsNumeric(value) || smsNotNumeric
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            v-if="dataProduct.type === 'n' || dataProduct.id_reserve > 0"
            :isMobile="isMobile"
            title="Añadir Cantidad"
        >
            <PrMTextFieldComp
                v-model="dataProduct.new_quantity"
                :label="isMobile ? 'Añadir Cantidad' : ''"
                @click="eValidateForm()"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => mRIsInteger(value) || smsNotInteger
                ]"
            />
        </PrCFillFormComp>
        <div class="d-flex justify-center mt-1">
            <PrMBtnNormalComp
                @click="eValidateForm()"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                GUARDAR
            </PrMBtnNormalComp>
        </div>
    </v-form>
</template>
<script>
import {
    PrMTextFieldComp,
    PrMBtnNormalComp,
    PrMSelectComp
} from "../../molecules";
import {
    PrCFillFormComp,
} from "../../cells";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        PrMTextFieldComp,
        PrCFillFormComp,
        PrMBtnNormalComp,
        PrMSelectComp
    },
    props: {
        dataProduct: Object,
        isMobile: Boolean,
        categories: Array,
        indexProduct: Number
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidateForm() {
            if (this.$refs.formproduct.validate())
                this.$emit('clickform', true);
            else this.$emit('clickform', false);
        },
        resetForm() {
            this.$refs.formproduct.resetValidation();
        }
    }
}
</script>