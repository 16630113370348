<template>
    <div
        class="pr-o-product-select mb-2"
    >
        <PrCImageTableComp
            :path="product.route_file"
        />
        <v-card
            class="ml-4 mr-2"
            elevation="0"
            width="140"
            min-width="140"
            color="transparent"
        >
            <PrCFillInformationComp
                title="Precio"
                :subtitle="product.price.toFixed(2)"
            />
            <PrCFillInformationComp
                title="Cantidad"
                :subtitle="`${product.quantity}`"
            >
                <div
                    :class="getClassInventory(product)"
                    style="margin-top:0px"
                ></div>
            </PrCFillInformationComp>
            <div class="d-flex mt-1">
                <PrCBtnCardComp
                    icon="mdi-format-list-bulleted-square"
                    @click="$emit('clickdetail')"
                />
                <PrCMenuQuantityComp
                    :options="options"
                    icon="mdi-check"
                    @clickquantity="eSelectQuantity"
                />
                <PrCBtnCardComp
                    icon="mdi-lead-pencil"
                    @click="$emit('clickform')"
                />
            </div>
        </v-card>
        <v-divider vertical></v-divider>
        <div class="ml-2 mr-2" style="width:100%">
            <text-highlight
                class="pr-app-title"
                :queries="search"
            >
                {{product.product}}
            </text-highlight>
            <div>
            <text-highlight
                class="pr-app-subtitle"
                :queries="search"
            >
                {{product.description}}
            </text-highlight>
            </div>
        </div>
    </div>
</template>
<script>
import {
    PrCMenuQuantityComp,
    PrCBtnCardComp,
    PrCFillInformationComp,
    PrCImageTableComp
} from "../../cells";
import TextHighlight from 'vue-text-highlight';
export default {
    components: {
        PrCMenuQuantityComp,
        PrCBtnCardComp,
        PrCFillInformationComp,
        PrCImageTableComp,
        TextHighlight
    },
    props: {
        product: Object,
        search: String
    },
    data: () => ({
        options: [1, 2, 3, 4, 5]
    }),
    methods: {
        getClassInventory(product) {
            if (product.quantity <= 5) return "categories__disabled ml-1";
            if (product.quantity <= 15) return "categories__warning ml-1";
            return "categories__enabled ml-1";
        },
        eSelectQuantity(quantity) {
            const parameters = {
                'product': this.product,
                'quantity': quantity
            }
            this.$emit('clickquantity', parameters);
        }
    }
}
</script>