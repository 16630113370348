<template>
    <div class="pr-o-card-categorie animation-opacity">
        <div class="pr-o-card-categorie__header d-flex justify-center">
            <h1 class="pr-app-title-dark">
                {{code.this_slope === 1
                    ? 'Pendiente'
                    : mCryDecryptText(code.code)}}
            </h1>
        </div>
        <div class="pa-2 pb-1">
            <h1
                v-if="code.this_slope === 0"
                class="pr-app-subtitle"
            >
                {{code.description != null
                    ? code.description
                    : "S/D"}}
            </h1>
            <PrCFillInformationComp
                v-if="code.this_slope === 1"
                title="Expiración"
                subtitle="---"
            />
            <PrCFillInformationComp
                v-else
                title="Expiración"
                :subtitle="mDateFormat(code.expiration)"
            >
                <div
                    :class="!code.expired
                        ? 'categories__enabled ml-1'
                        : 'categories__disabled ml-1'"
                ></div>
            </PrCFillInformationComp>
        </div>
    </div>
</template>
<script>
import {
    PrCFillInformationComp
} from "../../cells";
import {
    datesMixin,
    cryptographyMixin
} from "../../../../mixins";
export default {
    mixins: [
        datesMixin,
        cryptographyMixin
    ],
    props: {
        code: Object
    },
    components: {
        PrCFillInformationComp
    }
}
</script>