var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"formproduct",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Precio"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Precio' : '',"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => _vm.mRIsNumeric(value) || _vm.smsNotNumeric
            ]},on:{"click":function($event){return _vm.eValidateForm()}},model:{value:(_vm.dataProduct.price),callback:function ($$v) {_vm.$set(_vm.dataProduct, "price", $$v)},expression:"dataProduct.price"}})],1),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Precio Costo"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Precio Costo' : '',"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => _vm.mRIsNumeric(value) || _vm.smsNotNumeric
            ]},on:{"click":function($event){return _vm.eValidateForm()}},model:{value:(_vm.dataProduct.price_cost),callback:function ($$v) {_vm.$set(_vm.dataProduct, "price_cost", $$v)},expression:"dataProduct.price_cost"}})],1),(_vm.dataProduct.type === 'n' || _vm.dataProduct.id_reserve > 0)?_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Añadir Cantidad"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Añadir Cantidad' : '',"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => _vm.mRIsInteger(value) || _vm.smsNotInteger
            ]},on:{"click":function($event){return _vm.eValidateForm()}},model:{value:(_vm.dataProduct.new_quantity),callback:function ($$v) {_vm.$set(_vm.dataProduct, "new_quantity", $$v)},expression:"dataProduct.new_quantity"}})],1):_vm._e(),_c('div',{staticClass:"d-flex justify-center mt-1"},[_c('PrMBtnNormalComp',{on:{"click":function($event){return _vm.eValidateForm()}}},[_c('v-icon',[_vm._v("mdi-bookmark-check")]),_vm._v(" GUARDAR ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }