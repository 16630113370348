<template>
    <div>
        <div class="d-flex align-center pa-2">
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    v-if="!isMobile"
                    :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                    :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                    class="mr-2"
                    @click="$emit('clickmini')"
                />
                <h1 class="pr-app-title">
                    {{`PUBLICACIÓN (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    icon="mdi-plus"
                    class="mr-1"
                    smsToolTip="Registrar Publicación"
                    @click="eOpenForm()"
                />
                <PrCBtnToolTipHeaderComp
                    icon="mdi-database"
                    smsToolTip="Volver a la lista"
                    @click="eBackList()"
                />
            </div>
        </div>
        <PrMLineHeaderComp />
        <div class="pa-2 pb-0">
            <v-card
                width="100%"
                class="pa-2"
                color="#fad694"
                elevation="0"
            >
                <h1 class="pr-app-subtitle">La resolución recomendada para
                    registrar las publicaciones en la pantalla de inicio son
                    1200px de ancho
                </h1>
            </v-card>
        </div>
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <PrCLoaderDataComp
                    v-if="dialogLoaderDataIsOpen"
                    class="mt-2"
                />
                <PrMWithoutInfoCardsComp
                    v-if="!dialogLoaderDataIsOpen && publications.length===0"
                    class="ma-2"
                />
                <div
                    v-if="!dialogLoaderDataIsOpen"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardPublicationComp
                        class="ma-2"
                        v-for="publication in publications"
                        :key="publication.id"
                        :publication="publication"
                        @clickdelete="eConfirDelete(publication)"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2">
                    <PrESkeletonFormComp
                        title="REGISTRAR"
                    >
                        <PrOFormPublicationComp
                            ref="formpublicationcomp"
                            @clickfile="eConfirmForm"
                            @changefile="eChangeFile"
                        />
                    </PrESkeletonFormComp>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <PrOGeneralDialogsComp
            :dialogErrorIsOpen="dialogErrorIsOpen"
            :smsErrors="smsErrors"
            :actionErrorIsActivated="actionErrorIsActivated"
            @clickerror="eCloseError()"
            :dialogConfirmationIsOpen="dialogConfirmationIsOpen"
            :smsConfirmations="smsConfirmations"
            @clickyes="eProcessOperation()"
            @clickno="eCloseConfirm()"
            :smsActions="smsActions"
            :frameAction="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
            :dialogOperationIsOpen="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMTextFieldHeaderComp,
    PrMWithoutInfoCardsComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCLoaderDataComp
} from "../../components/components_private/cells";
import {
    PrOCardPublicationComp,
    PrOFormPublicationComp,
    PrOGeneralDialogsComp
} from "../../components/components_private/organisms";
import {
    PrESkeletonFormComp
} from "../../components/components_private/species";
import {
    Publication
} from "../../models";
import {
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin
} from "../../mixins";
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin
    ],
    components: {
        PrMLineHeaderComp,
        PrCBtnToolTipHeaderComp,
        PrMTextFieldHeaderComp,
        PrCLoaderDataComp,
        PrMWithoutInfoCardsComp,
        PrOCardPublicationComp,
        PrOFormPublicationComp,
        PrESkeletonFormComp,
        PrOGeneralDialogsComp
    },
    data: () => ({
        publications: [],
        numberOperation: 0,
        tab: 0,
        indexPublication: -1,
        idPublication: -1,
        dataPublication: new Publication
    }),
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        resetDataDirect() {
            this.idPublication = -1;
            this.indexPublication = -1;
            this.numberOperation = 0;  
        },
        eCloseError() {
            this.mGDProcesAccess();
            if (this.idPublication !== -1)
                this.resetDataDirect();
            this.dialogErrorIsOpen = false;
        },
        async getPublications() {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Publication.getPublication();
                this.publications = response;
                this.total = response.length;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eOpenForm() {
            this.numberOperation = 0;
            this.tab = 1;
        },
        eChangeFile(parameters) {
            if (parameters.state)
                this.dataPublication.file = parameters.file;
            else this.dataPublication.file = null;
        },
        eCloseConfirm() {
            if (this.idPublication !== -1) this.resetDataDirect();
            this.dialogConfirmationIsOpen = false;
        },
        eBackList() {
            if (this.tab !== 0) {
                this.indexPublication = -1;
                this.dataPublication = new Publication;
                this.$refs.formpublicationcomp.resetData();
                this.tab = 0;
            }
        },
        eConfirmForm() {
            if (this.dataPublication.file === null)
                this.mGDShowAction(["Operación Incorrecta", "Ingrese una fotografía"], "error");
            else {
                this.smsConfirmations = ['¿Está seguro de registrar la publicación?'];
                this.dialogConfirmationIsOpen = true;
            }
        },
        async addPublication() {
            this.dataPublication.name_file = `${new Date().getTime()}`;
            try {
                const response = await Publication.addPublication(this.$objectToFD(this.dataPublication));
                this.publications.unshift(response);
                this.total += 1;
                this.eBackList();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se agregó la publicación"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async deletePublication() {
            try {
                await Publication.deletePublication({id: this.idPublication});
                this.publications.splice(this.indexPublication, 1);
                this.total -= 1;
                this.resetDataDirect();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se eliminó la publicación"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eProcessOperation() {
            this.dialogConfirmationIsOpen = false;
            this.dialogOperationIsOpen = true;
            switch(this.numberOperation) {
                case 0:
                    this.addPublication();
                    break;
                case 1:
                    this.deletePublication();
                    break;
            }
        },
        eConfirDelete(publication) {
            this.numberOperation = 1;
            this.indexPublication = this.publications.indexOf(publication);
            this.idPublication = publication.id;
            this.smsConfirmations = ['¿Está seguro de eliminar la publicación?'];
            this.dialogConfirmationIsOpen = true;
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategoryEmployee() === 'a') this.getPublications();
            else this.$router.replace('/admin/home');
        } else this.$router.replace('/login-admin');
    }
    
}
</script>