<template>
    <div
        :class="isMobile ? 'mt-2' : 'd-flex justify-end mt-1'"
    >
        <h1 class="pr-app-title mt-1 mr-2" v-if="!isMobile">
            {{`${title}:`}}
        </h1>
        <div :class="isMobile
            ? 'pr-c-fill-form__input-mobile'
            : 'pr-c-fill-form__input-pc'"
        >
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        isMobile: Boolean,
        title: String
    }
}
</script>