<template>
    <div class="pr-o-card-categorie animation-opacity">
        <div class="pr-o-card-categorie__header d-flex justify-center">
            <h1 class="pr-app-title-dark">
                {{code.product_sale.sale.client.full_name}}
            </h1>
        </div>
        <div class="d-flex pa-2">
            <PrCImageTableComp
                :path="code.product_sale.product.route_file"
            />
            <div class="ml-2">
                <PrCFillInformationComp
                    title="Celular"
                    :subtitle="code.product_sale.sale.client.phone != null
                        ? code.product_sale.sale.client.phone
                        : 'S/N'"
                />
                <PrCFillInformationComp
                    title="Producto"
                    :subtitle="code.product_sale.product.product"
                />
                <PrCFillInformationComp
                    title="Fecha Compra"
                    :subtitle="code.product_sale.sale.date"
                />
                <PrCFillInformationComp
                    title="Expiración"
                    :subtitle="mDateFormat(code.expiration)"
                >
                    <div
                        :class="!code.expired
                            ? 'categories__enabled ml-1'
                            : 'categories__disabled ml-1'"
                    ></div>
                </PrCFillInformationComp>
            </div>
        </div>
        <v-divider class="mb-1"></v-divider>
        <div class="pa-2 pt-1 d-flex justify-center flex-wrap">
            <PrCBtnCardComp
                icon="mdi-format-list-bulleted-square"
                @click="$emit('clickdetail')"
            />
            <PrCBtnCardComp
                icon="mdi-whatsapp"
                @click="$emit('clickwhatsapp')"
            />
        </div>
    </div>
</template>
<script>
import {
    PrCFillInformationComp,
    PrCBtnCardComp,
    PrCImageTableComp
} from "../../cells";
import {
    datesMixin
} from "../../../../mixins";
export default {
    mixins: [datesMixin],
    props: {
        code: Object
    },
    components: {
        PrCFillInformationComp,
        PrCBtnCardComp,
        PrCImageTableComp
    }
}
</script>