<template>
    <v-dialog
        persistent
        :value="value"
        width="500"
    >
        <v-card class="pa-0 ma-0 cs-pu-background">
            <div class="d-flex align-center pa-2 cf-pu-background">
                <h1 class="pr-app-title-dark">
                    INFORMACIÓN ADICIONAL
                </h1>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    x-small
                    depressed
                    color="red"
                    @click="eCloseForm()"
                >
                    <v-icon
                        class="pr-cd-color"
                    >mdi-close</v-icon>
                </v-btn>
            </div>
            <div class="pa-2">
                <h1 class="pu-app-title-dark">
                    ¿Qué información necesitamos del producto?
                </h1>
                <h1 class="pu-app-subtitle-dark">
                    {{product.info_guide}}
                </h1>
                <v-form
                    v-model="valid"
                    lazy-validation
                    ref="forminfo"
                >
                    <MTextAreaComp
                        label="Información"
                        class="mt-3"
                        :singleLine="true"
                        v-model="info"
                        :counter="200"
                        background=""
                        styleTextField="font-weight:300 !important"
                        :rules="[
                            value => (`${value}`).length <= 200 || smsExecedCounter(200),
                            value => !mRItIsEmpty(value) || smsEmpty
                        ]"
                    />
                </v-form>
                <div class="d-flex justify-center">
                    <MBtnNormalComp
                        :xSmall="isMobile"
                        :small="!isMobile"
                        :outlined="false"
                        class="mr-1"
                        className="cp-pu-background"
                        @click="eSendInfo()"
                    >
                        AGREGAR
                    </MBtnNormalComp>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    MTextAreaComp,
    MBtnNormalComp
} from "../../molecules";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [rulesMixin],
    components: {
        MTextAreaComp,
        MBtnNormalComp
    },
    props: {
        value: Boolean,
        isMobile: Boolean,
        product: Object
    },
    data: () => ({
        valid: true,
        info: null
    }),
    methods: {
        eCloseForm() {
            this.info = null;
            this.$refs.forminfo.resetValidation();
            this.$emit('closeform');
        },
        eSendInfo() {
            let paramters = {
                info: this.info,
                form: false
            }
            if (this.$refs.forminfo.validate()) {
                this.eCloseForm();
                paramters.form = true;
            }
            this.$emit('clickaddinfo', paramters);
        }
    }
}
</script>