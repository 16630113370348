<template>
    <v-form
        v-model="valid"
        lazy-validation
        ref="formproduct"
    >
        <div
            v-if="indexProduct === -1"
            class="d-flex justify-center mb-6"
        >
            <PrCPhotoFormComp
                refImg="fotografiaup"
                ref="photoformcomp"
                title="Foto"
                subtitle="Producto"
                fileFormat="image/jpeg"
                @changephoto="eChangePhoto"
            />
        </div>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Producto"
        >
            <PrMTextFieldComp
                v-model="dataProduct.product"
                :label="isMobile ? 'Producto' : ''"
                :counter="50"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => (`${value}`).length <= 50 || smsExecedCounter(50)
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Descripción"
        >
            <PrMTextAreaComp
                v-model="dataProduct.description"
                :label="isMobile ? 'Descripción' : ''"
                :counter="200"
                :rules="[
                    value => (`${value}`).length <= 200 || smsExecedCounter(200)
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            v-if="indexProduct === -1"
            :isMobile="isMobile"
            title="Tipo"
        >
            <PrMSelectComp
                v-model="dataProduct.type"
                :label="isMobile ? 'Tipo' : ''"
                :items="options"
                itemValue="value"
                itemText="text"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Categoría"
        >
            <PrMSelectComp
                v-model="dataProduct.fk_categorie"
                :label="isMobile ? 'Categoría' : ''"
                :items="categories"
                itemValue="id"
                itemText="categorie"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Precio"
        >
            <PrMTextFieldComp
                v-model="dataProduct.price"
                :label="isMobile ? 'Precio' : ''"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => mRIsNumeric(value) || smsNotNumeric
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Precio Costo"
        >
            <PrMTextFieldComp
                v-model="dataProduct.price_cost"
                :label="isMobile ? 'Precio Costo' : ''"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => mRIsNumeric(value) || smsNotNumeric
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            v-if="dataProduct.type === 'n'"
            :isMobile="isMobile"
            title="Cantidad"
        >
            <PrMTextFieldComp
                v-model="dataProduct.quantity"
                :label="isMobile ? 'Cantidad' : ''"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => mRIsInteger(value) || smsNotInteger
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            v-if="indexProduct === -1"
            :isMobile="isMobile"
            title="Elección Saldo"
        >
            <PrMSelectComp
                v-model="dataProduct.id_reserve"
                :label="isMobile ? 'Elección Saldo' : ''"
                :items="reserves"
                itemValue="id"
                itemText="name"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            v-if="indexProduct === -1 && dataProduct.id_reserve > 0"
            :isMobile="isMobile"
            title="Retención Saldo"
        >
            <PrMTextFieldComp
                v-model="dataProduct.balance_hold"
                :label="isMobile ? 'Retención Saldo' : ''"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => mRIsNumeric(value) || smsNotNumeric
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Requiere Información"
        >
            <PrMSelectComp
                v-model="dataProduct.necessary_info"
                :label="isMobile ? 'Requiere Información' : ''"
                :items="optionsInfo"
                itemValue="value"
                itemText="text"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            v-if="dataProduct.necessary_info == 1"
            :isMobile="isMobile"
            title="Mensaje Info."
        >
            <PrMTextAreaComp
                v-model="dataProduct.info_guide"
                :label="isMobile ? 'Mensaje Info.' : ''"
                :counter="200"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty,
                    value => (`${value}`).length <= 200 || smsExecedCounter(200)
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Considerar Tiempo"
        >
            <PrMSelectComp
                v-model="dataProduct.consider_time"
                :label="isMobile ? 'Considerar Tiempo' : ''"
                :items="optionsTime"
                itemValue="value"
                itemText="text"
                :rules="[
                    value => !mRItIsEmpty(value) || smsEmpty
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Tiempo Entrega"
        >
            <PrMTextFieldComp
                v-model="dataProduct.time"
                :label="isMobile ? 'Tiempo Entrega' : ''"
                @click="eValidateForm()"
                :counter="20"
                :rules="[
                    value => eValidTime(value, dataProduct.consider_time) || smsEmpty,
                    value => (`${value}`).length <= 20 || smsExecedCounter(20)
                ]"
            />
        </PrCFillFormComp>
        <PrCFillFormComp
            :isMobile="isMobile"
            title="Instrucciones Web"
        >
            <PrMTextAreaComp
                v-model="dataProduct.instructions"
                :label="isMobile ? 'Instrucciones Web' : ''"
                :counter="500"
                :rules="[
                    value => (`${value}`).length <= 200 || smsExecedCounter(500)
                ]"
            />
        </PrCFillFormComp>
        <div class="d-flex justify-center mt-1">
            <PrMBtnNormalComp
                @click="eValidateForm()"
            >
                <v-icon>mdi-bookmark-check</v-icon>
                GUARDAR
            </PrMBtnNormalComp>
        </div>
    </v-form>
</template>
<script>
import {
    PrMTextFieldComp,
    PrMBtnNormalComp,
    PrMTextAreaComp,
    PrMSelectComp
} from "../../molecules";
import {
    PrCFillFormComp,
    PrCPhotoFormComp
} from "../../cells";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [ rulesMixin ],
    components: {
        PrMTextFieldComp,
        PrCFillFormComp,
        PrMBtnNormalComp,
        PrMTextAreaComp,
        PrMSelectComp,
        PrCPhotoFormComp
    },
    props: {
        dataProduct: Object,
        isMobile: Boolean,
        categories: Array,
        indexProduct: Number,
        reserves: Array
    },
    data: () => ({
        valid: true,
        options: [
            { value: "n", text: "Físico" },
            { value: "d", text: "Digital" }
        ],
        optionsTime: [
            { value: 1, text: "SI" },
            { value: 0, text: "NO" }
        ],
        optionsInfo: [
            { value: 1, text: "SI" },
            { value: 0, text: "NO" }
        ]
    }),
    computed: {
        categoriesFilter: function() {
            if (this.dataProduct.type === null) return [];
            if (this.dataProduct.type === "n")
                return this.categories.filter(categorie => {
                    return categorie.choice !== "d";
                });
            return this.categories.filter(categorie => {
                return categorie.choice !== "f";
            });
        }
    },
    methods: {
        eValidTime(input, isTime) {
            if (isTime !== null && !isTime) return true;
            return !this.mRItIsEmpty(input);
        },
        eValidateForm() {
            if (this.$refs.formproduct.validate())
                this.$emit('clickform', true);
            else this.$emit('clickform', false);
        },
        resetForm() {
            this.$refs.formproduct.resetValidation();
        },
        eChangePhoto(parameters) {
            this.$emit('changefile', parameters);
        },
        resetFile() {
            this.$refs.photoformcomp.resetData();
        }
    }
}
</script>