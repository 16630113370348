var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pr-s-complet-detail-sale"},[_vm._m(0),_c('div',{staticClass:"pr-s-complet-detail-sale__body"},[_c('PrODetailSaleComp',{attrs:{"detailSale":_vm.detailSale},on:{"clickedit":e => this.$emit('clickedit', e),"clickdelete":_vm.eDeleteProduct,"clickdetail":e => this.$emit('clickdetail', e)}})],1),_c('div',{staticClass:"pr-s-complet-detail-sale__footer"},[_c('v-card',{staticClass:"rounded-0 pr-cp-background",attrs:{"width":"100%","elevation":"0","height":"10"}}),_c('div',{staticClass:"pa-2"},[_c('div',{staticClass:"pr-4"},[_c('div',{staticClass:"d-flex"},[_c('h1',{staticClass:"pr-app-title"},[_vm._v("TOTAL:")]),_c('v-spacer'),_c('h1',{staticClass:"pr-app-title text-right"},[_vm._v(" "+_vm._s(_vm.mSaleTotalLocalStore(_vm.detailSale).toFixed(2))+" ")])],1),_c('div',{staticClass:"d-flex"},[_c('h1',{staticClass:"pr-app-title"},[_vm._v("Cancelado:")]),_c('v-spacer'),_c('PrMTextFieldHeaderComp',{staticStyle:{"width":"120px","max-width":"120px"},attrs:{"reverse":true,"clearable":false,"rules":[
                            value => !this.mRItIsEmpty(value),
                            value => this.mRIsNumeric(value)
                        ]},model:{value:(_vm.cancelled),callback:function ($$v) {_vm.cancelled=$$v},expression:"cancelled"}})],1),_c('div',{staticClass:"d-flex"},[_c('h1',{staticClass:"pr-app-title"},[_vm._v("Cambio:")]),_c('v-spacer'),_c('h1',{staticClass:"pr-app-title text-right"},[_vm._v(" "+_vm._s(_vm.changeMoney.toFixed(2))+" ")])],1)]),_c('v-divider',{staticClass:"mb-2"}),_c('div',{staticClass:"d-flex justify-center"},[_c('PrCBtnDetailComp',{staticClass:"mr-1",attrs:{"icon":"mdi-window-maximize","colorBtn":"blue","smsToolTip":"Limpiar Detalle"},on:{"click":function($event){return _vm.eClearDetail()}}}),_c('PrCBtnDetailComp',{staticClass:"mr-1",attrs:{"icon":"mdi-cash","colorBtn":"green","smsToolTip":"Registrar venta"},on:{"click":function($event){return _vm.eGenerateProductSale(true)}}}),_c('PrCBtnDetailComp',{staticClass:"mr-1",attrs:{"icon":"mdi-account-cash","colorBtn":"green","smsToolTip":"Registrar venta + cliente"},on:{"click":function($event){return _vm.eGenerateProductSale(false)}}}),(_vm.mUserCategoryEmployee() === 'a')?_c('PrCBtnDetailComp',{attrs:{"icon":_vm.isStore ? 'mdi-warehouse' : 'mdi-account-edit',"colorBtn":"orange","smsToolTip":_vm.isStore
                        ? 'Cambiar a venta personal'
                        : 'Cambiar a venta por tienda'},on:{"click":function($event){return _vm.$emit('clickstore')}}}):_vm._e()],1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pr-s-complet-detail-sale__header pr-cp-background pa-1"},[_c('h1',{staticClass:"pr-app-title-dark text-center"},[_vm._v("DETALLE")])])
}]

export { render, staticRenderFns }