<template>
    <div class="c-image-home">
        <div
            class="c-image-home__first"
            v-intersect="onIntersect"
        >
            <div class="c-image-home__img">
                <v-img
                    gradient="to top right, rgba(0, 0, 0, 1), rgba(0, 0, 9, 0.3)"
                    :src="dataImage.image_one"
                    width="100%"
                    height="100%"
                    position="left"
                ></v-img>
            </div>
            <div class="c-image-home__text">
                <v-card
                    class="cp-pu-background pa-4"
                    width="100%"
                    height="100%"
                >
                    <h1 class="pu-app-big-title-dark">
                        {{dataImage.title}}
                    </h1>
                    <v-card
                        width="100%"
                        height="5"
                        class="cd-pu-background rounded-0 mt-2"
                        elevation="0"
                    ></v-card>
                    <h1 class="pu-app-subtitle-dark mt-3">
                        {{dataImage.subtitle}}
                    </h1>
                </v-card>
            </div>
        </div>
        <div class="c-image-home__baner">
            <v-img
                gradient="to top right, rgba(0, 0, 0, 0.9), rgba(0, 0, 9, 0)"
                :src="dataImage.image_two"
                width="100%"
                height="100%"
                position="center top"
            >
                <v-card
                    width="100%"
                    height="100%"
                    class="rounded-0 d-flex align-center justify-center"
                    elevation="0"
                    color="transparent"
                >
                    <div class="c-image-home__baner-text">
                        <h1 class="o-fond-home--title">
                            {{dataImage.slogan}}
                        </h1>
                    </div>
                </v-card>
            </v-img>
            <div class="c-image-home__ornaments">
                <div class='light x1'></div>
                <div class='light x2'></div>
                <div class='light x3'></div>
                <div class='light x4'></div>
                <div class='light x5'></div>
                <div class='light x6'></div>
                <div class='light x7'></div>
                <div class='light x8'></div>
                <div class='light x9'></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        dataImage: Object
    },
    data: () => ({
        isIntersecting: false,
        isMobile: false
    }),
    methods: {
        onIntersect (entries, observer) {
            if (entries[0].isIntersecting && !this.isMobile) {
                this.isIntersecting = entries[0].isIntersecting;
                const element = document.getElementsByClassName("c-image-home__first");
                element[0].style.transition = "800ms";
                element[0].style.paddingTop = "0px";
                element[0].style.opacity = "1";
            }
        },
        closeAnimation() {
            const element = document.getElementsByClassName("c-image-home__first");
            element[0].style.paddingTop = "0px";
            element[0].style.opacity = "1";
        }
    },
    mounted() {
        if (window.innerWidth <= 600) {
            this.isMobile = true;
            this.closeAnimation();
        }
    }
}
</script>