<template>
    <v-dialog
        persistent
        width="400px"
        :value="value"
    >
        <v-card class="pa-0" color="#FAFAFA">
            <div class="pr-ct-background ma-0 pa-2">
                <h1 class="pr-app-text--space pr-app-title-dark text-center">CONFIRMACIÓN</h1>
            </div>
            <div class="d-flex justify-center pt-2">
                <v-icon
                    class="animation-icon-rotate pr-ct-color"
                    style="font-size:50px"
                >
                    mdi-message-check-outline</v-icon>
            </div>
            <div class="d-block pa-2 pb-0">
                <h1
                    class="text-center pr-app-subtitle"
                    v-for="(sms, index) in smsConfirmations"
                    :key="index"
                >{{sms}}</h1>
            </div>
            <div class="d-flex justify-center pa-2">
                <v-btn
                    small
                    color="red"
                    outlined
                    class="mr-2"
                    @click="$emit('clickno')"
                    :ripple="false"
                >NO</v-btn>
                <v-btn
                    small
                    color="green"
                    outlined
                    :ripple="false"
                    @click="$emit('clickyes')"
                >SI</v-btn>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: {
        value: Boolean,
        smsConfirmations: Array
    }
}
</script>