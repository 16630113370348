<template>
    <div>
        <div class="d-flex justify-center">
            <div class="products-digital__body product-public mt-2">
                <div class="product-public__img">
                    <div class="product-public__img-container">
                        <v-img
                            :src="product.route_file|imgNormal"
                            width="100%"
                            height="100%"
                            contain
                        ></v-img>
                    </div>
                </div>
                <div class="product-public__info">
                    <h1 class="pu-app-big-title-dark">{{product.product}}</h1>
                    <v-card
                        width="100%"
                        height="5"
                        elevation="0"
                        style="opacity:0.5"
                        class="o-card-recommendation__ornament mt-2"
                    ></v-card>
                    <h1 class="pu-app-subtitle-dark mt-2">{{product.description}}</h1>
                    <CFillInformationComp
                        title="Tipo"
                        :subtitle="product.type === 'd'
                            ? 'Digital'
                            : 'Físico'"
                    />
                    <CFillInformationComp
                        title="Categoría"
                        :subtitle="product.categorie"
                    />
                    <CFillInformationComp
                        title="Precio"
                        :subtitle="getPrice(product.price)"
                    />
                    <CFillInformationComp
                        title="Tiempo entrega"
                        :subtitle="product.consider_time === 1
                            ? product.time : 'Inmediata'"
                    />
                    <div v-if="product.necessary_info == 1">
                        <h1 class="pu-app-title-dark">Información requerida para la compra</h1>
                        <h1 class="pu-app-subtitle-dark">
                            {{product.info_guide}}
                        </h1>
                        <v-form
                            v-model="valid"
                            lazy-validation
                            ref="formproduct"
                        >
                            <MTextAreaComp
                                v-model="info"
                                label="Información Requerida"
                                :dark="true"
                                background=""
                                class="mt-3"
                                styleTextField="font-weight:300 !important"
                                :counter="200"
                                :rules="[
                                    value => !mRItIsEmpty(value) || smsEmpty,
                                    value => (`${value}`).length <= 200 || smsExecedCounter(200)
                                ]"
                            />
                        </v-form>
                    </div>
                    <MBtnNormalComp
                        class="mt-1"
                        @click="eSelectProduct()"
                    >
                        <v-icon small class="mr-1">mdi-cart-outline</v-icon>
                        AÑADIR CARRITO
                    </MBtnNormalComp>
                </div>
            </div>
        </div>
        <div
            v-if="product.instructions != null"
            class="d-flex justify-center"
        >
            <div class="products-digital__body mt-2">
                <h1 class="pu-app-title-dark">INSTRUCCIONES</h1>
                <h1
                    class="pu-app-subtitle-dark"
                    style="white-space: pre-line;"
                >
                    {{product.instructions}}
                </h1>
            </div>
        </div>
        <div class="space--block"></div>
        <CSmsActionComp
            :smsActions="smsActions"
            :value="frameAction"
            :isError="isError"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
        />
    </div>
</template>
<script>
import {
    imgNormal
} from "../../filters";
import {
    CFillInformationComp,
    CSmsActionComp
} from "../../components/components_public/cells";
import {
    MBtnNormalComp,
    MTextAreaComp
} from "../../components/components_public/molecules";
import { mapMutations, mapState } from "vuex";
import {
    dataGeneralPublicMixin,
    rulesMixin
} from "../../mixins";
export default {
    mixins: [
        dataGeneralPublicMixin,
        rulesMixin
    ],
    filters: {
        imgNormal
    },
    components: {
        CFillInformationComp,
        CSmsActionComp,
        MBtnNormalComp,
        MTextAreaComp
    },
    data: () => ({
        product: {},
        info: null,
        valid: true
    }),
    computed: {
        ...mapState("cartClientStore", ["cart"]),
        ...mapState("settingStore", ["dollar", "isDollar"])
    },
    methods: {
        ...mapMutations("cartClientStore", ["addCart"]),
        existProductInCart(id) {
            for (let i = 0; i < this.cart.length; i++) {
                if (id === this.cart[i].product.id) return true;
            }
            return false;
        },
        processAddProduct(paramters) {
            if (this.product.quantity > 0) {
                if (!this.existProductInCart(this.product.id)) {
                    this.addCart(paramters);
                    this.mGDShowAction([this.product.product, "Agregado al carrito"], false);
                }
                else this.mGDShowAction([this.product.product, "Ya está agregado"], false);
            } else this.mGDShowAction(["Operación Incorrecta", "Producto no disponible"], true);
        },
        eSelectProduct() {
            const paramters = {
                "product": this.product,
                "quantity": 1,
                "info": this.info
            }
            if (this.product.necessary_info == 1) {
                if (!this.$refs.formproduct.validate())
                    this.mGDShowAction(["Operación Incorrecta", "Se requiere la información"], true);
                else this.processAddProduct(paramters);
            }
            else this.processAddProduct(paramters);
        },
        getPrice(price) {
            if (price == undefined) return "";
            if (this.isDollar) {
                const newPrice = price / this.dollar;
                return `${newPrice.toFixed(2)} $`;
            }
            return `${price.toFixed(2)} Bs.`;
        }
    },
    mounted() {
        if (!this.$route.params) this.$router.replace('/');
        else this.product = this.$route.params;
    }
}
</script>