var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"formuser",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Usuario"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Usuario' : '',"counter":30,"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => !_vm.mRHasSpaces(value) || _vm.smsWithSpaces,
                value => (`${value}`).length <= 30 || _vm.smsExecedCounter(50)
            ]},on:{"click":function($event){return _vm.eValidateForm()}},model:{value:(_vm.dataUser.username),callback:function ($$v) {_vm.$set(_vm.dataUser, "username", $$v)},expression:"dataUser.username"}})],1),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Contraseña"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Contraseña' : '',"type":_vm.showPassword ? 'text' : 'password',"icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye',"counter":30,"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                value => (`${value}`).length <= 30 || _vm.smsExecedCounter(50)
            ]},on:{"click":function($event){return _vm.eValidateForm()},"clicksee":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.dataUser.password),callback:function ($$v) {_vm.$set(_vm.dataUser, "password", $$v)},expression:"dataUser.password"}})],1),_c('PrCFillFormComp',{attrs:{"isMobile":_vm.isMobile,"title":"Repita Contraseña"}},[_c('PrMTextFieldComp',{attrs:{"label":_vm.isMobile ? 'Repita Contraseña' : '',"type":_vm.showPassword ? 'text' : 'password',"icon":_vm.showPassword ? 'mdi-eye-off' : 'mdi-eye',"rules":[
                value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty
            ]},on:{"click":function($event){return _vm.eValidateForm()},"clicksee":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.dataUser.repit_password),callback:function ($$v) {_vm.$set(_vm.dataUser, "repit_password", $$v)},expression:"dataUser.repit_password"}})],1),_c('div',{staticClass:"d-flex justify-center mt-1"},[_c('PrMBtnNormalComp',{on:{"click":function($event){return _vm.eValidateForm()}}},[_c('v-icon',[_vm._v("mdi-bookmark-check")]),_vm._v(" GUARDAR ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }