<template>
    <div>
        <div class="d-flex align-center pa-2">
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    v-if="!isMobile"
                    :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                    :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                    class="mr-2"
                    @click="$emit('clickmini')"
                />
                <h1 class="pr-app-title">
                    CIERRES DE CAJA (Bs.)
                </h1>
            </div>
            <v-spacer></v-spacer>
            <PrCBtnToolTipHeaderComp
                icon="mdi-database"
                smsToolTip="Volver a la lista"
                @click="eBackList()"
            />
        </div>
        <PrMLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="d-flex justify-center flex-wrap pa-2">
                    <div class="pr-products__search">
                        <div class="pr-products__search-block">
                            <PrMTextFieldComp
                                label="Fecha Inicio"
                                v-model="dateOne"
                                background=""
                                type="date"
                                :clearable="false"
                                :hideDetails="true"
                                @change="eChangeDateOne"
                            />
                        </div>
                        <div class="pr-products__search-block">
                            <PrMTextFieldComp
                                label="Fecha Fin"
                                v-model="dateTwo"
                                background=""
                                type="date"
                                :clearable="false"
                                :hideDetails="true"
                                @change="eChangeDateTwo"
                            />
                        </div>
                    </div>
                </div>
                <v-divider></v-divider>
                <PrCLoaderDataComp
                    v-if="dialogLoaderDataIsOpen"
                    class="mt-2"
                />
                <PrMWithoutInfoCardsComp
                    v-if="!dialogLoaderDataIsOpen && closingBoxes.length===0 && isMobile"
                    class="ma-2"
                />
                <PrOTableCloseBoxComp
                    v-if="!dialogLoaderDataIsOpen && !isMobile"
                    :closingBoxes="closingBoxes"
                    @clickedit="eOpenFormEdit"
                />
                <div
                    v-if="!dialogLoaderDataIsOpen && isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardBoxGeneralComp
                        class="ma-2"
                        v-for="closeBox in closingBoxes"
                        :key="closeBox.id"
                        :closeBox="closeBox"
                        @clickedit="eOpenFormEdit(closeBox)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="isMobile && !dialogLoaderDataIsOpen && closingBoxes.length > 0"
                ></v-divider>
                <PrSPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderDataIsOpen && closingBoxes.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                    @clickpage="eSearchForPage"
                />
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2">
                    <PrESkeletonFormComp
                        title="EDITAR"
                    >
                        <PrOFormBoxGeneralComp
                            ref="formcloseboxcomp"
                            :dataCloseBox="dataCloseBox"
                            :isMobile="isMobile"
                            @clickform="eConfirmForm"
                        />
                    </PrESkeletonFormComp>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <PrOGeneralDialogsComp
            :dialogErrorIsOpen="dialogErrorIsOpen"
            :smsErrors="smsErrors"
            :actionErrorIsActivated="actionErrorIsActivated"
            @clickerror="eCloseError()"
            :dialogConfirmationIsOpen="dialogConfirmationIsOpen"
            :smsConfirmations="smsConfirmations"
            @clickyes="eProcessOperation()"
            @clickno="dialogConfirmationIsOpen = false"
            :smsActions="smsActions"
            :frameAction="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
            :dialogOperationIsOpen="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMWithoutInfoCardsComp,
    PrMTextFieldComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCLoaderDataComp
} from "../../components/components_private/cells";
import {
    PrOTableCloseBoxComp,
    PrOFormBoxGeneralComp,
    PrOGeneralDialogsComp,
    PrOCardBoxGeneralComp
} from "../../components/components_private/organisms";
import {
    PrESkeletonFormComp,
    PrSPaginationComp
} from "../../components/components_private/species";
import {
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin
} from "../../mixins";
import {
    CloseBox
} from "../../models";
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin
    ],
    components: {
        PrCBtnToolTipHeaderComp,
        PrMLineHeaderComp,
        PrCLoaderDataComp,
        PrMWithoutInfoCardsComp,
        PrOTableCloseBoxComp,
        PrESkeletonFormComp,
        PrOFormBoxGeneralComp,
        PrMTextFieldComp,
        PrOGeneralDialogsComp,
        PrSPaginationComp,
        PrOCardBoxGeneralComp
    },
    data: () => ({
        tab: 0,
        closingBoxes: [],
        dataCloseBox: new CloseBox,
        dateOne: "",
        dateTwo: "",
        numberOperation: 0,
        indexCloseBox: -1,
        idCloseBox: -1
    }),
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        eBackList() {
            if (this.tab !== 0) {
                this.indexCloseBox = -1;
                this.dataCloseBox = new CloseBox;
                this.$refs.formcloseboxcomp.resetForm();
                this.tab = 0;
            }
        },
        async getCloseBox(page, dateOne, dateTwo) {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await CloseBox.list(page, dateOne, dateTwo);
                this.closingBoxes = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eChangeDateOne() {
            if (this.dateTwo === "" || this.dateOne === "")
                this.mGDShowAction(["Opereción incorrecta", "Seleccione las fechas porfavor"], "error");
            else {
                if (this.dateOne > this.dateTwo)
                    this.dateTwo = this.dateOne;
                this.getCloseBox(1, this.dateOne, this.dateTwo);
            }
        },
        eChangeDateTwo() {
            if (this.dateTwo === "" || this.dateOne === "")
                this.mGDShowAction(["Opereción incorrecta", "Seleccione las fechas porfavor"], "error");
            else {
                if (this.dateOne > this.dateTwo)
                    this.dateOne = this.dateTwo;
                this.getCloseBox(1, this.dateOne, this.dateTwo);
            }
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        eConfirmForm(isValidForm) {
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                if (isValidForm) {
                    this.smsConfirmations = [`¿Está seguro(a) de editar los datos de ${this.dataCloseBox.ranking.name}?`];
                    this.dialogConfirmationIsOpen = true;
                } else this.mGDShowError();
            }, time);
        },
        async editCloseBox() {
            try {
                const response = await CloseBox.editBox(this.dataCloseBox);
                Object.assign(this.closingBoxes[this.indexCloseBox], response);
                this.eBackList();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se editó el cierre de caja"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eProcessOperation() {
            this.dialogConfirmationIsOpen = false;
            this.dialogOperationIsOpen = true;
            this.editCloseBox();
        },
        eOpenFormEdit(closeBox) {
            this.numberOperation = 0;
            this.dataCloseBox = Object.assign({}, closeBox);
            this.indexCloseBox = this.closingBoxes.indexOf(closeBox);
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getCloseBox(this.currentPage, this.dateOne, this.dateTwo);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mGDSearchForPage(page, this.mRIsInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getCloseBox(page, this.dateOne, this.dateTwo);
                }, time);
            }
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategoryEmployee() === 'a') {
                this.dateOne = this.dateTwo = this.mDateSystem(false);
                this.getCloseBox(1, "", "");
            } else this.$router.replace('/admin/home');
        } else this.$router.replace('/login-admin');
    }
}
</script>