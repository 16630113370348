<template>
    <div>
        <div class="d-flex justify-center">
            <div class="c-image-home">
                <div class="d-flex justify-center mt-3">
                    <div class="verify-email__image">
                        <v-img
                            width="100%"
                            height="100%"
                            src="/imgs_public/mobile/cart.svg"
                            contain
                        ></v-img>
                    </div>
                </div>
                <div>
                    <div class="d-flex mt-4">
                        <div class="o-thid-home__ornament mr-2"></div>
                        <h1 class="pu-app-subtitle-dark">
                            Bienvenido a GATONET compras en linea, su cuenta fue regitrada exitosamente.
                        </h1>
                    </div>
                    <div class="mt-1">
                        <div class="d-flex">
                            <div class="o-thid-home__ornament mr-2"></div>
                            <h1 class="pu-app-subtitle-dark">
                                Se envió un correo de verificación, confirme su registro mediante el correo enviado a
                            </h1>
                        </div>
                        <h1
                            v-if="client.id != undefined"
                            class="pu-app-title-dark ml-5"
                        >
                            {{client.email}}
                        </h1>
                    </div>
                    <div
                        v-if="client.id != undefined"
                        class="mt-3 ml-5"
                    >
                        <PrCFillInformationComp
                            title="Nombre Completo"
                            :subtitle="client.full_name"
                            :isDark="true"
                        />
                        <PrCFillInformationComp
                            title="Correo Gmail"
                            :subtitle="client.email"
                            :isDark="true"
                        />
                        
                    </div>
                </div>
                <MBtnNormalComp
                    class="ml-5 mt-2"
                    @click="eSendEmail()"
                >Volver a Enviar Correo</MBtnNormalComp>
            </div>
        </div>
        <div class="space--block"></div>
        <CSmsErrorComp
            :smsErrors="smsErrors"
            :value="dialogErrorIsOpen"
            :actionErrorIsActivated="actionErrorIsActivated"
            @click="eCloseError()"
        />
        <CSmsActionComp
            :smsActions="smsActions"
            :value="frameAction"
            :isError="isError"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
        />
        <CLoaderOperationComp
            :value="dialogOperationIsOpen"
            :message="message"
        />
    </div>
</template>
<script>
import {
    MBtnNormalComp
} from "../../components/components_public/molecules";
import {
    CSmsErrorComp,
    CSmsActionComp,
    CLoaderOperationComp
} from "../../components/components_public/cells";
import {
    PrCFillInformationComp
} from "../../components/components_private/cells";
import {
    userPublicMixin,
    dataGeneralPublicMixin,
    responseServerMixin
} from "../../mixins";
import {
    User
} from "../../models";
export default {
    mixins: [
        userPublicMixin,
        dataGeneralPublicMixin,
        responseServerMixin
    ],
    components: {
        PrCFillInformationComp,
        MBtnNormalComp,
        CSmsErrorComp,
        CSmsActionComp,
        CLoaderOperationComp
    },
    data: () => ({
        client: {},
        message: "Verificando Cuenta"
    }),
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        async checkScreenEmail() {
            this.dialogOperationIsOpen = true;
            const client = JSON.parse(localStorage.getItem("client_gatonet"));
            this.client = client.client;
            try {
                const response = await User.checkScreenEmail();
                if (!response) this.$router.replace('/account');
                else this.dialogOperationIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async eSendEmail() {
            this.dialogOperationIsOpen = true;
            this.message = "Enviando Correo";
            try {
                const response = await User.sendEmail();
                this.mGDShowAction(["Opereción Procesada", response]);
                this.dialogOperationIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        }
    },
    mounted() {
        if (this.mUserSaveHeader())
            this.checkScreenEmail();
        else this.$router.replace('/login-client');
    }
}
</script>