<template>
    <div class="pa-2 pb-0">
        <div v-if="sale.id != undefined">
            <PrCFillInformationComp
                title="Cliente"
                :subtitle="sale.client.full_name"
            />
            <PrCFillInformationComp
                title="Total Venta"
                :subtitle="`${mSaleTotalDB(sale.product_sale).toFixed(2)} Bs.`"
            />
            <PrCFillInformationComp
                title="Entregado"
                :subtitle="sale.delivered === 0 ? 'No' : 'Si'"
            >
                <div
                    :class="sale.delivered === 1
                        ? 'categories__enabled ml-1'
                        : 'categories__disabled ml-1'"
                    style="margin-top: 0px"
                ></div>
            </PrCFillInformationComp>
            <PrCFillInformationComp
                title="Lugar Entrega"
                :subtitle="getTypeSale()"
            />
        </div>
    </div>
</template>
<script>
import {
    PrCFillInformationComp
} from "../../cells";
import {
    saleMixin
} from "../../../../mixins";
export default {
    mixins: [saleMixin],
    components: {
        PrCFillInformationComp
    },
    props: {
        sale: Object
    },
    methods: {
        getTypeSale() {
            if (this.sale.type_sale === "e") return "Página web";
            if (this.sale.type_sale === "s") return "Tienda";
            return "Personal"
        }
    }
}
</script>