<template>
    <div>
        <div class="d-flex align-center pa-2">
            <h1 class="pr-app-title">
                VENDER
            </h1>
            <v-spacer></v-spacer>
            <div class="d-flex">
                <PrCBtnToolTipHeaderComp
                    icon="mdi-cash"
                    smsToolTip="Ver detalle venta"
                    class="mr-1"
                    @click="tab = 1"
                />
                <PrCBtnToolTipHeaderComp
                    icon="mdi-database"
                    smsToolTip="Volver a la lista"
                    @click="tab = 0"
                />
            </div>
        </div>
        <PrMLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="pa-2 d-flex align-center">
                    <PrOSearchHeaderComp
                        label="Buscar Producto"
                        v-model="search"
                        @entersearch="eSearchProducts()"
                    />
                </div>
                <div class="d-flex align-center pa-2 pt-1">
                    <PrMSelectComp
                        :items="categories"
                        itemValue="id"
                        itemText="categorie"
                        label="Categorías"
                        classSelect="text-field__header mr-1"
                        background=""
                        v-model="idCategorie"
                        :hideDetails="true"
                    />
                    <PrMSelectComp
                        :items="optionsType"
                        itemValue="value"
                        itemText="text"
                        label="Tipo"
                        classSelect="text-field__header"
                        background=""
                        v-model="valueType"
                        :hideDetails="true"
                    />
                </div>
                <v-divider></v-divider>
                <PrCLoaderDataComp
                    v-if="dialogLoaderDataIsOpen"
                    class="mt-2"
                />
                <PrMWithoutInfoCardsComp
                    v-if="!dialogLoaderDataIsOpen && products.length===0"
                    class="ma-2"
                />
                <div
                    v-if="!dialogLoaderDataIsOpen"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardProductSaleComp
                        class="ma-2"
                        v-for="product in products"
                        :key="product.id"
                        :product="product"
                        @clickdetail="eShowDetailProduct(product)"
                        @clickquantity="eAddSelectProduct"
                        @clickform="eOpenFormProduct"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <PrSCompletDetailMobileSaleComp
                    :detailSale="detailSale"
                    :isStore="isStore"
                    @clickstore="isStore = !isStore"
                    @clickedit="eOpenFormEdit"
                    @clicksale="eProcessSale"
                    @cleardetail="eClearDetail()"
                    @clickdetail="eShowDetailProductSale"
                />
            </v-tab-item>
        </v-tabs-items>
        <PrSDialogDetailProductComp
            :value="dialogDetailIsOpen"
            :product="product"
            :categorieUser="categorieUser"
            @clickclose="dialogDetailIsOpen = false"
        />
        <PrCOptionsDeliveredComp
            :value="dialogDeliveredIsOpen"
            :dataSale="dataSale"
            ref="optiondelivered"
            @clickclose="eCloseFormDelivered()"
            @clickdelivered="eSaveDelivered"
        />
        <PrSSearchClientSaleComp
            ref="searchclientcomp"
            :value="dialogClientIsOpen"
            @errorserver="eProccessErrorServer"
            @clickform="eValidNewClient"
            @clickclient="eSelectClient"
            @clickclose="dialogClientIsOpen = false"
        />
        <PrOFormProductMobileSaleComp
            :value="dialogFormProduct"
            :product="productForm"
            :indexDetail="indexDetail"
            ref="formproductcomp"
            @clickclose="eCloseFormProduct()"
            @clickform="eAddProductDetail"
        />
        <PrSDialogSaleClientComp
            :value="dialogSaleClient"
            :sale="saleMade"
            @clickclose="dialogSaleClient = false"
            @clickwhatsapp="eSendWhatsappClient()"
        />
        <PrOGeneralDialogsComp
            :dialogErrorIsOpen="dialogErrorIsOpen"
            :smsErrors="smsErrors"
            :actionErrorIsActivated="actionErrorIsActivated"
            @clickerror="eCloseError()"
            :dialogConfirmationIsOpen="dialogConfirmationIsOpen"
            :smsConfirmations="smsConfirmations"
            @clickyes="addSale()"
            @clickno="eCloseSmsConfirm()"
            :smsActions="smsActions"
            :frameAction="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
            :dialogOperationIsOpen="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMWithoutInfoCardsComp,
    PrMSelectComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCLoaderDataComp,
    PrCOptionsDeliveredComp
} from "../../components/components_private/cells";
import {
    PrOCardProductSaleComp,
    PrOSearchHeaderComp,
    PrOGeneralDialogsComp,
    PrOFormProductMobileSaleComp
} from "../../components/components_private/organisms";
import {
    PrSDialogDetailProductComp,
    PrSCompletDetailMobileSaleComp,
    PrSSearchClientSaleComp,
    PrSDialogSaleClientComp
} from "../../components/components_private/species";
import {
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin,
    saleMixin
} from "../../mixins";
import {
    Product,
    Categorie,
    Sale,
    Client,
    User
} from "../../models";
export default {
    mixins: [
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin,
        saleMixin
    ],
    components: {
        PrMLineHeaderComp,
        PrCBtnToolTipHeaderComp,
        PrOCardProductSaleComp,
        PrCLoaderDataComp,
        PrMWithoutInfoCardsComp,
        PrOSearchHeaderComp,
        PrMSelectComp,
        PrOGeneralDialogsComp,
        PrSDialogDetailProductComp,
        PrOFormProductMobileSaleComp,
        PrSCompletDetailMobileSaleComp,
        PrCOptionsDeliveredComp,
        PrSSearchClientSaleComp,
        PrSDialogSaleClientComp
    },
    data: () => ({
        tab: 0,
        products: [],
        search: "",
        idCategorie: 0,
        categories: [{ id: 0, categorie: "Todos" }],
        optionsType: [
            { value: "t", text: "Todos" },
            { value: "n", text: "Físicos" },
            { value: "d", text: "Digitales" }
        ],
        valueType: "t",
        product: {},
        dialogDetailIsOpen: false,
        detailSale: [],
        productForm: {
            new_price: null,
            quantity: null
        },
        dialogFormProduct: false,
        indexDetail: -1,
        isStore: true,
        dataSale: new Sale,
        dataClient: new Client,
        dataUser: new User,
        with_client: false,
        new_client: false,
        dialogClientIsOpen: false,
        dialogDeliveredIsOpen: false,
        saleMade: {},
        dialogSaleClient: false,
        categorieUser: ""
    }),
    methods: {
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        async getCategories() {
            try {
                const response = await Categorie.getCategoriesSale(this.mUserCategoryEmployee());
                response.forEach(element => {
                    this.categories.push(element);
                });
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async eSearchProducts() {
            if (this.mRItIsEmpty(this.search)) this.search = "";
            this.products = [];
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Product.getProductsSale(this.search, this.idCategorie, this.valueType, this.mUserCategoryEmployee());
                this.products = response;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eShowDetailProduct(product) {
            this.product = Object.assign({}, product);
            this.dialogDetailIsOpen = true;
        },
        thereIsProductInDetail(id) {
            for (let i = 0; i < this.detailSale.length; i++) {
                if (this.detailSale[i].product.id === id) return true;
            }
            return false;
        },
        eAddSelectProduct(parameters) {
            if (this.thereIsProductInDetail(parameters.product.id))
                this.mGDShowAction(["Operación Denegada", "El producto ya se encuentra en el detalle"], "error");
            else {
                let product = parameters.product;
                product.new_quantity = `${parameters.quantity}`;
                product.new_price = `${parameters.product.price}`;
                const dataProduct = {
                    "product": product,
                    "price": parameters.product.price,
                    'quantity': parameters.quantity,
                    "id_reserve": parameters.product.id_reserve,
                    "balance_hold": parameters.product.balance_hold
                }
                this.detailSale.push(dataProduct);
                this.mGDShowAction(["Operación Realizada", "Se agregó el producto"]);
            }
        },
        eOpenFormProduct(product) {
            this.productForm = Object.assign({}, product);
            this.productForm.new_price = null;
            this.productForm.new_quantity = null;
            this.dialogFormProduct = true;
        },
        eCloseFormProduct() {
            this.indexDetail = -1;
            this.productForm.new_price = null;
            this.productForm.new_quantity = null;
            this.$refs.formproductcomp.resetForm();
            this.dialogFormProduct = false;
        },
        generateProductForm(product) {
            const priceValue = parseFloat(product.new_price);
            return {
                "product": product,
                "price": parseFloat(priceValue.toFixed(2)),
                'quantity': parseInt(product.new_quantity)
            }
        },
        addProductDetailOfForm(product) {
            if (this.thereIsProductInDetail(product.id))
                this.mGDShowAction(["Operación Denegada", "El producto ya se encuentra en el detalle"], "error");
            else {
                this.detailSale.push(this.generateProductForm(product));
                this.eCloseFormProduct();
                this.mGDShowAction(["Operación Realizada", "Se agregó el producto"], "success");
            }
        },
        updateProductDetail(product) {
            const newProduct = this.generateProductForm(product);
            Object.assign(this.detailSale[this.indexDetail], newProduct);
            this.indexDetail = -1;
            this.eCloseFormProduct();
            this.mGDShowAction(["Operación Realizada", "Se actualizó el producto"], "success");
        },
        eAddProductDetail(parameters) {
            if (!parameters.isValidForm)
                this.mGDShowAction(["Operación incorrecta", "Corrija los campos rojos"], "error");
            if (this.indexDetail === -1)
                this.addProductDetailOfForm(this.productForm);
            else this.updateProductDetail(this.productForm);
        },
        eClearDetail() {
            this.detailSale = [];
        },
        eOpenFormEdit(product) {
            this.indexDetail = this.detailSale.indexOf(product);
            this.productForm = Object.assign({}, product.product);
            this.dialogFormProduct = true;
        },
        eProcessSale(parameters) {
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                if (this.detailSale.length > 0) {
                    this.productSale = parameters.productSale;
                    this.with_client = !parameters.isDirect;
                    this.dialogDeliveredIsOpen = true;
                } else this.mGDShowAction(["Operación Denegada", "Agregue productos antes de vender"], "error");
            }, time);
        },
        eShowDetailProductSale(product) {
            this.product = Object.assign({}, product.product);
            this.dialogDetailIsOpen = true;
        },
        //
        eCloseSmsConfirm() {
            this.eCloseFormDelivered();
            this.dialogConfirmationIsOpen = false;
        },
        eCloseFormDelivered() {
            this.dataSale.delivered = null;
            this.dataSale.type_coin = "e";
            this.$refs.optiondelivered.resetForm();
            this.dialogDeliveredIsOpen = false;
        },
        eSaveDelivered() {
            if (this.dataSale.delivered == null)
                this.mGDShowAction(["Error de Elección", "Seleccione la entrega"], "error");
            else {
                this.dialogDeliveredIsOpen = false;
                if (this.with_client) this.dialogClientIsOpen = true;
                else this.showInfoConfirmationSale();
            }
        },
        eProccessErrorServer(error) {
            this.processErrorServer(error);
        },
        eValidNewClient(parameters) {
            if (!parameters.isValidForm)
                this.mGDShowAction(["Operación Incorrecta", "Corrija los datos en rojo"], "error");
            else {
                this.new_client = true;
                this.dialogClientIsOpen = false;
                this.$refs.searchclientcomp.resetData();
                this.dataClient = parameters.client;
                this.dataUser = parameters.user;
                this.showInfoConfirmationSale();
            }
        },
        eSelectClient(client) {
            this.new_client = false;
            this.dialogClientIsOpen = false;
            this.dataClient = Object.assign({}, client);
            this.$refs.searchclientcomp.resetData();
            this.showInfoConfirmationSale();
        },
        resetDataSale() {
            this.detailSale = [];
            this.dataUser = new User;
            this.dataClient = new Client;
            this.dataSale = new Sale;
            this.$refs.optiondelivered.resetForm();
            this.productSale = [];
            this.new_client = false;
            this.with_client = false;
            this.products = [];
        },
        showInfoConfirmationSale() {
            const total = this.mSaleTotalLocalStore(this.productSale);
            const nameClient = this.with_client
                ? this.dataClient.full_name : "S/N";
            const textDelivered = this.dataSale.delivered === 0 ? 'No' : 'Si';
            const textTypeSale = this.dataSale.type_sale === "t" ? "Tienda" : "Venta Personal";
            this.smsConfirmations = [`¿${this.mUserNameEmployee()}, está seguro(a) de registrar la venta:`,
                `Total Venta: ${total.toFixed(2)} Bs.`,
                `Cliente: ${nameClient}`,
                `Entregado: ${textDelivered}`,
                `Vendido de: ${textTypeSale}`];
            this.dialogConfirmationIsOpen = true;
        },
        dataUserForm() {
            if (!this.with_client) return {};
            if (!this.new_client) return {};
            let md5 = require('md5');
            return {
                "username": this.dataClient.email,
                "password": md5(this.dataUser.password)
            }
        },
        async addSale() {
            this.dialogConfirmationIsOpen = false;
            this.dialogOperationIsOpen = true;
            const parameters = {
                "with_client": this.with_client,
                "new_client": this.new_client,
                "client": !this.with_client ? {} : this.dataClient,
                "user": this.dataUserForm(),
                "sale": {
                    "delivered": this.dataSale.delivered,
                    "type_sale": this.isStore ? "s" : "p",
                    "type_coin": this.dataSale.type_coin,
                    "note": this.dataSale.note
                },
                "product_sale": this.productSale
            }
            try {
                const response = await Sale.addSale(parameters);
                this.saleMade = response;
                //if (response.client.email != '0' && response.client.phone != null)
                this.dialogSaleClient = true;
                this.resetDataSale();
                this.dialogOperationIsOpen = false;
                this.tab = 0;
                this.mGDShowAction(["Operación Exitosa", "La venta fue registrada"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eSendWhatsappClient() {
            this.dialogSaleClient = false;
            const message = `${this.saleMade.client.full_name} muchas gracias por tu compra,
                que tengas un gran día. GATONET`;
            setTimeout(() => {
                window.open(`https://api.whatsapp.com/send?phone=${this.saleMade.client.phone}&text=${message}`);
            }, 300);
        }
    },
    mounted() {
        if (window.innerWidth > 600) this.$router.replace('/admin/sales');
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            this.getCategories('', 2);
            this.categorieUser = this.mUserCategoryEmployee();
        } else this.$router.replace('/login-admin');
    }
}
</script>