<template>
    <v-dialog
        persistent
        :value="value"
        width="500"
    >
        <v-card class="pa-0 ma-0 cs-pu-background">
            <div class="d-flex align-center pa-2 cf-pu-background">
                <h1 class="pr-app-title-dark">
                    PEDIDO EXITOSO
                </h1>
            </div>
            <div class="pa-2">
                <h1 class="pu-app-subtitle-dark">
                    {{getTitle()}}
                </h1>
                <h1 class="pu-app-title-dark">
                    N° | Producto (Cantidad)
                </h1>
                <div
                    v-for="(product, index) in dataSend"
                    :key="index"
                >
                    <h1 class="pu-app-title-dark">
                        {{eFillText(index, product)}}
                    </h1>
                </div>
                <div class="text-center mt-1">
                    <MBtnNormalComp
                        :xSmall="isMobile"
                        :small="!isMobile"
                        class="mr-1"
                        :outlined="false"
                        className="cp-pu-background"
                        @click="$emit('clickwhatsapp')"
                    >
                        ENVIAR WHATSAPP
                    </MBtnNormalComp>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    MBtnNormalComp
} from "../molecules";
export default {
    props: {
        value: Boolean,
        dataSend: Array,
        isMobile: Boolean
    },
    components: {
        MBtnNormalComp
    },
    methods: {
        getTitle() {
            if (!JSON.parse(localStorage.getItem("client_gatonet"))) return "";
            const client = JSON.parse(localStorage.getItem("client_gatonet")).client;
            const message = `${client.full_name}, GATONET recibió tu pedido`;
            return message;
        },
        eFillText(index, product) {
            return `${(index + 1)} | ${product.product} (${product.quantity})`;
        }
    }
}
</script>