var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","value":_vm.value,"width":"360"}},[_c('v-card',{staticClass:"pa-0 ma-0"},[_c('div',{staticClass:"d-flex align-center pa-2 pr-cp-background"},[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v(" "+_vm._s(_vm.indexDigital === -1 ? 'Agregar Código' : 'Editar Código')+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","x-small":"","depressed":"","color":"red"},on:{"click":function($event){return _vm.$emit('clickclose')}}},[_c('v-icon',{staticClass:"pr-cd-color"},[_vm._v("mdi-close")])],1)],1),_c('v-form',{ref:"formdigital",staticClass:"pa-2",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('PrMTextFieldComp',{staticClass:"mt-2",attrs:{"label":"Código","counter":50,"rules":[
                    value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty,
                    value => (`${value}`).length <= 50 || _vm.smsExecedCounter(50)
                ]},model:{value:(_vm.dataDigital.code),callback:function ($$v) {_vm.$set(_vm.dataDigital, "code", $$v)},expression:"dataDigital.code"}}),_c('PrMTextAreaComp',{staticClass:"mt-1",attrs:{"label":"Descripción","counter":200,"rules":[
                    value => (`${value}`).length <= 200 || _vm.smsExecedCounter(50)
                ]},model:{value:(_vm.dataDigital.description),callback:function ($$v) {_vm.$set(_vm.dataDigital, "description", $$v)},expression:"dataDigital.description"}}),_c('PrMTextFieldComp',{staticClass:"mt-1",attrs:{"label":"Fecha Expiración","type":"date","rules":[
                    value => !_vm.mRItIsEmpty(value) || _vm.smsEmpty
                ]},model:{value:(_vm.dataDigital.expiration),callback:function ($$v) {_vm.$set(_vm.dataDigital, "expiration", $$v)},expression:"dataDigital.expiration"}}),_c('div',{staticClass:"d-flex justify-center mt-1"},[_c('PrMBtnNormalComp',{on:{"click":function($event){return _vm.eValidateForm()}}},[_c('v-icon',[_vm._v("mdi-bookmark-check")]),_vm._v(" GUARDAR ")],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }