<template>
    <v-dialog
        persistent
        :value="value"
        width="450"
    >
        <v-card class="pa-0 ma-0">
            <div class="d-flex align-center pa-2 pr-cp-background">
                <h1 class="pr-app-title-dark">Detalle Producto</h1>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    x-small
                    depressed
                    color="red"
                    @click="$emit('clickclose')"
                >
                    <v-icon
                        class="pr-cd-color"
                    >mdi-close</v-icon>
                </v-btn>
            </div>
            <div class="pa-2">
                <PrODialogInfoProductComp
                    :product="product"
                    :categorieUser="categorieUser"
                />
                <div v-if="categorieUser === 'a'">
                    <PrCFillInformationComp
                        title="Requiere Información"
                        :subtitle="product.necessary_info === 1 ? 'Si' : 'No'"
                    />
                    <h1 class="pr-app-title">Información Requerida</h1>
                    <h1
                        class="pr-app-subtitle"
                        style="white-space: pre-line;"
                    >
                        {{product.info_guide}}
                    </h1>
                    <PrCFillInformationComp
                        title="Con Saldo"
                        :subtitle="product.id_reserve === 0 ? 'No' : 'Si'"
                    />
                    <PrCFillInformationComp
                        v-if="product.id_reserve > 0"
                        title="Retención Saldo"
                        :subtitle="product.balance_hold.toFixed(2)"
                    />
                    <PrCFillInformationComp
                        title="Estado"
                        :subtitle="product.enabled === 1 ? 'Habilitado' : 'Inhabilitado'"
                    >
                        <div
                            :class="product.enabled === 1
                                ? 'categories__enabled ml-1'
                                : 'categories__disabled ml-1'"
                        ></div>
                    </PrCFillInformationComp>
                    <PrCFillInformationComp
                        title="Web"
                        :subtitle="product.web_enabled === 1 ? 'Si' : 'No'"
                    >
                        <div
                            :class="product.web_enabled === 1
                                ? 'categories__enabled ml-1'
                                : 'categories__disabled ml-1'"
                        ></div>
                    </PrCFillInformationComp>
                    <PrCFillInformationComp
                        title="Considerar Tiempo"
                        :subtitle="product.consider_time == 1 ? 'Si' : 'No'"
                    />
                    <PrCFillInformationComp
                        title="Tiempo"
                        :subtitle="product.time != null ? product.time : 'S/T'"
                    />
                </div>
                <PrCFillInformationComp
                    title="Tipo"
                    :subtitle="product.type === 'n' ? 'Físico' : 'Digital'"
                />
                <div v-if="product.instructions != null">
                    <h1 class="pr-app-title">Instrucciones Web</h1>
                    <h1
                        class="pr-app-subtitle"
                        style="white-space: pre-line;"
                    >
                        {{product.instructions}}
                    </h1>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    PrCFillInformationComp
} from "../cells"
import {
    PrODialogInfoProductComp
} from "../organisms";
export default {
    props: {
        product: Object,
        value: Boolean,
        categorieUser: {
            type: String,
            default: "v"
        }
    },
    components: {
        PrODialogInfoProductComp,
        PrCFillInformationComp
    }
}
</script>