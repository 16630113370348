<template>
    <div>
        <div class="categories__header pa-2">
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    v-if="!isMobile"
                    :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                    :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                    class="mr-2"
                    @click="$emit('clickmini')"
                />
                <h1 class="pr-app-title">
                    {{`CÓDIGOS (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="categories__header-search">
                <v-card
                    width="100%"
                    height="32"
                    outlined
                    :ripple="false"
                    elevation="0"
                    class="mr-1"
                    @click="dialogSearchProductIsOpen = true"
                >
                    <h1 class="pr-app-subtitle text-center mt-1">
                        {{getNameProduct}}
                    </h1>
                </v-card>
            </div>
        </div>
        <PrMLineHeaderComp />
        <div class="d-flex justify-center flex-wrap pa-2">
            <div class="pr-products__search">
                <div class="pr-products__search-block">
                    <PrMSelectComp
                        label="Estado"
                        v-model="valueEnabled"
                        :items="optionsEnabled"
                        itemValue="value"
                        background=""
                        itemText="text"
                        :hideDetails="true"
                        @change="eChangeEnabled"
                    />
                </div>
                <div class="pr-products__search-block">
                    <PrMSelectComp
                        label="Expiración"
                        v-model="valueExpired"
                        :items="optionsExpired"
                        itemValue="value"
                        background=""
                        itemText="text"
                        :hideDetails="true"
                        @change="eChangeExpired"
                    />
                </div>
            </div>
        </div>
        <v-divider></v-divider>
        <PrCLoaderDataComp
            v-if="dialogLoaderDataIsOpen"
            class="mt-2"
        />
        <PrMWithoutInfoCardsComp
            v-if="!dialogLoaderDataIsOpen && digitals.length===0 && isMobile"
            class="ma-2"
        />
        <PrOTableDigitalCodeComp
            v-if="!dialogLoaderDataIsOpen && !isMobile"
            :digitals="digitals"
            @clickstate="eChangeState"
            @clickdetail="eShowDetailProduct"
            @clickdelete="eConfirmDelete"
        />
        <div
            v-if="!dialogLoaderDataIsOpen && isMobile"
            class="d-flex justify-center flex-wrap"
        >
            <PrOCardDigitalCodeComp
                class="ma-2"
                v-for="digital in digitals"
                :key="digital.id"
                :digital="digital"
                @clickstate="eChangeState(digital)"
                @clickdetail="eShowDetailProduct(digital)"
                @clickdelete="eConfirmDelete(digital)"
            />
        </div>
        <v-divider
            class="mt-1"
            v-if="isMobile && !dialogLoaderDataIsOpen && digitals.length > 0"
        ></v-divider>
        <PrSPaginationComp
            class="mt-2 mb-2"
            v-if="!dialogLoaderDataIsOpen && digitals.length > 0"
            :condicionGo="currentPage < lastPage"
            :condicionBack="currentPage > 1"
            :titlePagination="'Pag. '+currentPage+' de '+lastPage"
            @clickback="eChangePage(false)"
            @clickgo="eChangePage(true)"
            @clickpage="eSearchForPage"
        />
        <PrSDialogDetailProductComp
            :value="dialogDetailIsOpen"
            :product="product"
            @clickclose="dialogDetailIsOpen = false"
        />
        <PrSSearchProductComp
            :value="dialogSearchProductIsOpen"
            @clickclose="dialogSearchProductIsOpen = false"
            @clickproduct="eSelectProduct"
        />
        <PrOGeneralDialogsComp
            :dialogErrorIsOpen="dialogErrorIsOpen"
            :smsErrors="smsErrors"
            :actionErrorIsActivated="actionErrorIsActivated"
            @clickerror="eCloseError()"
            :dialogConfirmationIsOpen="dialogConfirmationIsOpen"
            :smsConfirmations="smsConfirmations"
            @clickyes="eProcessOperation()"
            @clickno="dialogConfirmationIsOpen = false"
            :smsActions="smsActions"
            :frameAction="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
            :dialogOperationIsOpen="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMWithoutInfoCardsComp,
    PrMSelectComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCLoaderDataComp
} from "../../components/components_private/cells";
import {
    PrOTableDigitalCodeComp,
    PrOGeneralDialogsComp,
    PrOCardDigitalCodeComp
} from "../../components/components_private/organisms";
import {
    PrSPaginationComp,
    PrSDialogDetailProductComp,
    PrSSearchProductComp
} from "../../components/components_private/species";
import {
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin
} from "../../mixins";
import {
    Digital
} from "../../models";

export default {
    props: {
        mini: Boolean
    },
    components: {
        PrMLineHeaderComp,
        PrCBtnToolTipHeaderComp,
        PrSPaginationComp,
        PrMWithoutInfoCardsComp,
        PrOTableDigitalCodeComp,
        PrCLoaderDataComp,
        PrMSelectComp,
        PrOGeneralDialogsComp,
        PrSDialogDetailProductComp,
        PrSSearchProductComp,
        PrOCardDigitalCodeComp
    },
    mixins: [
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin
    ],
    data: () => ({
        digitals: [],
        idProductSearch: 0,
        valueEnabled: 2,
        optionsEnabled: [
            { value: 2, text: "Todos" },
            { value: 0, text: "Inhabilitados" },
            { value: 1, text: "Habilitados" }
        ],
        valueExpired: "t",
        optionsExpired: [
            { value: "t", text: "Todos" },
            { value: "e", text: "Expirados" },
            { value: "o", text: "No Expirados" }
        ],
        indexDigital: -1,
        idDigital: -1,
        dialogDetailIsOpen: false,
        product: {},
        dialogSearchProductIsOpen: false,
        nameProduct: "Todos",
        numberOperation: 0,
        idProduct: -1
    }),
    computed: {
        getNameProduct: function() {
            if (this.nameProduct.length > 17)
                return `${this.nameProduct.substring(0, 14)}...`;
            return this.nameProduct;
        }
    },
    methods: {
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        async getDigitalsExpired(page, idProduct, enabled, expired) {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Digital.getDigitalExpired(page, idProduct, enabled, expired);
                this.digitals = response.data;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.total = response.total;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eChangeEnabled(enabled) {
            this.getDigitalsExpired(1, this.idProductSearch, enabled, this.valueExpired);
        },
        eChangeExpired(expired) {
            this.getDigitalsExpired(1, this.idProductSearch, this.valueEnabled, expired);
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getDigitalsExpired(this.currentPage, this.idProductSearch, this.valueEnabled, this.valueExpired);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mGDSearchForPage(page, this.mRIsInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getDigitalsExpired(page, this.idProductSearch, this.valueEnabled, this.valueExpired);
                }, time);
            }
        },
        eChangeState(digital) {
            this.numberOperation = 0;
            this.indexDigital = this.digitals.indexOf(digital);
            this.idDigital = digital.id;
            const textState = digital.enbled === 0 ? 'habilitar' : 'inhabilitar';
            this.smsConfirmations = [`¿Está seguro de ${textState} el código del producto: ${digital.product.product}?`];
            this.dialogConfirmationIsOpen = true;
        },
        async changeState() {
            try {
                const response = await Digital.changeStateDigital({id: this.idDigital});
                this.digitals[this.indexDigital].enabled = response;
                const textState = response === 0 ? 'inhbilitó' : 'habilitó';
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", `Se ${textState} el código`]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async deleteDigitalPending()
        {
            const parameters = {
                id: this.idDigital,
                id_product: this.idProduct
            };
            try {
                await Digital.deleteDigitalPending(parameters);
                this.digitals.splice(this.indexDigital, 1);
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se eliminó el código"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eProcessOperation() {
            this.dialogConfirmationIsOpen = false;
            this.dialogOperationIsOpen = true;
            switch (this.numberOperation) {
                case 0:
                    this.changeState();
                    break;
                case 1:
                    this.deleteDigitalPending();
                    break;
            }
        },
        eShowDetailProduct(digital) {
            this.product = digital.product;
            this.dialogDetailIsOpen = true;
        },
        eSelectProduct(product) {
            this.nameProduct = product.id === 0 ? 'Todos' : product.product;
            this.idProductSearch = product.id;
            this.dialogSearchProductIsOpen = false;
            this.getDigitalsExpired(1, product.id, this.valueEnabled, this.valueExpired);
        },
        eConfirmDelete(digital) {
            this.numberOperation = 1;
            this.idProduct = digital.product.id;
            this.idDigital = digital.id;
            this.indexDigital = this.digitals.indexOf(digital);
            this.smsConfirmations = ['¿Está seguro(a) de eliminar el código pendiente?'];
            this.dialogConfirmationIsOpen = true;
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategoryEmployee() === 'a')
                this.getDigitalsExpired(1, 0, 2, "t");
            else this.$router.replace('/admin/home');
        } else this.$router.replace('/login-admin');
    }
}
</script>