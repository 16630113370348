import { mapMutations, mapState } from "vuex";
export default {
    computed: {
        ...mapState("userClientStore", ["existHeader"])
    },
    methods: {
        ...mapMutations("userClientStore", ["saveHeader", "activateHeader"]),
        mUserSaveHeader() {
            if (JSON.parse(localStorage.getItem("client_gatonet"))) {
                if (!this.existHeader) {
                    let apitoken = JSON.parse(localStorage.getItem("client_gatonet")).token;
                    let header = {
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Authorization": "Bearer " + apitoken,
                    }
                    this.saveHeader(header);
                    this.activateHeader();
                }
                return true;
            }
            return false;
        },
        mUserUsername() {
            const user = JSON.parse(localStorage.getItem("client_gatonet"));
            return user.client.email;
        }
    }
}