<template>
    <div class="o-card-cart animation-opacity">
        <div class="o-card-cart__header">
            <h1 class="pu-app-title-dark">
                {{product.product.product}}
            </h1>
        </div>
        <div class="o-card-cart__body">
            <CFillInformationComp
                title="Tipo"
                :subtitle="product.product.type === 'd' ? 'Digital' : 'Físico'"
            />
            <CFillInformationComp
                title="Cantidad"
                :subtitle="`${product.quantity}`"
            />
            <CFillInformationComp
                title="Precio"
                :subtitle="product.price_unity.toFixed(2)"
            />
            <CFillInformationComp
                title="Total"
                :subtitle="(product.quantity * product.price_unity).toFixed(2)"
            />
            <div v-if="product.info != null">
                <h1 class="pu-app-title-dark">
                    Información Extra
                </h1>
                <h1
                    class="pu-app-subtitle-dark ml-2"
                    style="white-space: pre-line;"
                >
                    {{product.info != null
                        ? product.info
                        : 'S/I'}}
                </h1>
            </div>
            <CFillInformationComp
                v-else
                title="Información Extra"
                subtitle="S/I"
            />
            <CFillInformationComp
                title="Envío"
                :subtitle="getTextSend()"
            />
            <v-divider class="mt-1" dark></v-divider>
        </div>
        <div class="cf-pu-background d-flex justify-center pb-2">
            <MBtnNormalComp
                @click="$emit('clickdetail')"
                :xSmall="true"
                :small="false"
                class="mr-1"
            >
                <v-icon small>mdi-format-list-bulleted-square</v-icon>
            </MBtnNormalComp>
            <MBtnNormalComp
                @click="$emit('clickkeys')"
                :xSmall="true"
                :small="false"
                class="mr-1"
            >
                <v-icon small>mdi-gamepad-variant</v-icon>
            </MBtnNormalComp>
        </div>
    </div>
</template>
<script>
import {
    MBtnNormalComp
} from "../../molecules";
import {
    CFillInformationComp
} from "../../cells";
export default {
    components: {
        CFillInformationComp,
        MBtnNormalComp
    },
    props: {
        product: Object,
        delivered: Number
    },
    methods: {
        getTextSend() {
            if (this.product.product.type === "n" && this.delivered === 1) return "Entregado";
            for (let k = 0; k < this.product.digitals.length; k++) {
                if (this.product.digitals[k].this_slope === 1) return 'Pendiente';
            }
            return "Entregado";
        }
    }
}
</script>