const domain = process.env.VUE_APP_ROOT_API;
export const apiUrls = {
    CATEGORIE: `${domain}categorie/`,
    USER: `${domain}user/`,
    EMPLOYEE: `${domain}employee/`,
    PRODUCT: `${domain}product/`,
    SALE: `${domain}sale/`,
    IMAGE: `${domain}image`,
    DIGITAL: `${domain}digital/`,
    CLIENT: `${domain}client/`,
    CLOSE_BOX: `${domain}close-box/`,
    SETTING: `${domain}setting/`,
    RECOMMENDATION: `${domain}recommendation/`,
    PUBLICATION: `${domain}publication/`,
    DIGITAL_SALE: `${domain}digital-sale/`,
    RANKING: `${domain}ranking/`,
    RESERVE: `${domain}reserve/`
}