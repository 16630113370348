<template>
    <div
        class="pr-c-photo-form"
        @click="eSelectFileDinamic()"
        style="cursor:pointer"
        @drop.prevent="eAddFile"
        @dragover.prevent
    >
        <v-card
            class="rounded-0"
            elevation="0"
            color="transparent"
            width="100%"
            height="100%"
        >
            <v-card
                :class="routePhoto != ''
                    ? 'rounded-0'
                    : 'd-flex justify-center align-center'"
                elevation="0"
                color="transparent"
                width="100%"
                height="100%"
            >
                <v-img
                    v-if="routePhoto != ''"
                    :src="routePhoto"
                    width="100%"
                    height="100%"
                    contain
                ></v-img>
                <div v-else>
                    <h1 class="pr-app-title text-center">{{title}}</h1>
                    <h1 class="pr-app-subtitle text-center">{{subtitle}}</h1>
                    <h1 class="pr-app-subtitle text-center">(Máximo: 3Mb)</h1>
                </div>
            </v-card>
        </v-card>
        <input
            type="file"
            :ref="refImg"
            class="d-none"
            :accept="fileFormat"
            @change="eSelectFile"
        >
    </div>
</template>
<script>
export default {
    props: {
        refImg: String,
        title: String,
        subtitle: String,
        fileFormat: String,
    },
    data: () => ({
        routePhoto: ""
    }),
    methods: {
        resetData() {
            this.routePhoto = "";
        },
        loaderImg(file) {
            let reader = new FileReader();
            reader.onload = (e) => {
                this.routePhoto = e.target.result;
            }
            reader.readAsDataURL(file);
        },
        eAddFile(e) {
            const files = e.dataTransfer.files;
            if (files[0] !== undefined) {
                let file = e.dataTransfer.files[0];
                if (file.size <= 3145728) { //2097152
                    if (file.type != 'image/jpeg')
                        this.sendValuePhoto(null, false);
                    else this.sendValuePhoto(file, true);
                } else this.sendValuePhoto(null, false);
            } else this.sendValuePhoto(null, false);
        },
        sendValuePhoto(file, state) {
            const parameters = {
                "file": file,
                "state": state
            };
            this.$emit("changephoto", parameters);
            if (state) this.loaderImg(file);
            else this.routePhoto = "";
        },
        eSelectFile(e) {
            const files = e.target.files
            if (files[0] !== undefined) {
                let file = e.target.files[0];
                if (file.size <= 2097152)
                    this.sendValuePhoto(file, true);
                else this.sendValuePhoto(null, false);
            } else this.sendValuePhoto(null, false);
        },
        eSelectFileDinamic() {
            this.$refs[this.refImg].click();
        }
    }
}
</script>