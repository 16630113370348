<template>
    <div>
        <div class="categories__header pa-2">
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    v-if="!isMobile"
                    :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                    :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                    class="mr-2"
                    @click="$emit('clickmini')"
                />
                <h1 class="pr-app-title">
                    {{`VENTAS A ENTREGAR (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="categories__header-search">
                <PrOSearchHeaderComp
                    class="mr-1"
                    label="Buscar Cliente"
                    v-model="search"
                    @entersearch="eSearchClient()"
                />
                <PrCBtnToolTipHeaderComp
                    icon="mdi-database"
                    smsToolTip="Volver a la lista"
                    @click="tab = 0"
                />
            </div>
        </div>
        <PrMLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <div class="d-flex justify-center flex-wrap pa-2">
                    <div class="pr-products__search">
                        <div class="pr-products__search-block">
                            <PrMTextFieldComp
                                label="Fecha Inicio"
                                v-model="dateOne"
                                background=""
                                type="date"
                                :clearable="false"
                                :hideDetails="true"
                                @change="eChangeDateOne"
                            />
                        </div>
                        <div class="pr-products__search-block">
                            <PrMTextFieldComp
                                label="Fecha Fin"
                                v-model="dateTwo"
                                background=""
                                type="date"
                                :clearable="false"
                                :hideDetails="true"
                                @change="eChangeDateTwo"
                            />
                        </div>
                    </div>
                </div>
                <v-divider></v-divider>
                <PrCLoaderDataComp
                    v-if="dialogLoaderDataIsOpen"
                    class="mt-2"
                />
                <PrMWithoutInfoCardsComp
                    v-if="!dialogLoaderDataIsOpen && sales.length===0 && isMobile"
                    class="ma-2"
                />
                <PrOTableSalesComp
                    v-if="!dialogLoaderDataIsOpen && !isMobile"
                    :isSend="true"
                    :sales="sales"
                    :search="searchFilter"
                    @clickinfo="eShowInfoSale"
                    @clickdetail="eShowDetailSale"
                    @clicksend="eConfirmSendSale"
                />
                <div
                    v-if="!dialogLoaderDataIsOpen && isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardSaleComp
                        :isSend="true"
                        class="ma-2"
                        :search="searchFilter"
                        v-for="sale in sales"
                        :key="sale.id"
                        :sale="sale"
                        @clickinfo="eShowInfoSale(sale)"
                        @clickdetail="eShowDetailSale(sale)"
                        @clicksend="eConfirmSendSale(sale)"
                    />
                </div>
                <v-divider
                    class="mt-1"
                    v-if="isMobile && !dialogLoaderDataIsOpen && sales.length > 0"
                ></v-divider>
                <PrSPaginationComp
                    class="mt-2 mb-2"
                    v-if="!dialogLoaderDataIsOpen && sales.length > 0"
                    :condicionGo="currentPage < lastPage"
                    :condicionBack="currentPage > 1"
                    :titlePagination="'Pag. '+currentPage+' de '+lastPage"
                    @clickback="eChangePage(false)"
                    @clickgo="eChangePage(true)"
                    @clickpage="eSearchForPage"
                />
            </v-tab-item>
            <v-tab-item>
                <PrOInfoSaleComp
                    :sale="sale"
                />
                <PrOTableDetailSaleComp
                    v-if="!isMobile"
                    :detail="sale.product_sale"
                    :categorieUser="categorieUser"
                    @clickproduct="eInfoProduct"
                />
                <div
                    v-if="isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardDetailSaleComp
                        class="ma-2"
                        v-for="product in sale.product_sale"
                        :key="product.id"
                        :categorieUser="categorieUser"
                        :product="product"
                        @clickproduct="eInfoProduct(product)"
                    />
                </div>
            </v-tab-item>
        </v-tabs-items>
        <PrSDialogDetailReportSaleComp
            :value="dialogDetailReportIsOpen"
            :sale="sale"
            @clickclose="dialogDetailReportIsOpen = false"
        />
        <PrSDialogDetailProductComp
            :value="dialogDetailIsOpen"
            :product="product"
            :categorieUser="categorieUser"
            @clickclose="dialogDetailIsOpen = false"
        />
        <PrOGeneralDialogsComp
            :dialogErrorIsOpen="dialogErrorIsOpen"
            :smsErrors="smsErrors"
            :actionErrorIsActivated="actionErrorIsActivated"
            @clickerror="eCloseError()"
            :dialogConfirmationIsOpen="dialogConfirmationIsOpen"
            :smsConfirmations="smsConfirmations"
            @clickyes="eProcessOperation()"
            @clickno="dialogConfirmationIsOpen = false"
            :smsActions="smsActions"
            :frameAction="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
            :dialogOperationIsOpen="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMTextFieldComp,
    PrMWithoutInfoCardsComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCLoaderDataComp,
} from "../../components/components_private/cells";
import {
    PrOGeneralDialogsComp,
    PrOTableSalesComp,
    PrOCardSaleComp,
    PrOInfoSaleComp,
    PrOTableDetailSaleComp,
    PrOCardDetailSaleComp,
    PrOSearchHeaderComp
} from "../../components/components_private/organisms";
import {
    PrSPaginationComp,
    PrSDialogDetailReportSaleComp,
    PrSDialogDetailProductComp
} from "../../components/components_private/species";
import {
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin
} from "../../mixins";
import {
    Sale
} from "../../models";
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin
    ],
    components: {
        PrMLineHeaderComp,
        PrCBtnToolTipHeaderComp,
        PrMTextFieldComp,
        PrOGeneralDialogsComp,
        PrCLoaderDataComp,
        PrMWithoutInfoCardsComp,
        PrOTableSalesComp,
        PrOCardSaleComp,
        PrSPaginationComp,
        PrOInfoSaleComp,
        PrOTableDetailSaleComp,
        PrOCardDetailSaleComp,
        PrSDialogDetailReportSaleComp,
        PrSDialogDetailProductComp,
        PrOSearchHeaderComp
    },
    data: () => ({
        dateOne: "",
        dateTwo: "",
        tab: 0,
        sales: [],
        sale: {
            product_sale: []
        },
        dialogDetailReportIsOpen: false,
        product: {},
        dialogDetailIsOpen: false,
        search: "",
        categorieUser: ""
    }),
    computed: {
        searchFilter: function() {
            if (this.mRItIsEmpty(this.search)) return "";
            return this.search;
        }
    },
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        async getSales(page, dateOne, dateTwo, search) {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Sale.getSalesNotShipped(page, dateOne, dateTwo, search);
                this.sales = response.data;
                this.total = response.total;
                this.lastPage = response.last_page;
                this.currentPage = response.current_page;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eSearchClient() {
            if (this.mRItIsEmpty(this.search)) this.search = "";
            this.getSales(1, this.dateOne, this.dateTwo, this.search);
        },
        eChangeDateOne() {
            if (this.dateTwo === "" || this.dateOne === "")
                this.mGDShowAction(["Opereción incorrecta", "Seleccione las fechas porfavor"], "error");
            else {
                if (this.dateOne > this.dateTwo)
                    this.dateTwo = this.dateOne;
                this.getSales(1, this.dateOne, this.dateTwo, this.search);
            }
        },
        eChangeDateTwo() {
            if (this.dateTwo === "" || this.dateOne === "")
                this.mGDShowAction(["Opereción incorrecta", "Seleccione las fechas porfavor"], "error");
            else {
                if (this.dateOne > this.dateTwo)
                    this.dateOne = this.dateTwo;
                this.getSales(1, this.dateOne, this.dateTwo, this.search);
            }
        },
        eChangePage(isGo) {
            if (isGo) ++this.currentPage;
            else --this.currentPage;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.getSales(this.currentPage, this.dateOne, this.dateTwo, this.search);
            }, time);
        },
        eSearchForPage(page) {
            const isNotValidPage = this.mGDSearchForPage(page, this.mRIsInteger(page));
            if (isNotValidPage) {
                const time = this.mAnimationScrollTime();
                this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
                setTimeout(() => {
                    this.getSales(page, this.dateOne, this.dateTwo, this.search);
                }, time);
            }
        },
        eShowInfoSale(sale) {
            this.sale = sale;
            this.dialogDetailReportIsOpen = true;
        },
        eShowDetailSale(sale) {
            this.sale = sale;
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        },
        eInfoProduct(product) {
            this.product = product.product;
            this.dialogDetailIsOpen = true;
        },
        async eProcessOperation() {
            this.dialogConfirmationIsOpen = false;
            this.dialogOperationIsOpen = true;
            try {
                await Sale.sendSale({id: this.sale.id});
                const index = this.sales.indexOf(this.sale);
                this.sales.splice(index, 1);
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Opereción Realizada", "Se dió por enviada la venta"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eConfirmSendSale(sale) {
            this.sale = sale;
            this.smsConfirmations = [
                `¿Está seguro(a) de dar por enviado la venta 
                del cliente: ${sale.client.full_name}?`];
            this.dialogConfirmationIsOpen = true;
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            this.categorieUser = this.mUserCategoryEmployee();
            if (this.mUserCategoryEmployee() === 'a')
                this.getSales(1, "", "", "");
            else this.$router.replace('/admin/home');
        } else this.$router.replace('/login-admin');
    }
}
</script>