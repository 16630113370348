<template>
    <div class="pr-o-table-categories animation-opacity">
        <table class="pr-o-table-categories__table">
            <thead>
                <tr>
                    <th>
                        <h1 class="pr-app-title-dark text-center">N°</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Código</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Descripción</h1>
                    </th>
                    <th>
                        <h1 class="pr-app-title-dark">Fecha Exp.</h1>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(code, index) in codes"
                    :key="code.id"
                >
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{(index + 1)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{mCryDecryptText(code.code)}}
                        </h1>
                    </td>
                    <td>
                        <h1 class="pr-app-subtitle">
                            {{code.description != null
                                ? code.description
                                : "S/D"}}
                        </h1>
                    </td>
                    <td>
                        <div class="d-flex align-center">
                            <h1 class="pr-app-subtitle">
                                {{mDateFormat(code.expiration)}}
                            </h1>
                            <div
                                :class="code.expired
                                    ? 'categories__disabled ml-1'
                                    : 'categories__enabled ml-1'"
                                style="margin-top:-2px"    
                            ></div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import {
    datesMixin,
    cryptographyMixin
} from "../../../../mixins";
export default {
    mixins: [
        datesMixin,
        cryptographyMixin
    ],
    props: {
        codes: Array
    }
}
</script>