import { apiUrls } from "../plugins/apiUrls";
import axios from "axios";
import { userStore } from "../store/modules";
class Setting {
    constructor(
        address = null,
        phone = null,
        facebook = null,
        instagram = null,
        email = null,
        tik_tok = null
    ) {
        this.address = address;
        this.phone = phone;
        this.facebook = facebook;
        this.instagram = instagram;
        this.email = email;
        this.tik_tok = tik_tok;
    }
    static async getSetting() {
        const response = await axios.get(
            `${apiUrls.SETTING}list`,
        );
        return response.data;
    }
    static async editSetting(parameters) {
        const response = await axios.put(
            `${apiUrls.SETTING}edit`,
            parameters,
            { headers: userStore.state.header }
        );
        return response.data;
    }
    static async updateQr(parameters) {
        const apitoken = JSON.parse(localStorage.getItem("ecomerce_gatonet")).token;
        const header = {
            "Content-Type": "multipart/form-data",
            "Accept": "application/json",
            "Authorization": "Bearer " + apitoken,
        }
        const response = await axios.post(
            `${apiUrls.SETTING}update-qr`,
            parameters,
            { headers: header }
        );
        return response.data;
    }
    static dowloadQr(qr) {
        window.open(`${apiUrls.SETTING}download-qr?qr=${qr}`, '_blank')
    }
}
export default Setting;