<template>
    <div class="pr-o-card-categorie animation-opacity">
        <div class="pr-o-card-categorie__header d-flex justify-center">
            <h1 class="pr-app-title-dark">
                {{product.product.product}}
            </h1>
        </div>
        <div class="pa-2 pb-1">
            <PrCFillInformationComp
                title="Precio Unidad"
                :subtitle="product.price.toFixed(2)"
            />
            <PrCFillInformationComp
                title="Cantidad"
                :subtitle="`${product.quantity}`"
            />
            <PrCFillInformationComp
                title="Total"
                :subtitle="(product.price * product.quantity).toFixed(2)"
            />
        </div>
        <v-divider class="mb-1"></v-divider>
        <div class="pa-2 pt-1 d-flex justify-center flex-wrap">
            <PrCBtnCardComp
                icon="mdi-list-box"
                @click="$emit('clickdetail')"
            />
            <PrCBtnCardComp
                icon="mdi-pencil"
                @click="$emit('clickedit')"
            />
            <PrCBtnCardComp
                icon="mdi-delete"
                @click="$emit('clickdelete')"
            />
        </div>
    </div>
</template>
<script>
import {
    PrCFillInformationComp,
    PrCBtnCardComp
} from "../../cells";
export default {
    props: {
        product: Object
    },
    components: {
        PrCFillInformationComp,
        PrCBtnCardComp
    }
}
</script>