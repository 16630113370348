<template>
    <v-text-field
        outlined
        dense
        hide-details
        :background-color="backgroundColor"
        :clearable="clearable"
        single-line
        :reverse="reverse"
        :label="label"
        :value="value"
        :rules="rules"
        class="text-field__header"
        @input="e => this.$emit('input', e)"
        @keyup.enter="$emit('click')"
    ></v-text-field>
</template>
<script>
export default {
    props: {
        label: String,
        value: [Number, String],
        clearable: {
            type: Boolean,
            default: true
        },
        backgroundColor: String,
        reverse: Boolean,
        rules: Array
    },
    model: {
        prop: 'value',
        event: 'input'
    }
}
</script>