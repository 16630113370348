<template>
    <div>
        <div class="categories__header pa-2">
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    v-if="!isMobile"
                    :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                    :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                    class="mr-2"
                    @click="$emit('clickmini')"
                />
                <h1 class="pr-app-title">
                    {{`CATEGORÍAS (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="categories__header-search">
                <PrMTextFieldHeaderComp
                    backgroundColor="white"
                    class="mr-1"
                    label="Buscar"
                    v-model="search"
                />
                <PrCBtnToolTipHeaderComp
                    icon="mdi-plus"
                    class="mr-1"
                    smsToolTip="Registrar categoría"
                    @click="eOpenForm()"
                />
                <PrCBtnToolTipHeaderComp
                    icon="mdi-database"
                    smsToolTip="Volver a la lista"
                    @click="eBackList()"
                />
            </div>
        </div>
        <PrMLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <PrCLoaderDataComp
                    v-if="dialogLoaderDataIsOpen"
                    class="mt-2"
                />
                <PrMWithoutInfoCardsComp
                    v-if="!dialogLoaderDataIsOpen && categoriesFilter.length===0 && isMobile"
                    class="ma-2"
                />
                <PrOTCategoriesComp
                    v-if="!dialogLoaderDataIsOpen && !isMobile"
                    :categories="categoriesFilter"
                    :search="searchFilter"
                    @clickedit="eShowFormEdit"
                    @clickstate="eConfirmChangeState"
                    @clickdelete="eConfirmDeleteUser"
                    @clickseller="eConfirmChangeSeller"
                />
                <div
                    v-if="!dialogLoaderDataIsOpen && isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardCategorieComp
                        class="ma-2"
                        v-for="categorie in categoriesFilter"
                        :key="categorie.id"
                        :categorie="categorie"
                        :search="searchFilter"
                        @clickedit="eShowFormEdit(categorie)"
                        @clickstate="eConfirmChangeState(categorie)"
                        @clickdelete="eConfirmDeleteUser(categorie)"
                        @clickseller="eConfirmChangeSeller(categorie)"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2">
                    <PrESkeletonFormComp
                        :title="indexCategorie===-1 ? 'REGISTRAR' : 'EDITAR'"
                    >
                        <PrOFormCategorieComp
                            ref="formcategoriecomp"
                            :dataCategorie="dataCategorie"
                            :isMobile="isMobile"
                            @clickform="eConfirmForm"
                        />
                    </PrESkeletonFormComp>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <PrOGeneralDialogsComp
            :dialogErrorIsOpen="dialogErrorIsOpen"
            :smsErrors="smsErrors"
            :actionErrorIsActivated="actionErrorIsActivated"
            @clickerror="eCloseError()"
            :dialogConfirmationIsOpen="dialogConfirmationIsOpen"
            :smsConfirmations="smsConfirmations"
            @clickyes="eProcessOperation()"
            @clickno="eCloseConfirm()"
            :smsActions="smsActions"
            :frameAction="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
            :dialogOperationIsOpen="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMTextFieldHeaderComp,
    PrMWithoutInfoCardsComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCLoaderDataComp
} from "../../components/components_private/cells";
import {
    PrOCardCategorieComp,
    PrOTCategoriesComp,
    PrOFormCategorieComp,
    PrOGeneralDialogsComp
} from "../../components/components_private/organisms";
import {
    PrESkeletonFormComp
} from "../../components/components_private/species";
import {
    Categorie
} from "../../models";
import {
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin
} from "../../mixins";
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin
    ],
    components: {
        PrMLineHeaderComp,
        PrCBtnToolTipHeaderComp,
        PrMTextFieldHeaderComp,
        PrCLoaderDataComp,
        PrMWithoutInfoCardsComp,
        PrOCardCategorieComp,
        PrOTCategoriesComp,
        PrOFormCategorieComp,
        PrESkeletonFormComp,
        PrOGeneralDialogsComp
    },
    data: () => ({
        categories: [],
        numberOperation: 0,
        search: "",
        tab: 0,
        indexCategorie: -1,
        idCategorie: -1,
        dataCategorie: new Categorie
    }),
    computed: {
        searchFilter: function() {
            return this.mRItIsEmpty(this.search) ? "" : this.search;
        },
        categoriesFilter: function() {
            if (this.mRItIsEmpty(this.search)) return this.categories;
            return this.categories.filter(categorie => {
                return categorie.categorie.toLowerCase().indexOf(this.search.toLowerCase()) !== -1;
            });
        }
    },
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        async getCategories() {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Categorie.getCategories();
                this.categories = response;
                this.total = response.length;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eOpenForm() {
            this.numberOperation = 0;
            if (this.tab !== 0) {
                this.indexCategorie = -1;
                this.dataCategorie = new Categorie;
                this.$refs.formcategoriecomp.resetForm();
            }
            this.tab = 1;
        },
        eBackList() {
            if (this.tab !== 0) {
                this.indexCategorie = -1;
                this.dataCategorie = new Categorie;
                this.$refs.formcategoriecomp.resetForm();
                this.tab = 0;
            }
        },
        eConfirmForm(isValidForm) {
            if (isValidForm) {
                if (this.numberOperation === 0)
                    this.smsConfirmations = [`¿Está seguro(a) de registrar: ${this.dataCategorie.categorie}?`];
                if (this.numberOperation === 1)
                    this.smsConfirmations = [`¿Está seguro(a) de editar: ${this.dataCategorie.categorie}?`];
                this.dialogConfirmationIsOpen = true;
            } else this.mGDShowError();
        },
        resetDataDirect() {
            this.numberOperation = 0;
            this.idCategorie = -1;
            this.indexCategorie = -1;
        },
        eCloseConfirm() {
            if (this.idCategorie !== -1) this.resetDataDirect();
            this.dialogConfirmationIsOpen = false;
        },
        eCloseError() {
            this.mGDProcesAccess();
            if (this.idCategorie !== -1)
                this.resetDataDirect();
            this.dialogErrorIsOpen = false;
        },
        async addCategorie() {
            try {
                const response = await Categorie.addCategorie(this.dataCategorie);
                this.categories.unshift(response);
                this.total += 1;
                this.eBackList();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se agregó la categoría"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async editCategorie() {
            try {
                const response = await Categorie.editCategorie(this.dataCategorie);
                Object.assign(this.categories[this.indexCategorie], response);
                this.eBackList();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se editó la categoría"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async changeStateCategorie() {
            try {
                await Categorie.changeStateCategorie({id: this.idCategorie});
                this.categories[this.indexCategorie].enabled = this.categories[this.indexCategorie].enabled === 0 ? 1 : 0;
                this.resetDataDirect();
                this.mGDShowAction(["Operación Exitosa", "Se cambió el estado"]);
                this.dialogOperationIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async deleteCategorie() {
            try {
                await Categorie.deleteCategorie({id: this.idCategorie});
                this.categories.splice(this.indexCategorie, 1);
                this.resetDataDirect();
                this.mGDShowAction(["Operación Exitosa", "Se eliminó la categoría"]);
                this.dialogOperationIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async changeSeller() {
            try {
                await Categorie.changeSeller({id: this.idCategorie});
                this.categories[this.indexCategorie].is_seller = this.categories[this.indexCategorie].is_seller === 0 ? 1 : 0;
                this.resetDataDirect();
                this.mGDShowAction(["Operación Exitosa", "Se cambió la autorización"]);
                this.dialogOperationIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eProcessOperation() {
            this.dialogConfirmationIsOpen = false;
            this.dialogOperationIsOpen = true;
            switch(this.numberOperation) {
                case 0:
                    this.addCategorie();
                    break;
                case 1:
                    this.editCategorie();
                    break;
                case 2:
                    this.changeStateCategorie();
                    break;
                case 3:
                    this.deleteCategorie();
                    break;
                case 4:
                    this.changeSeller();
                    break;
            }
        },
        eShowFormEdit(categorie) {
            this.numberOperation = 1;
            this.dataCategorie = Object.assign({}, categorie);
            this.indexCategorie = this.categories.indexOf(categorie);
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        },
        eConfirmChangeState(categorie) {
            this.numberOperation = 2;
            this.idCategorie = categorie.id;
            this.indexCategorie = this.categories.indexOf(categorie);
            const smsState = categorie.enabled === 0 ? "habilitar" : "inhabilitar";
            this.smsConfirmations = [`Está seguro(a) de ${smsState} el usuario: ${categorie.categorie}?`];
            this.dialogConfirmationIsOpen = true;
        },
        eConfirmDeleteUser(categorie) {
            this.numberOperation = 3;
            this.idCategorie = categorie.id;
            this.indexCategorie = this.categories.indexOf(categorie);
            this.smsConfirmations = [`¿Está seguro de eliminar ${categorie.categorie}?`];
            this.dialogConfirmationIsOpen = true;
        },
        eConfirmChangeSeller(categorie) {
            this.numberOperation = 4;
            this.idCategorie = categorie.id;
            this.indexCategorie = this.categories.indexOf(categorie);
            const smsState = categorie.is_seller === 0 ? "habilitar" : "inhabilitar";
            this.smsConfirmations = [`Está seguro(a) de ${smsState} la categoría: ${categorie.categorie}, para los vendedores?`];
            this.dialogConfirmationIsOpen = true;
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategoryEmployee() === 'a') this.getCategories('', 2);
            else this.$router.replace('/admin/home');
        } else this.$router.replace('/login-admin');
    }
}
</script>