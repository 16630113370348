<template>
    <div class="d-flex justify-center">
        <div class="home__info">
            <h1 class="pu-app-title-dark text-center">POLITICAS DE USO</h1>
            <h1 class="pu-app-title-dark">
                Información que recopilamos de usted
            </h1>
            <h1 class="pu-app-subtitle-dark mt-1">
                Valoramos su confianza al proporcionarnos su información personal. GATONET (" GATONET ") no comparte, divulga ni vende ninguna información de identificación personal (como su nombre, dirección, número de teléfono o dirección de correo electrónico) recopilada en línea en los sitios de GATONET con otras empresas u organizaciones no afiliadas para fines de marketing que no sean GATONET. Debido a que esta información es una parte crítica de nuestro negocio, sería tratada como nuestros otros activos en el contexto de una fusión, venta u otra reorganización corporativa o procedimiento legal.
            </h1>
            <h1 class="pu-app-subtitle-dark mt-1">
                Al usar nuestros Servicios (como se define en nuestros Términos de uso), usted reconoce y acepta que acepta las prácticas, los requisitos y/o las políticas que se establecen a continuación, y por la presente acepta que recopilemos, usemos, divulguemos y/o procesemos sus datos personales como se describe en este documento. SI NO ACEPTA EL PROCESAMIENTO DE SUS DATOS PERSONALES, ES POSIBLE QUE NO PUEDA UTILIZAR NUESTROS SERVICIOS NI TENER ACCESO COMPLETO A NUESTRA PLATAFORMA (como se define en nuestros Términos de uso).
            </h1>
            <h1 class="pu-app-title-dark mt-1">Por qué recopilamos datos de usted</h1>
            <h1 class="pu-app-subtitle-dark mt-1">
                GATONET recabará datos suyos con las siguientes finalidades:
            </h1>
            <h1 class="pu-app-subtitle-dark mt-1">
                1. Cuando sea necesario para la ejecución de nuestro acuerdo con usted para proporcionar y entregar contenido y Servicios asociados;
            </h1>
            <h1 class="pu-app-subtitle-dark mt-1">
                2. Cuando sea necesario para el cumplimiento de obligaciones legales a las que podamos estar sujetos; y/o
            </h1>
            <h1 class="pu-app-subtitle-dark mt-1">
                3. Donde haya dado su consentimiento para la misma.
            </h1>
            <h1 class="pu-app-title-dark mt-1">
                Somos responsables de la privacidad de los niños
            </h1>
            <h1 class="pu-app-subtitle-dark mt-1">
            Proteger la privacidad de los niños pequeños es especialmente importante. GATONET tampoco comercializa ni recopila a sabiendas información personal de niños menores de 13 años en ningún sitio de la empresa. Si nos damos cuenta de que hemos recopilado información personal de un niño menor de 13 años sin verificación del consentimiento de los padres, tomamos medidas para eliminar esa información. En el caso de que los datos personales de un niño menor de 13 años bajo su cuidado se divulguen a GATONET, por la presente acepta el procesamiento de los datos personales del niño y acepta estar sujeto a esta Política en nombre de dicho niño.
            </h1>
            <h1 class="pu-app-title-dark mt-1">
                ¿Cuándo podemos recopilar su información personal?
            </h1>
            <h1 class="pu-app-subtitle-dark mt-1">
                Podemos recopilar información personal directamente de usted cuando:
            </h1>
            <h1 class="pu-app-subtitle-dark mt-1">
                1. Registrarse y/o utilizar nuestros Servicios o Plataforma;
            </h1>
            <h1 class="pu-app-subtitle-dark mt-1">
                2. Vincular sus redes sociales u otras cuentas externas para registrar una cuenta con nosotros;
            </h1>
            <h1 class="pu-app-subtitle-dark mt-1">
                3. Comunicarse con nosotros (incluido, entre otros, atenderlo a través de nuestros chats de servicio al cliente en línea, cartas, fax, llamadas (que pueden grabarse), reuniones cara a cara, plataformas de redes sociales, correos electrónicos, etc.);
            </h1>
            <h1 class="pu-app-subtitle-dark mt-1">
                4. Registrarse o suscribirse a un Producto y/o Servicio específico o a nuestras publicaciones (por ejemplo, boletines);
            </h1>
            <h1 class="pu-app-subtitle-dark mt-1">
                5. Participar en cualquiera de nuestras encuestas;
            </h1>
            <h1 class="pu-app-subtitle-dark mt-1">
                6. Ingresar o participar en cualquier competencia, concurso o programa de fidelización dirigido/organizado por GATONET;
            </h1>
            <h1 class="pu-app-subtitle-dark mt-1">
                7. Registrar interés y/o solicitud de información de (a través de nuestros portales en línea u otros canales disponibles) o suscribirse a nuestros Productos y/o Servicios;
            </h1>
            <h1 class="pu-app-subtitle-dark mt-1">
                8. Responder a cualquier material de marketing que enviemos;
            </h1>
            <h1 class="pu-app-subtitle-dark mt-1">
                9. Visitar o navegar por nuestros sitios web;
            </h1>
            <h1 class="pu-app-subtitle-dark mt-1">
                10.	Presentar una queja con nosotros;
            </h1>
            <h1 class="pu-app-subtitle-dark mt-1">
                11.	Proporcionarnos comentarios de cualquier manera; y/o
            </h1>
            <h1 class="pu-app-subtitle-dark mt-1">
                12.	Enviarnos sus datos personales por cualquier motivo.
            </h1>
            <h1 class="pu-app-subtitle-dark mt-1">
                Además de la información personal obtenida de usted directamente (como se detalla anteriormente), también podemos obtener su información personal de terceros con los que tratamos o estamos conectados con usted (agencias de referencia crediticia o instituciones financieras), y de otras fuentes en las que usted haya proporcionado su consentimiento para la divulgación de información relacionada con usted, y/o donde esté legalmente permitido.
            </h1>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>