var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pr-o-table-categories animation-opacity"},[_c('table',{staticClass:"pr-o-table-categories__table"},[_vm._m(0),_c('tbody',[_vm._l((_vm.users),function(user,index){return _c('tr',{key:user.id},[_c('td',[_c('h1',{staticClass:"pr-app-subtitle"},[_vm._v(" "+_vm._s((index + 1))+" ")])]),_c('td',[_c('text-highlight',{staticClass:"pr-app-subtitle",attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(user.employee.full_name)+" ")])],1),_c('td',[_c('h1',{staticClass:"pr-app-subtitle"},[_vm._v(" "+_vm._s(user.employee.category === "a" ? "Administrador" : "Vendedor")+" ")])]),_c('td',[_c('h1',{staticClass:"pr-app-subtitle"},[_vm._v(" "+_vm._s(user.username)+" ")])]),_c('td',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{class:user.enabled === 1
                                ? 'categories__enabled mr-1'
                                : 'categories__disabled mr-1',staticStyle:{"margin-top":"-2px"}}),_c('h1',{staticClass:"pr-app-subtitle"},[_vm._v(" "+_vm._s(user.enabled === 1 ? "Si" : "No")+" ")])])]),_c('td',[_c('h1',{staticClass:"pr-app-subtitle"},[_vm._v(" "+_vm._s(_vm.getTextIp(user))+" ")])]),_c('td',{staticClass:"d-flex justify-center flex-wrap"},[_c('PrCBtnToolTipTableComp',{attrs:{"icon":"mdi-pencil","smsToolTip":`Editar a: ${user.employee.full_name}`},on:{"click":function($event){return _vm.$emit('clickedit', user)}}}),_c('PrCBtnToolTipTableComp',{attrs:{"icon":"mdi-account-key","smsToolTip":`Cambiar credenciales de: ${user.employee.full_name}`},on:{"click":function($event){return _vm.$emit('clickcredentials', user)}}}),_c('PrCBtnToolTipTableComp',{attrs:{"icon":user.enabled === 1
                            ? 'mdi-eye-off'
                            : 'mdi-eye',"smsToolTip":user.enabled === 1
                            ? `Inhabilitar a: ${user.employee.full_name}`
                            : `Habilitar a: ${user.employee.full_name}`},on:{"click":function($event){return _vm.$emit('clickstate', user)}}}),(user.employee.category === 'v')?_c('PrCBtnToolTipTableComp',{attrs:{"icon":"mdi-key-wireless","smsToolTip":`Habilitar a ${user.employee.full_name} para ventas en el dispositivo accesado`},on:{"click":function($event){return _vm.$emit('clickkey', user)}}}):_vm._e()],1)])}),(_vm.users.length === 0)?_c('tr',[_vm._m(1)]):_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_c('h1',{staticClass:"pr-app-title-dark text-center"},[_vm._v("N°")])]),_c('th',[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Nombre Completo")])]),_c('th',[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Categoría")])]),_c('th',[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Usuario")])]),_c('th',[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Estado")])]),_c('th',[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("IP")])]),_c('th',{attrs:{"width":"190"}},[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Acciones")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"7"}},[_c('h1',{staticClass:"pr-app-subtitle text-center"},[_vm._v("Sin Información")])])
}]

export { render, staticRenderFns }