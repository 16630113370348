<template>
    <div>
        <div class="d-flex align-center pa-2">
            <PrCBtnToolTipHeaderComp
                v-if="!isMobile"
                :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                class="mr-2"
                @click="$emit('clickmini')"
            />
            <h1 class="pr-app-title">
                CONFIGURACIONES
            </h1>
            <v-spacer></v-spacer>
            <PrCBtnToolTipHeaderComp
                icon="mdi-database"
                smsToolTip="Volver a la lista"
                @click="eBackList()"
            />
        </div>
        <PrMLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <PrMWithoutInfoCardsComp
                    v-if="!dialogLoaderDataIsOpen && setting.id == undefined"
                    class="ma-2"
                />
                <PrCLoaderDataComp
                    v-if="dialogLoaderDataIsOpen"
                    class="mt-2"
                />
                <div v-if="setting.id != undefined" class="pa-2">
                    <PrCFillInformationComp
                        title="Dirección"
                        :subtitle="setting.address"
                    />
                    <PrCFillInformationComp
                        title="Correo"
                        :subtitle="setting.email"
                    />
                    <PrCFillInformationComp
                        title="Whatsapp"
                        :subtitle="setting.phone"
                    />
                    <PrCFillInformationComp
                        title="Facebook link"
                        :subtitle="setting.facebook != null ? setting.facebook : 'S/F'"
                    />
                    <PrCFillInformationComp
                        title="Instagram link"
                        :subtitle="setting.instagram != null ? setting.instagram : 'S/I'"
                    />
                    <PrCFillInformationComp
                        title="Tiktok link"
                        :subtitle="setting.tik_tock != null ? setting.tik_tock : 'S/T'"
                    />
                    <PrCFillInformationComp
                        title="Cambio dolar ($)"
                        :subtitle="parseFloat(setting.change_dollar).toFixed(2)"
                    />
                    <PrMBtnNormalComp
                        class="mb-2"
                        @click="eOpenFormEdit()"
                    >ACTUALIZAR</PrMBtnNormalComp>
                    <div class="d-flex flex-wrap">
                        <div>
                            <v-card
                                width="200px"
                                height="200px"
                                class="mt-1 mb-1"
                            >
                                <v-img
                                    width="100%"
                                    height="100%"
                                    contain
                                    :src="setting.qr|imgMiniature"
                                ></v-img>
                            </v-card>
                            <div class="text-center">
                                <PrMBtnNormalComp
                                    class="mt-2"
                                    @click="eOpenDialogQr(1)"
                                >CAMBIAR QR</PrMBtnNormalComp>
                            </div>
                        </div>
                        <div>
                            <v-card
                                width="200px"
                                height="200px"
                                class="mt-1 mb-1"
                            >
                                <v-img
                                    v-if="setting.qr_alternate != null"
                                    width="100%"
                                    height="100%"
                                    contain
                                    :src="setting.qr_alternate|imgMiniature"
                                ></v-img>
                                <div
                                    v-else
                                    class="pt-10 pl-3 pr-3 d-flex justify-center align-center"
                                >
                                    <h1 class="pr-app-title text-center">
                                        Segundo QR no registrado
                                    </h1>
                                </div>
                            </v-card>
                            <div class="text-center">
                                <PrMBtnNormalComp
                                    class="mt-2"
                                    @click="eOpenDialogQr(0)"
                                >CAMBIAR QR 2</PrMBtnNormalComp>
                            </div>
                        </div>
                    </div>
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2">
                    <PrESkeletonFormComp
                        title="ACTUALIZAR"
                    >
                        <PrOFormSettingComp
                            ref="formsetting"
                            :dataSetting="dataSetting"
                            @clickform="eConfirmUpdateSetting"
                        />
                    </PrESkeletonFormComp>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <PrOFormQrComp
            :value="dialogQrIsOpen"
            ref="formqr"
            @changefile="eChangeFile"
            @clickclose="dialogQrIsOpen = false"
            @sendForm="eValidDialogImage()"
        />
        <PrOGeneralDialogsComp
            :dialogErrorIsOpen="dialogErrorIsOpen"
            :smsErrors="smsErrors"
            :actionErrorIsActivated="actionErrorIsActivated"
            @clickerror="eCloseError()"
            :dialogConfirmationIsOpen="dialogConfirmationIsOpen"
            :smsConfirmations="smsConfirmations"
            @clickyes="eProcessOperation()"
            @clickno="eCloseConfirm()"
            :smsActions="smsActions"
            :frameAction="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
            :dialogOperationIsOpen="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMWithoutInfoCardsComp,
    PrMBtnNormalComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCFillInformationComp,
    PrCLoaderDataComp
} from "../../components/components_private/cells";
import {
    PrOFormSettingComp,
    PrOGeneralDialogsComp,
    PrOFormQrComp
} from "../../components/components_private/organisms";
import {
    PrESkeletonFormComp
} from "../../components/components_private/species";
import {
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin
} from "../../mixins";
import {
    Setting
} from "../../models";
import {
    imgMiniature
} from "../../filters";
export default {
    filters: {
        imgMiniature
    },
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin
    ],
    components: {
        PrCBtnToolTipHeaderComp,
        PrMLineHeaderComp,
        PrCFillInformationComp,
        PrMWithoutInfoCardsComp,
        PrCLoaderDataComp,
        PrMBtnNormalComp,
        PrOFormSettingComp,
        PrESkeletonFormComp,
        PrOGeneralDialogsComp,
        PrOFormQrComp
    },
    data: () => ({
        setting: {},
        tab: 0,
        dataSetting: new Setting,
        dataQr: {
            'id': null,
            'name_file': null,
            'qr': 1,
            'file': null
        },
        dialogQrIsOpen: false,
        numberOperation: 0
    }),
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        eBackList() {
            if (this.tab !== 0) {
                this.dataSetting = new Setting;
                this.$refs.formsetting.resetForm();
                this.tab = 0;
            }
        },
        eCloseConfirm() {
            this.dialogConfirmationIsOpen = false;
            if (this.numberOperation === 1)
                this.dialogQrIsOpen = true;
        },
        async getSetting() {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Setting.getSetting();
                this.setting = response;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eOpenFormEdit() {
            this.numberOperation = 0;
            this.dataSetting = Object.assign({}, this.setting);
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        },
        async editSetting() {
            try {
                const response = await Setting.editSetting(this.dataSetting);
                this.setting = response;
                this.tab = 0;
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se actualizó los datos"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async updateQr() {
            try {
                const response = await Setting.updateQr(this.$objectToFD(this.dataQr));
                this.setting = Object.assign({}, response);
                this.$refs.formqr.resetPhoto();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se actualizó el qr"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eProcessOperation() {
            this.dialogConfirmationIsOpen = false;
            this.dialogOperationIsOpen = true;
            switch(this.numberOperation) {
                case 0:
                    this.editSetting();
                    break;
                case 1:
                    this.updateQr();
                    break;
            }
        },
        eConfirmUpdateSetting(isValidForm) {
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                if (!isValidForm) this.mGDShowError();
                else {
                    this.smsConfirmations = ['¿Está seguro de actualizar los datos?'];
                    this.dialogConfirmationIsOpen = true;
                }
            }, time);
        },
        eChangeFile(parameters) {
            if (parameters.state) {
                this.dataQr.file = parameters.file;
                this.dataQr.name_file = `${new Date().getTime()}`;
            } else {
                this.dataQr.file = null;
                this.dataQr.name_file = null;
            }
        },
        eValidDialogImage() {
            if (this.dataQr.file == null)
                this.mGDShowAction(["Error de Archivo", `Escoja una imágen`], "error");
            else {
                this.dataQr.id = this.setting.id;
                this.numberOperation = 1;
                this.dialogQrIsOpen = false;
                this.smsConfirmations = [`¿Está seguro de actualizar el qr?`];
                this.dialogConfirmationIsOpen = true;
            }
        },
        eOpenDialogQr(value) {
            this.dataQr.qr = value;
            this.dialogQrIsOpen = true;
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategoryEmployee() === 'a') this.getSetting();
            else this.$router.replace('/admin/home');
        } else this.$router.replace('/login-admin');
    }
}
</script>