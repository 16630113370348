<template>
    <div>
        <v-tooltip bottom content-class="pr-c-tooltip">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    depressed
                    v-bind="attrs"
                    v-on="on"
                    icon
                    :ripple="false"
                    @click="$emit('click')"
                >
                    <PrMIconComp
                        :icon="icon"
                        classIcon="pr-cp-color"
                        :small="false"
                    />
                </v-btn>
            </template>
            <div class="d-flex align-center">
                <PrMIconComp
                    icon="mdi-hand-pointing-right"
                    :small="false"
                    classIcon="pr-cd-color"
                />
                <h1 class="pr-app-subtitle-dark ml-1">{{smsToolTip}}</h1>
            </div>
        </v-tooltip>
    </div>
</template>
<script>
import {
    PrMIconComp
} from "../molecules";
export default {
    components: {
        PrMIconComp
    },
    props: {
        icon: String,
        smsToolTip: String
    }
}
</script>
