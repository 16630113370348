<template>
    <div>
        <CSmsErrorComp
            :smsErrors="smsErrors"
            :value="dialogErrorIsOpen"
            :actionErrorIsActivated="actionErrorIsActivated"
            @click="$emit('clickerror')"
        />
        <CSmsConfirmationComp
            :smsConfirmations="smsConfirmations"
            :value="dialogConfirmationIsOpen"
            @clickyes="$emit('clickyes')"
            @clickno="$emit('clickno')"
        />
        <CSmsActionComp
            :smsActions="smsActions"
            :value="frameAction"
            :isError="isError"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="$emit('clickaccion')"
        />
        <CLoaderOperationComp
            :value="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    CSmsErrorComp,
    CSmsActionComp,
    CLoaderOperationComp,
    CSmsConfirmationComp
} from "../../cells";
export default {
    components: {
        CSmsErrorComp,
        CSmsActionComp,
        CLoaderOperationComp,
        CSmsConfirmationComp
    },
    props: {
        smsErrors: Array,
        dialogErrorIsOpen: Boolean,
        actionErrorIsActivated: Boolean,
        smsConfirmations: Array,
        dialogConfirmationIsOpen: Boolean,
        smsActions: Array,
        frameAction: Boolean,
        isError: Boolean,
        timeSuccessAction: Number,
        dialogOperationIsOpen: Boolean
    }
}
</script>