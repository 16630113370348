<template>
    <div>
        <div class="d-flex align-center pa-2">
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    v-if="!isMobile"
                    :icon="!mini ? 'mdi-arrow-left-bold' : 'mdi-arrow-right-bold'"
                    :smsToolTip="mini ? 'Mostrar menú' : 'Minimizar menú'"
                    class="mr-2"
                    @click="$emit('clickmini')"
                />
                <h1 class="pr-app-title">
                    {{`CONTROLES (${total})`}}
                </h1>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
                <PrCBtnToolTipHeaderComp
                    icon="mdi-plus"
                    class="mr-1"
                    smsToolTip="Registrar control"
                    @click="eOpenForm()"
                />
                <PrCBtnToolTipHeaderComp
                    icon="mdi-database"
                    smsToolTip="Volver a la lista"
                    @click="eBackList()"
                />
            </div>
        </div>
        <PrMLineHeaderComp />
        <v-tabs-items
            style="background:transparent !important"
            v-model="tab"
            :touchless="true"
        >
            <v-tab-item>
                <PrCLoaderDataComp
                    v-if="dialogLoaderDataIsOpen"
                    class="mt-2"
                />
                <PrMWithoutInfoCardsComp
                    v-if="!dialogLoaderDataIsOpen && rankings.length===0 && isMobile"
                    class="ma-2"
                />
                <PrOTableRankingsComp
                    v-if="!dialogLoaderDataIsOpen && !isMobile"
                    :rankings="rankings"
                    @clickedit="eShowFormEdit"
                    @clickdelete="eConfirmDelete"
                />
                <div
                    v-if="!dialogLoaderDataIsOpen && isMobile"
                    class="d-flex justify-center flex-wrap"
                >
                    <PrOCardRankingComp
                        class="ma-2"
                        v-for="(ranking, index) in rankings"
                        :key="ranking.id"
                        :index="index"
                        :ranking="ranking"
                        @clickedit="eShowFormEdit(ranking)"
                        @clickdelete="eConfirmDelete(ranking)"
                    />
                </div>
            </v-tab-item>
            <v-tab-item>
                <div class="pa-2">
                    <PrESkeletonFormComp
                        :title="indexRanking===-1 ? 'REGISTRAR' : 'EDITAR'"
                    >
                        <PrOFormRankingComp
                            ref="formrankingcomp"
                            :dataRanking="dataRanking"
                            :isMobile="isMobile"
                            @clickform="eConfirmForm"
                        />
                    </PrESkeletonFormComp>
                </div>
            </v-tab-item>
        </v-tabs-items>
        <PrOGeneralDialogsComp
            :dialogErrorIsOpen="dialogErrorIsOpen"
            :smsErrors="smsErrors"
            :actionErrorIsActivated="actionErrorIsActivated"
            @clickerror="eCloseError()"
            :dialogConfirmationIsOpen="dialogConfirmationIsOpen"
            :smsConfirmations="smsConfirmations"
            @clickyes="eProcessOperation()"
            @clickno="eCloseConfirmation()"
            :smsActions="smsActions"
            :frameAction="frameAction"
            :colorAction="colorAction"
            :timeSuccessAction="timeSuccessAction"
            @clickaccion="mGDClearTimeOut()"
            :dialogOperationIsOpen="dialogOperationIsOpen"
        />
    </div>
</template>
<script>
import {
    PrMLineHeaderComp,
    PrMTextFieldHeaderComp,
    PrMWithoutInfoCardsComp
} from "../../components/components_private/molecules";
import {
    PrCBtnToolTipHeaderComp,
    PrCLoaderDataComp
} from "../../components/components_private/cells";
import {
    PrOCardRankingComp,
    PrOTableRankingsComp,
    PrOGeneralDialogsComp,
    PrOFormRankingComp
} from "../../components/components_private/organisms";
import {
    PrESkeletonFormComp
} from "../../components/components_private/species";
import {
    Ranking
} from "../../models";
import {
    dataGeneralCompsMixin,
    rulesMixin,
    animationScrollMixin,
    responseServerMixin,
    userMixin,
    datesMixin
} from "../../mixins";
export default {
    props: {
        mini: Boolean
    },
    mixins: [
        dataGeneralCompsMixin,
        rulesMixin,
        animationScrollMixin,
        responseServerMixin,
        userMixin,
        datesMixin
    ],
    components: {
        PrMLineHeaderComp,
        PrCBtnToolTipHeaderComp,
        PrMTextFieldHeaderComp,
        PrCLoaderDataComp,
        PrMWithoutInfoCardsComp,
        PrOCardRankingComp,
        PrOTableRankingsComp,
        PrOFormRankingComp,
        PrESkeletonFormComp,
        PrOGeneralDialogsComp
    },
    data: () => ({
        rankings: [],
        numberOperation: 0,
        tab: 0,
        indexRanking: -1,
        dataRanking: new Ranking,
        idRanking: 0
    }),
    methods: {
        processErrorServer(error) {
            this.dialogOperationIsOpen = false;
            this.noAccess = this.mRSAutorization(error);
            this.smsErrors = this.mRSSmsError(error);
            this.actionErrorIsActivated = this.mRSActivateSmsError(error);
            this.dialogErrorIsOpen = true;
        },
        async getRankings() {
            this.dialogLoaderDataIsOpen = true;
            try {
                const response = await Ranking.getRankings();
                this.rankings = response;
                this.total = response.length;
                this.dialogLoaderDataIsOpen = false;
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eOpenForm() {
            this.numberOperation = 0;
            if (this.tab !== 0) {
                this.indexRanking = -1;
                this.dataRanking = new Ranking;
                this.$refs.formrankingcomp.resetForm();
            }
            this.tab = 1;
        },
        eBackList() {
            if (this.tab !== 0) {
                this.indexRanking = -1;
                this.dataRanking = new Ranking;
                this.$refs.formrankingcomp.resetForm();
                this.tab = 0;
            }
        },
        eConfirmForm(isValidForm) {
            if (isValidForm) {
                if (this.numberOperation === 0)
                    this.smsConfirmations = [`¿Está seguro(a) de registrar: ${this.dataRanking.name}?`];
                if (this.numberOperation === 1)
                    this.smsConfirmations = [`¿Está seguro(a) de editar: ${this.dataRanking.name}?`];
                this.dialogConfirmationIsOpen = true;
            } else this.mGDShowError();
        },
        eCloseError() {
            this.mGDProcesAccess();
            this.dialogErrorIsOpen = false;
        },
        async addRanking() {
            try {
                const response = await Ranking.addRankings(this.dataRanking);
                this.rankings.unshift(response);
                this.total += 1;
                this.eBackList();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se agregó el control"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async editRanking() {
            try {
                const response = await Ranking.editRankings(this.dataRanking);
                Object.assign(this.rankings[this.indexRanking], response);
                this.eBackList();
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se editó el control"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        async deleteRanking() {
            try {
                await Ranking.deleteRanking({ id: this.idRanking });
                this.rankings.splice(this.indexRanking, 1);
                this.indexRanking = -1;
                this.numberOperation = 0;
                this.idRanking = 0;
                this.dialogOperationIsOpen = false;
                this.mGDShowAction(["Operación Exitosa", "Se eliminó el control"]);
            } catch(error) {
                this.processErrorServer(error);
            }
        },
        eProcessOperation() {
            this.dialogConfirmationIsOpen = false;
            this.dialogOperationIsOpen = true;
            switch(this.numberOperation) {
                case 0:
                    this.addRanking();
                    break;
                case 1:
                    this.editRanking();
                    break;
                case 2:
                    this.deleteRanking();
                    break;
            }
        },
        eShowFormEdit(ranking) {
            this.numberOperation = 1;
            this.dataRanking = Object.assign({}, ranking);
            this.indexRanking = this.rankings.indexOf(ranking);
            const time = this.mAnimationScrollTime();
            this.$vuetify.goTo(0, this.mAnimationScrollOptions(time));
            setTimeout(() => {
                this.tab = 1;
            }, time);
        },
        eCloseConfirmation() {
            this.dialogConfirmationIsOpen = false;
            if (this.idRanking > 0) {
                this.idRanking = 0;
                this.indexRanking = -1;
                this.numberOperation = 0;
            }
        },
        eConfirmDelete(ranking) {
            this.numberOperation = 2;
            this.indexRanking = this.rankings.indexOf(ranking);
            this.idRanking = ranking.id;
            this.smsConfirmations = [`¿Está seguro(a) de eliminar el control: ${ranking.name}?`];
            this.dialogConfirmationIsOpen = true;
        }
    },
    mounted() {
        if (window.innerWidth <= 600) this.isMobile = true;
        if (this.mUserSaveHeader(this.mDateSystem(false))) {
            if (this.mUserCategoryEmployee() === 'a') this.getRankings();
            else this.$router.replace('/admin/home');
        } else this.$router.replace('/login-admin');
    }
}
</script>