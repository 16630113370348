<template>
    <v-icon
        :small="small"
        :class="classIcon"
        :color="color"
    >
        {{icon}}
    </v-icon>
</template>
<script>
export default {
    props: {
        icon: String,
        small: {
            type: Boolean,
            default: true
        },
        classIcon: {
            type: String,
            default: "pr-cs-color"
        },
        color: String
    }
}
</script>