<template>
    <v-dialog
        persistent
        :value="value"
        width="360"
    >
        <v-card class="pa-0 ma-0">
            <div class="d-flex align-center pa-2 pr-cp-background">
                <h1 class="pr-app-title-dark">
                    {{indexDigital === -1 ? 'Agregar Código' : 'Editar Código'}}
                </h1>
                <v-spacer></v-spacer>
                <v-btn
                    fab
                    x-small
                    depressed
                    color="red"
                    @click="$emit('clickclose')"
                >
                    <v-icon
                        class="pr-cd-color"
                    >mdi-close</v-icon>
                </v-btn>
            </div>
            <v-form
                v-model="valid"
                lazy-validation
                ref="formdigital"
                class="pa-2"
            >
                <PrMTextFieldComp
                    v-model="dataDigital.code"
                    label="Código"
                    class="mt-2"
                    :counter="50"
                    :rules="[
                        value => !mRItIsEmpty(value) || smsEmpty,
                        value => (`${value}`).length <= 50 || smsExecedCounter(50)
                    ]"
                />
                <PrMTextAreaComp
                    v-model="dataDigital.description"
                    label="Descripción"
                    class="mt-1"
                    :counter="200"
                    :rules="[
                        value => (`${value}`).length <= 200 || smsExecedCounter(50)
                    ]"
                />
                <PrMTextFieldComp
                    v-model="dataDigital.expiration"
                    class="mt-1"
                    label="Fecha Expiración"
                    type="date"
                    :rules="[
                        value => !mRItIsEmpty(value) || smsEmpty
                    ]"
                />
                <div class="d-flex justify-center mt-1">
                    <PrMBtnNormalComp
                        @click="eValidateForm()"
                    >
                        <v-icon>mdi-bookmark-check</v-icon>
                        GUARDAR
                    </PrMBtnNormalComp>
                </div>
            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    PrMTextFieldComp,
    PrMBtnNormalComp,
    PrMTextAreaComp
} from "../../molecules";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [rulesMixin],
    components: {
        PrMTextFieldComp,
        PrMBtnNormalComp,
        PrMTextAreaComp
    },
    props: {
        dataDigital: Object,
        value: Boolean,
        indexDigital: Number
    },
    data: () => ({
        valid: true
    }),
    methods: {
        eValidateForm() {
            if (this.$refs.formdigital.validate())
                this.$emit('clickform', true);
            else this.$emit('clickform', false);
        },
        resetForm() {
            this.$refs.formdigital.resetValidation();
        }
    }
}
</script>