<template>
    <div>
        <div class="d-flex" v-if="product.id != undefined">
            <PrCImageTableComp
                :path="product.route_file"
            />
            <div class="ml-2 mr-2">
                <h1 class="pr-app-title">{{product.product}}</h1>
                <h1 class="pr-app-subtitle">{{product.description}}</h1>
            </div>
            <v-divider vertical></v-divider>
            <v-form
                v-model="valid"
                lazy-validation
                ref="formproduct"
                class="ml-2 mr-2"
            >
                <PrCFillInformationComp
                    title="Precio"
                    :subtitle="product.price.toFixed(2)"
                />
                <v-card
                    width="160"
                    color="transparent"
                    elevation="0"
                >
                    <PrMTextFieldComp
                        v-model="product.new_price"
                        :hideDetails="true"
                        class="mt-2"
                        label="Nuevo Precio"
                        :rules="[
                            value => !mRItIsEmpty(value) || smsEmpty,
                            value => mRIsNumeric(value) || smsNotNumeric,
                            value => !mRExcedeLimite(value, product.price) || 'No debe exceder al precio de venta'
                        ]"
                    />
                </v-card>
                <PrCFillInformationComp
                    class="mt-2"
                    title="Cantidad"
                    :subtitle="`${product.quantity}`"
                >
                    <div
                        :class="getClassInventory(product)"
                        style="margin-top:0px"
                    ></div>
                </PrCFillInformationComp>
                <v-card
                    width="160"
                    color="transparent"
                    elevation="0"
                >
                    <PrMTextFieldComp
                        v-model="product.new_quantity"
                        :hideDetails="true"
                        class="mt-2"
                        label="Nueva Cantidad"
                        :rules="[
                            value => !mRItIsEmpty(value) || smsEmpty,
                            value => mRIsInteger(value) || smsNotInteger,
                            value => !mRExcedeLimite(value, product.quantity) || 'No debe exceder al precio de venta'
                        ]"
                    />
                </v-card>
                <div class="text-center mt-2">
                    <PrCBtnCardComp
                        icon="mdi-check"
                        @click="eValidateForm()"
                    />
                </div>
            </v-form>
        </div>
    </div>
</template>
<script>
import {
    PrMTextFieldComp
} from "../../molecules";
import {
    PrCImageTableComp,
    PrCFillInformationComp,
    PrCBtnCardComp
} from "../../cells";
import {
    rulesMixin
} from "../../../../mixins";
export default {
    mixins: [rulesMixin],
    props: {
        product: Object
    },
    components: {
        PrCImageTableComp,
        PrCFillInformationComp,
        PrCBtnCardComp,
        PrMTextFieldComp
    },
    data: () => ({
        valid: true
    }),
    methods: {
        getClassInventory(product) {
            if (product.id == undefined) return "categories__disabled ml-1";
            if (product.quantity <= 5) return "categories__disabled ml-1";
            if (product.quantity <= 15) return "categories__warning ml-1";
            return "categories__enabled ml-1";
        },
        eValidateForm() {
            if (this.$refs.formproduct.validate())
                this.$emit('clickform', true);
            else this.$emit('clickform', false);
        }
    }
}
</script>