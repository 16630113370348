var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pr-o-table-categories animation-opacity"},[_c('table',{staticClass:"pr-o-table-categories__table"},[_vm._m(0),_c('tbody',[_vm._l((_vm.products),function(product,index){return _c('tr',{key:product.id},[_c('td',[_c('h1',{staticClass:"pr-app-subtitle"},[_vm._v(" "+_vm._s((index + 1))+" ")])]),_c('td',[_c('text-highlight',{staticClass:"pr-app-subtitle",attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(product.product)+" ")])],1),_c('td',[_c('div',{staticClass:"d-flex justify-center"},[_c('PrCImageTableComp',{attrs:{"path":product.route_file}})],1)]),_c('td',[_c('text-highlight',{staticClass:"pr-app-subtitle",attrs:{"queries":_vm.search}},[_vm._v(" "+_vm._s(product.description == null ? "S/D" : product.description)+" ")])],1),_c('td',[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('div',{class:_vm.getClassInventory(product),staticStyle:{"margin-top":"-2px"}}),_c('h1',{staticClass:"pr-app-subtitle ml-1"},[_vm._v(" "+_vm._s(_vm.getTextInventory(product))+" ")])])]),_c('td',[_c('h1',{staticClass:"pr-app-subtitle text-right"},[_vm._v(" "+_vm._s(product.price.toFixed(2))+" ")])]),_c('td',[_c('h1',{staticClass:"pr-app-subtitle text-right"},[_vm._v(" "+_vm._s(product.quantity)+" ")])]),_c('td',[_c('h1',{staticClass:"pr-app-subtitle text-right"},[_vm._v(" "+_vm._s(product.price_cost.toFixed(2))+" ")])]),_c('td',[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('div',{class:product.enabled === 1
                                ? 'categories__enabled mr-1'
                                : 'categories__disabled mr-1',staticStyle:{"margin-top":"-2px"}}),_c('h1',{staticClass:"pr-app-subtitle"},[_vm._v(" "+_vm._s(product.enabled === 1 ? "Hab." : "Inhab.")+" ")])])]),_c('td',[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('div',{class:product.web_enabled === 1
                                ? 'categories__enabled mr-1'
                                : 'categories__disabled mr-1',staticStyle:{"margin-top":"-2px"}}),_c('h1',{staticClass:"pr-app-subtitle"},[_vm._v(" "+_vm._s(product.web_enabled === 1 ? "Si" : "No")+" ")])])]),_c('td',[_c('div',{staticClass:"d-flex justify-center align-center flex-wrap"},[_c('PrCBtnToolTipTableComp',{attrs:{"icon":"mdi-format-list-bulleted-square","smsToolTip":`Ver detalle: ${product.product}`},on:{"click":function($event){return _vm.$emit('clickdetail', product)}}}),_c('PrCBtnToolTipTableComp',{attrs:{"icon":"mdi-pencil","smsToolTip":`Editar: ${product.product}`},on:{"click":function($event){return _vm.$emit('clickedit', product)}}}),_c('PrCBtnToolTipTableComp',{attrs:{"icon":"mdi-image-plus","smsToolTip":`Actualizar foto de: ${product.product}`},on:{"click":function($event){return _vm.$emit('clickimage', product)}}}),_c('PrCBtnToolTipTableComp',{attrs:{"icon":product.enabled === 1
                                ? 'mdi-eye-off'
                                : 'mdi-eye',"smsToolTip":product.enabled === 1
                                ? `Inhabilitar: ${product.product}`
                                : `Habilitar: ${product.product}`},on:{"click":function($event){return _vm.$emit('clickstate', product)}}}),_c('PrCBtnToolTipTableComp',{attrs:{"icon":product.web_enabled === 1
                                ? 'mdi-laptop-off'
                                : 'mdi-laptop',"smsToolTip":product.web_enabled === 1
                                ? `Inhabilitar web: ${product.product}`
                                : `Habilitar web: ${product.product}`},on:{"click":function($event){return _vm.$emit('clickwebstate', product)}}}),_c('PrCBtnToolTipTableComp',{attrs:{"icon":"mdi-delete","smsToolTip":`Eliminar: ${product.product}`},on:{"click":function($event){return _vm.$emit('clickdelete', product)}}})],1)])])}),(_vm.products.length === 0)?_c('tr',[_vm._m(1)]):_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_c('h1',{staticClass:"pr-app-title-dark text-center"},[_vm._v("N°")])]),_c('th',[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Producto")])]),_c('th',[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Imágen")])]),_c('th',[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Descripción")])]),_c('th',[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Inventario")])]),_c('th',{attrs:{"width":"65"}},[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Precio")])]),_c('th',{attrs:{"width":"50"}},[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Cant.")])]),_c('th',{attrs:{"width":"65"}},[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Costo")])]),_c('th',{attrs:{"width":"65"}},[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Estado")])]),_c('th',{attrs:{"width":"65"}},[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Web")])]),_c('th',{attrs:{"width":"130"}},[_c('h1',{staticClass:"pr-app-title-dark"},[_vm._v("Acciones")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"11"}},[_c('h1',{staticClass:"pr-app-subtitle text-center"},[_vm._v("Sin Información")])])
}]

export { render, staticRenderFns }