<template>
    <v-btn
        :ripple="false"
        depressed
        :class="className"
        :color="color"
        :small="small"
        :x-small="xSmall"
        :outlined="outlined"
        :dark="dark"
        :loading="loading"
        @click="$emit('click')"
    >
        <slot></slot>
    </v-btn>
</template>
<script>
export default {
    props: {
        outlined: {
            type: Boolean,
            default: true
        },
        loading: Boolean,
        xSmall: Boolean,
        small: {
            type: Boolean,
            default: true
        },
        color: String,
        dark: Boolean,
        className: {
            type: String,
            default: "pr-cs-color"
        }
    }
}
</script>