<template>
    <div>
        <div class="d-flex justify-center">
            <PrCImageTableComp
                :path="product.route_file"
            />
        </div>
        <PrCFillInformationComp
            class="mt-2"
            title="Producto"
            :subtitle="product.product"
        />
        <h1 class="pr-app-subtitle">
            {{product.description}}
        </h1>
        <PrCFillInformationComp
            v-if="categorieUser === 'a'"
            title="Iventario"
            :subtitle="getTextInventory()"
        >
            <div :class="getClassInventory()"></div>
        </PrCFillInformationComp>
        <PrCFillInformationComp
            title="Categoría"
            :subtitle="product.categorie.categorie"
        />
        <PrCFillInformationComp
            title="Precio"
            :subtitle="product.price.toFixed(2)"
        />
        <PrCFillInformationComp
            v-if="categorieUser==='a'"
            title="Costo"
            :subtitle="product.price_cost.toFixed(2)"
        />
        <PrCFillInformationComp
            v-if="categorieUser==='a'"
            title="Cantidad"
            :subtitle="`${product.quantity}`"
        />
    </div>
</template>
<script>
import {
    PrCFillInformationComp,
    PrCImageTableComp
} from "../../cells";
export default {
    props: {
        product: Object,
        categorieUser: String
    },
    components: {
        PrCFillInformationComp,
        PrCImageTableComp
    },
    methods: {
        getTextInventory() {
            if (this.product.id == undefined) return "";
            if (this.product.quantity <= 5) return "Bajo";
            if (this.product.quantity <= 15) return "Medio";
            return "Alto";
        },
        getClassInventory() {
            if (this.product.id == undefined) return "";
            if (this.product.quantity <= 5) return "categories__disabled ml-1";
            if (this.product.quantity <= 15) return "categories__warning ml-1";
            return "categories__enabled ml-1";
        }
    }
}
</script>